import React from "react";
import { Button, Col, Drawer, Form, Row, Tabs, DatePicker } from "antd";
import "./changePassword.scss";
import { FormattedMessage } from "react-intl";
import { TextBox } from "../../general/TextBox";
import { showToasterMessage } from "../../../utils";
import { ErrorMsg } from "../../general/ErrorMessage";


const handleSubmit = (props) => {
  const { customerInfo } = props;
  if (!props.submittedOnce) {
      props.updateState({ submittedOnce: true });
  }
  if(props.newPassword !== props.confirmPassword){
      return showToasterMessage({
          messageType: "error",
          description:"Confirm password does not match"
      });
  }

  if (props.currentPassword && props.newPassword && props.confirmPassword && props.newPassword === props.confirmPassword) {
      let payload = {
          'password': props.currentPassword,
          'newPassword': props.newPassword,
          'relationshipId': (props.companyInfo || {}).relationshipId,
          'customerId': (customerInfo || {}).customerId,
          'email': ((customerInfo  || {}).boContactList[0] || {}).emailAddress || '',
          'isActive': 1,
          // 'dateCreated': '2020-03-13 16:02:59',
      }
      props.changePassword(payload, props);
  } else {
      props.updateState({
          invalidCurrentPasswordClass: props.currentPassword ? '' : 'input-text-error',
          invalidNewPasswordClass: props.newPassword ? '' : 'input-text-error',
          invalidConfirmPasswordClass: (props.confirmPassword && props.confirmPassword === props.newPassword) ? '' : 'input-text-error'
      });

  }
}

const ChangePassword = (props) => {
  let {
    currentPassword,
    newPassword,
    confirmPassword,
    intl,
    updateState,
    invalidNewPasswordClass,
    invalidConfirmPasswordClass,
    submittedOnce,
  } = props;

  return (
    
    <div className="change-password-container">
      <Row>
        <Col span="24">
          <div className="profile-pages-heading">
            <h1 className="page-heading">Change Password</h1>
          </div>
        </Col>
      </Row>

      <div className="page-content">
        <Form layout="vertical" hideRequiredMark className="pr30">
          <Row>
            <Col xs={24} xl={14}>
              <Form.Item>
                <Row>
                  <Col span={24}>
                   <label className="input-label"> <FormattedMessage
                      id="modal.settings.changePassword.currentPassword"
                      defaultMessage=""
                    />
                    <span className="required">*</span> </label>
                  </Col>
                  <Col xs={24} xl={14}>
                    <TextBox
                       className="custom-text-box"
                      placeholder={intl?.formatMessage({
                        id: "modal.settings.changePassword.currentPassword.placeholder",
                        defaultMessage: "",
                      })}
                      value={currentPassword}
                      type="password"
                      onChange={(e) => {
                        updateState({ currentPassword: e.target.value });
                      }}
                    />
                      <ErrorMsg
                       validator={() => { return   !(submittedOnce && invalidNewPasswordClass) ||!!currentPassword }}
                       message={<FormattedMessage id='modal.settings.changePassword.validation.currentPassword' defaultMessage='' />}
                       />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} xl={14}>
              <Form.Item>
                <Row>
                  <Col span={24}>
                  <label className="input-label"> <FormattedMessage
                      id="modal.settings.changePassword.newPassword"
                      defaultMessage=""
                    />
                    <span className="required">*</span></label>
                  </Col>
                  <Col xs={24} xl={14}>
                    <TextBox
                        className="custom-text-box"
                      placeholder={intl?.formatMessage({
                        id: "modal.settings.changePassword.newPassword.placeholder",
                        defaultMessage: "",
                      })}
                      value={newPassword}
                      type="password"
                      onChange={(e) => {
                        updateState({ newPassword: e.target.value });
                      }}
                    />
                
                       <ErrorMsg
                       validator={() => { return    !(submittedOnce && invalidNewPasswordClass) || !!newPassword }}
                       message={<FormattedMessage id='modal.settings.changePassword.validation.newPassword' defaultMessage='' />}
                       />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} xl={14}>
              <Form.Item>
                <Row>
                  <Col span={24}>
                   <label className="input-label"> <FormattedMessage
                      id="modal.settings.changePassword.confirmPassword"
                      defaultMessage=""
                    />
                    <span className="required">*</span></label>
                  </Col>
                  <Col xs={24} xl={14}>
                    <TextBox
                       className="custom-text-box"
                      placeholder={intl?.formatMessage({
                        id: "modal.settings.changePassword.confirmPassword.placeholder",
                        defaultMessage: "",
                      })}
                      value={confirmPassword}
                      type="password"
                      onChange={(e) => {
                        updateState({ confirmPassword: e.target.value });
                      }}
                      // className={invalidConfirmPasswordClass}
                    />
                       <ErrorMsg
                       validator={() => { return   !(submittedOnce && invalidConfirmPasswordClass) || !!confirmPassword }}
                       message={<FormattedMessage id='modal.settings.changePassword.validation.confirmPassword' defaultMessage='' />}
                       />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col span="24">
            <div className="footer-button-group">

            <Button
                 className="cutom-button-white"
                  onClick={() => {
                   {}
                  }}
                  type="default"
                 >
                  <FormattedMessage id="Cancel" defaultMessage="" />
                </Button>

              <Button
                  className="cutom-button"
                  type="primary"
                  onClick={() => {
                    handleSubmit(props);
                  }}
                >
                  <FormattedMessage
                    id="confirmation.submit"
                    defaultMessage=""
                  />
                </Button>

                </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChangePassword;
