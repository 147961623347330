import { SCHOOL_ACTIONS } from '../static/constants';
const initialState = {
  feeList: {
    1: []
  },
  feeCount: 0,
  studentFormList: [],
  registrationFormSetting: {},
  registrationList: [], 
  communicationList: {
    1: []
  },
  communicationCount: 0,
  assignmentCount: 0,
  assignmentList: {
    1: []
  },
  classStudentList: [],
  studentAttendanceList: [],
  studentAttendance: {},
  schoolCourseList: [],
  classSectionList: [],
  timeTableList: [],
  invoiceProformaList: [],
}

const SchoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCHOOL_ACTIONS.FEE_LIST:
      return {
        ...state,
        feeList: {
          ...state.feeList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SCHOOL_ACTIONS.FEE_COUNT:
      return { ...state, studentRegistrationCount: action.data };
    case SCHOOL_ACTIONS.FEE_LIST_PAGE_RESET:
      return { ...state, feeList: initialState.feeList };
    case SCHOOL_ACTIONS.STUDENT_FORM_LIST:
      return { ...state, studentFormList: action.data };
    case SCHOOL_ACTIONS.REGISTRATION_FORM_SETTING:
      return { ...state, registrationFormSetting: action.data };
    case SCHOOL_ACTIONS.RESET_REGISTRATION_FORM_SETTING:
      return { ...state, registrationFormSetting: initialState.registrationFormSetting };
    case SCHOOL_ACTIONS.REGISTRATION_List:
      return { ...state, registrationList: action.data };  
    case SCHOOL_ACTIONS.COMMUNICATION_LIST:
      return {
        ...state,
        communicationList: {
          ...state.communicationList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SCHOOL_ACTIONS.COMMUNICATION_COUNT:
      return { ...state, communicationCount: action.data };
    case SCHOOL_ACTIONS.COMMUNICATION_LIST_PAGE_RESET:
      return { ...state, communicationList: initialState.communicationList }; 

    case SCHOOL_ACTIONS.ASSIGNMENT_LIST:
      return {
        ...state,
        assignmentList: {
          ...state.assignmentList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SCHOOL_ACTIONS.ASSIGNMENT_COUNT:
      return { ...state, assignmentCount: action.data };
    case SCHOOL_ACTIONS.ASSIGNMENT_LIST_PAGE_RESET:
      return { ...state, assignmentList: initialState.assignmentList };

    case SCHOOL_ACTIONS.CLASS_STUDENT_LIST:
      return { ...state, classStudentList: action.data };  

    case SCHOOL_ACTIONS.SCHOOL_ATTENDENCE_BY_CLASS_ID:
      return { ...state, studentAttendanceList: action.data };
    case SCHOOL_ACTIONS.CLASS_LIST:
      return { ...state, classList: action.data };
    case SCHOOL_ACTIONS.SCHOOL_ATTENDENCE_BY_STUDENT_ID:
      return { ...state, studentAttendance: action.data };
    case SCHOOL_ACTIONS.CUSTOMER_SCHOOL_COURSE:
      return { ...state, schoolCourseList: action.data };
    case SCHOOL_ACTIONS.CLASS_SECTION_LIST:
      return { ...state, classSectionList: action.res.data };
    case SCHOOL_ACTIONS.TIME_TABLE_LIST:
      return { ...state, timeTableList: action.data };
    case SCHOOL_ACTIONS.INVOICE_PROFORMA_LIST:
      return { ...state, invoiceProformaList: action.data };
    default:
      return state;
  }
};

export default SchoolReducer;