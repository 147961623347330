import React, { useState, Fragment } from "react";
import { Form } from '@ant-design/compatible';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CustomAgGrid } from "../general/CustomAgGrid";
import './index.scss';
import { Row, Col, Button, DatePicker, Select } from "antd";
import { TextBox } from "../general/TextBox";
//import { TextArea } from "../general/TextAreaComponent";
import { CustomButton } from '../general/CustomButton';
import { Dropdown } from '../general/Dropdown';
import { getCurrentDateForBackend, showToasterMessage, formatDateForBackend, getMomentDateForUI, getMonthsBetweenDates, getMomentDateForUIReadOnly} from '../../utils';
import { CONSTANTS, ROLE_TYPES } from '../../static/constants';
import find from "lodash.find";
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { Option } = Select;


const EditProfile = (props) => {
    const { intl, formSetting = {}, companyInfo } = props;
     let self = props;
     const [gridApi, updateGridApi] = useState({});
     const onGridReady = (params) => {
        updateGridApi(params.api);
        params.api.sizeColumnsToFit();
    }
    const getRowHeight = (params) => {
        return 45;
    }
    let gradeArr = [];

    // ((formSetting.gradeFees && Object.keys(formSetting.gradeFees).length) ? Object.keys(formSetting.gradeFees) : []).map((i) => {
    //     if (formSetting.isGradeDisplayed && formSetting.isGradeDisplayed[i]) {
    //         let d = {};
    //         d.gradeValidation = Number(i);
    //         d.gradefee = formSetting.gradeFees[d.gradeValidation];
    //         gradeArr.push(d);
    //     }
    //     return gradeArr;
    // });
    (props.subcourseAndFeeList || []).map((subcourse) => {
        if (subcourse && subcourse.grade && subcourse.subcourseFee) {
            let d = {};
            d.gradeValidation = subcourse.grade;
            d.gradefee = subcourse.subcourseFee;
            d.subcourseName = subcourse.subcourseName;
            d.subcourseId = subcourse.subcourseId;
            d.gId = subcourse.gId;
            gradeArr.push(d);
        }
        return gradeArr;
    });

    const appendCountryCode = (number) => {
        // if (props.companyInfo.countryCallingCode && number) {
        //     return '+' + props.companyInfo.countryCallingCode + number;
        // } else {
        //     return number;
        // }
        return number;
    }

    // const getSelectedPlan = (plan) => {
    //     if (!plan) {
    //         return {};
    //     } else {
    //         let startDate = new Date(plan.startDate);
    //         let endDate = new Date(plan.endDate);
    //         if (plan.period === 'monthly') {
    //             startDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
    //         } else if (plan.period === 'quarterly') {
    //             startDate = new Date(startDate.setMonth(startDate.getMonth() + 3));
    //         }
    //         plan.startDate = formatDateForBackend(startDate);
    //         plan.endDate = formatDateForBackend(endDate);
    //         return plan;
    //     }
    // }

    const save = (e) => {
        e.preventDefault();
        let boContactChildList = [];
        let isRequiredFiledMissing = false;
        let isRequiredQuestionMissing = false;
        let studentList = [];
        let isInvalidGrade = false;
        let isFNameMissing= false;
        let isLNameMissing= false;
        let isDOBMissing= false;
        let isAllergiesMissing= false;
        let isGenderMissing= false;
        
        gridApi && gridApi.forEachNode && gridApi.forEachNode((rowNode, index) => {
            let { data } = rowNode;
            if (data.invalidGrade) {
                isInvalidGrade = true
            }
            else if (data.firstName || data.lastName || data.dob || data.allergies || data.gender) {
                if (!data.firstName || !data.lastName || !data.dob || !data.allergies || !data.gender) {
                    isRequiredFiledMissing = true;
                    if (!data.firstName) { isFNameMissing = true; }
                    if (!data.lastName) { isLNameMissing = true; }
                    if (!data.dob) { isDOBMissing = true; }
                    if (!data.allergies) { isAllergiesMissing = true; }
                    if (!data.gender) { isGenderMissing = true; }
                } else {
                    studentList.push({
                        sNo: index,
                        studentId: data.studentId || 0,
                        schoolStudentId: data.schoolStudentId || 0,
                        relationshipId: props.companyInfo.relationshipId,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        gender: data.gender,
                        grade: data.grade,
                        allergies: data.allergies,
                        dob: data.dob ? formatDateForBackend(data.dob) : null,
                        courseFee: data.gradefee ? Number(data.gradefee).toFixed(2) : 0.0,
                        customerId: props.customerId || 0,
                        courseId: data.courseId,
                        contactChildId: data.contactChildId,
                        createdByEmpId: data.createdByEmpId,
                        contactId: props.contactId,
                        orgRegistrationId: props.orgRegistrationId || 0,
                        schoolStudentClassRefList: data.schoolStudentClassRefList || [],
                        subcourseName: data.subcourseName,
                        subcourseId: data.subcourseId,
                        studentStatus: data.studentId ? data.studentStatus: 'Pending',
                        studentStatusColor: data.studentId ? data.studentStatusColor: '#277bcf'
                    });
                    boContactChildList.push({
                        sNo: index,
                        contactChildId: data.contactChildId || 0,
                        studentId: data.studentId || 0,
                        schoolStudentId: data.schoolStudentId || 0,
                        relationshipId: props.companyInfo.relationshipId,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        gender: data.gender,
                        allergies: data.allergies,
                        dob: data.dob ? formatDateForBackend(data.dob) : null,
                        //courseId: props.courseId,
                        contactId: props.contactId, 
                        customerId: props.customerInfo.customerId,
                        // studentList: [{
                        //     sNo: index,
                        //     studentId: data.studentId || 0,
                        //     schoolStudentId: data.schoolStudentId || 0,
                        //     relationshipId: props.relationshipId,
                        //     orgRegistrationId: props.orgRegistrationId || 0,
                        //     customerId: props.customerId || 0,
                        //     contactChildId: data.contactChildId,
                        //     createdByEmpId: data.createdByEmpId,
                        //     contactId: props.contactId, 
                        //     courseId: props.courseId,
                        //     grade: data.grade,
                        //     courseFee: data.gradefee ? Number(data.gradefee).toFixed(2) : 0.0,
                        //     schoolStudentClassRefList:data.schoolStudentClassRefList || [],
                        //     subcourseName: data.subcourseName,
                        //     subcourseId: data.subcourseId,
                        //     studentStatus: data.studentId ? data.studentStatus: 'Pending',
                        //     studentStatusColor: data.studentId ? data.studentStatusColor: '#277bcf'
                        // }]
                    })
                }
            } 
        });
       if (isFNameMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.firstName.missing' })
            });
        }
        if (isLNameMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.lastName.missing' })
            });
        }
        if (isDOBMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.dob.missing' })
            });
        }
        if (isGenderMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.gender.missing' })
            });
        }
        if (isAllergiesMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.allergies.missing' })
            });
        }

        if (!props.streetAddress1 || !props.cityName || !props.stateName || !props.zipCode) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'address.missing.message' })
            });
        }
        if (props.registrationType === 'student' && !props.primaryContact) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'primaryContactForInvoicing.validation.message' })
            });
        }
        // if (props.isAllowParentPaymentPlan && !props.selectedPlan) {
        //     return showToasterMessage({
        //         messageType: 'error', description: props.intl.formatMessage({ id: 'registration.parentPaymentPlan.required' })
        //     });
        // }
        // if ((props.emergencyPhone && props.emergencyPhone.length > 0 && props.emergencyPhone.length < 10) || (props.familyDoctorPhone && props.familyDoctorPhone.length > 0 && props.familyDoctorPhone.length < 10)) {
        //     return showToasterMessage({
        //         messageType: 'error', description: props.intl.formatMessage({ id: 'phoneNumber.incorrect.message' })
        //     });;
        // }
        const modalData = {
            modalBody: <FormattedMessage id='save.confirmation' />,
            handleSubmit: () => {
                let contactList = [];
                let boLocationList = [];
                if (props.fatherFirstName) {
                    contactList.push({
                        firstName: props.fatherFirstName,
                        lastName: props.fatherLastName,
                        cellPhone: appendCountryCode(props.fatherMobile),
                        emailAddress: props.fatherEmail,
                        relationshipId: props.companyInfo.relationshipId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        gender: 'Male',
                        role: ROLE_TYPES.FATHER,
                        contactType: "Customer",
                        // motherFirstName: props.motherFirstName,
                        // motherLastName: props.motherLastName,
                        // motherMobile: appendCountryCode(props.motherMobile),
                        // motherEmail: props.motherEmail,
                        dateOfBirth: props.fatherDOB ? formatDateForBackend(props.fatherDOB) : null,
                       // motherDateOfBirth: props.motherDOB ? formatDateForBackend(props.motherDOB) : null,
                        isPrimaryContact: props.primaryContact === 'father' ? 1 : 0,
                        primaryContactForInvoicing: props.primaryContact,
                        boContactChildList: boContactChildList,
                        contactId: props.contactId,
                        version: props.contactVersion,
                        customerId: props.customerId,
                        countryCallingCode: props.companyInfo.countryCallingCode,
                    });
                }
                if (props.motherFirstName) {
                    contactList.push({
                        firstName: props.motherFirstName,
                        lastName: props.motherLastName,
                        cellPhone: appendCountryCode(props.motherMobile),
                        emailAddress: props.motherEmail,
                        relationshipId: props.companyInfo.relationshipId,
                        dateCreated: getCurrentDateForBackend(new Date()),
                        gender: 'Female',
                        role: ROLE_TYPES.MOTHER,
                        contactType: "Customer",
                        isPrimaryContact: props.primaryContact === 'mother' ? 1 : 0,
                        contactId: props.motherContactId,
                        version: props.motherContactVersion,
                        customerId: props.customerId,
                        countryCallingCode: props.companyInfo.countryCallingCode,
                    });
                }

                if (props.firstName) {
                    contactList.push({
                        firstName: props.firstName,
                        lastName: props.lastName,
                        cellPhone: appendCountryCode(props.phoneNumber),
                        emailAddress: props.email,
                        relationshipId: props.relationshipId,
                        dateCreated: getCurrentDateForBackend(new Date()),
                        gender: props.gender,
                        contactType: "Customer",
                    });
                }

                if (props.spouseFirstName) {
                    contactList.push({
                        firstName: props.spouseFirstName,
                        lastName: props.spouseLastName,
                        cellPhone: appendCountryCode(props.spouseMobile),
                        emailAddress: props.spouseEmail,
                        relationshipId: props.relationshipId,
                        dateCreated: getCurrentDateForBackend(new Date()),
                        gender: 'Female',
                        contactType: "Customer",
                    });
                }

                if (props.streetAddress1 || props.cityName || props.stateName || props.zipCode) {
                    boLocationList.push({
                        
                    customerId: props.customerInfo.customerId,
                        streetAddress1: props.streetAddress1,
                        streetAddress2: props.streetAddress2,
                        cityName: props.cityName,
                        countryId: props.countryId,
                        countryName: props.countryName,
                        stateName: props.stateName,
                        zipCode: props.zipCode,
                        locationType: CONSTANTS.BILLING_ADDRESS,
                        relationshipId: props.companyInfo.relationshipId,
                        isDefault: 1,
                        locationId: props.locationId,
                        version: props.locationVersion,
                    })
                }

                // let registrationData = {
                //     customerId: props.customerId || 0,
                //     contactId: props.contactId,
                //     orgRegistrationId: props.orgRegistrationId || 0,
                //     registrationFormSettingId: props.registrationFormSettingId,
                //     courseId: props.courseId,
                //     relationshipId: props.relationshipId,
                //     fatherFirstName: props.fatherFirstName,
                //     fatherLastName: props.fatherLastName,
                //     fatherEmail: props.fatherEmail,
                //     fatherMobile: appendCountryCode(props.fatherMobile),

                //     motherFirstName: props.motherFirstName,
                //     motherLastName: props.motherLastName,
                //     motherEmail: props.motherEmail,
                //     motherMobile: appendCountryCode(props.motherMobile),

                //     emergencyContactName: props.emergencyContactName,
                //     emergencyPhone: appendCountryCode(props.emergencyPhone),
                //     //studentList: studentList,
                //     amount: feeAmt,
                //     questionList: JSON.stringify(props.formSetting.questionList),
                //     //isOrgMember: 1,
                //     registrationType: props.formSetting.registrationType,

                //     spouseFirstName: props.spouseFirstName,
                //     spouseLastName: props.spouseLastName,
                //     spouseEmail: props.spouseEmail,
                //     spouseMobile: appendCountryCode(props.spouseMobile),
                //     spouseDOB: props.spouseDOB,

                //     familyDoctorName: props.familyDoctorName,
                //     familyDoctorPhNumber: appendCountryCode(props.familyDoctorPhone),

                //     nationality: props.nationality,
                //     membershipType: props.membershipType,

                //     firstName: props.firstName,
                //     lastName: props.lastName,
                //     phoneNumber: appendCountryCode(props.phoneNumber),
                //     gender: props.gender,
                //     dob: props.dob,

                //     maritalStatus: props.maritalStatus,
                //     registrationType: props.formSetting.registrationType,
                //     selectedPlan: props.isAllowParentPaymentPlan ? getSelectedPlan(props.selectedPlan) : {},
                //     isInvoiced: props.isInvoiced || 0
                // }
                // if (registrationData && registrationData.amount) {
                //     registrationData.systemStatus = TX_STATUS.UNPAID;
                //     registrationData.isEnrollmentPaid = 1;
                // }
                let companyName = props.firstName + (props.lastName ? ' ' + props.lastName : '');
                if (props.primaryContact === 'mother') { companyName = props.motherFirstName + (props.motherLastName ? ' ' + props.motherLastName : '') };
                if (props.primaryContact === 'father') { companyName = props.fatherFirstName + (props.fatherLastName ? ' ' + props.fatherLastName : '') };

                let customerPayload = {
                    customerId: props.customerInfo.customerId,
                    clientOnlineUserAccountId: props.customerInfo.clientOnlineUserAccountId,
                    relationshipId: props.companyInfo.relationshipId,
                    companyName: companyName,
                    boContactList: contactList,
                    boLocationList: boLocationList, 
                    
                    fatherFirstName: props.fatherFirstName,
                    fatherLastName: props.fatherLastName,
                    fatherMobile: appendCountryCode(props.fatherMobile),
                    fatherEmail: props.fatherEmail,
                    fatherDateOfBirth: props.fatherDOB? formatDateForBackend(props.fatherDOB) : '',

                    motherFirstName: props.motherFirstName,
                    motherLastName: props.motherLastName,
                    motherMobile: appendCountryCode(props.motherMobile),
                    motherEmail: props.motherEmail,
                    motherDateOfBirth: props.motherDOB? formatDateForBackend(props.motherDOB) : '',
                    
                    boContactChildList : boContactChildList, 
                    countryCallingCode: props.companyInfo.countryCallingCode,
                    formId: props.defaultSchoolForm || 0,
                    primaryContact: props.primaryContact,
                }

                props.saveRegistration(customerPayload, props)
            }
        };
        props.pushModalToStack(modalData);
    }

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='signup.firstname.placeholder' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
            },
            field: "firstName",
            colId: 'firstName_1',
            width: 130,
            resizable: true,
            editable: true,
            cellEditor: 'customTextEditor',
            suppressMovable: true,
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
        },
        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='signup.lastname.placeholder' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
            },
            field: "lastName",
            resizable: true,
            editable: true,
            width: 130,
            cellEditor: 'customTextEditor',
            suppressMovable: true,
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
        },
        // {
        //     headerComponentFramework: () => {
        //         return <span><FormattedMessage id='grade.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
        //     },
        //     field: "gId",
        //     resizable: true,
        //     editable: true,
        //     suppressMovable: true,
        //     width: 150,
        //     suppressKeyboardEvent: (params) => {
        //         const KEY_ENTER = 13;
        //         const keyCode = params.event.keyCode;
        //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
        //         return gridShouldDoNothing;
        //     },
        //     cellEditor: 'customDropDownEditor',
        //     cellEditorParams: (obj) => {
        //         return {
        //             lastColumnCell: false,
        //             items: gradeArr,
        //             optionKeyName: 'gId',
        //             valueKeyName: 'gradeValidation',
        //             isGradeLevel: true,
        //             isDisplayYearlyFee: formSetting.isDisplayYearlyFee ? true: false,
        //             totalMonths: getMonthsBetweenDates(formSetting.sessionStart, formSetting.sessionEnd) || 1,
        //             valueKeyGradeFee: 'gradefee',
        //             addressLineKey1: 'subcourseName',
        //             currencyCode: companyInfo.currencyCode || '',
        //             onSelect: (gId, optionObj) => {
        //                 const selectedObj = find(gradeArr, { gId: optionObj.key  });
        //                 let selectedValue = selectedObj.gradeValidation;
                        
        //                 // if (obj.node.data.grade) {
        //                 //     let totalStudentCountByGrade = props.totalStudentCountByGrade || {};
        //                 //     totalStudentCountByGrade[obj.node.data.grade] = Number(totalStudentCountByGrade[obj.node.data.grade]) - 1;
        //                 //     props.updateState({
        //                 //         totalStudentCountByGrade
        //                 //     });
        //                 // }
        //                 obj.node.data.gradefee = null;
        //                 obj.node.setDataValue(obj.colDef.field, null);
                        
        //                 if (selectedObj) {
        //                     obj.node.data.gradefee = selectedObj.gradefee;
        //                     obj.node.data.subcourseName = selectedObj.subcourseName;
        //                     obj.node.data.subcourseId = selectedObj.subcourseId;
        //                     obj.node.data.gId = selectedObj.gId;
        //                     obj.node.data.grade = selectedValue;
        //                     obj.node.data.invalidGrade = formSetting.gradeLevelValidation ? true: false;
        //                    // obj.node.setDataValue(obj.colDef.field, selectedValue);
        //                     obj.node.setDataValue("gradefee", selectedObj.gradefee);
        //                    // obj.node.setDataValue("subcourseName", selectedObj.subcourseName);
        //                     //obj.node.setDataValue("subcourseId", selectedObj.subcourseId);
        //                 }

        //                 if (formSetting.gradeLevelValidation) {
        //                     let isInvalid = false;
        //                     if(selectedValue && (Number((formSetting.gradeValidations || {})[selectedValue] || 0)) <= (Number((self.totalStudentCountByGrade || {})[selectedValue] || 0)) ){
        //                         isInvalid = true;
        //                     }
        //                     // if ((selectedValue === 1 || selectedValue === 2) && (Number((formSetting.gradeValidations || {})[1] || 0) + Number((formSetting.gradeValidations || {})[2] || 0)) <= (Number((props.totalStudentCountByGrade || {})[1] || 0) + Number((props.totalStudentCountByGrade || {})[2] || 0))) {
        //                     //     isInvalid = true;
        //                     // } else if ((selectedValue === 3 || selectedValue === 4 || selectedValue === 5) && (Number((formSetting.gradeValidations || {})[3] || 0) + Number((formSetting.gradeValidations || {})[4] || 0) + Number((formSetting.gradeValidations || {})[5] || 0)) <= (Number((props.totalStudentCountByGrade || {})[3] || 0) + Number((props.totalStudentCountByGrade || {})[4] || 0) + Number((props.totalStudentCountByGrade || {})[5] || 0))) {
        //                     //     isInvalid = true;
        //                     // } else if ((selectedValue === 6 || selectedValue === 7 || selectedValue === 8) && (Number((formSetting.gradeValidations || {})[6] || 0) + Number((formSetting.gradeValidations || {})[7] || 0) + Number((formSetting.gradeValidations || {})[8] || 0)) <= (Number((props.totalStudentCountByGrade || {})[6] || 0) + Number((props.totalStudentCountByGrade || {})[7] || 0) + Number((props.totalStudentCountByGrade || {})[8] || 0))) {
        //                     //     isInvalid = true;

        //                     // } else if ((selectedValue === 9 || selectedValue === 10 || selectedValue === 11 || selectedValue === 12) && (Number((formSetting.gradeValidations || {})[9] || 0) + Number((formSetting.gradeValidations || {})[10] || 0) + Number((formSetting.gradeValidations || {})[11] || 0) + Number((formSetting.gradeValidations || {})[12] || 0)) <= (Number((props.totalStudentCountByGrade || {})[9] || 0) + Number((props.totalStudentCountByGrade || {})[10] || 0) + Number((props.totalStudentCountByGrade || {})[11] || 0) + Number((props.totalStudentCountByGrade || {})[12] || 0))) {
        //                     //     isInvalid = true;

        //                     // }
        //                     if (isInvalid && formSetting.invalidGradeMessage) {
        //                         //showToasterMessage({   messageType: 'warning', description: props.intl.formatMessage({ id: 'no.more.seat.available.inGrade.message', values: { value: selectedValue } })});
        //                         showToasterMessage({   messageType: formSetting.isStopRegistrationAfterMaxStudent? 'error': 'warning', description: formSetting.invalidGradeMessage });

        //                     } else {
        //                         obj.node.data.invalidGrade = false;
        //                         obj.node.data.gradefee = selectedObj.gradefee;
        //                         obj.node.data.subcourseName = selectedObj.subcourseName;
        //                         obj.node.data.subcourseId = selectedObj.subcourseId;
        //                         obj.node.data.gId = selectedObj.gId;
        //                        // obj.node.setDataValue(obj.colDef.field, selectedValue);
        //                         obj.node.setDataValue("gradefee", selectedObj.gradefee);
        //                       //  obj.node.setDataValue("subcourseName", selectedObj.subcourseName);
        //                        // obj.node.setDataValue("subcourseId", selectedObj.subcourseId);
        //                         obj.node.data.grade = selectedValue;
        //                         let totalStudentCountByGrade = self.totalStudentCountByGrade || {};
        //                         totalStudentCountByGrade[selectedValue] = Number(totalStudentCountByGrade[selectedValue]||0) + 1;
        //                         props.updateState({
        //                             totalStudentCountByGrade
        //                         });

        //                     }

        //                 }
        //             },
                   
        //         }
        //     },
        //     cellRendererFramework: (params) => {
        //         return <div>{params.data.grade ? <div>
        //             <div>Grade {params.data.grade}</div>
        //             <div>{params.data.subcourseName}</div>
        //         </div> : ''}</div>
        //     },
        //     // valueGetter: (obj) =>{
        //     //     if (formSetting.gradeLevelValidation && Number((formSetting.gradeValidations || {})[obj.data.grade] || 0) <= (props.totalStudentCountByGrade || {})[obj.data.grade]) {
        //     //         return ""
        //     //     }
        //     //     let totalfee = 0;
        //     //     obj.api.forEachNode((rowNode, index) => {
        //     //         const { data } = rowNode;
        //     //         if (data.grade) {
        //     //             totalfee += Number((formSetting.gradeFees || {})[data.grade] || 0);
        //     //         }
        //     //       });
        //     //       props.updateState({ totalfee })
        //     //     return obj.data.grade;
        //     // }
        // },
        // {
        //     headerComponentFramework: () => {
        //         return formSetting.isDisplayYearlyFee ? <span><FormattedMessage id='gradefeeYearly.header.text' /></span> : <span><FormattedMessage id='gradefee.header.text' /></span>;
        //     },
        //     width: 80,
        //    // hide: !(Object.keys(formSetting.gradeFees || {})).length,
        //     field: "gradefee",
        //     resizable: true,
        //     cellRendererFramework: (params) => {
        //         return <span>{formSetting.isDisplayYearlyFee ? (params.data.gradefee ? (Number(params.data.gradefee)*getMonthsBetweenDates(formSetting.sessionStart, formSetting.sessionEnd)).toFixed(2) : 0.00) : Number(params.data.gradefee||0).toFixed(2)}</span>
        //     },
        // },
        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='dob.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
            },
            field: "dob",
            resizable: true,
            editable: true,
            width: 130,
            cellEditor: 'customDatePicker',
            cellEditorParams: (obj) => {
                return { 
                    disabledDate: (date) => (date && date.valueOf() > Date.now()), 
                    onChange: (selectedDate) => {
                        obj.node.setDataValue(obj.colDef.field, selectedDate);
                      }
                }
            },
            cellRendererFramework: (params) => {
                return <span>{params.data.dob ? getMomentDateForUIReadOnly({ date: params.data.dob, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</span>
            },
        },

        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='allergies.header.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
            },
            field: "allergies",
            resizable: true,
            editable: true,
            cellEditor: 'customTextEditor',
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
            // cellEditorParams: (params) => {
            //     return {
            //         value: params.node.data.allergies || "",
            //         isEditorLoaded: props.isEditorLoaded,
            //         onChange: (value) => {
            //             params.node.data[params.colDef.field] = value;
            //             params.node.data.isEditorLoaded = true;
            //             props.updateState({
            //                 isEditorLoaded: true
            //             })
            //         }
            //     }
            // },
            cellRendererFramework: (params) => {
                return ReactHtmlParser(params.data.allergies)
            },
            // suppressKeyboardEvent: (params) => {
            //     const KEY_ENTER = 13;
            //     const keyCode = params.event.keyCode;
            //     const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
            //     params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
            //     if (params.event.target.scrollHeight > 30) {
            //         if (params.event.path[2]) {
            //             params.event.path[2].style.height = 'inherit';
            //             params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            //         }
            //         params.node.setRowHeight(params.event.target.scrollHeight);
            //         params.api && params.api.onRowHeightChanged();
            //     } else {
            //         if (params.event.path[2]) {
            //             params.event.path[2].style.height = 'inherit';
            //             params.event.path[2].style.height = `${30}px`;
            //         }
            //         params.node.setRowHeight(30);
            //         params.api && params.api.onRowHeightChanged();
            //     }
            //     return gridShouldDoNothing;
            // }
        },
        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='gender.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
            },
            field: "gender",
            resizable: true,
            editable: true,
            width: 70,
            suppressMovable: true,
            cellEditor: 'customDropDownEditor',
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
            cellEditorParams: (obj) => {
                return {
                    lastColumnCell: false,
                    //items: ['Male', 'Female', 'Rather Not Say'],
                    items: ['Male', 'Female'],
                    onSelect: (selectedValue) => {
                        obj.node.setDataValue(obj.colDef.field, selectedValue);
                    },
                    allowClear: true,
                    onClear: () => {
                        obj.node.setDataValue(obj.colDef.field, undefined);
                    },
                }
            }
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='customer.listing.header.action' />;
            },
            width: 70,
            field: "action",
            resizable: true,
            cellRenderer: 'customActionEditor',
            suppressNavigable: true,
            cellRendererParams: (params) => {
                return {
                    onClick: () => {
                        let totalStudentCountByGrade = self.totalStudentCountByGrade || {};
                        if (!params.data.invalidGrade) {
                            totalStudentCountByGrade[params.data.grade] = Number(totalStudentCountByGrade[params.data.grade]) - 1;
                            props.updateState({
                                totalStudentCountByGrade
                            });
                        }

                        params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                        if (params.api.rowRenderer.lastRenderedRow < 0) {
                            params.api.updateRowData({ add: [{}] });
                        }
                    },
                }
            }

        }
    ]
   
    return (
        <div style={{ padding: '0px 0px 200px' }} >

            <div className='main-container main-box'>
            {/* <div className="modal-header align-left titr"><div style={{textAlign:'center'}}>Edit Profile</div> 
                </div>*/}
                <Form layout="vertical" onSubmit={save} >

                    {/* <Row gutter={22}>

                        <Col span={12}>
                            <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='membershipType.text' /> {formSetting.membershipTypeRequired ? <span className="required">*</span> : ''} </span>}>
                                <Dropdown
                                    items={['New', 'Renew']}
                                    required={formSetting.membershipTypeRequired}
                                    placeholder={props.intl.formatMessage({ id: 'gender.text' })}
                                    onSelect={(selectedValue) => {
                                        props.updateState({ membershipType: selectedValue });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row gutter={16}>
                        <Col span={12} md={12} xs={24}>
                            <Form.Item className='label-emergency new-fed ' label={<span className="text-right"><FormattedMessage id='register.fatherFirstName' />{formSetting.fatherFirstNameRequired ? <span className="required">*</span> : ''}</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}>
                                <TextBox className='new-fed1'
                                    placeholder={intl.formatMessage(
                                        { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                    )}
                                    type='text'
                                    maxLength={25}
                                    value={props.fatherFirstName}
                                    onChange={(e) => {
                                        props.updateState({ fatherFirstName: e.target.value });
                                    }}
                                    required={formSetting.fatherFirstNameRequired}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24}>
                            <Form.Item className='label-emergency new-fed' label={<span className="text-right"><FormattedMessage id='mother.fName' /> {formSetting.motherFirstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                <TextBox className='new-fed1'
                                    placeholder={intl.formatMessage(
                                        { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                    )}
                                    type='text'
                                    maxLength={25}
                                    value={props.motherFirstName}
                                    onChange={(e) => {
                                        props.updateState({ motherFirstName: e.target.value });
                                    }}
                                    required={formSetting.motherFirstNameRequired}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                    <Col span={12} md={12} xs={24}>
                            <Form.Item className='label-emergency new-fed' label={<span className="text-right"><FormattedMessage id='register.fatherLastName' /> {formSetting.fatherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                <TextBox className='new-fed1'
                                    placeholder={intl.formatMessage(
                                        { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                    )}
                                    type='text'
                                    required={formSetting.fatherLastNameRequired}
                                    maxLength={25}
                                    value={props.fatherLastName}
                                    onChange={(e) => {
                                        props.updateState({ fatherLastName: e.target.value });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24}>
                            <Form.Item className='label-emergency new-fed' label={<span className="text-right"><FormattedMessage id='mother.lName' /> {formSetting.motherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                <TextBox className='new-fed1'
                                    placeholder={intl.formatMessage(
                                        { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                    )}
                                    type='text'
                                    maxLength={25}
                                    value={props.motherLastName}
                                    onChange={(e) => {
                                        props.updateState({ motherLastName: e.target.value });
                                    }}
                                    required={formSetting.motherLastNameRequired}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                    <Col span={12} md={12} xs={24}>
                            <Form.Item className='label-emergency  new-fed' label={<span className="text-right"><FormattedMessage id='father.email' /> (Portal Login Email) {formSetting.fatherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                <TextBox className='new-fed1'
                                    placeholder='Enter email'
                                    type='email'
                                    maxLength={45}
                                    value={props.fatherEmail}
                                    onChange={(e) => {
                                        props.updateState({ fatherEmail: e.target.value });
                                    }}
                                    onBlur={() => {
                                        if (!props.customerId) {
                                            props.fetchStudentFatherEmail({ email: props.fatherEmail, type: 'fatherEmail', registrationFormSettingId: props.registrationFormSettingId }, props)
                                        }
                                    }}
                                    required={formSetting.fatherEmailRequired}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24}>
                            <Form.Item className='label-emergency new-fed' label={<span className="text-right"><FormattedMessage id='mother.email' /> (Portal Login Email) {formSetting.motherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                <TextBox className='new-fed1'
                                    placeholder='Enter email'
                                    type='email'
                                    maxLength={45}
                                    value={props.motherEmail}
                                    onChange={(e) => {
                                        props.updateState({ motherEmail: e.target.value });
                                    }}
                                    onBlur={() => {
                                        if (!props.customerId) {
                                            props.fetchStudentFatherEmail({ email: props.motherEmail, type: 'motherEmail', registrationFormSettingId: props.registrationFormSettingId }, props)
                                        }

                                    }}
                                    required={formSetting.motherEmailRequired}
                                />
                            </Form.Item>
                        </Col>
                        
                    </Row>


                    <Row gutter={16}>
                    <Col span={12} md={12} xs={24} className="newregis">
                            <Form.Item className='label-emergency new-fed input-currency-control' label={<span className="text-right"><FormattedMessage id='father.mobile' /> {formSetting.fatherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                <span className="currency-tag" >
                                    {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                </span>
                                <TextBox className='new-fed1'
                                    placeholder="Enter mobile number"
                                    style={{ paddingLeft: '45px' }}
                                    type='text'
                                    //value={contact.lastName}
                                    required={formSetting.fatherMobileRequired}
                                    maxLength={10}
                                    value={props.fatherMobile}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            props.updateState({
                                                fatherMobile: e.target.value,
                                            });
                                        } else if (!props.fatherMobile) {
                                            props.updateState({ fatherMobile: '' });
                                        }
                                        //props.updateState({ fatherMobile: e.target.value });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} className="newregis">
                            <Form.Item className='label-emergency new-fed input-currency-control' label={<span className="text-right"><FormattedMessage id='mother.mobile' /> {formSetting.motherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                <span className="currency-tag" >
                                    {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                </span>
                                <TextBox className='new-fed1'
                                    placeholder="Enter mobile number"
                                    style={{ paddingLeft: '45px' }}
                                    type='text'
                                    maxLength={10}
                                    value={props.motherMobile}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            props.updateState({
                                                motherMobile: e.target.value,
                                            });
                                        } else if (!props.motherMobile) {
                                            props.updateState({ motherMobile: '' });
                                        }
                                        // props.updateState({ motherMobile: e.target.value });
                                    }}
                                    required={formSetting.motherMobileRequired}
                                />
                            </Form.Item>
                        </Col>
                         </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='primaryContactForInvoicing.text' /><span className="required">*</span> </span>}>
                                <Dropdown
                                    items={[{ name: 'Father', id: 'father' }, { name: 'Mother', id: 'mother' }]}
                                    placeholder={props.intl.formatMessage({ id: 'primaryContactForInvoicing.text' })}
                                    valueKeyName='name'
                                    optionKeyName='id'
                                    value={props.primaryContact}
                                    onSelect={
                                        (optionValue) => {
                                            props.updateState({ primaryContact: optionValue });
                                        }
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>

                        <Col span={12} md={12} xs={24}>
                            <Form.Item className='label-emergency  new-fed' label={<span className="text-right"><FormattedMessage id='fatherDOB.text' /> {formSetting.fatherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                <DatePicker
                                    allowClear={true}
                                    format={format}
                                    key='fatherDOB'
                                    defaultValue={props.fatherDOB ? getMomentDateForUI({ date: new Date(props.fatherDOB), format }) : null}
                                    value={props.fatherDOB ? getMomentDateForUI({ date: new Date(props.fatherDOB), format }) : null}
                                    onChange={(selectedDate) => {
                                        props.updateState({
                                            fatherDOB: selectedDate
                                        })
                                    }}
                                    size={0}
                                    disabledDate= {(current) =>{
                                        return current && current > moment().endOf('day');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24}>
                            <Form.Item className='label-emergency new-fed' label={<span className="text-right"><FormattedMessage id='motherDOB.text' /> {formSetting.motherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                <DatePicker
                                    allowClear={true}
                                    format={format}
                                    key='motherDOB'
                                    defaultValue={props.motherDOB ? getMomentDateForUI({ date: new Date(props.motherDOB), format }) : null}
                                    value={props.motherDOB ? getMomentDateForUI({ date: new Date(props.motherDOB), format }) : null}
                                    onChange={(selectedDate) => {
                                        props.updateState({
                                            motherDOB: selectedDate
                                        })
                                    }}
                                    size={0}
                                    disabledDate= {(current) =>{
                                        return current && current > moment().endOf('day');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={16}>
                                {formSetting.familyDoctorNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='familyDoctorName.text' /> {formSetting.familyDoctorNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Family Doctor Name"
                                                type='text'
                                                maxLength={45}
                                                value={props.familyDoctorName}
                                                onChange={(e) => {
                                                    props.updateState({ familyDoctorName: e.target.value });
                                                }}
                                                required={formSetting.familyDoctorNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.familyDoctorPhoneDisplay ?
                                    <Col span={12} className="newregis">
                                        <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='familyDoctorPhone.text' /> {formSetting.familyDoctorPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                        <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                            <TextBox className='new-fed1'
                                            style={{ paddingLeft: '45px' }}
                                                placeholder='Family Doctor Number'
                                                type='text'
                                                maxLength={10}
                                                value={props.familyDoctorPhone}
                                                onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        props.updateState({
                                                            familyDoctorPhone: e.target.value,
                                                        });
                                                    }
                                                    else if(!props.familyDoctorPhone){
                                                        props.updateState({ familyDoctorPhone: '' });
                                                    }
                                                }}
                                                required={formSetting.familyDoctorPhoneRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                            {formSetting.emergencyContactDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='setting.student.registration.form.emergencyContactName' /> {formSetting.emergencyContactRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter Name"
                                            type='text'
                                            maxLength={45}
                                            value={props.emergencyContactName}
                                            onChange={(e) => {
                                                props.updateState({ emergencyContactName: e.target.value });
                                            }}
                                            required={formSetting.emergencyContactRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.emergencyPhoneDisplay ?
                                <Col span={12} className="newregis">
                                    <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='setting.student.registration.form.emergencyPhone' /> {formSetting.emergencyPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                    <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                        <TextBox className='new-fed1'
                                        style={{ paddingLeft: '45px' }}
                                            placeholder='Enter Number'
                                            type='text'
                                            maxLength={10}
                                            value={props.emergencyPhone}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        emergencyPhone: e.target.value,
                                                    });
                                                }
                                                else if(!props.emergencyPhone){
                                                    props.updateState({ emergencyPhone: '' });
                                                }
                                            }}
                                            required={formSetting.emergencyPhoneRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row> */}

                    <Row gutter={16}>
                        <Col span={12} md={12} xs={24}>
                            <Form.Item className='label-emergency new-fed' label={<span className="text-right"><FormattedMessage id='signup.tab.address' /> <span className="required">*</span> </span>}>
                                <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                    <Col span={12} md={12} xs={24}>
                                        <TextBox
                                            placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetName.placeholder' })}
                                            type='text'
                                            maxLength={45}
                                            value={props.streetAddress1}
                                            onChange={(e) => {
                                                props.updateState({ streetAddress1: e.target.value });
                                            }}
                                            required={true}
                                        />
                                    </Col>
                                    <Col span={12} md={12} xs={24}>
                                        <TextBox
                                            placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetContd.placeholder' })}
                                            type='text'
                                            maxLength={45}
                                            value={props.streetAddress2}
                                            onChange={(e) => {
                                                props.updateState({ streetAddress2: e.target.value });
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                    <Col span={12} md={12} xs={24}>
                                        <TextBox
                                            placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.city.placeholder' })}
                                            type='text'
                                            maxLength={45}
                                            value={props.cityName}
                                            onChange={(e) => {
                                                props.updateState({ cityName: e.target.value });
                                            }}
                                            required={true}
                                        />
                                    </Col>
                                    <Col span={12} md={12} xs={24}>
                                        <Dropdown
                                            items={props.countries || []}
                                            optionKeyName='countryId'
                                            placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.country.placeholder' })}
                                            valueKeyName='countryName'
                                            value={props.countryName}
                                            onSelect={(selectedValue, option) => {
                                                props.updateState({ countryId: selectedValue, countryName: option.name });
                                                props.fetchStates(selectedValue)
                                            }}
                                            required={true}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                    <Col span={12} md={12} xs={24}>
                                        {(props.countryName === 'India' || props.countryName === 'United States' || props.countryName === 'United States (USA)') ?
                                            <Dropdown
                                                items={props.states || []}
                                                optionKeyName='stateId'
                                                placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                valueKeyName='stateName'
                                                value={props.stateName}
                                                onSelect={(selectedValue, option) => {
                                                    props.updateState({ stateName: option.name });
                                                }}
                                                required={true}
                                            /> : <TextBox
                                                placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                type='text'
                                                maxLength={45}
                                                value={props.stateName}
                                                onChange={(e) => {
                                                    props.updateState({ stateName: e.target.value });
                                                }}
                                                required={true}
                                            />}
                                    </Col>
                                    <Col span={12} md={12} xs={24}>
                                        <TextBox
                                            placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.zipCode.placeholder' })}
                                            type='text'
                                            maxLength={15}
                                            value={props.zipCode}
                                            onChange={(e) => {
                                                props.updateState({ zipCode: e.target.value });
                                            }}
                                            required={true}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Student info start */}
                    <div className="maibot">
                        <b className='label-emergency'><FormattedMessage id='studentDetail.text' /></b>
                        <Row gutter={16}>

                            <Col span={24} xs={24}>
                                <div className='grid-container mb-res'>
                                     <CustomAgGrid
                                        columnDefs={columnDefs}
                                        rowData={(props.studentList || [])}
                                        //editType='fullRow'
                                        domLayout={"autoHeight"}
                                        getRowHeight={getRowHeight}
                                        animateRows={true}
                                        onGridSizeChanged={(params) => {
                                            params.api.sizeColumnsToFit();

                                        }}
                                        defaultColDef={{
                                            flex: 1,
                                            autoHeight: true,
                                            wrapText: true,
                                        }}

                                        onGridReady={onGridReady}
                                    >
                                    </CustomAgGrid>
                                </div>
                            </Col>
                            <Col>
                                <Button className="grey-button ant-btn ant-btn-default" onClick={
                                    () => {
                                        let newRowIndex = -1;
                                        gridApi && gridApi.forEachNode((rowNode, index) => {
                                            newRowIndex = index;
                                        });
                                        if (formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount + newRowIndex + 1)) {
                                            return showToasterMessage({
                                                messageType: 'error', description: props.intl.formatMessage({ id: 'no.more.seat.available.message' })
                                            });
                                        }

                                        gridApi && gridApi.stopEditing();
                                        gridApi && gridApi.applyTransaction({ add: [{}] });
                                        gridApi && gridApi.startEditingCell({
                                            rowIndex: newRowIndex + 1,
                                            colKey: "firstName_1"
                                        });
                                        gridApi && gridApi.refreshCells({ force: true });
                                    }}><FormattedMessage id='add.student' defaultMessage='' /></Button>
                            </Col>
                        </Row>

                        <br />
                    </div>
                    {/* Student info end */}
                    <br />
                    <br />
                    <br />

                    <Row gutter={16} className='regists'>
                        <div className='regist'>
                            <Col span={24}>
                                {/* <Button className="primary ant-btn ant-btn-primary" onClick={() => {
                        save();
                    }}>Submit</Button> */}
                                <div>
                                    {formSetting.collectOnlinePayment ? <Button
                                        className="primary ant-btn ant-btn-primary"
                                        htmlType='submit'
                                        key={2}
                                        style={{ color: 'white', height: 'auto' }}
                                        disabled={props.modalType === 'preview'}
                                    ><FormattedMessage id='proceedToPay.text' />

                                    </Button> :
                                        <CustomButton
                                            intlId={'confirmation.submit'}
                                            defaultMessage={''}
                                            htmlType='submit'
                                            key={2}
                                        />
                                    }
                                </div>
                            </Col>
                            {/* <Col span={7}>
                                    <div>
                                    Powered by Alif App (<a href="https://www.alifapp.com">alifapp.com</a>)
                                    </div>
                                </Col> */}
                        </div>
                    </Row>


                </Form>

            </div>
        </div>
    )
};

export default injectIntl(EditProfile);
