import React, { useState, Fragment } from "react";
import { Form } from "@ant-design/compatible";
import { injectIntl, FormattedMessage } from "react-intl";
import { ErrorMsg } from "../general/ErrorMessage";
// import { EditOutlined } from "@ant-design/icons";

import "./index.scss";
import {
  Row,
  Col,
  Radio,
  Checkbox,
  Button,
  Select,
  Switch,
  Divider,
  Tabs,
  Menu,
} from "antd";
import { TextBox } from "../general/TextBox";
// import { CustomAgGrid } from "../general/CustomAgGrid";
import { TextArea } from "../general/TextAreaComponent";
import { CustomButton } from "../general/CustomButton";
import { Dropdown } from "../general/Dropdown";
import {
  getMomentDateForUIReadOnly,
  showToasterMessage,
  formatDateForBackend,
  getMonthsBetweenDates,
  getUserDefinedName,
  capitalizeFirstLetter,
} from "../../utils";
import {
  CONSTANTS,
  TX_STATUS,
  MODAL_TYPE,
} from "../../static/constants";
import ReactHtmlParser from "react-html-parser";
import { LogoComponent } from "../general/LogoComponent";
import config from "../../config/environmentConfig";
import find from "lodash.find";
import AllStudent from "./AllStudent";
// import moment from "moment";
// import EditProfile from "../../containers/EditProfile";
// import LocationIcon from "../../assets/images/location.png";
import DropdownAction from "antd/lib/dropdown";
import { 
  MoreOutlined,
  EditOutlined,
} from "@ant-design/icons";

const { TabPane } = Tabs;
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { Option } = Select;

const Register = (props) => {
  const { companyInfo } = props;
  const [formSetting, updateFormSetting] = useState(props.formSetting || {});

  let gradeArr = [];

  // const [gridApi, updateGridApi] = useState({});
  // const onGridReady = (params) => {
  //   updateGridApi(params.api);
  //   params.api.sizeColumnsToFit();
  // };
  // const getRowHeight = (params) => {
  //   return 45;
  // };

  // ((formSetting.gradeFees && Object.keys(formSetting.gradeFees).length) ? Object.keys(formSetting.gradeFees) : []).map((i) => {
  //     if (formSetting.isGradeDisplayed && formSetting.isGradeDisplayed[i]) {
  //         let d = {};
  //         d.gradeValidation = Number(i);
  //         d.gradefee = formSetting.gradeFees[d.gradeValidation];
  //         gradeArr.push(d);
  //     }
  //     return gradeArr;
  // });
  
  (props.subcourseAndFeeList || []).map((subcourse) => {
    if (subcourse && subcourse.grade && subcourse.subcourseFee) {
      let d = {};
      d.gradeValidation = subcourse.grade;
      d.gradefee = subcourse.subcourseFee;
      d.subcourseName = subcourse.subcourseName;
      d.subcourseId = subcourse.subcourseId;
      d.gId = subcourse.gId;
      gradeArr.push(d);
    }
    return gradeArr;
  });

  const getTotalGradeFees = () => {
    let total = 0;
    let totalOneTime = 0;

    // total monthly fee for each student
    (props.studentList || []).forEach((data) => {
      if (data.gradefee)
        total = total + Number(data.gradefee);
    });

    total = total * (formSetting.totalMonthSchoolSession || 1);

    // total one time charge for each student
    if(props.registrationFormSetting?.onetimeInvoiceItem && (JSON.parse(props.registrationFormSetting?.onetimeInvoiceItem || '') || []).length){
      totalOneTime = Number(getOneTimeCharges(JSON.parse(props.registrationFormSetting.onetimeInvoiceItem || '[]')) * ((props.studentList || []).length || 1));
    }

    if(props.selectedPlan && Object.keys(props.selectedPlan).length){
      total = Number(total / props.selectedPlan.totalInstallment)
    }

    // if(props.formSetting.isDisplayYearlyFee){
    //   total = total * (getMonthsBetweenDates(
    //     formSetting.sessionStart,
    //     formSetting.sessionEnd
    //   ) || 12);
    // }
    total = Number(total + totalOneTime).toFixed(2);

    return total;
  };

  const appendCountryCode = (number) => {
    // if (props.companyInfo.countryCallingCode && number) {
    //     return '+' + props.companyInfo.countryCallingCode + number;
    // }else{
    //     return number;
    // }
    return number;
  };

  const getSelectedPlan = (plan) => {
    if (!plan) {
      return {};
    } else {
      let startDate = new Date(plan.startDate);
      let endDate = new Date(plan.endDate);
      if (plan.period === "monthly") {
        startDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
        endDate = new Date(
          endDate.setMonth(endDate.getMonth() + plan.totalInstallment)
        );
      } else if (plan.period === "quarterly") {
        startDate = new Date(startDate.setMonth(startDate.getMonth() + 3));
        endDate = new Date(
          endDate.setMonth(endDate.getMonth() + plan.totalInstallment * 3)
        );
      }
      plan.startDate = formatDateForBackend(startDate);
      plan.endDate = formatDateForBackend(endDate);
      return plan;
    }
  };

  const getStudentAddress = (obj) =>{
    return <>
      <Col span={24}>{obj.streetAddress1}</Col>
      <Col span={24}>{obj.streetAddress2}</Col>
      <Col span={24}>{obj.cityName}, {obj.stateName}, {obj.zipCode}</Col>
      <Col span={24}>{obj.countryName}</Col>
    </>
  }

  const save = (e) => {
    e.preventDefault();
    let isRequiredFiledMissing = false;
    // let registerStudentChildIds = [];
    let isRequiredQuestionMissing = false;
    let studentList = [];
    let boContactChildList = [];
    // let isInvalidGrade = false;

    let isFNameMissing = false;
    let isLNameMissing = false;
    let isGradeMissing = false;
    let isGradeFeeMissing = false;
    let isAllergiesMissing = false;
    let isClassMissing = false;
    let isAgeMissing = false;
    // let isDOBMissing = false;
    // let isGenderMissing = false;

    // gridApi && gridApi.forEachNode && gridApi.forEachNode((rowNode, index) => {
    //     let { data } = rowNode;
    if (props.formSetting.addressDisplay && ( !props.address.streetAddress1 || !props.address.cityName || !props.address.stateName || !props.address.countryName )){
      return showToasterMessage({
        messageType: "error",
        description: 'Student Address is missing.',
      });
    }
    if (
      props.formSetting.collectStudentInfo &&
      props.registrationType === "student" &&
      !props.studentList.length
    ) {

      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({ id: "no.student.message" }),
      });
    }

    (props.studentList || []).forEach((data, index) => {
      if (
        props.registrationType === "student" &&
        (!data.firstName || !data.lastName || !data.className || !data.gradefee || (props.formSetting.dobType === 2 && !data.age) || !data.allergies )
      ) {
        isRequiredFiledMissing = true;

        if (!data.firstName) {isFNameMissing = true;}
        if (!data.lastName) {isLNameMissing = true;}
       // if (!data.grade) { isGradeMissing = true; }
        if (!data.gradefee){isGradeFeeMissing = true;}
        // if (!data.dob) { isDOBMissing = true; }
        // if (!data.gender) { isGenderMissing = true; }
        if (!data.className) { isClassMissing = true; }
        if(props.formSetting.dobType === 2 && !data.age){ isAgeMissing= true; }
        if (!data.allergies) { isAllergiesMissing = true; }
      } else {
        studentList.push({
          sNo: index,
          studentId: data.studentId || 0,
          schoolStudentId: data.schoolStudentId || 0,
          relationshipId: props.relationshipId,
          firstName: data.firstName,
          lastName: data.lastName,
          gender: data.gender,
          grade: data.grade,
          allergies: data.allergies,
          dob: data.dob ? formatDateForBackend(data.dob) : "",
          courseFee: data.gradefee ? Number(data.gradefee).toFixed(2) : "",
          customerId: props.customerId || 0,
          courseId: props.courseId,
          classId: Number(data.classId || ''),
          className: data.className,
          // contactChildId: data.contactChildId,
          createdByEmpId: data.createdByEmpId,
          contactId: data.contactId,
          orgRegistrationId: props.orgRegistrationId || 0,
          schoolStudentClassRefList: data.schoolStudentClassRefList || [],
          // subcourseName: data.subcourseName,
          // subcourseId: data.subcourseId,
          studentStatus: data.studentId ? data.studentStatus : "Pending",
          studentStatusColor: data.studentId ? data.studentStatusColor : "#277bcf",
          age: Number(data.age || 0).toFixed(1),
        });
        // boContactChildList.push({
        //     sNo: index,
        //     contactChildId: data.contactChildId || 0,
        //     studentId: data.studentId || 0,
        //     schoolStudentId: data.schoolStudentId || 0,
        //     relationshipId: props.relationshipId,
        //     firstName: data.firstName,
        //     lastName: data.lastName,
        //     gender: data.gender,
        //     allergies: data.allergies,
        //     dob: data.dob ? formatDateForBackend(data.dob) : '',
        //     //courseId: props.courseId,
        //     contactId: props.contactId,
        //     studentList: [{
        //         sNo: index,
        //         studentId: data.studentId || 0,
        //         schoolStudentId: data.schoolStudentId || 0,
        //         relationshipId: props.relationshipId,
        //         orgRegistrationId: props.orgRegistrationId || 0,
        //         customerId: props.customerId || 0,
        //         contactChildId: data.contactChildId,
        //         createdByEmpId: data.createdByEmpId,
        //         contactId: props.contactId,
        //         courseId: props.courseId,
        //         grade: data.grade,
        //         courseFee: data.gradefee ? Number(data.gradefee).toFixed(2) : '',
        //         schoolStudentClassRefList:data.schoolStudentClassRefList || [],
        //         subcourseName: data.subcourseName,
        //         subcourseId: data.subcourseId,
        //         studentStatus: data.studentId ? data.studentStatus: 'Pending',
        //         studentStatusColor: data.studentId ? data.studentStatusColor: '#277bcf'
        //     }]
        // });
        // if(data.contactChildId){
        //     registerStudentChildIds.push(data.contactChildId);
        // }
      }
    });
    


    // let contact = props.customerInfo.boContactList[0] || {};
    // if (contact.boContactChildList && contact.boContactChildList.length) {
    //     (contact.boContactChildList || []).forEach((childContact) => {
    //         if (childContact.contactChildId && registerStudentChildIds.indexOf(childContact.contactChildId) < 0) {
    //             childContact.studentList = [];
    //             boContactChildList.push(childContact);
    //         }
    //     })
    //   }
    // if (isInvalidGrade && formSetting.isStopRegistrationAfterMaxStudent) {
    //   return showToasterMessage({
    //     messageType: "error",
    //     description: formSetting.invalidGradeMessage
    //       ? formSetting.invalidGradeMessage
    //       : props.intl.formatMessage({
    //           id: "no.more.seat.available.inGrade.message",
    //           values: { value: "" },
    //         }),
    //   });
    // }


    if (isFNameMissing) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "student.firstName.missing",
        }),
      });
    }
    if (isLNameMissing) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "student.lastName.missing",
        }),
      });
    }
    if (isGradeMissing || isClassMissing) {
      return showToasterMessage({
        messageType: "error",
        // description: props.intl.formatMessage({ id: "student.grade.missing" }),
        description: getUserDefinedName("Class for some student is missing"),
      });
    }
    if (isAgeMissing) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({ id: "student.age.missing" }),
      });
    }
    // if (isGradeFeeMissing) {
    //   return showToasterMessage({
    //     messageType: "error",
    //     description: props.intl.formatMessage({ id: "student.gradeFee.missing" }),
    //   });
    // }
    // if (isDOBMissing) {
    //   return showToasterMessage({
    //     messageType: "error",
    //     description: props.intl.formatMessage({ id: "student.dob.missing" }),
    //   });
    // }
    // if (isGenderMissing) {
    //   return showToasterMessage({
    //     messageType: "error",
    //     description: props.intl.formatMessage({ id: "student.gender.missing" }),
    //   });
    // }

    if (isAllergiesMissing) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "student.allergies.missing",
        }),
      });
    }

    props.updateState({submitClicked : true})
    if(
      (formSetting.familyDoctorNameDisplay && formSetting.familyDoctorNameRequired && !(props.familyDoctorName || props.familyDoctorPhone))
      || (formSetting.emergencyContactDisplay && formSetting.emergencyContactRequired && !(props.emergencyContactName || props.emergencyPhone))
      || (formSetting.askOrgMember && !props.orgMember)
      || (props.isAllowParentPaymentPlan && !(props.selectedPlanIndex+1)) 
      || (props.isFinancialAssistanceNeeded && !props.financialAssistanceReason)
      ){
        return
    }


    (props.questionList || []).forEach((ques) => {
      if (ques.isRequired && (!ques.answers || !ques.answers.length)) {
        isRequiredQuestionMissing = true;
      }
    });

    if (isRequiredQuestionMissing) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "required.question.missing",
        }),
      });
    }

    if (props.formSetting.addressRequired && !props.streetAddress1) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "address.street.missing.message",
        }),
      });
    }

    if (props.formSetting.addressRequired && !props.cityName) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "address.city.missing.message",
        }),
      });
    }
    if (props.formSetting.addressRequired && !props.zipCode) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "address.zipCode.missing.message",
        }),
      });
    }

    // if ( props.registrationType === 'student' && !props.primaryContact) {
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({ id: 'primaryContactForInvoicing.validation.message' })
    //     });
    // }

    if (
      props.isAllowParentPaymentPlan &&
      (!props.selectedPlan || !Object.keys(props.selectedPlan).length)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "registration.parentPaymentPlan.required",
        }),
      });
    }

    if (
      (props.emergencyPhone &&
        props.emergencyPhone.length > 0 &&
        props.emergencyPhone.length < 10) ||
      (props.familyDoctorPhone &&
        props.familyDoctorPhone.length > 0 &&
        props.familyDoctorPhone.length < 10)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "phoneNumber.incorrect.message",
        }),
      });
    }

    if (formSetting.askOrgMemberRequired && !props.orgMember) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "registration.orgMember.required",
        }),
      });
    }
    // if (
    //   formSetting.askFinanacialAssistanceRequired &&
    //   !props.isFinancialAssistanceNeeded
    // ) {
    //   return showToasterMessage({
    //     messageType: "error",
    //     description: props.intl.formatMessage({
    //       id: "registration.financialAssistance.required",
    //     }),
    //   });
    // }
    // if (formSetting.askAddTeacherRequired && !props.isTeacher) {
    //   return showToasterMessage({
    //     messageType: "error",
    //     description: props.intl.formatMessage({
    //       id: "registration.addTeacher.required",
    //     }),
    //   });
    // }
    // if (formSetting.askAddVolunteerRequired && !props.isVolunteer) {
    //   return showToasterMessage({
    //     messageType: "error",
    //     description: props.intl.formatMessage({
    //       id: "registration.addVolunteer.required",
    //     }),
    //   });
    // }

    //father mother contact validation
    // if (props.primaryContact === 'mother') {
    // if(!props.motherFirstName && formSetting.motherFirstNameRequired){
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({ id: 'missing.mother.firstName.primary.contact' })
    //     });
    // }
    // if(!props.motherLastName && formSetting.motherLastNameRequired){
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({ id: 'missing.mother.lastName.primary.contact' })
    //     });
    // }
    // if(!props.motherEmail && (formSetting.motherEmailRequired || props.primaryContact === 'mother')){
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({ id: 'missing.mother.email.primary.contact' })
    //     });
    // }
    // if(!props.motherMobile && formSetting.motherMobileRequired){
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({ id: 'missing.mother.mobile.primary.contact' })
    //     });
    // }
    //  };
    // if (props.primaryContact === 'father') {
    // if(!props.fatherFirstName && formSetting.fatherFirstNameRequired){
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({ id: 'missing.father.firstName.primary.contact' })
    //     });
    // }
    // if(!props.fatherLastName && formSetting.fatherLastNameRequired){
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({ id: 'missing.father.lastName.primary.contact' })
    //     });
    // }
    // if(!props.fatherEmail && (formSetting.fatherEmailRequired || props.primaryContact === 'father')){
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({ id: 'missing.father.email.primary.contact' })
    //     });
    // }
    // if(!props.fatherMobile && formSetting.fatherMobileRequired){
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({ id: 'missing.father.mobile.primary.contact' })
    //     });
    // }
    // };

    let feeAmt = Number.isNaN(getTotalGradeFees()) ? 0 : getTotalGradeFees();
    const oneTimeFeePaid = props.oneTimeFeePaid || 0;
    if(formSetting.collectOnlinePayment && !props.isFinancialAssistanceNeeded && ((props.oneTimeCharges || []).length)){
      handleSubmitForSave(studentList, oneTimeFeePaid);
    }
    else{
      const modalData = {
        modalBody: props.isFinancialAssistanceNeeded ? (
          <>
            This form will be reviewed by school and applicable assistance will be provided.<br />
            <FormattedMessage id="save.confirmation" />
          </>
        ) : (
          <FormattedMessage id="save.confirmation" />
        ),
        handleSubmit: () => {
          handleSubmitForSave(studentList, oneTimeFeePaid);
        }
      };
      props.pushModalToStack(modalData);

    }
  };
  // const columnDefs = [
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <span>
  //           <FormattedMessage id="signup.firstname.placeholder" />
  //           {props.registrationType === "student" ? (
  //             <span className="required">*</span>
  //           ) : (
  //             ""
  //           )}
  //         </span>
  //       );
  //     },
  //     field: "firstName",
  //     colId: "firstName_1",
  //     width: 130,
  //     resizable: true,
  //     // editable: true,
  //     cellEditor: "customTextEditor",
  //     suppressMovable: true,
  //     suppressKeyboardEvent: (params) => {
  //       const KEY_ENTER = 13;
  //       const keyCode = params.event.keyCode;
  //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
  //       return gridShouldDoNothing;
  //     },
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <span>
  //           <FormattedMessage id="signup.lastname.placeholder" />
  //           {props.registrationType === "student" ? (
  //             <span className="required">*</span>
  //           ) : (
  //             ""
  //           )}
  //         </span>
  //       );
  //     },
  //     field: "lastName",
  //     resizable: true,
  //     // editable: true,
  //     width: 130,
  //     cellEditor: "customTextEditor",
  //     suppressMovable: true,
  //     suppressKeyboardEvent: (params) => {
  //       const KEY_ENTER = 13;
  //       const keyCode = params.event.keyCode;
  //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
  //       return gridShouldDoNothing;
  //     },
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <span>
  //           <FormattedMessage id="grade.text" />
  //           {props.registrationType === "student" ? (
  //             <span className="required">*</span>
  //           ) : (
  //             ""
  //           )}
  //         </span>
  //       );
  //     },
  //     field: "gId",
  //     resizable: true,
  //     editable: true,
  //     suppressMovable: true,
  //     width: 170,
  //     suppressKeyboardEvent: (params) => {
  //       const KEY_ENTER = 13;
  //       const keyCode = params.event.keyCode;
  //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
  //       return gridShouldDoNothing;
  //     },
  //     cellEditor: "customDropDownEditor",
  //     cellEditorParams: (obj) => {
  //       return {
  //         className: "grade-dropdown",
  //         lastColumnCell: false,
  //         items: gradeArr,
  //         optionKeyName: "gId",
  //         valueKeyName: "gradeValidation",
  //         isGradeLevel: true,
  //         isDisplayYearlyFee: formSetting.isDisplayYearlyFee ? true : false,
  //         totalMonths:
  //           getMonthsBetweenDates(
  //             formSetting.sessionStart,
  //             formSetting.sessionEnd
  //           ) || 1,
  //         valueKeyGradeFee: "gradefee",
  //         addressLineKey1: "subcourseName",
  //         currencyCode: companyInfo.currencyCode || "",
  //         onSelect: (gId, optionObj) => {
  //           const selectedObj = find(gradeArr, { gId: optionObj.key });
  //           let selectedValue = selectedObj.gradeValidation;

  //           // if (obj.node.data.grade) {
  //           //     let totalStudentCountByGrade = props.totalStudentCountByGrade || {};
  //           //     totalStudentCountByGrade[obj.node.data.grade] = Number(totalStudentCountByGrade[obj.node.data.grade]) - 1;
  //           //     props.updateState({
  //           //         totalStudentCountByGrade
  //           //     });
  //           // }
  //           obj.node.data.gradefee = null;
  //           obj.node.setDataValue(obj.colDef.field, null);

  //           if (selectedObj) {
  //             obj.node.data.gradefee = selectedObj.gradefee;
  //             obj.node.data.subcourseName = selectedObj.subcourseName;
  //             obj.node.data.subcourseId = selectedObj.subcourseId;
  //             obj.node.data.gId = selectedObj.gId;
  //             obj.node.data.grade = selectedValue;
  //             obj.node.data.invalidGrade = formSetting.gradeLevelValidation
  //               ? true
  //               : false;
  //             obj.node.setDataValue(obj.colDef.field, selectedValue);
  //             obj.node.setDataValue("gradefee", selectedObj.gradefee);

  //             obj.api && obj.api.stopEditing();
  //             // obj.node.setDataValue("subcourseName", selectedObj.subcourseName);
  //             //obj.node.setDataValue("subcourseId", selectedObj.subcourseId);
  //           }

  //           if (formSetting.gradeLevelValidation) {
  //             let isInvalid = false;
  //             if (
  //               selectedValue &&
  //               Number(
  //                 (formSetting.gradeValidations || {})[selectedValue] || 0
  //               ) <=
  //                 Number(
  //                   (self.totalStudentCountByGrade || {})[selectedValue] || 0
  //                 )
  //             ) {
  //               isInvalid = true;
  //             }
  //             // if ((selectedValue === 1 || selectedValue === 2) && (Number((formSetting.gradeValidations || {})[1] || 0) + Number((formSetting.gradeValidations || {})[2] || 0)) <= (Number((props.totalStudentCountByGrade || {})[1] || 0) + Number((props.totalStudentCountByGrade || {})[2] || 0))) {
  //             //     isInvalid = true;
  //             // } else if ((selectedValue === 3 || selectedValue === 4 || selectedValue === 5) && (Number((formSetting.gradeValidations || {})[3] || 0) + Number((formSetting.gradeValidations || {})[4] || 0) + Number((formSetting.gradeValidations || {})[5] || 0)) <= (Number((props.totalStudentCountByGrade || {})[3] || 0) + Number((props.totalStudentCountByGrade || {})[4] || 0) + Number((props.totalStudentCountByGrade || {})[5] || 0))) {
  //             //     isInvalid = true;
  //             // } else if ((selectedValue === 6 || selectedValue === 7 || selectedValue === 8) && (Number((formSetting.gradeValidations || {})[6] || 0) + Number((formSetting.gradeValidations || {})[7] || 0) + Number((formSetting.gradeValidations || {})[8] || 0)) <= (Number((props.totalStudentCountByGrade || {})[6] || 0) + Number((props.totalStudentCountByGrade || {})[7] || 0) + Number((props.totalStudentCountByGrade || {})[8] || 0))) {
  //             //     isInvalid = true;

  //             // } else if ((selectedValue === 9 || selectedValue === 10 || selectedValue === 11 || selectedValue === 12) && (Number((formSetting.gradeValidations || {})[9] || 0) + Number((formSetting.gradeValidations || {})[10] || 0) + Number((formSetting.gradeValidations || {})[11] || 0) + Number((formSetting.gradeValidations || {})[12] || 0)) <= (Number((props.totalStudentCountByGrade || {})[9] || 0) + Number((props.totalStudentCountByGrade || {})[10] || 0) + Number((props.totalStudentCountByGrade || {})[11] || 0) + Number((props.totalStudentCountByGrade || {})[12] || 0))) {
  //             //     isInvalid = true;

  //             // }
  //             if (isInvalid && formSetting.invalidGradeMessage) {
  //               //showToasterMessage({   messageType: 'warning', description: props.intl.formatMessage({ id: 'no.more.seat.available.inGrade.message', values: { value: selectedValue } })});
  //               showToasterMessage({
  //                 messageType: formSetting.isStopRegistrationAfterMaxStudent
  //                   ? "error"
  //                   : "warning",
  //                 description: formSetting.invalidGradeMessage,
  //               });

  //               obj.api && obj.api.stopEditing();
  //             } else {
  //               obj.node.data.invalidGrade = false;
  //               obj.node.data.gradefee = selectedObj.gradefee;
  //               obj.node.data.subcourseName = selectedObj.subcourseName;
  //               obj.node.data.subcourseId = selectedObj.subcourseId;
  //               obj.node.data.gId = selectedObj.gId;
  //               obj.node.setDataValue(obj.colDef.field, selectedValue);
  //               obj.node.setDataValue("gradefee", selectedObj.gradefee);
  //               //  obj.node.setDataValue("subcourseName", selectedObj.subcourseName);
  //               // obj.node.setDataValue("subcourseId", selectedObj.subcourseId);
  //               obj.node.data.grade = selectedValue;
  //               let totalStudentCountByGrade =
  //                 self.totalStudentCountByGrade || {};
  //               totalStudentCountByGrade[selectedValue] =
  //                 Number(totalStudentCountByGrade[selectedValue] || 0) + 1;
  //               props.updateState({
  //                 totalStudentCountByGrade,
  //               });
  //               obj.api && obj.api.stopEditing();
  //             }
  //           }
  //         },

  //         //allowClear: true,
  //         // onClear: () => {
  //         //     //obj.node.setDataValue(obj.colDef.field, undefined);
  //         // },
  //       };
  //     },
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div>
  //           {params.data.grade ? (
  //             <div>
  //               <div>Grade {params.data.grade}</div>
  //               <div>{params.data.subcourseName}</div>
  //             </div>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       );
  //     },
  //     // valueGetter: (obj) =>{
  //     //     if (formSetting.gradeLevelValidation && Number((formSetting.gradeValidations || {})[obj.data.grade] || 0) <= (props.totalStudentCountByGrade || {})[obj.data.grade]) {
  //     //         return ""
  //     //     }
  //     //     let totalfee = 0;
  //     //     obj.api.forEachNode((rowNode, index) => {
  //     //         const { data } = rowNode;
  //     //         if (data.grade) {
  //     //             totalfee += Number((formSetting.gradeFees || {})[data.grade] || 0);
  //     //         }
  //     //       });
  //     //       props.updateState({ totalfee })
  //     //     return obj.data.grade;
  //     // }
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return formSetting.isDisplayYearlyFee ? (
  //         <span>
  //           <FormattedMessage id="gradefeeYearly.header.text" />
  //         </span>
  //       ) : (
  //         <span>
  //           <FormattedMessage id="gradefee.header.text" />
  //         </span>
  //       );
  //     },
  //     width: 80,
  //     // hide: !(Object.keys(formSetting.gradeFees || {})).length,
  //     field: "gradefee",
  //     resizable: true,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <span>
  //           {formSetting.isDisplayYearlyFee
  //             ? params.data.gradefee
  //               ? (
  //                   Number(params.data.gradefee) *
  //                   getMonthsBetweenDates(
  //                     formSetting.sessionStart,
  //                     formSetting.sessionEnd
  //                   )
  //                 ).toFixed(2)
  //               : 0.0
  //             : Number(params.data.gradefee || 0).toFixed(2)}
  //         </span>
  //       );
  //     },
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <span>
  //           <FormattedMessage id="dob.text" />
  //           {props.registrationType === "student" ? (
  //             <span className="required">*</span>
  //           ) : (
  //             ""
  //           )}
  //         </span>
  //       );
  //     },
  //     field: "dob",
  //     resizable: true,
  //     // editable: true,
  //     width: 130,
  //     cellEditor: "customDatePicker",
  //     cellEditorParams: (obj) => {
  //       return {
  //         disabledDate: (date) => date && date.valueOf() > Date.now(),
  //         onChange: (selectedDate) => {
  //           obj.node.setDataValue(obj.colDef.field, selectedDate);
  //         },
  //       };
  //     },
  //     cellRendererFramework: (params) => {
  //       return (
  //         <span>
  //           {params.data.dob
  //             ? getMomentDateForUIReadOnly({
  //                 date: params.data.dob,
  //                 format: CONSTANTS.DISPLAY_DATE_FORMAT,
  //               })
  //             : ""}
  //         </span>
  //       );
  //     },
  //   },

  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <span>
  //           <FormattedMessage id="allergies.header.text" />
  //           {props.registrationType === "student" ? (
  //             <span className="required">*</span>
  //           ) : (
  //             ""
  //           )}
  //         </span>
  //       );
  //     },
  //     field: "allergies",
  //     resizable: true,
  //     // editable: true,
  //     cellEditor: "customTextEditor",
  //     suppressKeyboardEvent: (params) => {
  //       const KEY_ENTER = 13;
  //       const keyCode = params.event.keyCode;
  //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
  //       return gridShouldDoNothing;
  //     },
  //     // cellEditorParams: (params) => {
  //     //     return {
  //     //         value: params.node.data.allergies || "",
  //     //         isEditorLoaded: props.isEditorLoaded,
  //     //         onChange: (value) => {
  //     //             params.node.data[params.colDef.field] = value;
  //     //             params.node.data.isEditorLoaded = true;
  //     //             props.updateState({
  //     //                 isEditorLoaded: true
  //     //             })
  //     //         }
  //     //     }
  //     // },
  //     cellRendererFramework: (params) => {
  //       return ReactHtmlParser(params.data.allergies);
  //     },
  //     // suppressKeyboardEvent: (params) => {
  //     //     const KEY_ENTER = 13;
  //     //     const keyCode = params.event.keyCode;
  //     //     const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
  //     //     params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
  //     //     if (params.event.target.scrollHeight > 30) {
  //     //         if (params.event.path[2]) {
  //     //             params.event.path[2].style.height = 'inherit';
  //     //             params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
  //     //         }
  //     //         params.node.setRowHeight(params.event.target.scrollHeight);
  //     //         params.api && params.api.onRowHeightChanged();
  //     //     } else {
  //     //         if (params.event.path[2]) {
  //     //             params.event.path[2].style.height = 'inherit';
  //     //             params.event.path[2].style.height = `${30}px`;
  //     //         }
  //     //         params.node.setRowHeight(30);
  //     //         params.api && params.api.onRowHeightChanged();
  //     //     }
  //     //     return gridShouldDoNothing;
  //     // }
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <span>
  //           <FormattedMessage id="gender.text" />
  //           {props.registrationType === "student" ? (
  //             <span className="required">*</span>
  //           ) : (
  //             ""
  //           )}
  //         </span>
  //       );
  //     },
  //     field: "gender",
  //     resizable: true,
  //     // editable: true,
  //     width: 70,
  //     suppressMovable: true,
  //     cellEditor: "customDropDownEditor",
  //     suppressKeyboardEvent: (params) => {
  //       const KEY_ENTER = 13;
  //       const keyCode = params.event.keyCode;
  //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
  //       return gridShouldDoNothing;
  //     },
  //     cellEditorParams: (obj) => {
  //       return {
  //         lastColumnCell: false,
  //         //items: ['Male', 'Female', 'Rather Not Say'],
  //         items: ["Male", "Female"],
  //         onSelect: (selectedValue) => {
  //           obj.node.setDataValue(obj.colDef.field, selectedValue);
  //         },
  //         allowClear: true,
  //         onClear: () => {
  //           obj.node.setDataValue(obj.colDef.field, undefined);
  //         },
  //       };
  //     },
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="customer.listing.header.action" />;
  //     },
  //     width: 90,
  //     field: "action",
  //     resizable: true,
  //     cellRenderer: "actionEditAndDelete",
  //     suppressNavigable: true,
  //     cellRendererParams: (params) => {
  //       return {
  //         className: "delrt-sticky",
  //         onClick: (actionType) => {
  //           if (actionType === GRID_ACTIONS.DELETE) {
  //             let totalStudentCountByGrade =
  //               self.totalStudentCountByGrade || {};
  //             if (!params.data.invalidGrade) {
  //               totalStudentCountByGrade[params.data.grade] =
  //                 Number(totalStudentCountByGrade[params.data.grade]) - 1;
  //               props.updateState({
  //                 totalStudentCountByGrade,
  //               });
  //             }

  //             params.api.updateRowData({
  //               remove: [
  //                 params.api.getDisplayedRowAtIndex(params.node.rowIndex).data,
  //               ],
  //             });
  //             // if (params.api.rowRenderer.lastRenderedRow < 0) {
  //             //     params.api.updateRowData({ add: [{}] });
  //             // }
  //           } else if (actionType === GRID_ACTIONS.EDIT) {
  //             editProfile();
  //           }
  //         },
  //       };
  //     },
  //   },
  // ];


  const handleSubmitForSave= (xList, oneTimeFeePaid) => {
    // let contactList = [];
    let boLocationList = [];
    let feeAmt = Number.isNaN(getTotalGradeFees()) ? 0 : getTotalGradeFees();

    // if (props.fatherFirstName || props.motherFirstName) {
    //     contactList.push({
    //         firstName: props.fatherFirstName,
    //         lastName: props.fatherLastName,
    //         cellPhone: appendCountryCode(props.fatherMobile),
    //         emailAddress: props.fatherEmail,
    //         relationshipId: props.relationshipId,
    //         dateCreated: getCurrentDateForBackend(new Date()),
    //         gender: 'Male',
    //         role: ROLE_TYPES.FATHER,
    //         contactType: "Customer",
    //         // motherFirstName: props.motherFirstName,
    //         // motherLastName: props.motherLastName,
    //         // motherMobile: appendCountryCode(props.motherMobile),
    //         // motherEmail: props.motherEmail,
    //         dateOfBirth: props.fatherDOB? formatDateForBackend(props.fatherDOB) : '',
    //        // motherDateOfBirth: props.motherDOB? formatDateForBackend(props.motherDOB) : '',
    //         isPrimaryContact:  props.primaryContact === 'father' ? 1 : 0,
    //         primaryContactForInvoicing: props.primaryContact,
    //        // boContactChildList : boContactChildList,
    //         contactId: props.contactId,
    //         version: props.contactVersion,
    //         customerId: props.customerId,
    //         countryCallingCode: props.companyInfo.countryCallingCode,
    //     });
    // }
    // if (props.motherFirstName) {
    //     contactList.push({
    //         firstName: props.motherFirstName,
    //         lastName: props.motherLastName,
    //         cellPhone: appendCountryCode(props.motherMobile),
    //         emailAddress: props.motherEmail,
    //         relationshipId: props.relationshipId,
    //         dateCreated: getCurrentDateForBackend(new Date()),
    //         gender: 'Female',
    //         role: ROLE_TYPES.MOTHER,
    //         contactType: "Customer",
    //         isPrimaryContact: props.primaryContact === 'mother' ? 1 : 0,
    //         contactId: props.motherContactId,
    //         version: props.motherContactVersion,
    //         customerId: props.customerId,
    //         countryCallingCode: props.companyInfo.countryCallingCode,
    //     });
    // }

    // if (props.firstName) {
    //     contactList.push({
    //         firstName: props.firstName,
    //         lastName: props.lastName,
    //         cellPhone: appendCountryCode(props.phoneNumber),
    //         emailAddress: props.email,
    //         relationshipId: props.relationshipId,
    //         dateCreated: getCurrentDateForBackend(new Date()),
    //         gender: props.gender,
    //         contactType: "Customer",
    //         countryCallingCode: props.companyInfo.countryCallingCode,
    //     });
    // }

    // if (props.spouseFirstName) {
    //     contactList.push({
    //         firstName: props.spouseFirstName,
    //         lastName: props.spouseLastName,
    //         cellPhone: appendCountryCode(props.spouseMobile),
    //         emailAddress: props.spouseEmail,
    //         relationshipId: props.relationshipId,
    //         dateCreated: getCurrentDateForBackend(new Date()),
    //         gender: 'Female',
    //         contactType: "Customer",
    //         countryCallingCode: props.companyInfo.countryCallingCode,
    //     });
    // }

    if (
      props.streetAddress1 ||
      props.cityName ||
      props.stateName ||
      props.zipCode
    ) {
      boLocationList.push({
        streetAddress1: props.streetAddress1,
        streetAddress2: props.streetAddress2,
        cityName: props.cityName,
        countryId: props.countryId,
        countryName: props.countryName,
        stateName: props.stateName,
        zipCode: props.zipCode,
        locationType: CONSTANTS.BILLING_ADDRESS,
        relationshipId: props.relationshipId,
        isDefault: 1,
        locationId: props.locationId,
        version: props.locationVersion,
        customerId: props.customerId,
      });
    }
    let registrationData = {
      customerId: props.customerId || 0,
      contactId: props.contactId,
      orgRegistrationId: props.orgRegistrationId || 0,
      registrationFormSettingId: props.registrationFormSettingId,
      courseId: props.courseId,
      relationshipId: props.relationshipId,
      fatherFirstName: props.fatherFirstName,
      fatherLastName: props.fatherLastName,
      fatherEmail: props.fatherEmail,
      fatherMobile: appendCountryCode(props.fatherMobile),

      motherFirstName: props.motherFirstName,
      motherLastName: props.motherLastName,
      motherEmail: props.motherEmail,
      motherMobile: appendCountryCode(props.motherMobile),

      emergencyContactName: props.emergencyContactName,
      emergencyPhone: appendCountryCode(props.emergencyPhone),
      studentList: xList,
      amount: feeAmt,
      questionList: JSON.stringify(props.questionList),
      isOrgMember: 1,
      registrationType: props.formSetting.registrationType,

      spouseFirstName: props.spouseFirstName,
      spouseLastName: props.spouseLastName,
      spouseEmail: props.spouseEmail,
      spouseMobile: appendCountryCode(props.spouseMobile),
      spouseDOB: props.spouseDOB,

      familyDoctorName: props.familyDoctorName,
      familyDoctorPhNumber: appendCountryCode(props.familyDoctorPhone),

      nationality: props.nationality,
      membershipType: props.membershipType,

      firstName: props.firstName,
      lastName: props.lastName,
      phoneNumber: appendCountryCode(props.phoneNumber),
      gender: props.gender,
      dob: props.dob,

      maritalStatus: props.maritalStatus,
      selectedPlan: props.isAllowParentPaymentPlan
        ? JSON.stringify(getSelectedPlan(props.selectedPlan || {}))
        : "{}",
      isInvoiced: props.isInvoiced || 0,
      primaryContact: props.primaryContact,

      orgMember: props.orgMember,
      isFinancialAssistanceNeeded: props.isFinancialAssistanceNeeded,
      financialAssistanceReason: props.isFinancialAssistanceNeeded ? props.financialAssistanceReason : '',
      isTeacher: props.isTeacher,
      isVolunteer: props.isVolunteer,
      oneTimeFeePaid,
    };
    
    if (registrationData && registrationData.amount) {
      registrationData.systemStatus = TX_STATUS.UNPAID;
      registrationData.isEnrollmentPaid = 1;
    }
    let companyName =
      props.firstName + (props.lastName ? " " + props.lastName : "");
    if (props.primaryContact === "mother") {
      companyName =
        props.motherFirstName +
        (props.motherLastName ? " " + props.motherLastName : "");
    }
    if (props.primaryContact === "father") {
      companyName =
        props.fatherFirstName +
        (props.fatherLastName ? " " + props.fatherLastName : "");
    }

    let customerPayload = {
      clientOnlineUserAccountId:
        props.customerInfo.clientOnlineUserAccountId,
      companyName: companyName,
      boContactList: props.customerInfo.boContactList,
      boLocationList: boLocationList,
      customerId: props.customerId || 0,
      relationshipId: props.relationshipId,
      customerType: "individual",
      orgRegistrationList: [registrationData],
      paymentTermId: formSetting.paymentTermId,
      isOrgMember: 1,
      membershipOptionId: props.membershipOption,
      registrationType: props.formSetting.registrationType,
      primaryContact: props.primaryContact,

      fatherFirstName: props.fatherFirstName,
      fatherLastName: props.fatherLastName,
      fatherMobile: appendCountryCode(props.fatherMobile),
      fatherEmail: props.fatherEmail,
      fatherDateOfBirth: props.fatherDOB
        ? formatDateForBackend(props.fatherDOB)
        : "",

      motherFirstName: props.motherFirstName,
      motherLastName: props.motherLastName,
      motherMobile: appendCountryCode(props.motherMobile),
      motherEmail: props.motherEmail,
      motherDateOfBirth: props.motherDOB
        ? formatDateForBackend(props.motherDOB)
        : "",

      boContactChildList: props.boContactChildList,
      countryCallingCode: props.companyInfo.countryCallingCode,
    };

    props.saveRegistrationFound(customerPayload, props);
  }



  const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = (
      <Fragment>
        {obj.streetAddress1 ? (
          <span className="billing-address">{obj.streetAddress1}</span>
        ) : (
          ""
        )}
        {obj.streetAddress2 ? (
          <span className="billing-address"> {obj.streetAddress2}</span>
        ) : (
          ""
        )}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""}${
              obj.stateName ? ", " + obj.stateName : ""
            }${obj.zipCode ? ", " + obj.zipCode : ""}`}
          </div>
        ) : (
          ""
        )}
        {obj.countryName ? (
          <div className="billing-address">{`${obj.countryName || ""}`}</div>
        ) : (
          ""
        )}
        {additionInfo.workPhone ? (
          <div className="billing-address">
            <FormattedMessage id="phone" defaultMessage="Ph" /> -{" "}
            {additionInfo.workPhone}
          </div>
        ) : (
          ""
        )}
        {additionInfo.email ? (
          <div className="billing-address">
            <FormattedMessage id="email" defaultMessage="Email" /> -{" "}
            {additionInfo.email}
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
    return fragmentObj;
  };

  const editProfile = () => {
    props.showModal({
      maskClosable: true,
      isClosable: false,
      keyboard: false,
      modalBody: (
        <Fragment>
          <div className="warning-message">
            Personal information can be edited from the profile page. Do you
            want to continue?
          </div>
          <br />
          <div
            className="button-action"
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid #e5e5e5",
              paddingTop: "15px",
            }}
          >
            <Button
              className="ant-btn-default"
              onClick={() => {
                props.hideModal();
              }}
            >
              {" "}
              <FormattedMessage id="no" defaultMessage="" />
            </Button>
            <Button
              className="ant-btn-primary"
              onClick={() => {
                props.history.push("/admin/profile");
                props.hideModal();
                // const modalData = {
                //     title: <React.Fragment>
                //         <div className='ant-modal-title' style={{ textAlign: 'center' }}>
                //             Edit Profile
                //         </div>
                //     </React.Fragment>,
                //     modalBody: <EditProfile {...props}  addStudent={(data) => {

                //     }}/>,
                //     width: '100%',
                //     hideFooter: true,
                // };
                // props.pushModalToStack(modalData);
              }}
            >
              {" "}
              <FormattedMessage id="ok" defaultMessage="" />{" "}
            </Button>
          </div>
        </Fragment>
      ),
      hideFooter: true,
    });
  };
  const closeForm = () => {
    props.showModal({
      // maskClosable: true,
      // isClosable: false,
      // keyboard: false,
      hideFooter: false,
      modalBody: (
        <Fragment>
          <div className="warning-message">
            Are you sure you want to close the form ?
          </div>
          <br />
          <div
            className="button-action"
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid #e5e5e5",
              paddingTop: "15px",
            }}
          >
            <Button
              className="ant-btn-default"
              onClick={() => {
                props.hideModal();
              }}
            >
              {" "}
              No
            </Button>
            <Button
              className="ant-btn-primary"
              onClick={() => {
                props.history.push("/admin/open-registration");
                props.hideModal();
               
              }}
            >
              {" "}
              Yes
            </Button>
          </div>
        </Fragment>
      ),
      hideFooter: true,
    });
  };
  const addStudentHandle = () => {
    let selectedStudentIds = [];
    // gridApi && gridApi.forEachNode && gridApi.forEachNode((rowNode) => {
    //     let { data } = rowNode;
    //     if (data.contactChildId)
    //     selectedStudentIds.push(data.contactChildId);
    // });
    (props.studentList || []).forEach((data) => {
      if (data.contactId) selectedStudentIds.push(data.contactId);
    });
    const modalData = {
      title: (
          <div className="ant-modal-title">
            Students
          </div>
      ),
      modalBody: (
        <AllStudent
          {...props}
          selectedStudentIds={selectedStudentIds}
          addStudent={(data) => {
            let newRowIndex = ((props.studentList || []).length || 0) - 1;
            if (
              formSetting.schoolLevelValidation &&
              Number(formSetting.maxStudent || 0) <=
                props.totalStudentCount + newRowIndex + 1
            ) {
              return showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "no.more.seat.available.message",
                }),
              });
            }

            let studentList = props.studentList || [];
            data.dob = data.dateOfBirth;
            studentList.push(data);
            props.updateState(studentList);
            
            // gridApi && gridApi.stopEditing();
            // gridApi && gridApi.applyTransaction({ add: [data] });
            // gridApi && gridApi.startEditingCell({
            //     rowIndex: newRowIndex + 1,
            //     colKey: "firstName_1"
            // });
            // gridApi && gridApi.refreshCells({ force: true });
          }}
        />
      ),
      width: 1000,
      hideFooter: true,
    };
    props.pushModalToStack(modalData);
  };

  const handleNewContactAddition = (
    props,
    payload,
    isStudentGrid,
    studentIndex
  ) => {
    const formData = payload.formData;
    const { contactData = {} } = formData;
    const { pushModalToStack, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact, boContactList) => {
      if (contact.customerId) {
        props.updateState({ primayContactObj: contact });
      }
      if (
        contact.customerId &&
        isStudentGrid &&
        boContactList &&
        boContactList.length
      ) {
        const obj =
          find(boContactList || [], { contactId: contactData.contactId }) || {};
        let studentList = props.studentList || [];
        obj.dob = obj.dateOfBirth;
        studentList[studentIndex] = obj;
        props.updateState(studentList);
      }
    };
    const data = {
      title: (
        <div>
          <FormattedMessage id="addItem.text.contact" defaultMessage="" />
          <div style={{ fontSize: "12px" }}> {formData.customerName || ""}</div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.popModalFromStack(data);
      },
    };
    pushModalToStack(data);
  };
  const handleAddressAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (address) => {
      if (address.customerId) {
        props.updateState({ address: address });
      }
    };
    const data = {
      title: (
        <div>
          <FormattedMessage id="signup.tab.address" defaultMessage="" />
          <div style={{ fontSize: "12px" }}> {formData.customerName || ""}</div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.BILLING_ADDRESS,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };
  const handleNewEducationAddition = (props, payload, contact) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
      props.hideModal(data);
      if (contact.customerId) {
      }
    };
    const data = {
      title: (
        <div>
          <FormattedMessage id="education.text" defaultMessage="" />
          <div style={{ fontSize: "12px" }}>
            {" "}
            {contact.firstName +
              (contact.lastName ? " " + contact.lastName : "")}
          </div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EDUCATION,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };
  const actionMenu = (e, i) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="bold-title">
          <a>Actions</a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="1"
          value="Edit"
          onClick={() => {
            let formData = {
              customerId: props.customerId,
              customerName: props.customerInfo.companyName,
              contactData: e,
            };
            handleNewContactAddition(props, { formData: formData }, true, i);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="2"
          value="Delete"
          onClick={() => {
            let studentList = props.studentList;
            studentList.splice(i, 1);
            props.updateState(studentList);
          }}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  };
  const actionMenuEd = (e, contact) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="bold-title">
          Actions
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="1"
          value="Edit"
          onClick={() => {
            let formData = {  ...e,customerId: props.customerInfo
                  .customerId,
              customerName:
                props.customerInfo
                  .companyName,
              contactData: contact,
              contactId:
                contact.contactId,
            };
            handleNewEducationAddition(
              props,
              {
                formData: formData,
              },
              contact
            );
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="2"
          value="Delete"
          onClick={() => {
            props.showModal({
              modalBody:
                "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteContactEducation(
                  e,
                  props
                );
              },
            });
          }}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  };


  const addGradeFee = (student, selectedClass) =>{
    let stuList = props.studentList;

    //index of selected class
    const classIndex = props.classList.findIndex(obj => obj.classId === selectedClass.key);
    if(classIndex !== -1){
      //find student to which class is selected
      let stuIndex = stuList.findIndex(obj => obj.contactId === student.contactId)

      //temp student to add more info in student obj
      let tempStudent = stuList[stuIndex];
      tempStudent.gradefee = props.classList[classIndex].fees;
      //tempStudent.grade = selectedClass.name;
      tempStudent.className = selectedClass.name;
      tempStudent.classId = selectedClass.key;

      //removing the existing student and adding new one in list  
      stuList.splice(stuIndex, 1, tempStudent);

      props.updateState({studentList : stuList})
    }
  }

  const addStudentAge = (student, age) =>{
    let stuList = props.studentList;

    let stuIndex = stuList.findIndex(obj => obj.contactId === student.contactId);
    if(stuIndex > -1){
      let tempStudent = stuList[stuIndex];
      tempStudent.age = age;
      stuList.splice(stuIndex, 1, tempStudent);
      props.updateState({studentList : stuList})
    }
  }

  const getOneTimeCharges = (items) =>{
    let totalOneTimeCharge = 0;
    if(items && items.length){

      items.forEach((item)=>{
        totalOneTimeCharge += Number(item.amount)
      })
    }
    return totalOneTimeCharge.toFixed(2);
  }

  return (
    <>
      <div className="register-container">
        <Row
          gutter={24}
          className="reg-header"
          style={{
            background: formSetting.bgColor || "#31c0be",
            color: formSetting.textColor || "#ffffff",
          }}
        >
          <Col span={6} style={{ padding: "0px" }}>
            {(props.companyInfo || {}).logoAwsObjectKey ? (
              <LogoComponent
                src={CONSTANTS.BASE_URL_S3 +"/" +config.BUCKET_NAME.BO_RELATIONSHIP +"/" +(props.companyInfo || {}).logoAwsObjectKey +"?" +Date.now() }
                size={90}
              />
            ) : (
              ""
            )}
          </Col>
          {console.log('::::: props fot the registration form ', props)}
          <Col span={10}></Col>
          <Col span={8} className="address-part" style={{paddingRight: '0px'}}>
            {getAddressInfoFragment(
              props.relationshipBillingAddress || {},
              companyInfo
            )}
          </Col>
        </Row>

        <div className="form-container">
          <div className="form-header">
            <div className="form-heading">{props.programName ? props.programName : props.formSetting.formShortName}</div>
            {formSetting.sessionStart && formSetting.sessionEnd ? (
              <div className="from-subheading">
                Session ({formSetting.sessionStart.split(' ')[0]} - {formSetting.sessionEnd.split(' ')[0]}
                {/* {getMomentDateForUIReadOnly({date: formSetting.sessionStart.split(' ')[0]}) - getMomentDateForUIReadOnly({date: formSetting.sessionEnd.split(' ')[0]})} */}
                )
              </div>
            ) : (
              ""
            )}
            {formSetting.subHeading ? (
              <div className="from-subheading">{formSetting.subHeading}</div>
            ) : (
              ""
            )}
          </div>

        </div>

        {props.status === "success" && (
          <div style={{ fontSize: "16px", textAlign: "center" }}>
            <b>
              {ReactHtmlParser(props.successMessage) ||
                props.intl.formatMessage({
                  id: "registration.completion.success",
                })}{" "}
            </b>
          </div>
        )}

        {props.status === "error" && (
          <div style={{ fontSize: "16px", textAlign: "center", color: "red" }}>
            <b>
              {props.intl.formatMessage({
                id: "registration.completion.failure",
              })}{" "}
            </b>
          </div>
        )}

        {props.status === "inactive" && (
          <div style={{ fontSize: "16px", textAlign: "center" }}>
            <b>{props.formSetting.inactiveMessage || "Registration Closed"} </b>
          </div>
        )}

        {props.isFormSaved ? (
          <div style={{ fontSize: "16px", textAlign: "center" }}>
            <b>
              {props.successMessage ? ReactHtmlParser(props.successMessage) :
                props.intl.formatMessage({
                  id: "registration.completion.success",
                })}{" "}
            </b>
          </div>
        ) : formSetting.schoolLevelValidation &&
          Number(formSetting.maxStudent || 0) <= props.totalStudentCount ? (
          <div style={{ fontSize: "16px", textAlign: "center" }}>
            <b>
              {props.intl.formatMessage({
                id: "no.more.seat.available.message",
              })}{" "}
            </b>
          </div>
        ) : (
          (!props.status && !props.isFormSaved) && (<>
          <div className="info-box">
            <div className="parents-info">
              <Row>
                <Col span={20} className="info-head">
                  Parents Contact &nbsp;
                  <span className="primary-label"> Primary </span>
                </Col>
                <Col
                  span={4} className="cursor-pointer"
                  
                >
                  <Button className="edit-btn-rf" type="primary" ghost
                  onClick={() => {
                    let formData = {
                      customerId: props.customerId,
                      customerName: props.customerInfo.companyName,
                      contactData: props.primayContactObj,
                    };
                    handleNewContactAddition(props, { formData: formData });
                  }}>
                 <EditOutlined /> Edit
                 </Button>
                  
                </Col>

                <Col span={8}>
                  <b>Full Name</b>
                </Col>
                <Col span={16}>
                  {props.customerInfo.boContactList[0].firstName} {props.customerInfo.boContactList[0].lastName}

                </Col>

                <Col span={8}>
                  <b>Relationship</b>
                </Col>
                <Col span={16}>
                  {props.customerInfo?.boContactList[0]?.familyRelationship ? capitalizeFirstLetter(props.customerInfo.boContactList[0].familyRelationship) : '-'}
                </Col>

                <Col span={8}>
                  <b>Email</b>
                </Col>
                <Col span={16}>{props.customerInfo.boContactList[0].emailAddress} </Col>
                <Col span={8}>
                  <b>Mobile</b>
                </Col>
                <Col span={16}>{props.customerInfo.boContactList[0].cellPhone ? `+${props.companyInfo.countryCallingCode}-${props.customerInfo.boContactList[0].cellPhone}`:'-'}</Col>
              </Row>
            </div>
            <div
              style={{
                width: "1px",
                background: "#D1D0D6",
                margin: "25px 0px 15px 0px",
              }}
            />
            <div className="parents-info">
              {props.formSetting.addressDisplay ? 
              
                <Row>
                  <Col span={20} className="info-head">
                    Student Address<span className="required">*</span>
                  </Col>
                  <Col
                    span={4} className="cursor-pointer"
                  >
                    <Button className="edit-btn-rf" type="primary" ghost
                    onClick={() => {
                      let formData = {
                        isUpdate: true,
                        ...props.address,
                        locationType: CONSTANTS.BILLING_ADDRESS,
                        customerId: props.address.customerId || props.customerId,
                        customerName: props.customerInfo.companyName,
                      };
                      handleAddressAddition(props, { formData: formData });
                    }}>
                  <EditOutlined /> Add
                  </Button>
                  </Col>
                    {getStudentAddress(props.address ? props.address: {})}
                </Row>
                :''
              }
              {/* {getAddressInfoFragment(props.relationshipBillingAddress || {}, companyInfo)} */}
            </div>
          </div>
            <Form layout="vertical" onSubmit={save}>

              
              {/* <Row gutter={16}>
                            {formSetting.formDescription ?
                                <Col span={24}>
                                    {formSetting.formDescription ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>{ReactHtmlParser(formSetting.formDescription)}</div> : ''}
                                </Col> : ''}
                        </Row> 
                        <Row gutter={22}>
                           
                                
                                    {formSetting.membershipTypeDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed ' label={<span><FormattedMessage id='membershipType.text' /> {formSetting.membershipTypeRequired ? <span className="required">*</span> : ''} </span>}>
                                        <Dropdown
                                                items={['New' , 'Renew']}
                                                required={formSetting.membershipTypeRequired}
                                                placeholder={props.intl.formatMessage({ id: 'membershipType.text' })}
                                                onSelect={(selectedValue) => {
                                                    props.updateState({ membershipType: selectedValue });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                </Row> 
               <Row gutter={16} className='mb30'>
                            <Col span={12} md={12} xs={24} className="boxbor">
                                <Card
                                    title="Father Info"
                                    extra={<i className="fa fa-pencil" style={{cursor:'pointer'}} onClick={()=>{ editProfile(); }}></i>}
                                    style={{
                                        width: 450,
                                        background: '#f8f8f8'
                                    }}
                                >
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">First Name</Col><Col span={12}>{props.fatherFirstName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Last Name:</Col><Col span={12}>{props.fatherLastName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Email:</Col><Col span={12}>{props.fatherEmail || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6}className="textright15">Mobile:</Col><Col span={12}>{props.fatherMobile || '-'}</Col></Row>
                                </Card>
                            </Col>
                            <Col span={12} md={12} xs={24} className="boxbor">
                                <Card 
                                    title="Mother Info"
                                    extra={<i className="fa fa-pencil" style={{cursor:'pointer'}} onClick={()=>{ editProfile(); }}></i>}
                                    style={{
                                        width: 450,
                                        background: '#f8f8f8'
                                    }}
                                >
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">First Name:</Col><Col span={12}>{props.motherFirstName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Last Name:</Col><Col span={12}>{props.motherLastName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Email:</Col><Col span={12}>{props.motherEmail || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Mobile:</Col><Col span={12}>{props.motherMobile || '-'}</Col></Row>
                                </Card>
                            </Col>
                        </Row> 
               <Row gutter={16}>
                            {formSetting.fatherFirstNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='register.fatherFirstName' />{formSetting.fatherFirstNameRequired ? <span className="required">*</span> : ''}</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}>
                                        <TextBox className='new-fed1'
                                            placeholder={intl.formatMessage(
                                                { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                            )}
                                            type='text'
                                            maxLength={25}
                                            value={props.fatherFirstName}
                                            onChange={(e) => {
                                                props.updateState({ fatherFirstName: e.target.value });
                                            }}
                                            required={formSetting.fatherFirstNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.fatherLastNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='register.fatherLastName' /> {formSetting.fatherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder={intl.formatMessage(
                                                { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                            )}
                                            type='text'
                                            required={formSetting.fatherLastNameRequired}
                                            maxLength={25}
                                            value={props.fatherLastName}
                                            onChange={(e) => {
                                                props.updateState({ fatherLastName: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>
                        {formSetting.fatherEmailDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='father.email' /> {formSetting.fatherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter email'
                                            type='email'
                                            maxLength={45}
                                            value={props.fatherEmail}
                                            onChange={(e) => {
                                                props.updateState({ fatherEmail: e.target.value });
                                            }}
                                            onBlur={() => {
                                                if(!props.customerId){
                                                    props.fetchStudentFatherEmail({ email: props.fatherEmail, type: 'fatherEmail', registrationFormSettingId: props.registrationFormSettingId }, props)
                                                }
                                            }}
                                            required={formSetting.fatherEmailRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.fatherMobileDisplay ?
                                <Col span={12} className="newregis">
                                    <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='father.mobile' /> {formSetting.fatherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                    <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter mobile number"
                                            style={{ paddingLeft: '45px' }}
                                            type='text'
                                            //value={contact.lastName}
                                            required={formSetting.fatherMobileRequired}
                                            maxLength={10}
                                            value={props.fatherMobile}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        fatherMobile: e.target.value,
                                                    });
                                                }else if(!props.fatherMobile){
                                                    props.updateState({ fatherMobile: '' });
                                                }
                                                //props.updateState({ fatherMobile: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            
                        </Row>

                        <Row gutter={16}>
                            {formSetting.motherFirstNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.fName' /> {formSetting.motherFirstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder={intl.formatMessage(
                                                { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                            )}
                                            type='text'
                                            maxLength={25}
                                            value={props.motherFirstName}
                                            onChange={(e) => {
                                                props.updateState({ motherFirstName: e.target.value });
                                            }}
                                            required={formSetting.motherFirstNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.motherLastNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.lName' /> {formSetting.motherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder={intl.formatMessage(
                                                { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                            )}
                                            type='text'
                                            maxLength={25}
                                            value={props.motherLastName}
                                            onChange={(e) => {
                                                props.updateState({ motherLastName: e.target.value });
                                            }}
                                            required={formSetting.motherLastNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>


                        <Row gutter={16}>
                        {formSetting.motherEmailDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.email' /> {formSetting.motherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter email'
                                            type='email'
                                            maxLength={45}
                                            value={props.motherEmail}
                                            onChange={(e) => {
                                                props.updateState({ motherEmail: e.target.value });
                                            }}
                                            onBlur={() => {
                                                if(!props.customerId){
                                                    props.fetchStudentFatherEmail({ email: props.motherEmail, type: 'motherEmail', registrationFormSettingId: props.registrationFormSettingId }, props)
                                                }
                                                
                                            }}
                                            required={formSetting.motherEmailRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.motherMobileDisplay ?
                                <Col span={12} className="newregis">
                                    <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='mother.mobile' /> {formSetting.motherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                    <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter mobile number"
                                            style={{ paddingLeft: '45px' }}
                                            type='text'
                                            maxLength={10}
                                            value={props.motherMobile}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        motherMobile: e.target.value,
                                                    });
                                                }else if(!props.motherMobile){
                                                    props.updateState({ motherMobile: '' });
                                                }
                                               // props.updateState({ motherMobile: e.target.value });
                                            }}
                                            required={formSetting.motherMobileRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            
                        </Row> */}

              <Divider />

              <div className="form-box">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="bold-title">
                    Student Information
                  </div>
                  <Button type="primary"
                    onClick={() => {
                      if (
                        props.customerInfo.boContactList &&
                        props.customerInfo.boContactList.length > 0
                      ) {
                        addStudentHandle();
                      } else {
                        editProfile();
                      }
                    }}
                  >
                    <FormattedMessage id="add.student" defaultMessage="" />
                  </Button>
                </div>

                <div className="table-container ">
                  <table
                    onMouseLeave={() => {
                      props.updateState({
                        editableIndex: -1,
                      });
                    }}
                  >
                    <thead>
                      <tr>
                        <th width='20%'>Student Name<span className="required">*</span></th>
                        <th width='20%'>{getUserDefinedName('class', props)}<span className="required">*</span></th>
                        <th width='15%'>Fees<span className="required">*</span>{props.formSetting.isDisplayYearlyFee ? " (Session)": ' (Monthly)'}</th>
                        {JSON.parse(props.registrationFormSetting.onetimeInvoiceItem || '[]').length ? <th width='15%'>Charges(One Time)</th>:<th width='1% '></th>}
                        <th width='10%'>{props.formSetting.dobType === 2 ? 'Age' : 'DOB'}{props.formSetting.dobType === 2 ? <span className="required">*</span> : ''}</th>
                        <th width='6%'>Allergies<span className="required">*</span></th>
                        <th width='8%'>Gender</th>
                        <th width='6%'><FormattedMessage id="customer.listing.header.action" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(props.studentList || []).map((e, i) => {
                        return (
                          <tr key={"s" + i}>
                            <td>
                              {e.firstName} {e.lastName}
                            </td>
                            <td>
                              <Dropdown
                                items={props.classList || []}
                                optionKeyName='classId'
                                valueKeyName= 'className'
                                value={e.className}
                                placeholder={getUserDefinedName('class', props)}
                                onSelect={(selectedValue ,option) => {
                                  addGradeFee(e, option);
                                }}
                              />
                            </td>
                            <td>
                              {props.companyInfo?.currencyIcon && e.gradefee ? <i className={props.companyInfo?.currencyIcon} />: '' }{e.gradefee ? Number(e.gradefee * (props.formSetting.isDisplayYearlyFee ? (getMonthsBetweenDates(formSetting.sessionStart, formSetting.sessionEnd) || 12) : 1)).toFixed(2) : ''}
                            </td>
                            {JSON.parse(props.registrationFormSetting.onetimeInvoiceItem || '[]').length ?
                            <td>
                              {props.companyInfo?.currencyIcon ? <i className={props.companyInfo?.currencyIcon} />: '' }{getOneTimeCharges(JSON.parse(props.registrationFormSetting.onetimeInvoiceItem))}
                            </td>:<td></td>}
                            <td>
                              {/* {props.formSetting.dobType === 2 && e.dateOfBirth ? getAgeFromDOB(e.dateOfBirth) : (e.dateOfBirth ? getMomentDateForUIReadOnly(e.dateOfBirth) : '-') } */}
                              {props.formSetting.dobType === 2 ? 
                                <TextBox
                                  placeholder={props.intl.formatMessage({ id: 'age.text', defaultMessage: '' })}
                                  type="number"
                                  maxLength={5}
                                  value={e.age ? Number(e.age): ''}
                                  onChange={(ee) => {
                                    addStudentAge(e, ee.target.value)
                                  }}
                                />
                                : 
                                (e.dateOfBirth ? getMomentDateForUIReadOnly(e.dateOfBirth) : '-')
                              }
                            </td>
                            <td>
                              {e.allergies === 'No' ? 'No' : 'Yes'}
                            </td>
                            <td>{e.gender || '-'}</td>
                            <td style={{paddingLeft: '50px'}}>
                              <DropdownAction overlay={actionMenu(e, i)} trigger={['click']}>
                                <MoreOutlined />
                              </DropdownAction>
                              {/* <div className="delrt-sticky action-cell">
                                <i
                                  className="fa fa-edit"
                                  onClick={() => {
                                    let formData = {  ...e,customerId: props.customerInfo
                                          .customerId,
                                      customerName:
                                        props.customerInfo
                                          .companyName,
                                      contactData: contact,
                                      contactId:
                                        contact.contactId,
                                    };
                                    handleNewEducationAddition(
                                      props,
                                      {
                                        formData: formData,
                                      },
                                      contact
                                    );
                                  }}
                                  type="edit"
                                />
                                <i
                                  className="fa fa-trash"
                                  onClick={() => {
                                    props.showModal({
                                      modalBody:
                                        "Are you sure you want to delete?",
                                      handleSubmit: () => {
                                        props.deleteContactEducation(
                                          e,
                                          props
                                        );
                                      },
                                    });
                                  }}
                                  type="delete"
                                />
                              </div> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* /grade validator for feessss */}
                {/* {(Object.keys(formSetting.gradeValidations || {}).length ||
                  !formSetting.gradeLevelValidation) && (
                  <div className="agGridWrapper">
                    <div className="table-container mt10 mb20">
                      <table
                        onMouseLeave={() => {
                          props.updateState({ editableIndex: -1 });
                        }}
                      >
                        <thead
                          style={{
                            borderTop: "1px solid #E8E8FF",
                            borderBottom: "1px solid #E8E8FF",
                            height: "35px",
                            color: "#5f5f60",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>
                              <span>
                                <FormattedMessage id="signup.firstname.placeholder" />
                                {props.registrationType === "student" ? (
                                  <span className="required">*</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                            <th>
                              <span>
                                <FormattedMessage id="signup.lastname.placeholder" />
                                {props.registrationType === "student" ? (
                                  <span className="required">*</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                            <th className="w18">
                              <span>
                                <FormattedMessage id="grade.text" />
                                {props.registrationType === "student" ? (
                                  <span className="required">*</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                            <th>
                              {formSetting.isDisplayYearlyFee ? (
                                <span>
                                  <FormattedMessage id="gradefeeYearly.header.text" />
                                </span>
                              ) : (
                                <span>
                                  <FormattedMessage id="gradefee.header.text" />
                                </span>
                              )}
                            </th>
                            <th>
                              <span>
                                <FormattedMessage id="dob.text" />
                                {props.registrationType === "student" ? (
                                  <span className="required">*</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                            <th className="w12">
                              <span>
                                <FormattedMessage id="allergies.header.text" />
                                {props.registrationType === "student" ? (
                                  <span className="required">*</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                            <th className="w8">
                              <span>
                                <FormattedMessage id="gender.text" />
                                {props.registrationType === "student" ? (
                                  <span className="required">*</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                            <th>
                              <FormattedMessage id="customer.listing.header.action" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(props.studentList || []).map((e, i) => {
                            return (
                              <tr key={"std" + i}>
                                <td style={{ textAlign: "center" }}>{i + 1}</td>
                                <td>{e.firstName}</td>
                                <td>{e.lastName}</td>
                                <td
                                  className="wr18"
                                  onMouseEnter={() => {
                                    props.updateState({ editableIndex: i });
                                  }}
                                >
                                  {props.editableIndex === i ? (
                                    <Dropdown
                                      className="grade-dropdown"
                                      items={gradeArr}
                                      value={e.gId}
                                      optionKeyName="gId"
                                      valueKeyName="gradeValidation"
                                      isGradeLevel={true}
                                      isDisplayYearlyFee={
                                        formSetting.isDisplayYearlyFee
                                          ? true
                                          : false
                                      }
                                      totalMonths={
                                        getMonthsBetweenDates(
                                          formSetting.sessionStart,
                                          formSetting.sessionEnd
                                        ) || 1
                                      }
                                      valueKeyGradeFee="gradefee"
                                      addressLineKey1="subcourseName"
                                      currencyCode={
                                        companyInfo.currencyCode || ""
                                      }
                                      onSelect={(gId, optionObj) => {
                                        const selectedObj = find(gradeArr, {
                                          gId: optionObj.key,
                                        });
                                        let selectedValue =
                                          selectedObj.gradeValidation;

                                        e.gradefee = null;

                                        if (selectedObj) {
                                          e.gradefee = selectedObj.gradefee;
                                          e.subcourseName =
                                            selectedObj.subcourseName;
                                          e.subcourseId =
                                            selectedObj.subcourseId;
                                          e.gId = selectedObj.gId;
                                          e.grade = selectedValue;
                                          e.invalidGrade =
                                            formSetting.gradeLevelValidation
                                              ? true
                                              : false;
                                        }

                                        if (formSetting.gradeLevelValidation) {
                                          let isInvalid = false;
                                          if (
                                            selectedValue &&
                                            Number(
                                              (formSetting.gradeValidations ||
                                                {})[selectedValue] || 0
                                            ) <=
                                              Number(
                                                (self.totalStudentCountByGrade ||
                                                  {})[selectedValue] || 0
                                              )
                                          ) {
                                            isInvalid = true;
                                          }

                                          if (
                                            isInvalid &&
                                            formSetting.invalidGradeMessage
                                          ) {
                                            //showToasterMessage({   messageType: 'warning', description: props.intl.formatMessage({ id: 'no.more.seat.available.inGrade.message', values: { value: selectedValue } })});
                                            showToasterMessage({
                                              messageType:
                                                formSetting.isStopRegistrationAfterMaxStudent
                                                  ? "error"
                                                  : "warning",
                                              description:
                                                formSetting.invalidGradeMessage,
                                            });
                                          } else {
                                            e.invalidGrade = false;
                                            e.gradefee = selectedObj.gradefee;
                                            e.subcourseName =
                                              selectedObj.subcourseName;
                                            e.subcourseId =
                                              selectedObj.subcourseId;
                                            e.gId = selectedObj.gId;
                                            e.grade = selectedValue;
                                            let totalStudentCountByGrade =
                                              self.totalStudentCountByGrade ||
                                              {};
                                            totalStudentCountByGrade[
                                              selectedValue
                                            ] =
                                              Number(
                                                totalStudentCountByGrade[
                                                  selectedValue
                                                ] || 0
                                              ) + 1;
                                            props.updateState({
                                              totalStudentCountByGrade,
                                            });
                                          }
                                        }
                                        let studentList = props.studentList;
                                        studentList.splice(i, 1, e);
                                        props.updateState(studentList);
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {e.grade ? (
                                        <div>
                                          <div>Grade {e.grade}</div>
                                          <div>{e.subcourseName}</div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <span>
                                    {formSetting.isDisplayYearlyFee
                                      ? e.gradefee
                                        ? (
                                            Number(e.gradefee) *
                                            getMonthsBetweenDates(
                                              formSetting.sessionStart,
                                              formSetting.sessionEnd
                                            )
                                          ).toFixed(2)
                                        : 0.0
                                      : Number(e.gradefee || 0).toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {e.dob
                                      ? getMomentDateForUIReadOnly({
                                          date: e.dob,
                                          format: CONSTANTS.TABLE_DATE_FORMAT,
                                        })
                                      : ""}
                                  </span>
                                </td>
                                <td className="w12">
                                  {ReactHtmlParser(e.allergies)}
                                </td>
                                <td className="w8">{e.gender || ""}</td>
                                <td>
                                  <DropdownAction
                                    overlay={actionMenu(e, i)}
                                    trigger={["click"]}
                                  >
                                    <MoreOutlined />
                                  </DropdownAction>
                                  {/* <div className="delrt-sticky action-cell">
                                   
                                    <i
                                      className="fa fa-edit"
                                      onClick={() => {
                                        let formData = {
                                          customerId: props.customerId,
                                          customerName:
                                            props.customerInfo.companyName,
                                          contactData: e,
                                        };
                                        handleNewContactAddition(
                                          props,
                                          { formData: formData },
                                          true,
                                          i
                                        );
                                      }}
                                      type="edit"
                                    />
                                    <i
                                      className="fa fa-trash"
                                      onClick={() => {
                                        let studentList = props.studentList;
                                        studentList.splice(i, 1);
                                        props.updateState(studentList);
                                      }}
                                      type="delete"
                                    />
                                  </div> */}
                                {/* </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )} */} 


                {formSetting.educationHistory ? (
                    <Row>
                      <Col span={24}>
                        {(props.studentList || []).length ? 
                        
                          <Tabs
                            onChange={(key) => {
                              props.resetContactEducation();
                              props.resetContactEmployment();
                              let contactObj =
                                find(props.customerInfo.boContactList || [], {
                                  contactId: Number(key),
                                }) || {};
                              props.getContactEducation(contactObj);
                              props.getContactEmployment(contactObj);
                            }}
                            onTabClick={(key)=>{
                              if((props.studentList || []).length === 1){
                                props.resetContactEducation();
                                props.resetContactEmployment();
                                let contactObj =
                                  find(props.customerInfo.boContactList || [], {
                                    contactId: Number(key),
                                  }) || {};
                                props.getContactEducation(contactObj);
                                props.getContactEmployment(contactObj);
                              }
                            }}
                          >
                            {(props.studentList || []).map(
                              (student, i) => {
                                if(student.isPrimaryContact !== 1){
                                  return (
                                    <TabPane
                                      tab={
                                        student.firstName +
                                        (student.lastName
                                          ? " " + student.lastName
                                          : "")
                                      }
                                      key={student.contactId}
                                    >
                                      <div className="student-info">
                                        <div className="info-head">
                                          <div className="head-title">
                                            Education
                                          </div>
                                        
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              let formData = {
                                                customerId:
                                                  props.customerInfo.customerId,
                                                customerName:
                                                  props.customerInfo.companyName,
                                                contactData: student,
                                                contactId: student.contactId,
                                              };
                                              handleNewEducationAddition(
                                                props,
                                                { formData: formData },
                                                student
                                              );
                                            }}
                                          >
                                            Add Education
                                          </Button>
                                        
                                        </div>
                                        <div className="table-container ">
                                          {(props.contactEducationList || []).length ? 
                                          <table
                                            onMouseLeave={() => {
                                              props.updateState({
                                                editableIndex: -1,
                                              });
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th width={'6%'}>S.No.</th>
                                                <th>Institution Name</th>
                                                <th>Degree Type / {getUserDefinedName('Class', props)}</th>
                                                <th>Major</th>
                                                {/* <th>Grade</th> */}
                                                <th>Score/GPA</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th width={'6%'}><FormattedMessage id="customer.listing.header.action" /></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {(
                                                props.contactEducationList || []
                                              ).map((e, i) => {
                                                return (
                                                  <tr key={"std" + i}>
                                                    <td>
                                                      {i + 1}
                                                    </td>
                                                    <td>
                                                      {e.institutionName || "-"}
                                                    </td>
                                                    <td>
                                                      {e.schoolType === "college"
                                                        ? e.degreeType || "-"
                                                        : 
                                                        (e.schoolType === "school" ?
                                                          <>{getUserDefinedName('Class', props)} {e.grade || "-"}</>
                                                          : "NA"
                                                        )
                                                      }
                                                    </td>
                                                    <td>
                                                      {e.schoolType === "college"
                                                        ? e.major || "-"
                                                        : "NA"}
                                                    </td>
                                                    {/* <td>{(e.schoolType === 'college' ? 'NA' :(e.grade || '-'))}</td> */}
                                                    <td>{e.score || '-'}</td>
                                                    <td>
                                                      <span>
                                                        {e.startDate
                                                          ? getMomentDateForUIReadOnly(
                                                              {
                                                                date: e.startDate,
                                                                format:
                                                                  CONSTANTS.TABLE_DATE_FORMAT,
                                                              }
                                                            )
                                                          : "-"}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span>
                                                        {e.endDate
                                                          ? getMomentDateForUIReadOnly(
                                                              {
                                                                date: e.endDate,
                                                                format:
                                                                  CONSTANTS.TABLE_DATE_FORMAT,
                                                              }
                                                            )
                                                          : "-"}
                                                      </span>
                                                    </td>
                                                    <td style={{paddingLeft: '50px'}}>
                                                      <DropdownAction overlay={actionMenuEd(e, student)} trigger={['click']}>
                                                        <MoreOutlined />
                                                      </DropdownAction>
                                                      {/* <div className="delrt-sticky action-cell">
                                                        <i
                                                          className="fa fa-edit"
                                                          onClick={() => {
                                                            let formData = {  ...e,customerId: props.customerInfo
                                                                  .customerId,
                                                              customerName:
                                                                props.customerInfo
                                                                  .companyName,
                                                              contactData: contact,
                                                              contactId:
                                                                contact.contactId,
                                                            };
                                                            handleNewEducationAddition(
                                                              props,
                                                              {
                                                                formData: formData,
                                                              },
                                                              contact
                                                            );
                                                          }}
                                                          type="edit"
                                                        />
                                                        <i
                                                          className="fa fa-trash"
                                                          onClick={() => {
                                                            props.showModal({
                                                              modalBody:
                                                                "Are you sure you want to delete?",
                                                              handleSubmit: () => {
                                                                props.deleteContactEducation(
                                                                  e,
                                                                  props
                                                                );
                                                              },
                                                            });
                                                          }}
                                                          type="delete"
                                                        />
                                                      </div> */}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                          :''}
                                        </div>
                                      </div>
                                    </TabPane>
                                  );
                                }
                              
                              }
                            )}
                          </Tabs>
                          :
                          <div className="notification">
                              No student added, please add student by clicking on "Add Student" button
                            </div>
                              }
                      </Col>
                    </Row>
                  )
                  : 
                  ""
                }

                {/* additional form details */}
                <Row gutter={[16, 16]} className="pt20">
                  {formSetting.familyDoctorNameDisplay ? (
                    <>
                      <Col span={12}>
                        <div className="input-label">
                          <FormattedMessage id="familyDoctorName.text" />
                          {formSetting.familyDoctorNameRequired ? <span className="required">*</span>:''}
                        </div>
                        <TextBox
                          placeholder="Family Doctor Name"
                          type="text"
                          maxLength={45}
                          value={props.familyDoctorName}
                          onChange={(e) => {
                            props.updateState({
                              familyDoctorName: e.target.value,
                            });
                          }}
                          // required={true}
                        />
                        <ErrorMsg
                          validator={() => { return !props.submitClicked || !(formSetting.familyDoctorNameDisplay && formSetting.familyDoctorNameRequired && !props.familyDoctorName) }}
                          message={props.intl.formatMessage(
                            {
                                id: 'requiredField.missing.msg',
                                defaultMessage: ''
                            })
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <div className="input-label">
                          <FormattedMessage id="familyDoctorPhone.text" />{" "}
                          {formSetting.familyDoctorNameRequired ? <span className="required">*</span> : ''}
                        </div>
                        <div className="input-currency-control">
                          <span
                            className="currency-tag2"
                            style={{ marginTop: "0px" }}
                          >
                            {props.companyInfo.countryCallingCode
                              ? "+" + props.companyInfo.countryCallingCode
                              : " "}
                          </span>

                          <TextBox
                            style={{ paddingLeft: "45px" }}
                            placeholder="Family Doctor Phone"
                            type="text"
                            maxLength={10}
                            value={props.familyDoctorPhone}
                            onChange={(e) => {
                              props.updateState({
                                familyDoctorPhone: e.target.value,
                              });
                            }}
                            // required={formSetting.familyDoctorPhoneRequired}
                          />
                          <ErrorMsg
                            validator={() => { return !props.submitClicked || !(formSetting.familyDoctorNameDisplay && formSetting.familyDoctorNameRequired && !props.familyDoctorPhone) }}
                            message={props.intl.formatMessage(
                              {
                                id: 'requiredField.missing.msg',
                                defaultMessage: ''
                              })
                            }
                          />
                        </div>
                      </Col>
                    </>
                  ) : ""
                  }

                {formSetting.emergencyContactDisplay ? (
                    <>
                    <Col span={12}>
                      <div className="input-label">
                        <FormattedMessage id="setting.student.registration.form.emergencyContactName" />{" "}
                        {formSetting.emergencyContactRequired ? <span className="required">*</span>:''}
                      </div>
                      <TextBox
                        placeholder="Enter Name"
                        type="text"
                        maxLength={45}
                        value={props.emergencyContactName}
                        onChange={(e) => {
                          props.updateState({
                            emergencyContactName: e.target.value,
                          });
                        }}
                        // required={true}
                      />
                      <ErrorMsg
                        validator={() => { return !props.submitClicked || !(formSetting.emergencyContactDisplay && formSetting.emergencyContactRequired && !props.emergencyContactName) }}
                        message={props.intl.formatMessage(
                          {
                            id: 'requiredField.missing.msg',
                            defaultMessage: ''
                          })
                        }
                      />
                    </Col>

                    <Col span={12}>
                      <div className="input-label">
                        <FormattedMessage id="setting.student.registration.form.emergencyPhone" />{" "}
                        <span className="required">*</span>
                      </div>
                      <div className="input-currency-control">
                        <span
                          className="currency-tag2"
                          style={{ marginTop: "0px" }}
                        >
                          {props.companyInfo.countryCallingCode
                            ? "+" + props.companyInfo.countryCallingCode
                            : " "}
                        </span>

                        <TextBox
                          style={{ paddingLeft: "45px" }}
                          placeholder="Enter Number"
                          type="text"
                          maxLength={10}
                          value={props.emergencyPhone}
                          onChange={(e) => {
                            props.updateState({
                              emergencyPhone: e.target.value,
                            });
                          }}
                          required={formSetting.emergencyPhoneRequired}
                        />

                        <ErrorMsg
                          validator={() => { return !props.submitClicked || !(formSetting.emergencyContactDisplay && formSetting.emergencyContactRequired && !props.emergencyPhone) }}
                          message={props.intl.formatMessage(
                            {
                              id: 'requiredField.missing.msg',
                              defaultMessage: ''
                            })
                          }
                        />
                      </div>
                    </Col>
                    </>
                ) : ""}

                {formSetting.askAddTeacher ? 
                  <Col span={12}>
                    <div className="input-label">
                      Are you Teacher?
                      {/* {props.askAddTeacherRequired ? (
                        <span className="required">*</span>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <Switch
                      checked={props.isTeacher === 1}
                      onClick={(checked, e) => {
                        props.updateState({ isTeacher: checked ? 1 : 0 });
                      }}
                    />
                  </Col>
                  :''
                }

                {formSetting.askAddVolunteer ? 
                  <Col span={12}>
                    <div className="input-label">
                      Do you volunteer?
                      {/* {props.askAddVolunteerRequired ? (
                        <span className="required">*</span>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <Switch
                      checked={props.isVolunteer === 1}
                      onClick={(checked, e) => {
                        props.updateState({
                          isVolunteer: checked ? 1 : 0,
                        });
                      }}
                    />
                  </Col> :''
                }

                {formSetting.askFinanacialAssistance ? 
                  <Col span={12}>
                    <div className="input-label">
                      Need financial assistance?
                      {/* {formSetting.askFinanacialAssistanceRequired ? (
                        <span className="required">*</span>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <Switch
                      checked={props.isFinancialAssistanceNeeded === 1}
                      onClick={(checked, e) => {
                        props.updateState({
                          isFinancialAssistanceNeeded: checked ? 1 : 0,
                        });
                      }}
                    />
                  </Col>
                 : 
                ""
                }
                {props.isFinancialAssistanceNeeded === 1 ? 
                  <Col span={12}>
                    <div className="input-label">
                      Reason for financial assistance <span className="required">*</span>
                    </div>
                    <TextArea
                      onChange ={(e)=>{
                        props.updateState({financialAssistanceReason : e.target.value})
                      }}
                      style={{width: '100%', padding: '5px 10px'}}
                      value = {props.financialAssistanceReason}
                    />
                    <ErrorMsg
                      validator={() => { return !props.submitClicked || !(formSetting.askFinanacialAssistance && !props.financialAssistanceReason) }}
                      message={props.intl.formatMessage(
                        {
                          id: 'requiredField.missing.msg',
                          defaultMessage: ''
                        })
                      }
                    />
                    
                  </Col>
                 : 
                ""
                }

                {formSetting.askOrgMember ? 
                  <Col span={12}>
                    <div className="input-label">
                      Are you a member of {companyInfo.storeName}?
                      {formSetting.askOrgMemberRequired ? (
                        <span className="required">*</span>
                      ) : ''
                      }
                    </div>
                    <Select
                      style={{ width: "100%" }}
                      value={props.orgMember}
                      placeholder="Membership Type"
                      onChange={(val) => {
                        props.updateState({ orgMember: val });
                      }}
                    >
                      <Option key={"No"} value={"No"}>
                        No
                      </Option>
                      <Option
                        key={"Individual Membership"}
                        value={"Individual Membership"}
                      >
                        Individual Membership
                      </Option>
                      <Option
                        key={"Family Membership"}
                        value={"Family Membership"}
                      >
                        Family Membership
                      </Option>
                      <Option key={"Yes-Other"} value={"Yes-Other"}>
                        Yes-Other
                      </Option>
                    </Select>
                    <ErrorMsg
                      validator={() => { return !props.submitClicked || !(formSetting.askOrgMember && !props.orgMember) }}
                      message={props.intl.formatMessage(
                        {
                          id: 'requiredField.missing.msg',
                          defaultMessage: ''
                        })
                      }
                    />
                  </Col>
                  : 
                  ""
                }

                {( props.isAllowParentPaymentPlan && props.paymentPlan && props.paymentPlan.length) ?
                  <Col span={12}>
                    <div className="input-label"><FormattedMessage id='select.paymentPlan' /> <span className="required">*</span></div>
                    <Select value={props.selectedPlanIndex} onChange={(val) => {
                        let selectedPlan = props.paymentPlan[val];
                        props.updateState({ selectedPlanIndex: val, selectedPlan: selectedPlan });
                    }}>
                      {
                        (props.paymentPlan || []).map((plan, i) => {
                            return (
                                // <Option key={i} value={i}>Pay in {plan.totalInstallment || 0} {plan.totalInstallment === 1 ? 'installment' : 'installments'}</Option>
                                <Option key={i} value={i}>Pay {capitalizeFirstLetter(plan.period === 'completeSession' ? 'One Time' : plan.period)}</Option>

                            )
                        })
                      }
                    </Select>
                    <ErrorMsg
                      validator={() => { return !props.submitClicked || !(props.isAllowParentPaymentPlan && !(props.selectedPlanIndex+1)) }}
                      message={props.intl.formatMessage(
                        {
                          id: 'requiredField.missing.msg',
                          defaultMessage: ''
                        })
                      }
                    />
                  </Col>:''                                            
                }

                </Row>


                <Row gutter={[16, 16]} className="pt20">
                  {Object.keys(formSetting).length &&
                    Object.keys(formSetting.questionList).length > 0 && formSetting.questionList[0].questionText ? <>
                    {/* <Col span={24} className="bold-title">
                      Questions
                    </Col> */}
                    <Col span={24}>
                      {
                        (props.questionList || []).map((queObj, i) => {
                            return (
                              <>
                                <Row gutter={[0, 16]}>
                                    <Col span={24}><span className="bold-title">{i+1}. </span>{queObj.questionText} {queObj.isRequired ? <span className="required">*</span> : ''}</Col>
                                
                                    <Col span={24} style={{paddingLeft: '10px'}}>
                                      {(queObj.optionList && queObj.optionList.length) ?<>
                                        {queObj.answerType === 'multiChoice' ?
                                             <Checkbox.Group 
                                              onChange={(answers) => {
                                                queObj.answers = answers;
                                                let qamList = props.questionList;
                                                qamList.splice(i, 1, queObj);
                                                // formSetting.questionList = questionList;
                                                // updateFormSetting(formSetting);
                                                props.updateState({questionList: qamList, isAnswerSubmitted: true});
                                              }}
                                              value={queObj.answers}
                                              
                                              >
                                                {(queObj.optionList || []).map((optObj, j) => {
                                                    return (<div key={'opt'+j}><Checkbox value={optObj.optText}> {optObj.optText} </Checkbox></div>)
                                                })
                                                }
                                              </Checkbox.Group> 
                                            :
                                            <Radio.Group onChange={(e) => {
                                                queObj.answers = e.target.value;
                                                let qasList = props.questionList;
                                                qasList.splice(i, 1, queObj);
                                                // formSetting.questionList = questionList;
                                                // updateFormSetting(formSetting);
                                                props.updateState({questionList: qasList, isAnswerSubmitted: true});
                                            }} 
                                            value={queObj.answers}
                                            >
                                              {(queObj.optionList || []).map((optObj, k) => {
                                                  return (optObj.optText ? <div key={'optA'+k}><Radio value={optObj.optText}> {optObj.optText} </Radio></div> : '')
                                              })}

                                            </Radio.Group>
                                        }</>
                                          : ''
                                      }

                                      {queObj.answerType === 'shortAnswer' ?
                                          <TextArea
                                            style={{ width: '100%', padding: '2px 10px' }}
                                            placeholder='Enter here'
                                            type='text'
                                            value={queObj.answers}
                                            onChange={(e) => {
                                              e.preventDefault();
                                                queObj.answers = e.target.value;
                                                let qashList = props.questionList;
                                                qashList.splice(i, 1, queObj);
                                                // formSetting.questionList = questionList;
                                                // updateFormSetting(formSetting);
                                                props.updateState({questionList: qashList, isAnswerSubmitted: true});
                                            }}
                                          />
                                        : ''
                                      }

                                    </Col>
                                </Row>
                              
                              </>
                            )
                        })
                      }
                    </Col>


                    {/* {formSetting.questionList.map((rowData, index) => {
                      return (
                        <>
                          <Col span={24}>
                            <b>Question {index + 1}. </b>{rowData.questionText}
                          </Col> */}

                          {/* <Col span={24}>
                            {rowData.answerType === "multiChoice" ? (
                              rowData.questionText &&
                              rowData.optionList.map((opt, index) => {
                                return (<>
                                  <Checkbox>{opt.optText}</Checkbox><br />
                                </>
                                );
                              })
                            ) : ''}

                            {!rowData.answerType || rowData.answerType === "singleChoice" ? (
                              <Radio.Group>
                                {rowData.questionText &&
                                  rowData.optionList.map((opt, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <Radio value={index + 1}>
                                            {opt.optText}
                                          </Radio>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </Radio.Group>
                            ) : ''}

                            {rowData.answerType === "shortAnswer" ? (
                              <TextArea
                                rows={4}
                                placeholder="Write your answer here"
                              />
                            ) : (
                              ""
                            )}
                          </Col> */}

                          {/* </div> */}
                          {/* <Divider />
                        </>
                      );
                    })} */}
                    
                  </> :
                  ""
                  }

         

                </Row>

                {/* <Row justify="end" className="pb30">
                  <Col md={{span: 8}} lg={{span: 6}}>
                    <Row className="p10" style={{background: '#e7e7e7'}}>
                      <Col span={18}>One Time Charge</Col>
                      <Col span={6}>
                        <i className={props.companyInfo?.currencyIcon || 'fa fa-dollar'} />{(getOneTimeCharges(JSON.parse(props.registrationFormSetting.onetimeInvoiceItem)) * ((props.studentList || []).length || 1)).toFixed(2)} 
                      </Col>
                      <Col span={18}>Fees</Col>
                      <Col span={6}>
                      <i className={props.companyInfo?.currencyIcon || 'fa fa-dollar'} />{(getTotalGradeFees() - getOneTimeCharges(JSON.parse(props.registrationFormSetting.onetimeInvoiceItem)) * ((props.studentList || []).length || 1)).toFixed(2)}
                      </Col>
                      <Col span={12}>Discount</Col>
                      <Col span={12}></Col>

                    </Row>
                  </Col>
                </Row> */}
              </div>
              {/* <Card
                                    title={<span><FormattedMessage id='primaryContactForInvoicing.text' /><span className="required">*</span> </span>}
                                    extra={<i className="fa fa-pencil" style={{cursor:'pointer'}} onClick={()=>{ 
                                        let formData = { customerId: props.customerId, customerName: props.customerInfo.companyName, contactData: props.primayContactObj }
                                        handleNewContactAddition(props, { formData: formData })
                                     }}></i>}
                                    style={{
                                        width: 450,
                                        background: '#f8f8f8'
                                    }}
                                >
                                    <Row style={{padding:'8px'}}><Col span={6} className="textright15">First Name</Col><Col span={12}>{(props.primayContactObj || {}).firstName || '-'}</Col></Row>
                                    <Row style={{padding:'8px'}}><Col span={6} className="textright15">Last Name:</Col><Col span={12}>{(props.primayContactObj || {}).lastName || '-'}</Col></Row>
                                    <Row style={{padding:'8px'}}><Col span={6} className="textright15">Email:</Col><Col span={12}>{(props.primayContactObj || {}).emailAddress || '-'}</Col></Row>
                                    <Row style={{padding:'8px'}}><Col span={6}className="textright15">Mobile:</Col><Col span={12}>{(props.primayContactObj || {}).cellPhone || '-'}</Col></Row>
                                </Card> */}
              {/* </Col> */}
              {/* { (props.address && props.address.locationId )?
                                    <Card
                                        title={<span><FormattedMessage id='signup.tab.address' /> </span>}
                                        extra={<i className="fa fa-pencil" style={{ cursor: 'pointer' }} onClick={() => {
                                            let formData = { isUpdate: true, ...props.address, locationType: CONSTANTS.BILLING_ADDRESS, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName };
                                            handleAddressAddition(props, { formData: formData });
                                        }}></i>}
                                        style={{
                                            width: 450,
                                            background: '#f8f8f8'
                                        }}
                                    >
                                        <Row style={{ padding: '8px' }}><Col span={18} className="pl10">{props.address.streetAddress1 || ''}</Col></Row>
                                       {props.address.streetAddress2 ? <Row style={{ padding: '8px' }}><Col span={18} className="pl10">{props.address.streetAddress2 || ''}</Col></Row> : ''}
                                        <Row style={{ padding: '8px' }}><Col span={18} className="pl10">{props.address.cityName ? `${props.address.cityName || ''}${props.address.stateName ? ', ' + props.address.stateName : ''}${props.address.zipCode ? ', ' + props.address.zipCode : ''}` : ' '}</Col></Row>
                                        <Row style={{ padding: '8px' }}><Col span={18} className="pl10">{props.address.countryName || ''}</Col></Row>
                                    </Card> :

                                    <Card
                                        title={<span><FormattedMessage id='signup.tab.address' /> </span>}
                                        extra={<i className="fa fa-pencil" style={{ cursor: 'pointer' }} onClick={() => {
                                            let formData = { isUpdate: true, ...props.address, locationType: CONSTANTS.BILLING_ADDRESS, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName };
                                            handleAddressAddition(props, { formData: formData });
                                        }}></i>}
                                        style={{
                                            width: 450,
                                            background: '#f8f8f8'
                                        }}
                                    >
                                        <Row>
                                            <Col span={6}><img src={LocationIcon} alt="Location" className="pt35" /> </Col>
                                            <Col span={18} className="pl10 pt35 text-center pt35"> Address does not exist. <span style={{ cursor: 'pointer', color: '#676ED3' }} onClick={() => {
                                                    let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName }
                                                    handleAddressAddition(props, { formData: formData });
                                                }}>Click Here </span> to add address.

                                            </Col>
                                        </Row>
                                    </Card>
                                } */}
              {/* </Col> */}
              {/* </Row> */}

              {/* <Row gutter={16}>
                            {formSetting.primaryContactForInvoicing ?
                                <Col span={12} >
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='primaryContactForInvoicing.text' /><span className="required">*</span> </span>}>
                                        <Dropdown
                                            items={[{ name: 'Father', id: 'father' }, { name: 'Mother', id: 'mother' }]}
                                            placeholder={props.intl.formatMessage({ id: 'primaryContactForInvoicing.text' })}
                                            valueKeyName='name'
                                            optionKeyName='id'
                                            value={props.primaryContact}
                                            onSelect={
                                                (optionValue) => {
                                                    props.updateState({ primaryContact: optionValue });
                                                }
                                            }
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row> */}

              {/* <Row gutter={16}>
                            {formSetting.firstNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='contact.firstName' /> {formSetting.firstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="First Name"
                                            type='text'
                                            maxLength={45}
                                            value={props.firstName}
                                            onChange={(e) => {
                                                props.updateState({ firstName: e.target.value });
                                            }}
                                            required={formSetting.firstNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.lastNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='contact.lastName' /> {formSetting.lastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Last Name'
                                            type='text'
                                            maxLength={15}
                                            value={props.lastName}
                                            onChange={(e) => {
                                                props.updateState({ lastName: e.target.value });
                                            }}
                                            required={formSetting.lastNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row> */}

              {/* <Row gutter={16}>
                            {formSetting.phoneDisplay ?
                                <Col span={12} className="newregis">
                                    <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='company.employee.phone' /> {formSetting.phoneRequired ? <span className="required">*</span> : ''}</span>}>
                                        <span className="currency-tag" >
                                            {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                        </span>
                                        <TextBox className='new-fed1'
                                            style={{ paddingLeft: '45px' }}
                                            placeholder="Phone Number"
                                            type='text'
                                            maxLength={10}
                                            value={props.phoneNumber}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        phoneNumber: e.target.value,
                                                    });
                                                }
                                                else if (!props.phoneNumber) {
                                                    props.updateState({ phoneNumber: '' });
                                                }
                                                //props.updateState({ phoneNumber: e.target.value });
                                            }}
                                            required={formSetting.phoneRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.genderDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='gender.text' /> {formSetting.genderRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Dropdown
                                            items={['Male', 'Female', 'Rather Not Say']}
                                            placeholder={props.intl.formatMessage({ id: 'gender.text' })}
                                            onSelect={(selectedValue) => {
                                                props.updateState({ gender: selectedValue });
                                            }}
                                            required={formSetting.genderRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.emailDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='email' /> {formSetting.emailRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter email'
                                            type='email'
                                            maxLength={45}
                                            value={props.email}
                                            onChange={(e) => {
                                                props.updateState({ email: e.target.value });
                                            }}
                                            required={formSetting.emailRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                                {formSetting.maritalStatusDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='maritalStatus.text' /> {formSetting.maritalStatusRequired ? <span className="required">*</span> : ''}</span>}>
                                            <Dropdown
                                                items={['Single', 'Married', 'Divorced', 'Widowed']}
                                                placeholder={props.intl.formatMessage({ id: 'maritalStatus.text' })}
                                                onSelect={(selectedValue) => {
                                                    props.updateState({ maritalStatus: selectedValue });
                                                }}
                                                required={formSetting.maritalStatusRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                        </Row> */}
              {/* <Row gutter={16} >
                           
                           
                               {formSetting.dobDisplay ?
                               <Col span={12}>
                                   <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='dob.text' /> {formSetting.dobRequired ? <span className="required">*</span> : ''}</span>}>
                                       <DatePicker
                                           allowClear={false}
                                           format={format}
                                           key='dob'
                                           onChange={(selectedDate) => {
                                               props.updateState({
                                                   dob: selectedDate
                                               })
                                           }}
                                           size={0}
                                       />
                                   </Form.Item>
                               </Col> : ''}
                               {formSetting.nationalityDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='nationality.text' /> {formSetting.nationalityRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'nationality.text', defaultMessage: '' }
                                                )}
                                                type='text'
                                                required={formSetting.nationalityRequired}
                                                maxLength={25}
                                                value={props.nationality}
                                                onChange={(e) => {
                                                    props.updateState({ nationality: e.target.value });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                       </Row> */}

              {/* <Row gutter={16}>
                {formSetting.spouseFirstNameDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.fName' /> {formSetting.spouseFirstNameRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder={intl.formatMessage(
                                    { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                                maxLength={25}
                                value={props.spouseFirstName}
                                onChange={(e) => {
                                  props.updateState({ spouseFirstName: e.target.value });
                                }}
                            required={formSetting.spouseFirstNameRequired}
                            />
                        </Form.Item>
                    </Col> : ''}
                    {formSetting.spouseLastNameDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.lName' /> {formSetting.spouseLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder={intl.formatMessage(
                                    { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                                maxLength={25}
                                value={props.spouseLastName}
                                onChange={(e) => {
                                  props.updateState({ spouseLastName: e.target.value });
                                }}
                            required={formSetting.spouseLastNameRequired}
                            />
                        </Form.Item>
                    </Col> : ''}
                    {formSetting.spouseMobileDisplay ?
                    <Col span={12} className="newregis">
                        <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='spouse.mobile' /> {formSetting.spouseMobileRequired ? <span className="required">*</span> : ''}</span>}>
                        <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                        <TextBox className='new-fed1'
                                placeholder="Enter mobile number"
                                style={{ paddingLeft: '45px' }}
                                type='text'
                                maxLength={10}
                                value={props.spouseMobile}
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        props.updateState({
                                            spouseMobile: e.target.value,
                                        });
                                    }else if(!props.spouseMobile){
                                        props.updateState({ spouseMobile: '' });
                                    }
                                 // props.updateState({ spouseMobile: e.target.value });
                                }}
                            required={formSetting.spouseMobileRequired}
                            />
                        </Form.Item>
                    </Col>: ''}
                    {formSetting.spouseEmailDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.email' /> {formSetting.spouseEmailRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder='Enter email'
                                type='text'
                                maxLength={45}
                                value={props.spouseEmail}
                                onChange={(e) => {
                                  props.updateState({ spouseEmail: e.target.value });
                                }}
                            required={formSetting.spouseEmailRequired}
                            />
                        </Form.Item>
                    </Col>: ''}
                                {formSetting.spouseDOBDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouseDOB.text' /> {formSetting.spouseDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                key='spouseDOB'
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        spouseDOB: selectedDate
                                                    })
                                                }}
                                                size={0}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                </Row> */}

              {/* <Row gutter={16}>
                           
                           {formSetting.fatherDOBDisplay ?
                               <Col span={12}>
                                   <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='fatherDOB.text' /> {formSetting.fatherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                   <DatePicker
                                           allowClear={false}
                                           format={format}
                                           key='fatherDOB'
                                           defaultValue={props.fatherDOB ? getMomentDateForUI({ date: new Date(props.fatherDOB), format }) : null}
                                           value={props.fatherDOB ? getMomentDateForUI({ date: new Date(props.fatherDOB), format }) : null}
                                           onChange={(selectedDate) => {
                                               props.updateState({
                                                fatherDOB: selectedDate
                                               })
                                           }}
                                           size={0}
                                           disabledDate= {(current) =>{
                                            return current && current > moment().endOf('day');
                                            }}
                                    />
                                   </Form.Item>
                               </Col> : ''}
                               {formSetting.motherDOBDisplay ?
                               <Col span={12}>
                                   <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='motherDOB.text' /> {formSetting.motherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                       <DatePicker
                                           allowClear={false}
                                           format={format}
                                           key='motherDOB'
                                           defaultValue={props.motherDOB ? getMomentDateForUI({ date: new Date(props.motherDOB), format }) : null}
                                           value={props.motherDOB ? getMomentDateForUI({ date: new Date(props.motherDOB), format }) : null}
                                           onChange={(selectedDate) => {
                                               props.updateState({
                                                motherDOB: selectedDate
                                               })
                                           }}
                                           size={0}
                                           disabledDate= {(current) =>{
                                            return current && current > moment().endOf('day');
                                            }}
                                       />
                                   </Form.Item>
                               </Col> : ''}
                       </Row> */}
              {/* <Row gutter={16}>
                                {formSetting.familyDoctorNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='familyDoctorName.text' /> {formSetting.familyDoctorNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Family Doctor Name"
                                                type='text'
                                                maxLength={45}
                                                value={props.familyDoctorName}
                                                onChange={(e) => {
                                                    props.updateState({ familyDoctorName: e.target.value });
                                                }}
                                                required={formSetting.familyDoctorNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.familyDoctorPhoneDisplay ?
                                    <Col span={12} className="newregis">
                                        <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='familyDoctorPhone.text' /> {formSetting.familyDoctorPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                        <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                            <TextBox className='new-fed1'
                                            style={{ paddingLeft: '45px' }}
                                                placeholder='Family Doctor Number'
                                                type='text'
                                                maxLength={10}
                                                value={props.familyDoctorPhone}
                                                onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        props.updateState({
                                                            familyDoctorPhone: e.target.value,
                                                        });
                                                    }
                                                    else if(!props.familyDoctorPhone){
                                                        props.updateState({ familyDoctorPhone: '' });
                                                    }
                                                }}
                                                required={formSetting.familyDoctorPhoneRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row> */}
              {/* 
                            <Row gutter={16}>
                            {formSetting.emergencyContactDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='setting.student.registration.form.emergencyContactName' /> {formSetting.emergencyContactRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter Name"
                                            type='text'
                                            maxLength={45}
                                            value={props.emergencyContactName}
                                            onChange={(e) => {
                                                props.updateState({ emergencyContactName: e.target.value });
                                            }}
                                            required={formSetting.emergencyContactRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.emergencyPhoneDisplay ?
                                <Col span={12} className="newregis">
                                    <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='setting.student.registration.form.emergencyPhone' /> {formSetting.emergencyPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                    <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                        <TextBox className='new-fed1'
                                        style={{ paddingLeft: '45px' }}
                                            placeholder='Enter Number'
                                            type='text'
                                            maxLength={10}
                                            value={props.emergencyPhone}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        emergencyPhone: e.target.value,
                                                    });
                                                }
                                                else if(!props.emergencyPhone){
                                                    props.updateState({ emergencyPhone: '' });
                                                }
                                            }}
                                            required={formSetting.emergencyPhoneRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row> */}

              {/* <Row gutter={16}>
                            {formSetting.addressDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='signup.tab.address' /> {formSetting.addressRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetName.placeholder' })}
                                                    type='text'
                                                    maxLength={45}
                                                    value={props.streetAddress1}
                                                    onChange={(e) => {
                                                        props.updateState({ streetAddress1: e.target.value });
                                                    }}
                                                    // required={true}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetContd.placeholder' })}
                                                    type='text'
                                                    maxLength={45}
                                                    value={props.streetAddress2}
                                                    onChange={(e) => {
                                                        props.updateState({ streetAddress2: e.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.city.placeholder' })}
                                                    type='text'
                                                    maxLength={45}
                                                    value={props.cityName}
                                                    onChange={(e) => {
                                                        props.updateState({ cityName: e.target.value });
                                                    }}
                                                    // required={true}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <Dropdown
                                                    items={props.countries || []}
                                                    optionKeyName='countryId'
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.country.placeholder' })}
                                                    valueKeyName='countryName'
                                                    value={props.countryName}
                                                    onSelect={(selectedValue, option) => {
                                                        props.updateState({ countryId: selectedValue, countryName: option.name });
                                                        props.fetchStates(selectedValue)
                                                    }}
                                                    required={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                            <Col span={12}>
                                                {(props.countryName === 'India' || props.countryName === 'United States' || props.countryName === 'United States (USA)') ?
                                                    <Dropdown
                                                        items={props.states || []}
                                                        optionKeyName='stateId'
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                        valueKeyName='stateName'
                                                        value={props.stateName}
                                                        onSelect={(selectedValue, option) => {
                                                            props.updateState({ stateName: option.name });
                                                        }}
                                                        required={true}
                                                    /> : <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                        type='text'
                                                        maxLength={45}
                                                        value={props.stateName}
                                                        onChange={(e) => {
                                                            props.updateState({ stateName: e.target.value });
                                                        }}
                                                        required={true}
                                                    />}
                                            </Col>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.zipCode.placeholder' })}
                                                    type='text'
                                                    maxLength={15}
                                                    value={props.zipCode}
                                                    onChange={(e) => {
                                                        props.updateState({ zipCode: e.target.value });
                                                    }}
                                                    // required={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col> : ''}
                        </Row> */}
              {/* <Row gutter={16}>

                            {formSetting.membershipOptions ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='sidebar.menuItem.membershipOption' /> </span>}>
                                        <Radio.Group value={props.membershipOption} onChange={(e) => {
                                            props.updateState({ membershipOption: e.target.value });
                                        }} >
                                            {
                                                (formSetting.membershipOptions || []).map((membershipOption, i) => {
                                                    return (
                                                        <div><Radio value={membershipOption}>{membershipOption}</Radio></div>
                                                    )
                                                })
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                </Col> : ''}
                        </Row> */}

              {/*     <Row gutter={16}>

                            {(formSetting.selectedMembershipOptions && formSetting.selectedMembershipOptions.length) ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='sidebar.menuItem.membershipOption' /> </span>}>
                                        <Radio.Group value={props.membershipOption} onChange={(e) => {
                                            props.updateState({ membershipOption: e.target.value });
                                        }} >
                                            {
                                                (formSetting.selectedMembershipOptions || []).map((membershipOption, i) => {
                                                    return (
                                                        <div key={'mem'+i}><Radio value={membershipOption.membershipOptionId}>{membershipOption.name} - {`$${membershipOption.termAndFees.fee}/${membershipOption.termAndFees.frequency} for ${membershipOption.termAndFees.duration} ${membershipOption.termAndFees.period}${membershipOption.termAndFees.type? ' - '+membershipOption.termAndFees.type : ''}`}</Radio></div>
                                                    )
                                                })
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                </Col> : ''}
                        </Row>
                        {0 ?
                            <div  className="maibot">
                               <b className='label-emergency'><FormattedMessage id='studentDetail.text' /></b> 
                                <Row gutter={16}>

                                  
                                            {/* {(Object.keys(formSetting.gradeValidations).length || !formSetting.gradeLevelValidation) && <CustomAgGrid
                                                columnDefs={columnDefs}
                                                rowData={(props.studentList || [])}
                                                //editType='fullRow'
                                                domLayout={"autoHeight"}
                                                getRowHeight={getRowHeight}
                                                animateRows={true}
                                                onGridSizeChanged={(params) => {
                                                    params.api.sizeColumnsToFit();

                                                }}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}

                                                onGridReady={onGridReady}
                                            >
                                            </CustomAgGrid>} */}

              {/* <Col> */}
              {/* <Button className="grey-button ant-btn ant-btn-default" onClick={ */}
              {/* // () => { */}
              {/* // let newRowIndex = -1; */}
              {/* // gridApi && gridApi.forEachNode((rowNode, index) => { */}
              {/* //     newRowIndex = index; */}
              {/* // }); */}
              {/* // if (formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount + newRowIndex + 1)) { */}
              {/* //     return showToasterMessage({ */}
              {/* //         messageType: 'error', description: props.intl.formatMessage({ id: 'no.more.seat.available.message' })
                                                //     });
                                                // }

                                                // gridApi && gridApi.stopEditing();
                                                // gridApi && gridApi.applyTransaction({ add: [{}] });
                                                // gridApi && gridApi.startEditingCell({ */}
              {/* //     rowIndex: newRowIndex + 1,
                                                //     colKey: "firstName_1"
                                                // });
                                                // gridApi && gridApi.refreshCells({ force: true });
                                                //editProfile();
                                                // if(props.customerInfo.boContactList && props.customerInfo.boContactList.length > 0){ */}
              {/* // addStudentHandle();
                                                // }else{ */}
              {/* // editProfile(); 
                                                // }
                                            // }}><FormattedMessage id='add.student' defaultMessage='' /></Button> */}
         
              {/* <Row gutter={16}>
                                {(props.isAllowParentPaymentPlan && props.paymentPlan && props.paymentPlan.length) ?
                                    <Col span={12}>

                                        <Form.Item className='label-emergency  new-fed'>
                                            <div><FormattedMessage id='paymentType.ask.message' /> </div>
                                            <Radio.Group onChange={(e) => {
                                                props.updateState({ paymentType: e.target.value });
                                            }} value={props.paymentType} >
                                               <div> <Radio value='oneTime'> <FormattedMessage id='oneTimePayment.text' /></Radio></div>
                                               <div> <Radio value='installment'><FormattedMessage id='installment.text' /></Radio></div>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col> : ''}
                            </Row> */}
              {/* <Row gutter={16}>

                                {(props.isAllowParentPaymentPlan && props.paymentPlan && props.paymentPlan.length) ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency netiu' label={<span><FormattedMessage id='select.paymentPlan' /> <span className="required">*</span></span>}>

                                            <Select style={{ width: '80%' }} value={props.selectedPlanIndex} onChange={(val) => {
                                                let selectedPlan = props.paymentPlan[val];
                                                props.updateState({ selectedPlanIndex: val, selectedPlan: selectedPlan });
                                            }}>
                                                {
                                                    (props.paymentPlan || []).map((plan, i) => {
                                                        return (
                                                            <Option key={i} value={i}>Pay in {plan.totalInstallment || 0} {plan.totalInstallment === 1 ? 'installment' : 'installments'}</Option>
                                                        )
                                                    })
                                                }


                                            </Select>

                                            {/* <Radio.Group value={props.selectedPlan} onChange={(e) => {
                                                props.updateState({ selectedPlan: e.target.value });
                                            }} >
                                                {
                                                    (props.paymentPlan || []).map((plan, i) => {
                                                        return (
                                                            <div key={i}><Radio value={plan.period}>{plan.totalInstallment || 0} Installments {plan.period} </Radio></div>
                                                        )
                                                    })
                                                }
                                            </Radio.Group> */}
         

              {/* <Row>

                        {
                            (props.questionList || []).map((queObj, i) => {

                                return (
                                    <div style={{ marginBottom: '10px', padding: '10px 10px 10px 10px' }} key={'que'+i}>
                                        <Row style={{ marginBottom: '10px' }}>
                                            <Col span={20}><b>{queObj.questionText} {queObj.isRequired ? <span className="required">*</span> : ''}</b></Col>
                                        </Row>
                                        <Row>
                                        <Col span={20}>
                                            {(queObj.optionList && queObj.optionList.length) ?
                                                <div className="nertr">
                                                    {queObj.answerType === 'multiChoice' ?
                                                        <Checkbox.Group value={queObj.answers} onChange={(answers) => {
                                                            queObj.answers = answers;
                                                            let questionList = props.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            // formSetting.questionList = questionList;
                                                            // updateFormSetting(formSetting);
                                                            props.updateState({questionList: questionList});
                                                        }}
                                                        >
                                                            {(queObj.optionList || []).map((optObj, j) => {
                                                                return (<div key={'opt'+j}><Checkbox value={optObj.optText}>{optObj.optText}</Checkbox></div>)
                                                            })
                                                            }

                                                        </Checkbox.Group> :
                                                        <Radio.Group value={queObj.answers} onChange={(e) => {
                                                            queObj.answers = e.target.value;
                                                            let questionList = props.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            // formSetting.questionList = questionList;
                                                            // updateFormSetting(formSetting);
                                                            props.updateState({questionList: questionList});
                                                        }} >
                                                            {(queObj.optionList || []).map((optObj, k) => {
                                                                return (optObj.optText ? <div key={'optA'+k}><Radio value={optObj.optText}>{optObj.optText}</Radio></div> : '')
                                                            })
                                                            }

                                                        </Radio.Group>
                                                    }
                                                </div> : ''
                                            }
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                        <Col span={20}>   
                                        </Col>
                                        </Row> */}
              {/* {queObj.answerType === 'shortAnswer' ?
                                            <Row>
                                                <Col span={20}>
                                                    <TextArea
                                                        style={{ width: '100%' }}
                                                        placeholder='Enter here'
                                                        type='text'
                                                        value={queObj.answers}
                                                        onChange={(e) => {
                                                            queObj.answers = e.target.value;
                                                            let questionList = props.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            // formSetting.questionList = questionList;
                                                            // updateFormSetting(formSetting);
                                                            
                                                            props.updateState({questionList: questionList});
                                                        }}
                                                    />
                                                </Col>
                                            </Row> : ''
                                        }
                                    </div>
                                )
                            })
                        } */}

              
                        {/* {formSetting.footerMessage ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>{ReactHtmlParser(formSetting.footerMessage)}</div> : ''} */}
                       
  
              <Row gutter={16}>
                <div className="regist-form-foot">
                    {/* <Button className="primary ant-btn ant-btn-primary" onClick={() => {
                        save();
                    }}>Submit</Button> */}
                    <button
                      onClick={() => {
                        closeForm();
                      }}
                      className="ant-btn"
                      key={1}
                      id='cancel'
                      style={{
                        backgroundColor: "white",
                        height: "38px",
                        width: "100px",
                      }}
                    >
                      Cancel
                    </button>

                    {formSetting.collectOnlinePayment && !props.isFinancialAssistanceNeeded && ((props.oneTimeCharges || []).length) && !props.isFormedit ? 
                      <button
                        className="submit-btn"
                        htmlType="submit"
                        key={2}
                        // disabled={props.modalType === "preview"}
                      >
                        {/* <FormattedMessage
                          id="proceedToPay.text"
                          values={{
                            value: getTotalGradeFees()
                              ? " (" +
                                (companyInfo.currencyCode || "") +
                                " " +
                                getTotalGradeFees() +
                                ")"
                              : "",
                          }}
                        /> */}
                        <FormattedMessage
                          id="proceedToPay.text"
                          values={{
                            value: ((companyInfo.currencyCode + ' ') || "") + ((getOneTimeCharges(props.oneTimeCharges) * ((props.studentList || []).length || 1)).toFixed(2))
                          }}
                        />
                      </button>
                      : 
                      <CustomButton
                        intlId={"confirmation.submit"}
                        defaultMessage={""}
                        htmlType="submit"
                        key={2}
                      />
                    }
                  {/* <Col span={7}>
                                    <div>
                                    Powered by Alif App (<a href="https://www.alifapp.com">alifapp.com</a>)
                                    </div>
                                </Col> */}
                </div>
              </Row>
            </Form></>
          )
        )}
        {props.isFormSaved ||
        (formSetting.schoolLevelValidation &&
          Number(formSetting.maxStudent || 0) <= props.totalStudentCount) ? (
          <div style={{ display: "flex", justifyContent: "center", marginTop: '20px' }}>
            <div>
              Powered by Alif App (
              <a href="https://www.alifapp.com">alifapp.com</a>)
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default injectIntl(Register);