import React from 'react';
import { Route, BrowserRouter, Switch, Redirect, withRouter } from 'react-router-dom';
import { AuthContext } from "../context/auth";
import Login from '../containers/Login';
import Header from '../components/header/header';
import Signup from '../containers/Signup';
import Forgot from '../containers/Forgot';
import { fetchFromLocalStorage } from '../utils';
import { LOCAL_STORAGE_KEYS } from '../static/constants';
import ActivationPending from '../containers/ActivationPending';

function AppRoute(prop) {
    const history = new BrowserRouter().history;
    const isAuthenticated = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    //enable interceptor for auth
    //axiosInterceptorConfig(history);

    const routes = [
        {
            path: '/',
            component: Login
        },
        {
            path: '/activate',
            component: Login
        },
        // {
        //     path: '/signup',
        //     component: Signup
        // },
        {
            path: '/forgotpassword',
            component: Forgot
        },
        {
            path: '/activation-pending',
            component: ActivationPending
        },
        // {
        //     path: '/profile',
        //     component: Profile
        // },
    ];

    const routeComponents = routes.map(({ path, component: Component }, key) => {
        return (
            <Route
                exact
                path={path}
                key={key}
                render={(props) =>
                    !isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                            <Redirect to="/admin/dashboard" />
                            // <Redirect to="/admin/open-registration" />

                        )
                }
            />
        )
    });

    return (
        <AuthContext.Provider value={false}>
            <Switch>
                {routeComponents}
                <Route path='/admin/:id' history={history} props={prop} component={Header} />
            </Switch>
        </AuthContext.Provider >
    )
}

export default withRouter(AppRoute);
