import { Button, Col, Drawer, Form, Row, Tabs, DatePicker } from "antd";
import React from "react";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import * as find from "lodash.find";
import { formatDateForBackend, getMomentDateForUI } from "../../../utils";
import { CONSTANTS } from "../../../static/constants";
import { Constants } from "ag-grid-community";
import { ErrorMsg } from "../../general/ErrorMessage";
import { FormattedMessage } from "react-intl";

const { TabPane } = Tabs;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const EmploymentComp = (props) => {
  const closeDrawer = () => {
    props.updateHeaderState({
      drawer: {
        ...props.drawer,
        employmentDrawerVisible: false,
      },
    });

    props.updateHeaderState({ employer: null });
  };

  const handleSubmit = (e) => {
    props.updateHeaderState({
      submittedOnce : true
    });
    if(props.employer?.employerName && props.employer?.employerPhone && props.employer?.employerEmail ) {
    const { companyInfo } = props;
    const payload = {
      employerName: props.employer.employerName,
      jobTitle: props.employer.jobTitle,
      reasonForLeaving: props.employer.reasonForLeaving,
      employerPhone: props.employer.employerPhone,
      employerEmail: props.employer.employerEmail,
      // countryCallingCode: props.employer.countryCallingCode,
      startDate: props.employer.startDate
        ? formatDateForBackend(props.employer.startDate)
        : null,
      endDate: props.employer.endDate
        ? formatDateForBackend(props.employer.endDate)
        : null,
      // contactId: ((this.props.data || {}).formData || {}).contactId,
      contactId: props.conatctObj
        ? props.conatctObj?.contactId
        : props.customerInfo?.boContactList[0]?.contactId,
      customerId: props.conatctObj
        ? props.conatctObj.customerId
        : props.customerInfo?.boLocationList[0]?.customerId,
      relationshipId: (props.companyInfo || {}).relationshipId,
      contact_employment_id:  props.employer.contact_employment_id && props.employer.contact_employment_id ,
    };
    props.createEmployment(payload, props);
    closeDrawer();
  }
  };

  const applyFilter = () => {};
  const onTabChange = () => {};
  return (
    <>
      <Drawer
        title={"Add Employment"}
        width={580}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.drawer.employmentDrawerVisible}
        footer={
          <>
            <Button defaultMessage="" type="default" onClick={closeDrawer}>
              Cancel
            </Button>
            <div>
              <Button type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </>
        }
      >
        <Row gutter={[16, 25]}>
          <Col span="24">
            <label className="input-label">
              {"Employer Name"} <span className="required">*</span>
            </label>
            <TextBox
              className="custom-text-box"
              value={props.employer?.employerName}
              onChange={(e) => {
                props.updateHeaderState({
                  employer: {
                    ...props.employer,
                   employerName: e.target.value,
                  },
                });
              }}
              placeholder={"Employer Name"}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || props.employer?.employerName;
              }}
              message={
                <FormattedMessage
                  id="modal.education.employerName"
                  defaultMessage=""
                />
              }
            />
          </Col>

          <Col span="24">
            <label className="input-label">
              {"Employer Phone"} <span className="required">*</span>
            </label>

            <div className="input-currency-control">
            <span
                      className="currency-tag2"
                      style={{ marginTop: "0px" }}
                    >
                     +91-
                    </span>
            <TextBox
              className="custom-text-box"
              value={props.employer?.employerPhone}
              onChange={(e) => {
                props.updateHeaderState({
                  employer: {
                    ...props.employer,
                    employerPhone: e.target.value,
                  },
                });
              }}
             
            />
            </div>
            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedOnce || props.employer?.employerPhone
                );
              }}
              message={
                <FormattedMessage
                  id="modal.education.employerPhone"
                  defaultMessage=""
                />
              }
            />
          </Col>

          <Col span="24">
            <label className="input-label">
              {"Employer Email"} <span className="required">*</span>
            </label>
            <TextBox
              className="custom-text-box"
              value={props.employer?.employerEmail}
              onChange={(e) => {
                props.updateHeaderState({
                  employer: {
                    ...props.employer,
                    employerEmail: e.target.value,
                  },
                });
              }}
              placeholder={"hello@yourname.com"}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || props.employer?.employerEmail;
              }}
              message={
                <FormattedMessage
                  id="modal.education.educationType"
                  defaultMessage=""
                />
              }
            />
          </Col>

          <Col span="12">
            <label className="input-label">{"Job Title"}</label>
            <TextBox
              className="custom-text-box"
              value={props.employer?.jobTitle}
              onChange={(e) => {
                props.updateHeaderState({
                  employer: {
                    ...props.employer,
                    jobTitle: e.target.value,
                  },
                });
              }}
              placeholder={"Job Title"}
            />
          </Col>

          <Col span="12">
            <label className="input-label">{"Reason for Leaving"}</label>
            <TextBox
              className="custom-text-box"
              value={props.employer?.reasonForLeaving}
              onChange={(e) => {
                props.updateHeaderState({
                  employer: {
                    ...props.employer,
                    reasonForLeaving: e.target.value,
                  },
                });
              }}
              placeholder={"Reason"}
            />
          </Col>

          <Col span="12">
            <label className="input-label">Start Date</label>
            <DatePicker
              className="custom-date-picker"
              allowClear={false}
              format={format}
              defaultValue={getMomentDateForUI({
                date: props.education?.startDate,
                format,
              })}
              onChange={(selectedDate) => {
                props.updateState({
                  employer: {
                    ...props.employer,
                    startDate: selectedDate,
                  },
                });
              }}
              placeholder="startDate"
            />
          </Col>

          <Col span="12">
            <label className="input-label">End Date</label>
            <DatePicker
              className="custom-date-picker"
              allowClear={false}
              format={format}
              defaultValue={getMomentDateForUI({
                date: props.education?.endDate,
                format,
              })}
              onChange={(selectedDate) => {
                props.updateState({
                  employer: {
                    ...props.employer,
                    endDate: selectedDate,
                  },
                });
              }}
              placeholder="endDate"
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default EmploymentComp;
