import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BroadcastComp from '../../../components/Communication/Broadcast';
import { getBroadcastMessageByCustomerId } from './action';


class Broadcast extends Component {
    componentDidMount() {
        this.props.updateHeaderState({headerHeading: ''})

        const companyInfo = this.props.companyInfo || {};
        const customerInfo = this.props.customerInfo || {};

        const payload = { 
            relationshipId: companyInfo.relationshipId, 
            customerId: customerInfo.customerId,
            contactId: ((customerInfo.boContactList || [])[0] || {}).contactId,
            pageNumber: 0, pageSize: 100,
        };
        this.props.getBroadcastMessageByCustomerId(payload);
    }

    render() {
         return <BroadcastComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }
}

const mapStateToProps = (state) => {
    return {
        broadcastMessageList: state.dashboard.broadcastMessageList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getBroadcastMessageByCustomerId,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Broadcast);
