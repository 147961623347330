import React, { useState } from 'react';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Carousel, Form, Button } from 'antd';
//import { Checkbox } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextBox } from '../general/TextBox';
//import { CustomButton } from '../general/CustomButton';
//import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'
import { STORAGE_CONSTANTS } from './../../static/constants';
import { ErrorMsg } from '../general/ErrorMessage';
import { validateEmail, addToLocalMemory, deleteFromLocalMemory, getAWSFileURL } from '../../utils';
import './login.scss';
import './login1.scss';
//import LoginIcon from '../../assets/images/logon.png';
import config from '../../config/environmentConfig';

// import cardPic from '../../assets/images/credit-card.png';
// import SeCurity from '../../assets/images/security.png';
// import EasyUse from '../../assets/images/easy-use.png';
// import Trkas from '../../assets/images/trkas.png';
// import SignUp from '../../assets/images/signup.png';
// import OperaTion from '../../assets/images/operation.png';
// import GeroWup from '../../assets/images/gerowup.png';
// import Mlot from '../../assets/images/mlot.png';
// import Koamt from '../../assets/images/koamt.png';
// import KioskIcons from '../../assets/images/kioskicons.png';
// import DonaTions from '../../assets/images/donations.png';
// import PleDge from '../../assets/images/pledge.png';
//  import Invitation from '../../assets/images/invitation.png';
//  import IocnInver from '../../assets/images/iocn-inver.png';
//  import RecurRing from '../../assets/images/recurring.png';
//  import Reminders from '../../assets/images/reminders.png';
//  import TracKings from '../../assets/images/trackings.png';
import footerImg from '../../assets/images/Frame.png'
import headImg from '../../assets/images/Group 1.png'
// import slide1 from '../../assets/images/loginSlider/slide1.jpeg';
// import slide2 from '../../assets/images/loginSlider/slide2.jpeg';
// import slide3 from '../../assets/images/loginSlider/slide3.jpeg';
// import slide4 from '../../assets/images/loginSlider/slide4.jpeg';

import Avatar from '../../assets/images/avatar.svg';
import Background from '../../assets/images/Union 1.svg';






function LoginComponent(props) {
  const {
    intl, updateState, username, usernameInvalidClass, password,
    // passwordClass,  rememberMe, usernameClass, 
  } = props;

  // const contentStyle = {
  //   height: '500px',
  //   width: '600px',
  //   color:'#fff',
  //   fontSize:'40px',
  //   margin: '0px auto 0px',
  //   textAlign: 'left',
  //   borderBottom:'1px solid #fff',
  // }; 
  // const [showDetail,setShowDetail] = useState(true);

  // const handleToggle = () => {
  //   setShowDetail(!showDetail);
  // };

  return (
    <div className='login-container'>

<div className='panel1' 

// style={{backgroundImage: `url("${Background}")`}}
>
      
      
      {/* // <img src={Background}/> */}


          <div className="login-container-body">
            <div>
              <div className='login-content'>
                “Teaching is not about answering questions but about raising questions –
                opening doors for them in places that they could not imagine.”
              </div>

              <div className='avatar'>
                <img className='avatar-profile' src={Avatar} />
                <div className='avatar-name'>Adam James
                  <div style={{ fontSize: "14px", paddingTop: "5px" }}>Writer</div>
                </div>
              </div>
              
            </div>
            
          </div>
      </div>

      <div className='panel2'>

        <div className='login-container-body'>

          <div className='body-1'>
            <div className='title-page'>
             
              <div>
                {(props.companyInfo || {}).logoAwsObjectKey ? (
                  <img
                    src={getAWSFileURL(
                      (props.companyInfo || {}).logoAwsObjectKey,
                      config.BUCKET_NAME.BO_RELATIONSHIP
                    )}
                    style={{ height: "40px", width: "50px" }}
                    alt="a"
                  />
                ) : (
                  ""
                )}
              </div>

              <div className='parent-portal'>
                {(props.companyInfo || {}).storeName ? (
                  (props.companyInfo || {}).storeName
                ) : (
                  <div>
                    Parent Portal
                  </div>
                )}
              </div>


            </div>

          <div className='form-details'>

              <h4 className="heading-title-login">
                <FormattedMessage id="Log In" defaultMessage="" />
              </h4>
              <div className="login-subheading-text">
                Welcome back, please login to your account
              </div>
              <div className="login-form-container">
                <Form
                  layout="vertical"
                  // className="login-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleLogIn(props);
                  }}
                >
                  <Row className="pb15">
                    <Col md={24} sm={24} xs={24}>
                      <TextBox
                        placeholder={intl.formatMessage({
                          id: "login.email.placeholder",
                          defaultMessage: "",
                        })}
                        type="text"
                        value={username}
                        className={usernameInvalidClass || "login-form-input"}
                        // invalidClassName={usernameInvalidClass || 'login-form-input-error'}
                        validator={(val) => {
                          return validateEmail(val);
                        }}
                        onChange={(e) => {
                          updateState({
                            username: e.target.value,
                            usernameClass: "login-form-input",
                          });
                        }}
                      />
                      <ErrorMsg
                        validator={() => {
                          return (
                            !props.submittedOnce ||
                            !username ||
                            validateEmail(username)
                          );
                        }}
                        message={
                          <FormattedMessage
                            id="login.email.invalidEmail"
                            defaultMessage=""
                          />
                        }
                      />
                      <ErrorMsg
                        validator={() => {
                          return !props.submittedOnce || !!username;
                        }}
                        message={
                          <FormattedMessage
                            id="login.email.requiredEmail"
                            defaultMessage=""
                          />
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="pb15">
                    <Col md={24} sm={24} xs={24}>
                      <TextBox
                        placeholder={intl.formatMessage({
                          id: "login.password.placeholder",
                          defaultMessage: "",
                        })}
                        type="password"
                        className={usernameInvalidClass || "login-form-input"}
                        // invalidClassName={usernameInvalidClass || 'login-form-input-error'}
                        value={password}
                        validator={(val) => {
                          return !!val;
                        }}
                        onChange={(e) => {
                          updateState({
                            password: e.target.value,
                            passwordClass: "login-form-input",
                          });
                        }}
                      />
                      <ErrorMsg
                        validator={() => {
                          return !props.submittedOnce || !!password;
                        }}
                        message={
                          <FormattedMessage
                            id="login.email.requiredPassword"
                            defaultMessage=""
                          />
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="signup-action-container">
                    <Col md={24} sm={24} xs={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-create-account"
                        intlId="signup.btn.create"
                        // defaultMessage=''
                        onClick={() => {
                          handleLogIn(props);
                        }}
                      >
                        <FormattedMessage id="Login" defaultMessage="" />
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <div className='bottom-text'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "-webkit-fill-available"
                    }}
                  >
                    <Col span={12} className="col-lg-6 top-add">
                      <div className="create-btn">
                        {/* <a href="/portal/signup" rel="noopener noreferrer">
                          <FormattedMessage
                            id="createAccount.text"
                            defaultMessage=""
                          />
                        </a> */}
                      </div>
                    </Col>
                    <Col span={12} className="col-lg-6 top-add">
                      <div className="create-btn" style={{ textAlign: "end" ,marginTop:"5px" }}>
                        <a href="/portal/forgotpassword" style={{ color: "#F85353"}}>Forgot password?</a>{" "}
                      </div>
                    </Col>
                  </div>
                </Row>
                {/* <Row>
                  <Col span={24} className='mt20 required'>
                  This site is best viewed in 1024 * 768 resolution (Desktop Only) with latest version of Chrome, Firefox, Safari.
                  </Col>
                </Row> */}
              </div>
            </div>

          </div>


        </div>
          </div>
      </div>

  );
}

const handleLogIn = (payload) => {
  payload.updateState({ submittedOnce: true });
  if (payload.username && payload.password && validateEmail(payload.username)) {
    if (payload.rememberMe) {
      addToLocalMemory(STORAGE_CONSTANTS.LOGIN_EMAIL, payload.username);
      addToLocalMemory(STORAGE_CONSTANTS.LOGIN_PASSWORD, payload.password);
      addToLocalMemory(STORAGE_CONSTANTS.REMEMBER_ME, payload.password);
    } else {
      deleteFromLocalMemory(STORAGE_CONSTANTS.LOGIN_EMAIL);
      deleteFromLocalMemory(STORAGE_CONSTANTS.LOGIN_PASSWORD);
      deleteFromLocalMemory(STORAGE_CONSTANTS.REMEMBER_ME);
    }
    payload.verifyCredentials({ email: payload.username, password: payload.password, relationshipId: payload.relationshipId }, payload);
  } else {
    if (!validateEmail(payload.username)) {
      payload.updateState({
        usernameClass: 'login-form-input-error input-error',
        usernameInvalidClass: 'login-form-input-error input-error'
      });
    } else {
      payload.updateState({ usernameClass: 'login-form-input' });
    }
    if (!payload.password) {
      payload.updateState({ passwordClass: 'input-error' });
    }
  }
}

export default injectIntl(LoginComponent);



