import config from '../../config/environmentConfig';
import axios from 'axios';
//import React, { Fragment } from 'react';
//import { FormattedMessage } from 'react-intl';
import { COMMON_ACTIONS, MEMBERSHIP_ACTIONS, LOCAL_STORAGE_KEYS, } from '../../static/constants'
import { showToasterMessage, addToLocalStorage, fetchFromLocalStorage } from '../../utils';
//import { Button } from 'antd';
//import * as find from 'lodash.find';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getContactEducation = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/education/getByContactId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&contactId=${payload.contactId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: COMMON_ACTIONS.CONTACT_EDUCATION_LIST, data });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = COMMON_ACTIONS.CONTACT_EDUCATION_LIST;
          }
        })
    }
  }

  export const resetContactEducation = () => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.CONTACT_EDUCATION_LIST, data: [] });
    }
  }

  
export const deleteContactEducation = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/education/delete`, payload)
      .then(res => {
        getContactEducation(payload)(dispatch);
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}
  export const getContactEmployment = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/employment/getByContactId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&contactId=${payload.contactId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: COMMON_ACTIONS.CONTACT_EMPLOYMENT_LIST, data });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = COMMON_ACTIONS.CONTACT_EMPLOYMENT_LIST;
          }
        })
    }
  }

  export const resetContactEmployment = () => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.CONTACT_EMPLOYMENT_LIST, data: [] });
    }
  }

  export const deleteContactEmployment = (payload, props) => {
    return dispatch => {
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/employment/delete`, payload)
        .then(res => {
          getContactEmployment(payload)(dispatch);
        })
        .catch((err) => {
          //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
  }

  
  export const getMembershipInfo = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgMembership/getByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&contactId=${payload.contactId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: MEMBERSHIP_ACTIONS.MEMBERSHIP_INFO, data });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = MEMBERSHIP_ACTIONS.MEMBERSHIP_INFO;
          }
        })
    }
  }


export const deleteContact = (payload, cb) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/deleteContact`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Contact deleted successfully' });
        payload.customerId && axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=100&pageNumber=0`)
          .then(contactRes => {
            const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
            loggedInEntityInfo.boContactList = contactRes.data;
            dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: loggedInEntityInfo });
            addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(loggedInEntityInfo));
            dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const makePrimary = (payload, cb) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/makePrimary`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Saved successfully' });
        payload.customerId && axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=100&pageNumber=0`)
          .then(contactRes => {
            const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
            loggedInEntityInfo.boContactList = contactRes.data;
            dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: loggedInEntityInfo });
            addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(loggedInEntityInfo));
            dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}


export const enablePortalAccess = (payload, cb) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/enablePortalAccess`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Saved successfully' });
        payload.customerId && axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=100&pageNumber=0`)
          .then(contactRes => {
            const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
            loggedInEntityInfo.boContactList = contactRes.data;
            dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: loggedInEntityInfo });
            addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(loggedInEntityInfo));
            dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}