import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import LoginComponent from '../../components/login/LoginComponent';
import { verifyCredentials, activateCustomerAccount, fetchRelationshipByDomainName, fetchRelationshipDataByRID } from './action';
import { STORAGE_CONSTANTS } from '../../static/constants';
import { fetchFromLocalMemory } from './../../utils';
import { pushModalToStack, popModalFromStack, showModal, hideModal } from './../../actions/commonActions';
class Login extends Component {
  constructor(props) {
    super(props);
    const rememberMe = fetchFromLocalMemory(STORAGE_CONSTANTS.REMEMBER_ME);
    const loginEmail = fetchFromLocalMemory(STORAGE_CONSTANTS.LOGIN_EMAIL);
    const loginPassword = fetchFromLocalMemory(STORAGE_CONSTANTS.LOGIN_PASSWORD);
   // const linkProps = (props.location && props.location.state) || {};
    const linkProps = queryString.parse(this.props.location.search) || {};
   // let adminIds = (linkProps.at || '').split("_");
    this.state = {
      rememberMe,
      username: loginEmail,
      password: loginPassword,
      usernameClass: '',
      usernameInvalidClass: '',
      passwordClass: 'login-form-input',
      source: linkProps.source
    }
  }
  componentDidMount() {
    const self = this;
    const queryStringObj = queryString.parse(this.props.location.search);
    let adminIds = (queryStringObj.at || '').split("_");
    this.props.fetchRelationshipByDomainName({ domainName: window.location.host}, this.props);
    const payload = {
      token: (queryStringObj || {}).t
    }
    payload.token && this.props.activateCustomerAccount(payload, this.props);
    setTimeout(function(){
    (queryStringObj || {}).token && self.props.verifyCredentials({ accountActivationToken: (queryStringObj || {}).token, source: 'admin', relationshipId: adminIds[0] }, self.props, queryStringObj);
    },1000)
  }
  render() {
    return <div> {
        <LoginComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
    </div>
  }
}
const mapStateToProps = (state) => {
  return {
    relationshipId: state.common.relationshipId,
    companyInfo: state.common.companyInfo,
  }
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
  verifyCredentials,
  activateCustomerAccount,
  fetchRelationshipByDomainName,fetchRelationshipDataByRID,
  pushModalToStack, popModalFromStack, showModal, hideModal
 }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Login);