import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../static/constants'
import { showToasterMessage } from '../../utils';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const sendResetMail = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/onlineUser/recoverLostPassword?email=${payload.email}&relationshipId=${payload.relationshipId}&relationshipName=${payload.relationshipName}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        history.push('/');
        showToasterMessage({
          messageType: 'success',
          description: (res || {}).message || 'Please check your email.'
        })
      })
      .catch((err = {}) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}