import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../actions/commonActions';

class Attendance extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true,
    // });
    // const companyInfo = this.props.companyInfo || {};
    // const customerInfo = this.props.customerInfo || {};
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: 1, pageSize: 25,
    //   customerId: customerInfo.customerId
    // };
    // this.props.getFeesList(payload);
    // this.props.getFeesCount(payload);
  }
  componentWillReceiveProps(props) {
   
  }

  render() {
    // return <FeeListComp  {...this.props}
    //   {...this.state}
    //   updateState={(data) => {
    //     this.setState(data);
    //   }}>

    // </FeeListComp>;
    return <div style={{textAlign: 'center', marginTop: '150px'}}>
      <span><h2>No Fundraising Campaign available</h2></span></div>
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    customerInfo: state.common.customerInfo,
    feeList: state.school.feeList,
    feeCount: state.school.feeCount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
