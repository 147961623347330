import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimeTableComp from "../../components/TimeTable";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../actions/commonActions';
import { getClassSectionList, getTimeTableByClassSection } from "./action";

class TimeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
    };
  }
    
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId
    };
    this.props.getClassSectionList(payload);
  }


  render() {
    return (
      <TimeTableComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    customerInfo: state.common.customerInfo,
    studentClassList: state.dashboard.studentClassList,
    allStudentList: state.dashboard.allStudentList,
    classSectionList: state.school.classSectionList,
    timeTableList: state.school.timeTableList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getClassSectionList,
      getTimeTableByClassSection
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TimeTable);
