import React, { Fragment } from "react";
// import config from "../../config/environmentConfig";
import { FormattedMessage, injectIntl} from "react-intl";
import { Pagination, Button, Tabs } from "antd";
import { getMomentDateForUIReadOnly } from "../../utils";
import { CONSTANTS } from '../../static/constants';
import './index.scss';
// import ReactHtmlParser from 'react-html-parser';
import EmailInfo from '../../components/modal/modalBody/communication/EmailInfo'
import PageBreadcrumb from "../PageBreadcrumb";
import Broadcast from "./Broadcast";
//import checkIcon from '../../../assets/images/check.png';
//import crossIcon from '../../../assets/images/cross.png';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { TabPane } = Tabs;


const FeeList = (props) => {
  const { updateState, companyInfo, customerInfo, communicationCount, pageNumber } =
    props;


    const breadCrumbList=[
      {
        name: <FormattedMessage id='breadcrum.dashboard' defaultMessage='' />,
      },
      {
        name: <FormattedMessage id='communication.listing.heading.commination' defaultMessage='' />,
    
    
      },
    
      {
        name: <FormattedMessage id='supplier.listing.showContact.gridHeader.email' defaultMessage='' />,
    
      },
        ];
    

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      customerId: customerInfo.customerId
    };

    props.getCustomerCommunication(payload);
    props.getCustomerCommunicationCount(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      customerId: customerInfo.customerId
    };

    props.resetPaginatedData();
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getCustomerCommunication(payload);
    props.getCustomerCommunicationCount(payload);
  };

  const onChangetabs = (key) => {
    console.log(key);
  };
  

  

  return (
    <Fragment>
      {/* <div className="pb10">
        {/* <PageBreadcrumb breadCrumbList={breadCrumbList} /> */}
      {/* </div> */}
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
          <FormattedMessage id='communication.listing.heading.commination' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
           <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          
        </div>
      </div>
      
        <div className='view-container'>
          <Tabs
          // activeKey={"1"}
          onChange={onChangetabs}
          type="line"
         >
          <TabPane tab="Email" key="1">
         <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='communication.listing.heading.CommunicationEmailList' defaultMessage='' />
              </div>
             
            </div>
            <div className="right-actions">
            </div>
           </div>
        
            <div className='table-container'>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Type</th>
                    {/* <th>Description</th> */}
                    {/* <th>Communiated To</th> */}
                    {/* <th>Transaction</th> */}
                    <th>Email Subject</th>
                    <th>Communicated By</th>
                    <th>Date</th>
                    {/* <th>Origination</th> */}
                  </tr>

                </thead>
                  <tbody>
                  {(props.communicationList[pageNumber] || []).map((e, i) => {
                    return <tr key={'std' + i}>
                      <td>{i+1}</td>
                      <td>
                        {/* {e.origination === 'System' ? */}
                       <span className="cursor-pointer" 
                       onClick={() => {
                        props.showModal({
                          title: <div>Communication</div>,
                          modalBody: <EmailInfo {...props} communicationDetail={e} />,
                          width: 700,
                          hideFooter: true,
                        })
                      }}
                      
                      >{e.communicationMode || '-'}</span>
                        {/*  : <span>{e.communicationMode || '-'}</span>
                      } */}
                    </td>
                      {/* <td>{ReactHtmlParser(e.description)} </td> */}
                      {/* <td>{e.communiatedTo || '-'} </td> */}
                      {/* <td><div>
                        {e.transactionType ? <FormattedMessage id={e.transactionType} /> : ''}
                      </div></td> */}
                      <td>{e.emailSubject || '-'}</td>
                      <td>{ e.relationshipEmployeeName === 'System Generated'? 'System':  e.relationshipEmployeeName || '-'}</td>
                      <td>{e.communicationDate ? getMomentDateForUIReadOnly({ date: new Date(e.communicationDate), format }) : '-'}</td>
                      {/* <td>{e.origination || '-'}</td> */}
                    </tr>
                    // })
                  })}
                </tbody>
              </table>
            </div>
          <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">

          <Pagination
            size="small"
            total={communicationCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
      </div>
     </div>
     </TabPane>

     <TabPane tab="Broadcast Message" key="2">
      <Broadcast/>
     </TabPane>
     </Tabs>
        </div>
       
    </Fragment>
  );
};

export default injectIntl(FeeList);
