import React from 'react';
import { Button } from 'antd';

import './tiles.scss';

const Tile = (props) => {
    const { tilesData, customClass, getSelectedTile } = props;
    return (
        <div className={`tiles-wrapper-dashboard`}>
            {
                (tilesData && tilesData.length > 0) && tilesData.map((option, i) => {
                    const { header, footer, count, icon, currencyIcon, color='default', leble , count2, leble2,antIcon } = option;
                    return (
                        <div key={i}
                        className={`tile-container-dashboard ${color}`}
                            onClick={() => getSelectedTile(option)}
                        >
                            
                            {/* {
                                header && (
                                    <div className='tile-header'>
                                        {header}
                                    </div>
                                )
                            } */}
                            {/* <div className='tile-body-dashboard'>
                                <div>
                                { icon ? <i className={icon || (props.companyInfo || {}).currencyIcon} /> : <span style={{color: 'white'}}>{currencyCode || (props.companyInfo || {}).currencyCode}</span>}
                                <div className='tile-name'>
                                            {footer}
                                    </div>

                                </div>
                                <div className="count-value">
                                    <div>
                                    <div className='tile-count '>
                                        {leble && <span>{leble} </span>}
                                        <span className='icon-currency'>
                                { icon ? <i className={icon || (props.companyInfo || {}).currencyIcon} /> : <span style={{color: 'white'}}>{currencyCode || (props.companyInfo || {}).currencyCode}</span>}
                                        </span>
                                        <span>{count}</span>
                                    </div>
                                    {count2 ?
                                    <div className='tile-count' style={{lineHeight: '10px'}}>
                                        {leble && <span>{leble2} </span>}
                                        <span className="icon-currency">
                                { icon ? <i className={icon || (props.companyInfo || {}).currencyIcon} /> : <span style={{color: 'white'}}>{currencyCode || (props.companyInfo || {}).currencyCode}</span>}

                                        </span>
                                        <span >{count2}</span>
                                    </div>:''
                                    }

                                    </div>
                                    <div style={{alignItems: 'right', color: '#ecf5ff'}} size='small'><Button onClick={()=>{
                                            getSelectedTile(option);
                                        }}></Button>
                                    </div>
                                </div>
                            </div> */}
                            {/* /////////////////////////////////////////////////// */}
                            <div className="tile-body-dashboard">
                              {/* { icon ? <i className={icon || (props.companyInfo || {}).currencyIcon} /> : <span style={{color: 'white'}}>{currencyCode || (props.companyInfo || {}).currencyCode}</span>} */}
                              <div className="left-tile-body-dashboard">
                                <div className="tile-name-dashboard">
                                  {footer}
                                </div>
                                <div className='total-fee'>
                                  {leble && <span>{leble} </span>}
                                  {currencyIcon ? (
                                    <i
                                      className={currencyIcon}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {count}
                                  </span>
                                </div>
                              </div>

                              <div className="count-value-dashboard">
                                {antIcon ? (
                                  <div className="ant-icons-dashboard">
                                    {antIcon ? antIcon : ""}
                                  </div>
                                ) : (
                                  <i
                                    className={
                                      currencyIcon ||
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  />
                                )}
                                <div className="ant-icons-dashboard">
                                  {icon ? icon : ""}
                                </div>
                              </div>
                              {/* <div></div> */}
                            </div>
                            {/* {
                                footer && (
                                    <div className='tile-footer' style={{fontSize: '15px'}}>
                                        {footer}
                                    </div>
                                )
                            } */}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default Tile;

//
// Tile have some color options
// for that you need to pass color key in data object for every tile
// default color is gray '#999'
// color option: 'green-tile',  'darkGray-tile',  'blue-tile',  'orange-tile' 'magenta-tile', 'purple-tile', 'default'
//
//
Tile.defaultProps = {
    customClass: 'cstmCls',
    tilesData: [
        {
            header: 'Demo Label1',
            count: 0,
            icon: '',
            color: 'green-tile'
        },
        {
            footer: 'Demo Label2',
            count: 0,
            icon: '',
            color: 'darkGray-tile'
        },
        {
            header: 'Demo Label3',
            count: 0,
            icon: '',
            color: 'blue-tile'
        },
        {
            footer: 'Demo Label4',
            count: 0,
            icon: '',
            color: 'orange-tile'
        }
        // ,
        // {
        //     header: 'Demo Label5',
        //     count: 0,
        //     icon: 'dollar',
        //     color: 'magenta-tile'
        // },
        // {
        //     header: 'Demo Label6',
        //     count: 0,
        //     icon: 'dollar',
        //     color: 'purple-tile'
        // },
        // {
        //     header: 'Demo Label7',
        //     count: 0,
        //     icon: 'dollar',
        // },
        // {
        //     header: 'Demo Label8',
        //     count: 0,
        //     icon: 'dollar',
        //     color: 'default'
        // }
    ]
};
