import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MODAL_ACTIONS, MODAL_TYPE } from '../../static/constants';
import { CustomButton } from '../general/CustomButton';
import { AddItemBody } from './../modal/modalBody/customerInfo';
import ContactBody from '../../containers/modal/modalBody/common/Contact';
import AddressBody from '../../containers/modal/modalBody/common/Address';
import EducationBody from '../../containers/modal/modalBody/common/Education';
import EmploymentBody from '../../containers/modal/modalBody/common/Employment';
// import IframeComponent from './modalBody/common/IframeComponent';

import './modal.scss';

function CustomModal(props) {

  const cancelBtnProps = (props.footer || {}).cancel || {};
  const submitBtnProps = (props.footer || {}).submit || {};
  const [formData, updateFormData] = useState(props.formData)
  const dispatch = useDispatch();
  const bodyHtml = getBody(props, formData, updateFormData);

  const defaultTitle = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />

  return (
    <Fragment>
      {props.visible ? (<Modal
        title={props.title || defaultTitle}
        visible={props.visible}
        closable={props.isClosable}
        maskClosable={props.maskClosable === true ? false : true}
        width={props.width}
        keyboard={props.keyboard}
        wrapClassName={props.wrapClassName} //custom-modal-content, pass this classname for fixed height modal
        onCancel={() => { dispatch({ type: MODAL_ACTIONS.HIDE_MODAL }) }}
        footer={props.hideFooter ? null : [
          <div key={0} className='customModal-button-group'>
            <CustomButton
              intlId={cancelBtnProps.intlId || 'confirmation.cancel'}
              defaultMessage={cancelBtnProps.defaultMessage || ''}
              type={cancelBtnProps.type || 'default'}
              key={1}
              onClick={() => {
                updateFormData({ ...formData, textBoxValue: "", submittedOnce: false });
                props.isStack ? props.onCancel((props.modalData || {}).modalType) : dispatch({ type: MODAL_ACTIONS.HIDE_MODAL })
              }
              }
            />
            <CustomButton
              intlId={submitBtnProps.intlId || 'confirmation.submit'}
              defaultMessage={submitBtnProps.defaultMessage || ''}
              htmlType='submit'
              key={2}
              onClick={() => {
                document.getElementsByClassName("add-new-input-text").length && document.getElementsByClassName("add-new-input-text")[0].classList.add("input-text-error")
                if (props.formData && Object.keys(props.formData).length) {
                  props.handleSubmit({ ...props.formData });
                  updateFormData({ ...formData, textBoxValue: "", submittedOnce: false });
                } else {
                  updateFormData({ ...formData, textBoxValue: "", submittedOnce: false });
                  props.handleSubmit();
                  dispatch({ type: MODAL_ACTIONS.HIDE_MODAL });
                }
              }}
            />
          </div>
        ]}
      >
        {
          bodyHtml
        }

      </Modal>) : ''}
    </Fragment >
  );
}

const getBody = (props, formData, updateFormData) => {
  const { modalBody, modalData = {}, handleSubmit } = props;

  let body = <Fragment />;
  if (modalBody) {
    body = modalBody;
  } else {
    //customise your modal body here
    const { modalType } = modalData;
    switch (modalType) {
      case MODAL_TYPE.EMPLOYEE_TYPE: {
        body = <AddItemBody {...modalData} {...props} formData={formData} updateFormData={updateFormData} handleSubmit={handleSubmit} />
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.SHIPPING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
        body = <AddressBody {...modalData} formData={formData} handleSubmit={handleSubmit} />
        break;
      }
      case MODAL_TYPE.CONTACT: {
        body = <ContactBody {...modalData} formData={formData} handleSubmit={handleSubmit} />
        break;
      }
      case MODAL_TYPE.EDUCATION: {
        body = <EducationBody {...modalData} formData={formData} handleSubmit={handleSubmit} />
        break;
      }
      case MODAL_TYPE.EMPLOYMENT: {
        body = <EmploymentBody {...modalData} formData={formData} handleSubmit={handleSubmit} />
        break;
      }
      default:
        break;
    }
  }
  return body;
}

export default injectIntl(CustomModal);