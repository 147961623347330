import axios from 'axios';
//import React, { Fragment } from 'react';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, ROLE_TYPES } from '../../static/constants';
import { showToasterMessage, getCurrentDateForBackend } from '../../utils';
//import { Button } from 'antd';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const signIn = (payload) => {
  const requestObj = getSignInRequestObj(payload);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/createOnlineCustomer`,
        requestObj
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: (res.data || {}).message || "Your account has been created successfully. Check your email to activate your account." })
        payload.history.push("/");
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

const getSignInRequestObj = (props) => {
  const { email, password, firstName, lastName, primaryContact } = props;
  let contactList = [];
  let boLocationList = [];
  if (firstName) {
    contactList.push({
      firstName: firstName.split(' ')[0] || '',
      lastName: firstName.split(' ')[1] ? firstName.split(' ')[1] :'',
      cellPhone: props.mobileNumber,
      emailAddress: email || '',
      // firstName: primaryContact === 'father' ? firstName : '',
      // lastName: primaryContact === 'father' ? lastName : '',
      // cellPhone: appendCountryCode(props.fatherMobile),
      // emailAddress: primaryContact === 'father' ? email : '',
      relationshipId: props.relationshipId,
      dateCreated: getCurrentDateForBackend(new Date()),
     // gender: primaryContact === 'father' ? 'Male' : 'Female',
      contactType: "Customer",
      // motherFirstName: primaryContact === 'mother' ? firstName : '',
      // motherLastName: primaryContact === 'mother' ? lastName : '',
      // motherMobile: appendCountryCode(props.motherMobile),
      // motherEmail: primaryContact === 'mother' ? email : '',
      // dateOfBirth: props.fatherDOB ? formatDateForBackend(props.fatherDOB) : '',
      // motherDateOfBirth: props.motherDOB ? formatDateForBackend(props.motherDOB) : '',
      isPrimaryContact: 1,
      isEnablePortalAccess: 1,
      primaryContactForInvoicing: props.primaryContact,
      boContactChildList: [],
      role: primaryContact === 'father' ? ROLE_TYPES.FATHER : ROLE_TYPES.MOTHER,
      familyRelationship: primaryContact === 'father' ? ROLE_TYPES.FATHER : ROLE_TYPES.MOTHER,
    });
  }
  // let companyName = props.firstName + (props.lastName ? ' ' + props.lastName : '');
  let companyName = props.firstName;


  let payload = {
    companyName: companyName,
    relationshipId: props.relationshipId,
    boContactList: contactList,
    boLocationList: boLocationList,
    password,
    countryCallingCode: props.companyInfo.countryCallingCode,
    primaryContact: props.primaryContact,
  }
  // if (primaryContact === 'mother') {
  //   payload.motherFirstName = firstName;
  //   payload.motherLastName = lastName;
  //   // payload.motherMobile= props.motherMobile;
  //   payload.motherEmail = email;
  //   // payload.motherDateOfBirth= props.motherDOB? formatDateForBackend(props.motherDOB) : '';
  // }
  // else {
  //   payload.fatherFirstName = firstName;
  //   payload.fatherLastName = lastName;
  //   //  payload.fatherMobile= props.fatherMobile;
  //   payload.fatherEmail = email;
  //   //  payload.fatherDateOfBirth= props.fatherDOB? formatDateForBackend(props.fatherDOB) : '';
  // }
  return payload;
}


export const doesEmailExist = (payload, props, cb) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/doesEmailExist?relationshipId=${props.relationshipId}&emailAddress=${payload.emailAddress}`)
      .then(res => {
        const { data } = res;
        if (data) {
          props.updateState({ isEmailExist: true, email: '', invalidEmailClass: 'input-text-error' });
          showToasterMessage({ description: 'Email exists. Use different email address' });
          cb && cb(data);
          // props.showModal({
          //   maskClosable: true,
          //   isClosable: false,
          //   keyboard: false,
          //   modalBody: <Fragment>
          //     <div className="warning-message">
          //       Email exists. Use different email address.
          //     </div>
          //     <br />
          //     <div className="button-action" style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #e5e5e5', paddingTop: '15px' }}>
          //       <Button className="ant-btn-default"
          //         onClick={() => {
          //           props.updateState({ isEmailExist: true, email: '' });
          //           props.hideModal();
          //         }}>Close
          //       </Button>
          //     </div>
          //   </Fragment>,
          //   hideFooter: true
          // })
        }
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}