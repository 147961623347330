const sideMasterMenu = [
    {
        menuName : 'sidebar.menuItem.dashboard',
        iconClassName : 'fi fi-rr-dashboard',
        rightIconClass : '',
        childList:'',
        path:'/admin/dashboard'
        
    },
    // {
    //     menuName : 'sidebar.menuItem.registration',
    //     iconClassName : 'fi fi-bs-user-pen',
    //     rightIconClass : '',
    //     path: '/admin/open-registration',
    //     // childList:[
    //     //     {
    //     //         menuName : 'sidebar.menuItem.registerNow',
    //     //         iconClassName : 'fi fi-rr-blog-pencil',
    //     //         rightIconClass : '',
    //     //         path: '/admin/open-registration',
    //     //         childList:[]
    //     //     },
    //     //     {
    //     //         menuName : 'sidebar.menuItem.enrolled',
    //     //         iconClassName : 'fi fi-rr-id-badge',
    //     //         rightIconClass : '',
    //     //         path: '/admin/enrolled',
    //     //         childList:[]
    //     //     },

    //     // ]
    // },
    {
        menuName : 'sidebar.menuItem.fees',
        iconClassName : 'fi fi-rr-badge-dollar',
        rightIconClass : '',
        path:'/admin/fee',
        childList:'',
    },
    {
        menuName : 'sidebar.menuItem.attendance',
        iconClassName : 'fi fi-rr-hand-paper',
        rightIconClass : '',
        path:'/admin/attendance',
        childList:'',
    },
    {
        menuName : 'sidebar.menuItem.assignment',
        iconClassName : 'fi fi-rr-file-signature',
        rightIconClass : '',
        path:'/admin/assignment-list',
        childList:'',
    },
    {
        menuName : 'sidebar.menuItem.timeTable',
        iconClassName : 'fi fi-rr-game-board-alt',
        rightIconClass : '',
        path:'/admin/time-table',
        childList:'',
    },
    {
        menuName : 'sidebar.menuItem.communication',
        iconClassName : 'fi fi-rr-paper-plane',
        // rightIconClass : 'fi fi-bs-menu-dots-vertical',
        path: '/admin/communication',

        // childList:[
        //     {
        //         menuName : 'sidebar.menuItem.email',
        //         iconClassName : 'fi fi-br-envelope',
        //         rightIconClass : '',
        //         path: '/admin/communication',
        //         childList:[]
        //     },
        //     {
        //         menuName : 'sidebar.menuItem.broadcast',
        //         iconClassName : 'fi fi-ss-broadcast-tower',
        //         rightIconClass : '',
        //         path: '/admin/broadcast',
        //         childList:[]
        //     },

        // ]
    },
    // {
    //     menuName : 'sidebar.menuItem.membership',
    //     iconClassName : 'fi fi-bs-id-card-clip-alt',
    //     rightIconClass : '',
    //     path:'/admin/membership',
    //     childList:'',
    // },

]


  export default sideMasterMenu;