import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const changePassword = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/onlineUser/changePassword`, payload)
      .then(res => {
        
        showToasterMessage({ messageType: 'success', description: 'Your password has been reset successfully.' })
      
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // const payload = {
        //   companyInfo: props.companyInfo,
        //   relationshipId: props.companyInfo.relationshipId,
        //   userId: (props.userInfo || {}).userId,
        //   pageNumber: 1,
        //   pageSize: 25
        // };
       // props.fetchSalesInvoices(payload);
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}