import React, {Fragment} from "react";
import { Row, Col, Button } from "antd";
import { injectIntl, FormattedMessage } from 'react-intl';
import { getMomentDateForUIReadOnly } from '../../utils';
import { CONSTANTS, MODAL_TYPE, FAMILY_RELATIONSHIP_NAME_BY_KEY } from '../../static/constants';
import AddUser from '../../assets/images/add-user.png';
import StudentPic from '../../assets/images/student-pic.png'


const AllStudents = (props) => {
  const { customerInfo = {} } = props;
  const boContactChildList = customerInfo.boContactChildList || [];

 const handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
        props.hideModal(data);
        if (contact.customerId) {
            // props.fetchAllContacts({
            //     customerId: contact.customerId,
            //     relationshipId: (props.companyInfo || {}).relationshipId,
            // });
        }
      }
    const data = {
        title: <div>
            <FormattedMessage id='addItem.text.contact' defaultMessage='' />
            <div style={{ 'fontSize': '12px' }}> {formData.customerName || ''}</div>
        </div>,
        formData,
        hideFooter: true,
        modalData: {
            modalType: MODAL_TYPE.CONTACT,
            data: payload,

        },
        handleSubmit: (formData = {}) => {
            data.formData.submittedOnce = true;
            props.hideModal(data);
        }
    };
    showModal(data);
}
  
  return (
    //     <div className="dark-blue" >
    //       <div className="dark-blue" >
    // </div>
    //     </div>

    <div className="conta90">
      <Row  className=" ">
      <Col xs={24} md={8} className='mat mab mt5 pl10 pr10 pt10 pb10'>
              <div className="add-adrsbox" style={{textAlign:'center', padding: '97px 0px', background:'#F8F8F8', cursor:'pointer'}}>
                <div className="plus-icon" onClick={() => {
                  let formData = {customerId : props.customerId }
                  handleNewContactAddition(props, {formData: formData});
                //  props.showModal({
                //   maskClosable: true,
                //   isClosable: false,
                //   keyboard: false,
                //   modalBody: <Fragment>
                //     <div className="warning-message">
                //     Personal information can be edited from the profile page. Do you want to continue?
                //     </div>
                //     <br />
                //     <div className="button-action" style={{
                //       display: 'flex',
                //       justifyContent: 'space-between',
                //       borderTop: '1px solid #e5e5e5',
                //       paddingTop: '15px'
                //     }}>
                //        <Button className="ant-btn-default"
                //         onClick={() => {
                //           props.hideModal();
                //         }}>  <FormattedMessage id='no' defaultMessage='' />
                //       </Button>
                //       <Button className="ant-btn-primary" onClick={() => {
                //       //  props.history.push('/admin/edit-profile');
                //         props.hideModal();
                //         props.popModalFromStack();
                //       }}>  <FormattedMessage id='ok' defaultMessage='' /> </Button>
                //     </div>
                //   </Fragment>,
                //   hideFooter: true
                // })
                }}>
                  <div style={{textAlign:'center'}}><img src={AddUser} alt=""/></div>
                  {/* <span><i className="fa fa-plus add-adrsbox" aria-hidden="true"></i></span> */}
                  <div className="add-address">
                    <span><h3 className="add-heading"><b>Add Student</b></h3></span>
                  </div>

                </div>
              </div>


            </Col>

        {(customerInfo.boContactList || []).map((student, i) => {
          return <Col xs={24} md={8} className='mat mt5 mab pl10 pr10 pt10 pb10' key={'std-'+i}>
            <div className="add-adrsboxne add-boxtop-bot">
              <div className="hed-studl"> <Row style={{ padding: '0px 0px 5px', fontSize:'18px', borderBottom: 'solid 1px #ccc' }}>
                {/* <Col span={8} className="textright15"> <b>First Name</b></Col> */}
                <Col span={23}  style={{padding:'0px 0px 5px 0px'}}><b>{student.firstName + (student.lastName ? ' ' + student.lastName : '')}</b>
                <div className="primarys"> 
                {/* {student.familyRelationship ?<span>{FAMILY_RELATIONSHIP_NAME_BY_KEY[student.familyRelationship]}</span> : ''}  */}
                {student.isPrimaryContact ?<span className="ml5">Primary</span> :''} </div>
                </Col></Row>  <span><img src={StudentPic} alt=""/></span></div>
              {student.isDefault ? <div className="add-top-hed">Default</div> : ''}
              <div className="plus-icon">
              <Row style={{ padding: '8px 0px'  }}><Col span={10} className="textright15"><b>Relationship </b></Col><Col span={14}  className="primarys">{student.familyRelationship ? <span> {FAMILY_RELATIONSHIP_NAME_BY_KEY[student.familyRelationship]}</span> : '-'}</Col></Row>
                <Row style={{ padding: '8px 0px'}}><Col span={10} className="textright15"><b>Gender </b></Col><Col span={14}>{student.gender || '-'}</Col></Row>
                <Row style={{ padding: '8px 0px'}}><Col span={10} className="textright15"><b>DOB </b></Col><Col span={14}>{student.dateOfBirth ? getMomentDateForUIReadOnly({ date: student.dateOfBirth, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</Col></Row>
                <Row style={{ padding: '8px 0px'}}><Col span={10} className="textright15"><b>Allergies </b></Col><Col span={14}>{student.allergies || '-'}</Col></Row>
              </div>
              {/* <div><span className="cursor-pointer">Edit</span></div> */}
              <br/>
              {props.selectedStudentIds.indexOf(student.contactId) > -1 ?
                <div className="add-bot-hed1"><span style={{background: 'black', cursor:'auto'}} >Added</span>  </div> 
                :
                <div className="add-bot-hed1"><span className="cursor-pointer" onClick={() => {
                  props.addStudent(student);
                  props.popModalFromStack();
                }}>Add</span>  </div>
              }
            </div>

          </Col>
        })
        }
      </Row>
    </div>

  )
}

export default injectIntl(AllStudents);