import React from "react";
// import DevelopingPic from '../../../assets/images/working.gif';
import CommingSoon from '../../../assets/images/Framenotfound.png';
import './index.scss';

const NotFound = () =>{
    return <div className="main-div">
              <img src={CommingSoon} alt="Under Development" className="img2"/>
    </div>
}

export default NotFound;