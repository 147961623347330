import React, {useState} from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Empty, Tabs } from "antd";
import PageBreadcrumb from "../PageBreadcrumb";
import './index.scss'
import TableView from '../../containers/Timetable/tableView';

const {TabPane} = Tabs;


const TimeTableComp = (props) => {

  const {classSectionList, allStudentList} = props;

  const [activeStuKey, setActiveStuKey] = useState(props.activeStuKey || allStudentList?.length ? allStudentList[0].schoolStudentId : 0);

  const breadCrumbList=[
    {name: <FormattedMessage id='breadcrum.dashboard' defaultMessage='' />},
    {name: <FormattedMessage id='sidebar.menuItem.timeTable' defaultMessage='' />},
    {name: <FormattedMessage id='list.text' defaultMessage='' />},
  ];

  const getStudentClasses = (classSectionList=[], allStudentList=[]) =>{
    const classIdMap = {};
    allStudentList.forEach(item => {
      classIdMap[item.classId] = true;
    });

    const filteredList = classSectionList.filter(item => {
      return classIdMap[item.classId];
    });
    return (filteredList || []);
  }

  const openTimeTable = (props, data) =>{

    props.pushModalToStack({
      title: `Time Table(${data.className || ''}${data.sectionName ? `-${data.sectionName}` :  ''})`,
      maskClosable: true,
      isClosable: false,
      keyboard: false,
      modalBody: <TableView {...props} classData={data}/>,
      wrapClassName: "model-open-wrapper",
      width: '100%',
      hideFoote: true
    })

  }

  const handleOnChange = (studentId) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
      classId: (allStudentList || []).find(obj => obj.schoolStudentId === Number(studentId)).classId
    }
    setActiveStuKey(studentId);
  };


  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='sidebar.menuItem.timeTable' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div>
      
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='table.heading.timeTableList' defaultMessage='' />
            </div>
          </div>
          <div className="right-actions">
          </div>
        </div>

        {
          (allStudentList || []).length ?
            <Tabs
              onChange={handleOnChange}
              activeKey={activeStuKey || props.activeStuKey}
            >
              {(props.allStudentList || []).map((item, i) => {
                return (
                  <TabPane
                    tab={`${item.firstName || `Student ${i}`} ${item.lastName || ''}`}
                    key={item.schoolStudentId.toString()}
                  >
                    <TableView {...props} studentData={item} classData={{classId: 158, classSectionId: 122}} />
                  </TabPane>
                );
              })}
            </Tabs>
          :
          <Empty />
        }
      </div>
    </>
  );
};

export default injectIntl(TimeTableComp);
