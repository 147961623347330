import React, { useRef, useState, Fragment, forwardRef, useImperativeHandle } from "react";
import { FormattedMessage } from 'react-intl';
import { Select, Divider } from "antd";
import * as get from 'lodash.get';
import * as find from 'lodash.find';
import { PERMISSION_VALUES } from '../../static/constants';
import { checkACLPermission } from '../../utils';

import './general.scss';

const { Option } = Select;

const DropdownComp = (props, ref) => {
  const items = props.items || [];
  const operations = props.operations;
  const moduleName = props.moduleName;
  const permissions = props.permissions || {};
  const primaryPerm = permissions.primary;
  let canAddNew = props.canAddNew || false;
  let canDelete = props.canDelete || false;
  if (canAddNew && Array.isArray(operations) && operations.indexOf(PERMISSION_VALUES.CREATE) > -1) {
    canAddNew = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.CREATE);
  }
  if (canDelete && Array.isArray(operations) && operations.indexOf(PERMISSION_VALUES.DELETE) > -1) {
    canDelete = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.DELETE);
  }
  const deleteClickHandler = props.deleteClickHandler || (() => { });
  const [newTextObj, setNewTextObj] = useState({ textEntered: '', existsInData: false });
  const inputRef = useRef();
  const [selectedValue, updateSelectedValue] = useState(props.value);
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return selectedValue;
      },
      afterGuiAttached: () => {
        inputRef.current.focus();
      },
      isCancelAfterEnd: () => {
        if (props.lastColumnCell && props.node.lastChild && !props.node.shiftKey && (props.node.tabKey || props.node.enterKey || props.node.downKey)) {
          props.api.updateRowData({ add: [{}] });
        }
        return false;
      }
    };
  });
  
  
  return (
    <Select
      ref={inputRef}
      onChange={(val)=>{
        if(!val){
          updateSelectedValue(null);
          props.onClear();
        }
      }}
      defaultValue={props.value}
      key={`${props.value}`}
      mode={props.mode || 'default'}
      showSearch={props.showSearch || true}
      className={props.className + ' custom-dropdown-width cell-dropdown'}
      onDeselect={props.onDeSelect ? props.onDeSelect : () => {
        console.log("====");
      }}
      placeholder={props.placeholder}
      disabled={props.disabled}
      optionFilterProp='name'
      clearIcon={<i className="fa fa-times-circle" onClick={()=>{
        updateSelectedValue(null);
      }}/>}
      allowClear={props.allowClear}
      onClick={props.onClick}
      notFoundContent='No record(s) found'
      onSelect={(selectedValue, optionObj) => {
        if (props.optionKeyName) {
          let payload = {};
          payload[props.optionKeyName] = Number(selectedValue);
          let objecToFind = find(items, payload) || {};
          props.onSelect(objecToFind[props.valueKeyName], optionObj);
          updateSelectedValue(objecToFind[props.valueKeyName]);
        } else {
          props.onSelect(selectedValue);
          updateSelectedValue(selectedValue);
        }
      }}
      onSearch={searchedText => {
        const searchedResults = items.filter(
          x => (x[props.valueKeyName] || '').toString().toLowerCase().indexOf(searchedText.toString().toLowerCase()) > -1
        );
        if (!searchedResults.length) {
          setNewTextObj({ textEntered: searchedText, existsInData: false });
        } else {
          setNewTextObj({ textEntered: searchedText, existsInData: true });
        }
      }}
      dropdownRender={dropdown => {
        return (
          // added className="tax-dropdown" for tax dropdown
          <div className="">
            {
              canAddNew ?
                <Fragment>
                  <div
                    style={{ padding: "0 8px", cursor: "pointer" }}
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => { props.onAddNewClick(newTextObj) }}
                    className='add-new'
                  >
                    <i className="fa fa-plus" />
                    <FormattedMessage id='add' defaultMessage='' />
                    {
                      newTextObj.existsInData || !newTextObj.textEntered
                        ? <FormattedMessage id='new' defaultMessage=' ' />
                        : ` ${newTextObj.textEntered}`
                    }
                  </div>
                  <Divider style={{ margin: "1px 0" }} />
                </Fragment>
                : ''
            }
            {dropdown}
          </div>
        )
      }}
    >
      {
        items.map((item) => {
          const value = props.valueKeyName ? get(item, props.valueKeyName, '') : item;
          const isFavourite = props.valueKeyName ? get(item, props.isFavouriteKeyName, '') : '';
          const lastNameKey = props.lastNameKey ? get(item, props.lastNameKey, '') : '';
          const value2 = props.valueKeyName2 ? get(item, props.valueKeyName2, '') : '';
          const value3 = props.valueKeyName3 ? get(item, props.valueKeyName3, '') : '';
          const value4 = props.valueKeyName4 ? get(item, props.valueKeyName4, '') : '';
          const value5 = props.valueKeyName5 ? get(item, props.valueKeyName5, '') : '';
          const addressLine1 = props.addressLineKey1 ? get(item, props.addressLineKey1, '') : '';
          const addressLine2 = props.addressLineKey2 ? get(item, props.addressLineKey2, '') : '';
          const addressLine3 = props.addressLineKey3 ? get(item, props.addressLineKey3, '') : '';
          const addressLine4 = props.addressLineKey4 ? get(item, props.addressLineKey4, '') : '';
          const valueGrade = props.gradeKeyName ? get(item, props.gradeKeyName, '') : '';
          const key = typeof item === 'object' ? get(item, props.optionKeyName, '') : item;
          const isLock = props.isLockKeyName ? get(item, props.isLockKeyName, '') : '';
          return (
            <Option key={key} name={value + (value2 || lastNameKey || "")} value={key}
              className={props.optionClassAddress + " " + props.optionClass || ' custom-dropdown-option'}>
              <span className={props.optionClass || ' custom-dropdown-option'}>
                {value}
                {value2 ? ` (${value2})` : ''}
                {lastNameKey ? ` ${lastNameKey}` : ''}
                {value3 ? ` (Bal. ${props.currencyCode + " "} ${value3})` : ''}
                {value4 ? ` ${" " + value4} ` : ''}
                {value5 ? `${" " + value5}` : ''}
                {addressLine1 ? <br /> : ""}
                {addressLine1 ? `${" " + addressLine1}` : ''}
                {addressLine2 ? <br /> : ""}
                {addressLine2 ? `${" " + addressLine2}` : ''}
                {addressLine3 ? <br /> : ""}
                {addressLine3 ? `${" " + addressLine3}` : ''}
                {addressLine4 ? `${",  " + addressLine4}` : ''}
                {valueGrade ? ` (Grade ${valueGrade})` : ''}
              </span>
              {
                canDelete ?
                  isLock ?
                    <i className='fa fa-lock option-delete-icon' style={{ float: 'right', marginTop: 5 }} /> :
                    <i
                      className='fa fa-trash delete-list-item option-delete-icon'
                      title="Delete"
                      onClick={e => {
                        const payload = {
                          id: key,
                          text: value,
                        };
                        e.preventDefault();
                        e.stopPropagation();
                        deleteClickHandler(payload);
                      }}
                    />
                  : ''
              }
              {
                isFavourite ? <span className="favourite-mark"><i className="fa fa-star"/></span> : ''
              }
            </Option>
          )
        })
      }
    </Select >
  );
}

export const DropdownGrid = forwardRef(DropdownComp);
