import React from "react";
import { Tabs, Drawer, Row, Col, DatePicker, Form } from 'antd';
import ContactComponent from './modal/modalBody/common/Contact/index'



const InboundDeliveryFilters = (props) => {
    const { updateState, companyInfo } = props;

    const closeDrawer = () =>{
        props.updateState({
            inboundDeliveryFilterDrawerVisible:false
        })
    }




    const rowGutter = [24, 16];
    return (
        <>
          
          <Drawer
                title={
                    <div className="drawer-head-txt">
                       
                    </div>
                }
                width={720}
                zIndex={1}
                maskClosable={false}
                destroyOnClose={true}
                onClose={closeDrawer}
                className="custom-drawer"
                visible={props.inboundDeliveryFilterDrawerVisible}
            
            >
                <ContactComponent {...props} formData={props.formData}/>
          </Drawer>
        </>
    )
};

export default InboundDeliveryFilters;
