import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import {
  fetchFromLocalStorage, checkACLPermission, showToasterMessage, primaryPermissionConversion
} from '../utils';
import { LOCAL_STORAGE_KEYS } from '../static/constants';


export function PrivateRoute(props) {
  const { component: Component, permissions, operation, moduleCode, ...rest } = props;
  const isAuthenticated = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ? true : false;
  let permissionData = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.PERMISSION_DATA);
  if (permissionData) {
    permissionData = JSON.parse(permissionData);
    permissionData.primary = primaryPermissionConversion(permissionData.primary);
  }
  let hasPermission = true;
  if (moduleCode) {
    hasPermission = checkACLPermission((permissions || permissionData || {}).primary, moduleCode, operation);
  }
  if (isAuthenticated && !hasPermission) {
    showToasterMessage({ messageType: 'error', description: 'You don\'t have permission. Kindly contact administrator.' });
  } else {
    props.history.renderedLocation = props.location.pathname;
  }
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          hasPermission
            ? <Component {...rest} />
            : <Redirect to={props.history.renderedLocation || '/'} />
        ) : (
            <Redirect to="/" />
          )
      }
    />
  );
}

export default withRouter(PrivateRoute);