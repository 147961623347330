import axios from "axios";
import { connectAdvanced } from "react-redux";
import config from "../../config/environmentConfig";
import { SCHOOL_ACTIONS, COMMON_ACTIONS } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");



export const submitAssignment = (payload, props) => {
  var formData = new FormData();
  formData.append("payload", JSON.stringify(payload));
  // if (props.fileToUpload) {
  //   formData.append("file", props.fileToUpload);
  // }
  if(props.fileList && props.fileList.length){
    props.fileList.forEach((file) => {
      formData.append('file', file, file.name);
    })
  }

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignmentResponse/submitAssignment`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: lang[(res.data || {}).message] || "Saved succesfully" });
       props.popModalFromStack();
       props.history.push({
        pathname: '/admin/assignment-list',
        state: {
          classId: payload.classId,
          schoolStudentId: payload.studentId
        }
      })
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({ messageType: "error", description: lang[((err.response || {}).data || {}).message] || "Some error occurred." });
        } else {
          showToasterMessage({ messageType: "error", description: "Some error occurred." });
         // props.popModalFromStack();
        }
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const getSchoolAssignment = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignment/getSchoolAssignment?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SCHOOL_ACTIONS.ASSIGNMENT_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getSchoolAssignmentCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignment/countSchoolAssignment?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: SCHOOL_ACTIONS.ASSIGNMENT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SCHOOL_ACTIONS.ASSIGNMENT_LIST_PAGE_RESET });
  };
};

export const deleteAssignment = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignment/delete`, payload)
      .then(res => {
        // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // props.getFees && props.getFees(props);
        getSchoolAssignment({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm}, )(dispatch);
        getSchoolAssignmentCount({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm})(dispatch);
       // showToasterMessage({ messageType: 'success', description: 'Term & Fee deleted successfully' });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}


export const getStudentByClassId = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentClassRef/getStudentByClassId?relationshipId=${payload.relationshipId}&orgRegistrationId=${payload.orgRegistrationId || 0}&classId=${payload.classId || 0}`
      )
      .then((res) => {
        dispatch({
          type: SCHOOL_ACTIONS.CLASS_STUDENT_LIST,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};