import React, { useRef, useState, forwardRef, useImperativeHandle } from "react";
import { DatePicker } from 'antd';
import { CONSTANTS } from '../../static/constants';
import { getMomentDateForUI } from "../../utils";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const DatePickerComp = (props, ref) => {
  const inputRef = useRef();
  const [value, setValue] = useState(props.value);
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: () => {
        inputRef.current.focus();
      },
      isCancelAfterEnd: () => {
        if (props.lastColumnCell && props.node.lastChild && !props.node.shiftKey) {
          props.api.updateRowData({ add: [{}] });
        }
        return false;
      }
    };
  });

  return (
    <DatePicker
      ref={inputRef}
      format={format}
      defaultValue={value ? getMomentDateForUI({date: new Date(value), format}) : ''}
      disabledDate={(date) => { if(props.disabledDate){ return props.disabledDate(date)} } }
      onChange={(selectedDate) => {
        if (props.onChange) {
          props.onChange(getMomentDateForUI({date: selectedDate, format}));
        }
        setValue(getMomentDateForUI({date: selectedDate, format}));
      }}
    />
    // <input
    //   ref={inputRef}
    //   value={value || ""}
    //   onBlur={(e) => {
    //     if (props.onBlur) {
    //       props.onBlur(e.target.value);
    //     }
    //     setValue(e.target.value);
    //   }}
    //   onChange={(e) => {
    //     if (props.onChange) {
    //       props.onChange(e.target.value);
    //     }
    //     setValue(e.target.value);
    //   }}
    //   className={ props.className + " editable-cell-textarea"}
    // />
  );
}

export const DatePickerGrid = forwardRef(DatePickerComp);