import axios from "axios";
import config from "../../config/environmentConfig";
import { SCHOOL_ACTIONS, COMMON_ACTIONS } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getOrgRegistration = (payload) => {
    return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getOrgRegistrationByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
            .then((res) => {
                dispatch({
                    type: SCHOOL_ACTIONS.REGISTRATION_List,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            })
            .finally(() => {
                dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
              //dispatch({ type: SCHOOL_ACTIONS.FEE_LIST, data: {isStudentFormListLoaded : true}});
            });
    };
};


