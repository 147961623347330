import React, { useRef, useState, forwardRef, useImperativeHandle } from "react";
const InputComp = (props, ref) => {
  const inputRef = useRef();
  const [value, setValue] = useState(props.value);
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: () => {
        inputRef.current.focus();
      },
      isCancelAfterEnd: () => {
        if (props.lastColumnCell && props.node.lastChild && !props.node.shiftKey) {
          props.api.updateRowData({ add: [{}] });
        }
        return false;
      }
    };
  });

  return (
    <input
      ref={inputRef}
      value={value || ""}
      onBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e.target.value);
        }
        setValue(e.target.value);
      }}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e.target.value);
        }
        setValue(e.target.value);
      }}
      className={ props.className + " editable-cell-textarea"}
    />);
}

export const InputGrid = forwardRef(InputComp);