// import React from 'react';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
// import { Row, Col } from 'antd';
// import { FormattedMessage, injectIntl } from 'react-intl';

// import { TextBox } from '../general/TextBox';
// import { CustomButton } from '../general/CustomButton';
// import { validateEmail } from '../../utils';
// import './forgotpassword.scss';

// function ForgotPassword(props) {
//   const { email, emailClass, emailInvalidClass, updateState, intl, history } = props;
//   return (
//     <Row className='forgot-wrapper' type='flex' justify='center' align='middle'>
//     <Col md={6} xs={24} sm={24} className="forgot-panel">
//       <h4 align="left">
//         <FormattedMessage id='forgot.headingText' defaultMessage='' />
//       </h4>
//       <p className='forget-password-msg'>
//         <FormattedMessage
//           id='forgot.text'
//           defaultMessage=''
//         />
//       </p>
//       <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(props); }}>
//         <Row className='pb15'>
//           {/* <Col span={3} className='forgot-lock-icon'>
//             <span>
//               <i className='fa fa-lock'></i>
//             </span>
//           </Col> */}
//           <Col span={24}>
//             <TextBox
//               placeholder={intl.formatMessage(
//                 { id: 'forgot.email.placeholder', defaultMessage: '' }
//               )}
//               type='text'
//               value={email}
//               className={emailClass || 'forgot-form-input'}
//               invalidClassName={emailInvalidClass || 'forgot-form-input-error'}
//               validator={(val) => { return validateEmail(val); }}
//               onChange={(e) => {
//                 updateState({
//                   email: e.target.value,
//                   emailClass: 'forgot-form-input'
//                 })
//               }}
//             />
//           </Col>
//         </Row>
//         <Form.Item>
//           <Row>
//             <Col span={24} align="end">
//               <CustomButton
//                 type='primary'
//                 htmlType='submit'
//                 className='forgot-send-email'
//                 intlId='forgot.btn.submit'
//                 defaultMessage=''
//               />
//             </Col>
//           </Row>
//           <Row>
//             <Col span={24}>
//               <CustomButton
//                 type='default'
//                 htmlType='button'
//                 className='forgot-goback'
//                 intlId='forgot.btn.back'
//                 defaultMessage=''
//                 onClick={(e) => { e.preventDefault(); history.push('/') }}
//               />
//             </Col>

//           </Row>
         
//         </Form.Item>
//       </Form>
//     </Col>
//   </Row >

//     // <Row className='forgot-wrapper' type='flex' justify='center' align='middle'>
//     //   <Col md={6} xs={24} sm={24} className="forgot-panel">
//     //     <h4 align="center">
//     //       <FormattedMessage id='forgot.headingText' defaultMessage='' />
//     //     </h4>
//     //     <p>
//     //       <FormattedMessage
//     //         id='forgot.text'
//     //         defaultMessage=''
//     //       />
//     //     </p>
//     //     <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(props); }}>
//     //       <Row className='pb15'>
//     //         <Col span={3} className='forgot-lock-icon'>
//     //           <span>
//     //             <i className='fa fa-lock'></i>
//     //           </span>
//     //         </Col>
//     //         <Col span={21}>
//     //           <TextBox
//     //             placeholder={intl.formatMessage(
//     //               { id: 'forgot.email.placeholder', defaultMessage: '' }
//     //             )}
//     //             type='text'
//     //             value={email}
//     //             className={emailClass || 'forgot-form-input'}
//     //             invalidClassName={emailInvalidClass || 'forgot-form-input-error'}
//     //             validator={(val) => { return validateEmail(val); }}
//     //             onChange={(e) => {
//     //               updateState({
//     //                 email: e.target.value,
//     //                 emailClass: 'forgot-form-input'
//     //               })
//     //             }}
//     //           />
//     //         </Col>
//     //       </Row>
//     //       <Form.Item>
//     //         <Row>
//     //           <Col span={12}>
//     //             <CustomButton
//     //               type='default'
//     //               htmlType='button'
//     //               className='forgot-goback'
//     //               intlId='forgot.btn.back'
//     //               defaultMessage=''
//     //               onClick={(e) => { e.preventDefault(); history.push('/') }}
//     //             />
//     //           </Col>
//     //           <Col span={12} align="end">
//     //             <CustomButton
//     //               type='primary'
//     //               htmlType='submit'
//     //               className='forgot-send-email'
//     //               intlId='forgot.btn.submit'
//     //               defaultMessage=''
//     //             />
//     //           </Col>
//     //         </Row>
//     //       </Form.Item>
//     //     </Form>
//     //   </Col>
//     // </Row >
//   );
// }

// const handleSubmit = (payload) => {
//   if (payload.email && validateEmail(payload.email)) {
//     payload.sendResetMail(payload, payload.history);
//   } else {
//     if (!validateEmail(payload.email)) {
//       payload.updateState({
//         emailClass: 'forgot-form-input-error input-error',
//         emailInvalidClass: 'forgot-form-input-error input-error'
//       });
//     }
//   }
// }

// export default injectIntl(ForgotPassword);








import React from 'react';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Form } from 'antd';
//import { Checkbox } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextBox } from '../general/TextBox';
import { CustomButton } from '../general/CustomButton';

//import { CustomButton } from '../general/CustomButton';
//import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'
// import { STORAGE_CONSTANTS } from './../../static/constants';
// import { ErrorMsg } from '../general/ErrorMessage';
import { validateEmail, getAWSFileURL } from '../../utils';
//import LoginIcon from '../../assets/images/logon.png';
import config from '../../config/environmentConfig';
// import cardPic from '../../assets/images/credit-card.png';
// import SeCurity from '../../assets/images/security.png';
// import EasyUse from '../../assets/images/easy-use.png';
// import Trkas from '../../assets/images/trkas.png';
// import SignUp from '../../assets/images/signup.png';
// import OperaTion from '../../assets/images/operation.png';
// import GeroWup from '../../assets/images/gerowup.png';
// import Mlot from '../../assets/images/mlot.png';
// import Koamt from '../../assets/images/koamt.png';
// import KioskIcons from '../../assets/images/kioskicons.png';
// import DonaTions from '../../assets/images/donations.png';
// import PleDge from '../../assets/images/pledge.png';
//  import Invitation from '../../assets/images/invitation.png';
//  import IocnInver from '../../assets/images/iocn-inver.png';
//  import RecurRing from '../../assets/images/recurring.png';
//  import Reminders from '../../assets/images/reminders.png';
//  import TracKings from '../../assets/images/trackings.png';
// import footerImg from '../../assets/images/Frame.png'
// import headImg from '../../assets/images/Group 1.png'
// import slide1 from '../../assets/images/loginSlider/slide1.jpeg';
// import slide2 from '../../assets/images/loginSlider/slide2.jpeg';
// import slide3 from '../../assets/images/loginSlider/slide3.jpeg';
// import slide4 from '../../assets/images/loginSlider/slide4.jpeg';

import Avatar from '../../assets/images/avatar.svg';


function ForgotPassword(props) {

  const { email, emailClass, updateState, intl, history } = props;

  const handleSubmit = (payload) => {
      if (payload.email && validateEmail(payload.email)) {
        payload.sendResetMail(payload, payload.history);
      } else {
        if (!validateEmail(payload.email)) {
          payload.updateState({
            emailClass: 'forgot-form-input-error input-error',
            emailInvalidClass: 'forgot-form-input-error input-error'
          });
        }
      }
    }


  return (
    <div className='login-container'>
      <div className='panel1'>
        <div className="login-container-body">
          <div>
            <div className='login-content'>
              “Teaching is not about answering questions but about raising questions –
              opening doors for them in places that they could not imagine.”
            </div>

            <div className='avatar'>
              <img src={Avatar} />
              <div className='avatar-name'>Adam James
                <div style={{ fontSize: "14px", paddingTop: "5px" }}>Writer</div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>

      <div className='panel2'>
        <div className='login-container-body'>
          <div className='body-1'>
            <div className='title-page'>
             
              <div>
                {(props.companyInfo || {}).logoAwsObjectKey ? (
                  <img
                    src={getAWSFileURL(
                      (props.companyInfo || {}).logoAwsObjectKey,
                      config.BUCKET_NAME.BO_RELATIONSHIP
                    )}
                    style={{ height: "40px", width: "50px" }}
                    alt="a"
                  />
                ) : (
                  ""
                )}
              </div>

              <div className='parent-portal'>
                {(props.companyInfo || {}).storeName ? (
                  (props.companyInfo || {}).storeName
                ) : (
                  <div>
                    Parent Portal
                  </div>
                )}
              </div>


            </div>

          <div className='form-details'>

              <h4 className="heading-title-login">
                <FormattedMessage id="forgot.headingText" defaultMessage="" />
              </h4>
              <div className="login-subheading-text">
              Enter your email address that you used to register your account. We will send you an email with your username and password.              </div>
              <div className="login-form-container">



                <Form
                  layout="vertical"
                  // className="login-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(props);
                  }}
                >
                  <Row className="pb15">
                    <Col md={24} sm={24} xs={24}>
                      <TextBox
                        placeholder={intl.formatMessage({
                          id: "login.email.placeholder",
                          defaultMessage: "",
                        })}
                        type="text"
                        value={email}
                        className={emailClass || "login-form-input"}
                        // invalidClassName={usernameInvalidClass || 'login-form-input-error'}
                        validator={(val) => {
                          return validateEmail(val);
                        }}
                        onChange={(e) => {
                          updateState({
                            username: e.target.value,
                            usernameClass: "login-form-input",
                          });
                        }}
                      />
                    
                    </Col>
                  </Row>


                  <Form.Item>
          <Row>
            <Col span={24} align="end">
              <CustomButton
                type='primary'
                htmlType='submit'
                className="login-create-account"
                intlId='forgot.btn.submit'
                defaultMessage=''
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/* <CustomButton
                type='default'
                htmlType='button'
                className='forgot-goback'
                intlId='forgot.btn.back'
                defaultMessage=''
                onClick={(e) => { e.preventDefault(); history.push('/') }}
              /> */}

              <a className='forgot-goback'  onClick={(e) => { e.preventDefault(); history.push('/') }}>Go Back</a>
            </Col>

          </Row>
         
        </Form.Item>


{/* 
                  <Row className="signup-action-container">
                    <Col md={24} sm={24} xs={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-create-account"
                        intlId="signup.btn.create"
                        // defaultMessage=''
                        onClick={() => {
                          handleLogIn(props);
                        }}
                      >
                        <FormattedMessage id="Login" defaultMessage="" />
                      </Button>
                    </Col>
                  </Row> */}
                </Form>

{/* 
                <Row>
                  <div className='bottom-text'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "-webkit-fill-available"
                    }}
                  >
                    <Col span={12} className="col-lg-6 top-add">
                      <div className="create-btn">
                        <a href="/portal/signup" rel="noopener noreferrer">
                          <FormattedMessage
                            id="createAccount.text"
                            defaultMessage=""
                          />
                        </a>
                      </div>
                    </Col>
                    <Col span={12} className="col-lg-6 top-add">
                      <div className="create-btn" style={{ textAlign: "end" }}>
                        <a href="/portal/forgotpassword" style={{ color: "#F85353" }}>Forgot password?</a>{" "}
                      </div>
                    </Col>
                  </div>
                </Row> */}
                {/* <Row>
                  <Col span={24} className='mt20 required'>
                  This site is best viewed in 1024 * 768 resolution (Desktop Only) with latest version of Chrome, Firefox, Safari.
                  </Col>
                </Row> */}
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>

  );
}



export default injectIntl(ForgotPassword);




