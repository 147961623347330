import React, { useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Checkbox } from 'antd';

const CheckboxComp = (props, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState(props.value);
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                inputRef.current.focus();
            },
            isCancelAfterEnd: () => {
                if (props.lastColumnCell && props.node.lastChild && !props.node.shiftKey) {
                    props.api.updateRowData({ add: [{}] });
                }
                return false;
            }
        };
    });

    return (
        <Checkbox
            style={{ marginLeft: "10px" }}
            className={props.className || ""}
            ref={inputRef}
            disabled={props.disabled}
            onClick={() => {
                if (props.onChange) {
                    props.onChange(!value);
                }
                setValue(!value);
            }}
            checked={value} />
    );
}

export const CheckboxGrid = forwardRef(CheckboxComp);