import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import LogoShape from '../../assets/images/logo-shape.png';

export class LogoComponent extends React.Component {
    constructor(props){
        super(props);
        this.state={
            src: props.src,
            size: props.size,
            style: props.style, 
        }
    }
    shouldComponentUpdate(nextProps, nextState){
        return this.state.src !== this.props.src; 
     }

    render() {
        return <div>
            {
                <Avatar shape='square' size={this.state.size || 64} style={this.props.style} icon= {this.props.islogoImage ? <img src={LogoShape} alt="" /> : <UserOutlined />}
                src={this.state.src} />
            }
        </div>
    }
} 