import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../actions/commonActions';
import { downloadFileFromBucket } from './../../actions/downloadFileAction';
import CommunicationCompo from '../../components/Communication';
import {getCustomerCommunication, getCustomerCommunicationCount, resetPaginatedData } from './action';
import EmailInfo from "../../components/Communication/EmailInfo";

class Communication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
  // this.props.updateHeaderState({
    //   collapsed: true,
    // });
    this.props.updateHeaderState({headerHeading: 'Communications'})

    const companyInfo = this.props.companyInfo || {};
    const customerInfo = this.props.customerInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1, pageSize: 25,
      customerId: customerInfo.customerId
    };
    this.props.getCustomerCommunication(payload);
    this.props.getCustomerCommunicationCount(payload);
  }
  componentWillReceiveProps(props) {
   
  }

  render() {
    return <>
    <CommunicationCompo  {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}>

    </CommunicationCompo>
     <EmailInfo updateState={(data) => { this.setState(data) }} {...this.props} {...this.state} />
    </>
    // return <div style={{textAlign: 'center', marginTop: '150px'}}>
    //   <span><h2>School session has not yet started</h2></span></div>
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    customerInfo: state.common.customerInfo,
    communicationList: state.school.communicationList,
    communicationCount: state.school.communicationCount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getCustomerCommunication, getCustomerCommunicationCount, resetPaginatedData,
      downloadFileFromBucket
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Communication);
