import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal, hideModal, pushModalToStack, popModalFromStack, } from './../../actions/commonActions';
import { getContactEducation, resetContactEducation,deleteContactEducation, getContactEmployment,
   resetContactEmployment,deleteContactEmployment, getMembershipInfo,
   deleteContact, makePrimary, enablePortalAccess } from './action';
import { createContact } from './../../containers/modal/modalBody/common/Contact/action';
import { getAllMembrshipForm } from '../../containers/Membership/action';
import ProfileComp from '../../components/Profile';
import * as find from 'lodash.find';
import queryString from 'query-string';
import InboundDeliveryFilters from "../../components/hello";
// import EducationComp from "../../components/Profile/Education";

class Profile extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(this.props.location.search) || {};
    // const inboundDeliveryFilterDrawerVisible = false;
    this.state = {
      primaryContact: {},
      source: linkProps.source,
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      headerHeading: "My Profile",
    });
    let contact = (this.props.customerInfo.boContactList || [])[0] || {};
    const companyInfo = this.props.companyInfo || {};
    const customerInfo = this.props.customerInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0, pageSize: 100,
      contactId: (customerInfo?.boContactList[0] || {}).contactId,
      customerId: customerInfo.customerId,
    };
    (contact && contact.contactId) && this.props.getContactEducation(contact);
    (contact && contact.contactId) && this.props.getContactEmployment(contact);
    this.props.getMembershipInfo(contact);
    this.props.getAllMembrshipForm(payload);
    

  }
  componentWillReceiveProps(props) {
    const primaryContact = find(props.customerInfo.boContactList || [], { isPrimaryContact: 1 }) || {};
    const address = find(props.customerInfo.boLocationList || [], { isDefault: 1 }) || {};
    this.setState({primaryContact: primaryContact, address: address});
  }

  render() {
    return <><ProfileComp  {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}/>
        {this.state.inboundDeliveryFilterDrawerVisible && <InboundDeliveryFilters {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />}
        {/* {this.state.educationDrawerVisible  && <EducationComp updateState={(data) => { this.setState(data) }} {...this.props} {...this.state} />} */}
</>
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    companyInfo: state.common.companyInfo,
    customerInfo: state.common.customerInfo,
    contactEducationList: state.common.contactEducationList,
    contactEmploymentList: state.common.contactEmploymentList,
    membershipInfo: state.membership.membershipInfo,
    membershipFormList: state.membership.membershipFormList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getContactEducation, 
      resetContactEducation,
      deleteContactEducation,
      getContactEmployment,
      resetContactEmployment,
      deleteContactEmployment,
      getMembershipInfo,
      createContact,
      deleteContact,
      getAllMembrshipForm,
      makePrimary,
      enablePortalAccess
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);