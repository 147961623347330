import React, { useRef, useEffect, Fragment, useState } from 'react';
//import { Link } from 'react-router-dom';
import Resizer from "react-image-file-resizer";
import config from '../../config/environmentConfig';
import { base64Toblob, getAWSFileURL } from '../../utils';
import { logout } from '../../actions/commonActions';
import { ImageCropper } from '../../components/general/ImageCropper';
import { CustomUploader } from "../../components/general/CustomUploader";
import { CONSTANTS } from '../../static/constants';
import ChangePassword from '../../containers/modal/modalBody/settings/ChangePassword';
import { FormattedMessage } from 'react-intl';
import {  Divider } from 'antd';
// import EditProfile from '../../containers/EditProfile';
// import AvatarImg from '../../assets/images/avatar.png'
import { getInitialsFromString } from '../../utils';
export const SignoutForm = (props) => {
  //  let selectedLanguage = fetchFromLocalStorage("language") || "en";
    const [profileIcon, updateProfileIcon] = useState();
    //const [profileIconUrl, updateProfileIconUrl] = useState();
    const cropperRef = useRef();
    const customerInfo = props.customerInfo || {};

    const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();

        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });

        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);

            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    if (((cropperRef.current &&
                        cropperRef.current.contains(e.target)) || e.target.contains(cropperRef.current)) ||
                        (e.target.classList.value.includes("ant-btn"))
                    ) {
                        return;
                    }
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep

        return innerRef; // return ref; client can omit `useRef`
    }

    const innerRef = useOuterClick(e => {
        props.updateState({
            showUserDropdown: false
        })
    });
    // const getMonthName = function (month) {
    //     switch (month) {
    //         case 1: {
    //             return <span><FormattedMessage id='january' defaultMessage='' />   </span>
    //         }
    //         case 2: {
    //             return <span><FormattedMessage id='february' defaultMessage='' />   </span>
    //         }
    //         case 3: {
    //             return <span><FormattedMessage id='march' defaultMessage='' />   </span>
    //         }
    //         case 4: {
    //             return <span><FormattedMessage id='april' defaultMessage='' />   </span>
    //         }
    //         case 5: {
    //             return <span><FormattedMessage id='may' defaultMessage='' />   </span>
    //         }
    //         case 6: {
    //             return <span><FormattedMessage id='june' defaultMessage='' />   </span>
    //         }
    //         case 7: {
    //             return <span><FormattedMessage id='july' defaultMessage='' />   </span>
    //         }
    //         case 8: {
    //             return <span><FormattedMessage id='august' defaultMessage='' />   </span>
    //         }
    //         case 9: {
    //             return <span><FormattedMessage id='september' defaultMessage='' />   </span>
    //         }
    //         case 10: {
    //             return <span><FormattedMessage id='october' defaultMessage='' />   </span>
    //         }
    //         case 11: {
    //             return <span><FormattedMessage id='november' defaultMessage='' />   </span>
    //         }
    //         case 12: {
    //             return <span><FormattedMessage id='december' defaultMessage='' />   </span>
    //         }
    //         default: return <span>-</span>
    //     }
    // };

    // const getFinacialYearFragment = function (companyInfo) {
    //     const fragmentObj = <Fragment>
    //         {companyInfo.fyStartDate && companyInfo.fyEndDate ? <div>
    //             {getMomentDateForUIReadOnly({ date: companyInfo.fyStartDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY })} - {getMomentDateForUIReadOnly({ date: companyInfo.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY })}
    //         </div>
    //             : ''
    //         }
    //     </Fragment>
    //     return fragmentObj;
    // }

    const customProps = {
        type: CONSTANTS.TYPE_IMAGE,
        imgUrl: props.showUserDropdown ? getAWSFileURL((props.companyInfo || {}).logoAwsObjectKey, config.BUCKET_NAME.BO_RELATIONSHIP) || profileIcon : null,
        height: 60,
        width: 60,
        name: 'file',
        action: null,
        buttonName: <FormattedMessage id='button.uploadCustomerPO.label' defaultMessage='' />,
        onChange: (file) => {
            if (file.file.originFileObj) {
                new Promise((resolve) => {
                    Resizer.imageFileResizer(
                        file.file.originFileObj,
                        230,
                        90,
                        "png",
                        100,
                        0,
                        (uri) => {
                            //updateProfileIconUrl(uri);
                            const fileData = base64Toblob(uri);

                            var reader = new FileReader();

                            reader.onload = function (e) {
                                props.showModal({
                                    title: <Fragment>
                                        <div className='ant-modal-title'>Crop Image</div>
                                    </Fragment>,
                                    modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                                    }} handleSubmit={(newUrl) => {
                                        props.uploadImage({
                                            objectKey: props.companyInfo.logoAwsObjectKey,
                                            bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                                            file: base64Toblob(newUrl)
                                        });
                                        updateProfileIcon(newUrl);
                                            
                                    }}{...props} url={e.target.result} />,
                                    width: 800,
                                    hideFooter: true,
                                    wrapClassName: 'Upload-profile-modal'
                                })
                            }
            
                            reader.readAsDataURL(fileData); // convert to base64 string

                        },
                        "base64"
                    );
                });
            }
            //   file.file.originFileObj = file.file.originFileObj || {};
            //   file.file.originFileObj.name = `${file.file.originFileObj.name} (${bytesToSize(file.file.originFileObj.size)})`;
            //   this.setState({
            //     fileToUpload: file.file.originFileObj
            //   })
        }
    }

    


    return <div ref={innerRef}>
        <div style={{display:"flex",fontSize:"12px"}} onClick={() => {
            props.updateState({
                showUserDropdown: !props.showUserDropdown
            })
        }}>
        <div className='user-icon'>
            {customerInfo ? getInitialsFromString(customerInfo.companyName || 'XX') : ''}
        </div>
        {/* <div className='user-detail'>
                <div className='fw-600'>{customerInfo ? customerInfo.companyName: 'User'}</div>
                <div className='role'>Parent</div>
            </div> */}
            <div className="right-dropdown">
                <i className="fi fi-rr-angle-small-down"></i>
            </div>
        </div>
        {props.showUserDropdown ? <div className="user-dropdown">
            <div className="user-header">
                {/* <i className="close">
                    <svg viewBox="64 64 896 896"
                        width="1em" height="1em" fill="currentColor"
                        aria-hidden="true">
                        <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
                    </svg>
                </i> */}
                {/* <CustomUploader {...customProps} /> */}
                {/* <img src={AvatarImg} /> */}
                <div className="user-header-heading">
                <div className='user-icon-dropdown' >
            {customerInfo ? getInitialsFromString(customerInfo.companyName || 'XX') : ''}
                </div>
                <div className='user-details'>
                    <div className='fw-600'>
                        {(customerInfo) ? customerInfo.companyName : ''}
                    </div>

                    
                        <div>
                         {props.loggedInEmail}
                        </div>
                        <div 
                            className="link"
                            onClick={()=>{
                                // props.history.push('/admin/profile');
                            }}
                        >
                            <a href='/portal/admin/profile'>{'View My Profile'|| 'Edit Profile'}</a>
                        </div>
                        
                        </div>
                </div>
                
                <Divider style={{margin:'14px 2px 10px 0px' ,}}></Divider>
                <div className='user-info' >
                        {/* <div className='user-info-line'
                           
                            onClick={()=>{
                                props.history.push('/admin/dashboard')
                            }}
                        >
                           
                           <span style={{display:'flex'}}> <i className="fi fi-rr-dashboard"></i> <div style={{paddingTop:'3px'}}>Dashboard</div></span>
                        </div> */}
                        <div 
                            className='user-info-line'
                            onClick={()=>{
                                props.showModal({
                                    title: <div>Change Password</div>,
                                    modalBody: <ChangePassword {...props} />,
                                    width: 700,
                                    hideFooter: true,
                                })
                            }}
                        >
                           <span style={{display:'flex'}}><i  className="fi fi-rr-key"></i> <div style={{paddingTop:'3px'}}>Change Password</div></span>
                        </div>
                        <Divider></Divider>

                        <div  className='user-info-line' onClick={() => {
                    logout();
                }}> <span style={{display:'flex'}}> <i style={{color:'red'}} className="fi fi-rs-sign-out-alt"></i>  <a style={{color:'red' ,paddingTop:'3px'}} href='/portal/'> <FormattedMessage id='signout.text' defaultMessage='' /> </a> </span> 
                </div>
                    </div>

              

                {/* <div className="my-account-logout pt30">
                <span className="link mr20" onClick={() => {
                        props.showModal({
                            title: <div>Edit Profile</div>,
                            modalBody: <ChangePassword {...props} />,
                            width: 700,
                            hideFooter: true,
                        })
                    }}><FormattedMessage id='editProfile.text' defaultMessage='' /></span>

                    <a href='/portal/'>
                        <span className="logout ml20" onClick={() => {
                            logout();
                        }}>
                            <FormattedMessage id='signout.text' defaultMessage='' />
                        </span>
                    </a>
                </div> */}
            </div>
            <div>
            {/* <div className="my-account-logout">
                <span className="link mr20" onClick={() => {
                       
                    }}>
                        <a href='/portal/admin/profile/'>
                    <i className="fa fa-user pr10" /> 
                    <FormattedMessage id='viewProfile.text' defaultMessage='' />
                    </a>
                </span>
            </div> */}
            {/* <div className="my-account-logout ">
                <span className="link mr20" style={{cursor: 'pointer'}} onClick={() => {
                        // const modalData = {
                        //     title: <React.Fragment>
                        //         <div className='ant-modal-title' style={{ textAlign: 'center' }}>
                        //             Edit Profile
                        //         </div>
                        //     </React.Fragment>,
                        //     modalBody: <EditProfile {...props}  addStudent={(data) => { 
                               
                        //     }}/>,
                        //     width: '100%',
                        //     hideFooter: true,
                        // };
                        // props.pushModalToStack(modalData);
                    }}>
                        <a href='/portal/admin/profile'>
                    <i className="fa fa-user pr10" /> 
                    <FormattedMessage id='myProfile.text' defaultMessage='' />
                    </a>
                    </span>
                </div> */}

                {/* <div className="my-account-logout pb10 pt10" style={{borderBottom:'solid 1px #e4e4e4'}}>
                    <span className="link mr20" style={{cursor:'pointer'}} onClick={() => {
                        props.showModal({
                            title: <div>Change Password</div>,
                            modalBody: <ChangePassword {...props} />,
                            width: 700,
                            hideFooter: true,
                        })
                    }}>
                        <i className="fa fa-lock pr10" />Change Password

                    </span>
                </div> */}
                
                {/* <div className="custom-row pb20">
                    <div className="title">
                    <FormattedMessage id='lastLogin.text' defaultMessage='' />
                    </div>
                    <div>
                        24th March 2019 11:30 AM
                    </div>
                </div> */}
                {/* <div className="custom-row pb20">
                    <div className="title">
                        Country
                    </div>
                    <div>
                        {(props.companyInfo || {}).countryName}
                    </div>
                </div>
                <div className="custom-row pb20">
                    <div className="title">
                        <FormattedMessage id='homeCurr.text' defaultMessage='' />
                    </div>
                    <span><i
                        className={(props.companyInfo || {}).currencyIcon} /> ({(props.companyInfo || {}).currencyCode + ""})</span>
                </div>
                {<div className="custom-row pb20">
                    <div className="title">
                        <FormattedMessage id='language.text' defaultMessage='' />
                    </div>
                    <select name="language" value={selectedLanguage} onChange={(e) => {
                        addToLocalStorage("language", e.target.value);
                        window.location.reload();
                    }}>
                        <option value="en">English</option>
                        <option value="fr">French</option>
                        <option value="ar">Arabic</option>
                        <option value="de">German</option>
                    </select>
                </div>}
                <div className="custom-row pb20">
                    <div className="title">
                        <FormattedMessage id='timezon.text' defaultMessage='' />
                    </div>
                    <div>
                        {(props.companyInfo || {}).timezone}
                    </div>
                </div>
                <div className="custom-row pb20">
                    <div className="title">
                        <FormattedMessage id='financialYear.text' defaultMessage='' />
                    </div>
                    <div>
                        {getFinacialYearFragment(props.companyInfo || {})}
                    </div>
                </div> */}
                {/* <div className="custom-row pb20">
                    <div className="title">
                        Knowledge Base
                    </div>
                    <a
                        href="https://thepromptsolutions.com/businessnotes/knowledgebase/index.html"
                        target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-book"/>
                    </a>
                </div> */}
                {/* <div className="custom-row">
                    <div className="title">
                        <FormattedMessage id='setting.text' defaultMessage='' />
                    </div>
                    <Link to='/admin/show-settings'>
                        <i className="fa fa-cog" />
                    </Link>
                </div> */}
            </div>
            {/* <div className="user-footer">
                <ul>
                    <li><FormattedMessage id='privacyPolicy.text' defaultMessage='' /></li>
                    <li><FormattedMessage id='termsOfService.text' defaultMessage='' /></li>
                </ul>
            </div> */}
        </div> : null}
    </div>
}
