import React, { Fragment } from 'react';
import './general.scss';
import { GRID_ACTIONS } from '../../static/constants'
export function ActionComponent(props) {
  return (
    <i className='fa fa-trash fa-lg cursor-pointer' onClick={props.onClick} type="delete" />
  )
}

export function BiddingSuppliersGridActions(props) {
  return (
    <div className="supplier-action-cell">
      <i className='fa fa-envelope fa-lg' onClick={() => props.onClick(GRID_ACTIONS.EMAIL)} type="email" />
      <i className='fa fa-save fa-lg' onClick={() => props.onClick(GRID_ACTIONS.SAVE)} type="save" />
      <i className='fa fa-trash fa-lg' onClick={() => props.onClick(GRID_ACTIONS.DELETE)} type="delete" />
      <i className='fa fa-file-pdf-o fa-lg' onClick={() => props.onClick(GRID_ACTIONS.DOWNLOAD_PDF)} type="pdf" />
    </div>
  )
}

export function ActionEditAndDeleteComponent(props) {
  return (
    <div className={(props.className || '') +" action-cell"}>
      {
        !props.permittedAction || props.permittedAction.update
          ? (<i className='fa fa-edit' onClick={() => props.onClick(GRID_ACTIONS.EDIT)} type="edit" />)
          : <Fragment />
      }
      {
        !props.permittedAction || props.permittedAction.delete
          ? (<i className='fa fa-trash' onClick={() => props.onClick(GRID_ACTIONS.DELETE)} type="delete" />)
          : <Fragment />
      }
    </div>
  )
}

export function ActionSaveAndDeleteComponent(props) {
  return (
    <div>
      <i className='fa fa-save fa-lg' onClick={() => props.onClick(GRID_ACTIONS.SAVE)} type="save" />
      <i style={{ padding: '10px' }} className='fa fa-trash fa-lg' onClick={() => props.onClick(GRID_ACTIONS.DELETE)} type="delete" />
    </div>
  )
}


export function SplitActionComponent(props) {
  return (
    <div>
      {props.isChild ? <i style={{ padding: '5px' }} className='fa fa-arrow-circle-up fa-lg' onClick={() => props.onClick(GRID_ACTIONS.DELETE)} type="delete" /> : ''}
      {!props.isChild && !props.isDisable ? <i style={{ padding: '5px' }} className='fa fa-arrow-circle-down fa-lg' onClick={() => props.onClick(GRID_ACTIONS.DELETE)} type="delete" /> : ""}

    </div>
  )
}


export function QualityAction(props) {
  return (
    <div>
      <i className='fa fa-trash fa-lg cursor-pointer' onClick={props.onClick} type="delete" />
      <i className='fa fa-upload fa-lg cursor-pointer' onClick={props.onClickUpload} type="delete" />

    </div>
  )
  }
  export function ActionLock(props) {
    return (
      <div>
        <i className='fa fa-lock fa-lg' type="lock" />
      </div>
    )
    }