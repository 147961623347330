import axios from 'axios';
import config from '../config/environmentConfig';
import { COMMON_ACTIONS, CONSTANTS, MODAL_ACTIONS, LOCAL_STORAGE_KEYS } from '../static/constants';
import { showToasterMessage, clearLocalStorage, addToLocalStorage } from '../utils';
const { lang } = require('../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchCountries = () => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/country/getCountries`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.COUNTRIES_LIST, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const uploadImage = (payload) => {
  return dispatch => {
    let formData = new FormData();
    formData.append("fileAsByte", payload.file);
    formData.append('objectKey', payload.objectKey);
    formData.append('bucketName', payload.bucketName);
    formData.append("contentType", "multipart/form-data;");
    formData.append("contentLength", "1234");
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.AWS_BASE_URL}${config.API_VERSION}alpide-aws/boBucketOperations/saveOrUpdateFile`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
         })
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (err || {}).message || 'Created successfully.',
          messageType: 'error'
         })
      })
  }
}



// export const fetchProducts = (payload, history) => {
//   return dispatch => {
//     //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     const url = payload.searchString ? `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getAllInventoryItems?relationshipId=${payload.relationshipId}&inventoryType=Product&removeChildren=no&warehouseMasterId=0&pageSize=${payload.pageSize || 2000}&pageNumber=0&searchString=${payload.searchString}` :
//       `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getAllInventoryItems?relationshipId=${payload.relationshipId}&inventoryType=Product&removeChildren=no&warehouseMasterId=0&pageSize=${payload.pageSize || 2000}&pageNumber=0`
//     return axios
//       .get(url)
//       .then(res => {
//         dispatch({ type: SALES_INVOICE_ACTION_LIST.PRODUCT_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (payload.updateAllOptions) {
//           payload.updateAllOptions(res.data);
//           payload.updateFetching(false);
//         }
//       })
//       .catch((err) => {
//         //  dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = SALES_INVOICE_ACTION_LIST.PRODUCT_LIST;
//         }
//       })
//   }
// }


export const fetchStates = (countryId) => {
  if (Number(countryId) === CONSTANTS.INDIA_COUNTRY_ID) {
    countryId = 1;
  }

  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/states/getStates?countryId=${countryId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.STATES_LIST, data: res.data });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.STATES_LIST, data: [] });
      })
  }
}

export const resetStates = () => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.STATES_LIST, data: [] });
  }
}

export const showModal = (data) => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.SHOW_MODAL, data });
  }
}

export const setFlash = (data) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.IS_FLASH_VISIBLE, data });
  }
}

// export const addStudentContact = (data) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.ADD_STUDENT_CONTACT, data });
//   }
// }

// export const fetchJobTitles = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/jobTitle/getAllJobTitles?relationshipId=${payload.relationshipId}`
//     )
//       .then(response => {
//         dispatch({ type: COMMON_ACTIONS.JOB_TITLE_LIST, data: response.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//   }
// }

// export const deleteJobTitle = (payload) => {
//   const requestObj = {
//     jobTitleId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/jobTitle/deleteJobTitle`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.JOB_TITLE_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: lang[(res || {}).message] || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.JOB_TITLE_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addJobTitle = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     jobTitle: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/jobTitle/createJobTitle`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.JOB_TITLE_LIST_ADDITION, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: lang[(res || {}).message] || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.JOB_TITLE_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }


export const hideModal = () => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.HIDE_MODAL });
  }
}

export const pushModalToStack = (modalData) => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.PUSH_STACK_MODAL, data: modalData });
  }
}

export const popModalFromStack = () => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL });
  }
}

// export const clearModalStack = () => {
//   return dispatch => {
//     dispatch({ type: MODAL_ACTIONS.CLEAR_STACK_MODAL });
//   }
// }

// export const setModalFormData = (formData) => {
//   return dispatch => {
//     dispatch({ type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE, data: { formData } })
//   }
// }

// export const fetchSources = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerSource/getAllCustomerSources?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=50`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SOURCE_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SOURCE_LIST;
//         }
//       })
//   }
// }

// export const deleteCustomerSource = (payload) => {
//   const requestObj = {
//     customerSourceId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerSource/deleteCustomerSource`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SOURCE_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if(payload.props){
//           payload.props.fetchSources({
//             relationshipId: payload.relationshipId
//           });
//         }
//         showToasterMessage({
//           description: lang[(res || {}).message] || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SOURCE_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addCustomerSource = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     customerSourceName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date())
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerSource/createCustomerSource`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if(payload.props){
//           payload.props.fetchSources({
//             relationshipId: payload.relationshipId
//           });
//         }
//         showToasterMessage({
//           description: lang[(res || {}).message] || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SOURCE_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }


// export const fetchBusinessTypes = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/businessType/getBusinessType?relationshipId=${payload.relationshipId}&pageSize=50&pageNumber=0`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.BUSINESS_TYPE_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.BUSINESS_TYPE_LIST;
//         }
//       })
//   }
// }

// export const deleteBusinessType = (payload) => {
//   const requestObj = {
//     businessTypeId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/businessType/deleteBusinessType`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.BUSINESS_TYPE_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if(payload.props){
//           payload.props.fetchSources({
//             relationshipId: payload.relationshipId
//           });
//         }
//         showToasterMessage({
//           description: lang[(res || {}).message] || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.BUSINESS_TYPE_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addBusinessType = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     businessType: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//     userCreated: "Mayank Tyagi"
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/businessType/createBusinessType`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if(payload.props){
//           payload.props.fetchSources({
//             relationshipId: payload.relationshipId
//           });
//         }
//         dispatch({ type: COMMON_ACTIONS.BUSINESS_TYPE_LIST_ADDITION, data: res.data });
//         showToasterMessage({
//           description: lang[(res || {}).message] || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.BUSINESS_TYPE_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

export const fetchPaymentTerms = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/paymentTerm/getAllPaymentTerms?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.PAYMENT_LIST, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.PAYMENT_LIST;
        }
      })
  }
}

// export const deletePaymentTerm = (payload) => {
//   const requestObj = {
//     paymentTermId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/paymentTerm/deletePaymentTerm`, requestObj)
//       .then(res => {
//         if(payload.props){
//           payload.props.fetchSources({
//             relationshipId: payload.relationshipId
//           });
//         }
//         dispatch({ type: COMMON_ACTIONS.PAYMENT_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.PAYMENT_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addPaymentTerm = (payload) => {
//   // dateCreated: "2020-01-01 12:24:59"
//   // relationshipId: 121
//   // isDefault: 1
//   // termName: "a"
//   // description: "qwerty"
//   // numberOfDays: 1
//   const requestObj = {
//     ...payload,
//   }
//   requestObj.numberOfDays = Number(payload.days);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/paymentTerm/insertPaymentTerm`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.PAYMENT_LIST_ADDITION, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.PAYMENT_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchIndustries = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/businessIndustry/getBusinessIndustriesByRId?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=50`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.INDUSTRY_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.INDUSTRY_LIST;
//         }
//       })
//   }
// }

// export const deleteIndustry = (payload) => {
//   const requestObj = {
//     businessIndustryId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/businessIndustry/deleteIndustry`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.INDUSTRY_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if(payload.props){
//           payload.props.fetchSources({
//             relationshipId: payload.relationshipId
//           });
//         }
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.INDUSTRY_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addIndustry = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     industryName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date())
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/businessIndustry/createIndustry`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if(payload.props){
//           payload.props.fetchSources({
//             relationshipId: payload.relationshipId
//           });
//         }
//         dispatch({ type: COMMON_ACTIONS.INDUSTRY_LIST_ADDITION, data: res.data });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.INDUSTRY_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchSalutation = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salutation/getAllSalutations?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SALUTATION_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SALUTATION_LIST;
//         }
//       })
//   }
// }

// export const deleteSalutation = (payload) => {
//   const requestObj = {
//     salutationId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salutation/deleteSalutation`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SALUTATION_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SALUTATION_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addSalutation = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     titleName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salutation/saveSalutation`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SALUTATION_LIST_ADDITION, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SALUTATION_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchPriceList = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/priceList/getPriceList?relationshipId=${payload.relationshipId}&pageSize=100&pageNumber=0`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.PRICE_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.PRICE_LIST;
//         }
//       })
//   }
// }

// export const deletePriceList = (payload) => {
//   const requestObj = {
//     priceListId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/priceList/deletePriceList`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.PRICE_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.PRICE_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

export const logout = () => {
  clearLocalStorage();
  window.location.reload();
}

export const setLocalStorageData = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data: payload.companyInfo });
    dispatch({ type: COMMON_ACTIONS.LOGGEDIN_USER_INFO, data: payload.userInfo });
    dispatch({ type: COMMON_ACTIONS.LOGGEDIN_USER_BANK_INFO, data: payload.bankInfo });
    dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_EMP_LIST, data: payload.companyEmpList });
    dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: payload.loggedInEntityInfo });
    dispatch({ type: COMMON_ACTIONS.ADMIN_TOKEN, data: payload.adminToken });
    // dispatch({ type: COMMON_ACTIONS.DEFAULT_SCHOOL_FORM, data: payload.formId });
    dispatch({ type: COMMON_ACTIONS.LOGGEDIN_EMAIL, data: payload.loggedInEmail })
  }
}
export const getSubdomain = (payload, cb) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getSubdomain?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.SUBDOMAIN, data: res.data });
        cb && cb(res.data);
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        cb && cb({});
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}
// export const fetchUOM = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lookupUOM/getAllUOM?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.UOM_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.UOM_LIST;
//         }
//       })
//   }
// }


// export const fetchRecentTransactions = (payload) => {
//   const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getRecentTx?relationshipId=${(loggedInEntityInfo.defaultCompany || {}).relationshipId}&pageSize=10&pageNumber=0&startDate=2019-04-01%2000:00:00&endDate=2021-03-31%2023:59:59`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.RECENT_TX_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//   }
// }

// export const deleteUOM = (payload) => {
//   const requestObj = {
//     uomId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lookupUOM/deleteUOM`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.UOM_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.UOM_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addUOM = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     uomName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lookupUOM/createUOM`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.UOM_LIST_ADDITION, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.UOM_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchExpenseTypes = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/expenseType/getAllExpenseTypes?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.EXPENSE_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.EXPENSE_LIST;
//         }
//       })
//   }
// }

// export const deleteExpenseTypes = (payload) => {
//   const requestObj = {
//     lkExpenseTypeId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/expenseType/deleteExpenseType`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.EXPENSE_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.EXPENSE_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const createEmployee = (payload, props) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relEmployee/createEmployee`, payload)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         dispatch({ type: COMMON_ACTIONS.EMPLOYEES_LIST_ADDITION, data: res.data });
//         if (props) {
//           props.updateState({
//             company: {
//               employeeDrawerVisible: false
//             }
//           });
//           props.fetchAllEmployees({ relationshipId: payload.relationshipId })
//         }
//         showToasterMessage({ messageType: 'success', description: 'Employee created successfully' });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = COMMON_ACTIONS.EMPLOYEES_LIST_ADDITION;
//         }
//       })
//   }
// }

// export const addExpenseTypes = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     expenseType: payload.text,
//    // dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/expenseType/createExpenseType`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.EXPENSE_LIST_ADDITION, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.EXPENSE_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }


// export const fetchTaxes = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/taxModule/getAllSingleTaxRates?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.TAX_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.TAX_LIST;
//         }
//       })
//   }
// }

// export const deleteTax = (payload) => {
//   const requestObj = {
//     taxSingleRateId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/taxModule/deleteSingleTaxRate`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.TAX_LIST_DELETION, data: { id: payload.id } })
//         if (payload.taxType === 'VAT') {
//           dispatch({ type: INVENTORY_ACTION_LIST.GLOBLE_TAXES_DELETION, data: { id: payload.id } })
//         }
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.TAX_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }


// export const fetchProjects = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/projectMaster/getAllProjects?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.PROJECT_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.PROJECT_LIST;
//         }
//       })
//   }
// }

// export const deleteProject = (payload, props) => {
//   const requestObj = {
//     projectMasterId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/projectMaster/deleteProject`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.PROJECT_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         (props && props.getAllProject) && props.getAllProject(payload);
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.PROJECT_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchDocuments = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/documentName/getAllDocumentNames?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.DOCUMENT_LIST;
//         }
//       })
//   }
// }

// export const deleteDocumentName = (payload) => {
//   const requestObj = {
//     documentNameId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/documentName/deleteDocumentName`, requestObj)
//       .then(res => {
//         //dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST_DELETION, data: { id: payload.id } })
//         fetchDocuments({relationshipId : payload.relationshipId})(dispatch);
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.DOCUMENT_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addDocumentName = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     docName: payload.text,
//     txType: payload.txType,
//    // dateCreated: getCurrentDateForBackend(new Date())
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/documentName/createDocumentName`, requestObj)
//       .then(res => {
//         //dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST_ADDITION, data: res.data });
//         fetchDocuments({relationshipId : payload.relationshipId})(dispatch);
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.DOCUMENT_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchSupplierSources = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierSource/getAllSupplierSources?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=50`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SUPPLIER_SOURCE_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SUPPLIER_SOURCE_LIST;
//         }
//       })
//   }
// }

// export const addSupplierSource = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     supplierSourceName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//     userCreated: "Mayank Tyagi"
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierSource/saveOrUpdateSupplier`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         dispatch({ type: COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_ADDITION, data: res.data });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteSupplierSource = (payload) => {
//   const requestObj = {
//     supplierSourceId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierSource/deleteSupplierSource`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchPaymentModes = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/paymentMode/getAllPaymentModes?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.PAYMENT_MODE_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = COMMON_ACTIONS.PAYMENT_MODE_LIST;
//         }
//       })
//   }
// }

// export const fetchCashEquivalentLedgers = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/chartOfAccountDetails/getCashEquivalentLedgers?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.CASH_EQUIVALENT_LEDGER, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = COMMON_ACTIONS.CASH_EQUIVALENT_LEDGER;
//         }
//       })
//   }
// }

// export const resetSupplierDetail = () => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SUPPLIER_DETAIL_RESET });
//   }
// }

// export const fetchSupplierDetailsBySupplierId = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SUPPLIER_DETAIL_RESET });
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/suppliers/getSupplier?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
//       .then(res => {
//         if(payload.callback){
//           payload.callback();
//         }
//         dispatch({ type: COMMON_ACTIONS.SUPPLIER_DETAIL, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = COMMON_ACTIONS.SUPPLIER_DETAIL;
//         }
//       })
//   }
// }

// export const fetchCustomerDetailsByCustomerId = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/getCustomer?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.CUSTOMER_DETAIL, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = COMMON_ACTIONS.CUSTOMER_DETAIL;
//         }
//       })
//   }
// }

// export const fetchCustomerDetailsByCustomerIdForUpdate = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/getCustomer?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.CUSTOMER_DETAIL_UPDATE, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = COMMON_ACTIONS.CUSTOMER_DETAIL_UPDATE;
//         }
//       })
//   }
// }

// export const getRelationshipAddress = (payload)=>{
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/getRelationshipAddress?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.BO_RELATIONSHIP_ADDRESS, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         payload.callback && payload.callback()
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//   }
// }

// export const resetCustomerDetail = () =>{
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.CUSTOMER_DETAIL_RESET });
//   }
// }

// export const resetCustomerDetailUpdate = () =>{
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.CUSTOMER_DETAIL_UPDATE_RESET });
//   }
// }

// export const fetchAllDepartments = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boDepartment/getAllDepartments?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.DEPARTMENT_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = COMMON_ACTIONS.DEPARTMENT_LIST;
//         }
//       })
//   }
// }

// export const addDepartment = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     departmentName: payload.text,
//    // dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boDepartment/saveOrUpdateDepartment`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.DEPARTMENT_LIST_ADDITION, data: res.data })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.DEPARTMENT_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteDepartment = (payload) => {
//   const requestObj = {
//     departmentId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boDepartment/deleteDepartment`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.DEPARTMENT_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.DEPARTMENT_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchAllEmployees = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relEmployee/getAllEmployee?relationshipId=${payload.relationshipId}&searchedText=${payload.searchedText}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.EMPLOYEES_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.EMPLOYEES_LIST;
//         }
//       })
//   }
// }

// export const deleteEmployee = (payload) => {
//   const requestObj = {
//     relationshipEmployeeId: payload.id,
//     relationshipId: payload.relationshipId,
//     emailAddress: (payload.employee || {}).emailAddress,
//     firstName: (payload.employee || {}).firstName,
//     lastName: (payload.employee || {}).lastName
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relEmployee/deleteEmployee`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.EMPLOYEES_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.EMPLOYEES_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteContact = (payload) => {
//   const requestObj = {
//     contactId: payload.id,
//     relationshipId: payload.relationshipId,
//     customerId: payload.customerId,
//     supplierId: payload.supplierId,
//     isPrimaryContact: payload.isPrimaryContact || 0
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/deleteContact`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.EMPLOYEES_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         payload.callback && payload.callback({...payload, contactId: payload.id});
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.EMPLOYEES_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteAddress = (payload) => {
//   const requestObj = {
//     locationId: payload.id,
//     relationshipId: payload.relationshipId
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/deleteAddress`, requestObj)
//       .then(res => {
//         if(payload.customerId) fetchAddresses({...payload, locationType: CONSTANTS.BILLING_ADDRESS})(dispatch);
//         if(payload.customerId) fetchAddresses({...payload, locationType: CONSTANTS.SHIPPING_ADDRESS})(dispatch);
//          dispatch({ type: COMMON_ACTIONS.ADDRESS_DELETION, data: { id: payload.id } });
//         //if(payload.customerId) fetchCustomerDetailsByCustomerId({relationshipId: payload.relationshipId, customerId: payload.customerId})(dispatch);
//         payload.callback && payload.callback({...payload});
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.ADDRESS_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteActivityPriorities = (payload) => {
//   const requestObj = {
//     activityPriorityId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/crmActivity/deleteActivityPriority`, requestObj)
//       .then(res => {
//         dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_PRIORITIES, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_PRIORITIES;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addActivityPriorities = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     activityPriorityName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/crmActivity/saveOrUpdateActivityPriority`, requestObj)
//       .then(res => {
//         dispatch({ type: CUSTOMER_ACTION_LIST.ADD_ACTIVITY_PRIORITIES, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = CUSTOMER_ACTION_LIST.ADD_ACTIVITY_PRIORITIES;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteActivityStatus = (payload) => {
//   const requestObj = {
//     activityStatusId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/crmActivity/deleteActivityStatus`, requestObj)
//       .then(res => {
//         dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_STATUS, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_STATUS;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addActivityStatus = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     activityStatusName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/crmActivity/saveOrUpdateActivityStatus`, requestObj)
//       .then(res => {
//         dispatch({ type: CUSTOMER_ACTION_LIST.ADD_ACTIVITY_STATUS, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = CUSTOMER_ACTION_LIST.ADD_ACTIVITY_STATUS;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteActivityTypes = (payload) => {
//   const requestObj = {
//     activityTypeId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/crmActivity/deleteActivityType`, requestObj)
//       .then(res => {
//         dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_TYPES, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_TYPES;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addActivityTypes = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     activityTypeName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/crmActivity/saveOrUpdateActivityType`, requestObj)
//       .then(res => {
//         dispatch({ type: CUSTOMER_ACTION_LIST.ADD_ACTIVITY_TYPES, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = CUSTOMER_ACTION_LIST.ADD_ACTIVITY_TYPES;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchUserPreferences = (payload) => {
//   return dispatch => {
//     //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/userPrefernce/getUserPreferences?relationshipId=${payload.relationshipId}&clientUserAccountId=${payload.userId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.USER_PREFERENCES, data: res.data });
//         // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.USER_PREFERENCES_FAIL });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.USER_PREFERENCES;
//         }
//       })
//   }
// }

// export const saveUserPrefrences = (payload) => {
//   return dispatch => {
//     // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/userPrefernce/createUserPrefernce`, payload)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SAVE_OR_UPDATE_PREFERENCE, data: res.data });
//         // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         payload.userId = payload.clientUserAccountId;
//         fetchUserPreferences(payload)(dispatch);
//         // showToasterMessage({
//         //   description: (res || {}).message || 'Saved successfully.',
//         //   messageType: 'success'
//         // })
//       })
//       .catch((err = {}) => {
//         //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SAVE_OR_UPDATE_PREFERENCE;
//         } else {
//           // showToasterMessage({
//           //   description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           // })
//         }
//       })
//   }
// }

// export const fetchShippingInstructions = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/shippingInstruction/getShippingInstructions?relationshipId=${payload.relationshipId}&pageSize=1000&pageNumber=0`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SHIPPING_INSTRUCTION_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = COMMON_ACTIONS.SHIPPING_INSTRUCTION_LIST;
//         }
//       })
//   }
// }

// export const addShippingInstruction = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     shippingInstructions: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/shippingInstruction/saveShippingInstruction`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SHIPPING_INSTRUCTION_ADDITION, data: res.data })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SHIPPING_INSTRUCTION_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteShippingInstruction = (payload) => {
//   const requestObj = {
//     shippingInstructionId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/shippingInstruction/deleteShippingInstruction`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SHIPPING_INSTRUCTION_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SHIPPING_INSTRUCTION_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchTaxIdentifications = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/taxIdentification/getAllTaxIdentifications?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.TAX_IDENTIFICATION_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = COMMON_ACTIONS.TAX_IDENTIFICATION_LIST;
//         }
//       })
//   }
// }

// export const addTaxIdentification = (payload, gridApi) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     type: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/taxIdentification/createTaxIdentification`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.TAX_IDENTIFICATION_ADDITION, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if(payload.callback){
//           payload.callback(res.data);
//         }
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         console.log(err, "errerrerr")
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.TAX_IDENTIFICATION_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteTaxIdentifications = (payload) => {
//   const requestObj = {
//     taxIdentificationId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/taxIdentification/deleteTaxIdentification`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.TAX_IDENTIFICATION_DELETION, data: { id: payload.id } })
//         if(payload.callback){
//           payload.callback(payload)
//         }
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.TAX_IDENTIFICATION_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const fetchRelationshipTaxIdentifications = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relationshipTaxIdentification/getAllRelationshipTaxIdentifications?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.REL_TAX_IDENTIFICATION_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         // if (!err.__isRetryRequest) {
//         //   //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         // } else {
//         //   err.actionToCall = COMMON_ACTIONS.REL_TAX_IDENTIFICATION_LIST;
//         // }
//       })
//   }
// }

// export const addRelationshipTaxIdentification = (payload) => {
//   const requestObj = {
//     ...payload,
//    // dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relationshipTaxIdentification/createRelationshipTaxIdentification`, requestObj)
//       .then(res => {
//         !payload.relationshipTaxIdentificationId && dispatch({ type: COMMON_ACTIONS.REL_TAX_IDENTIFICATION_ADDITION, data: res.data })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.REL_TAX_IDENTIFICATION_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteRelationshipTaxIdentifications = (payload) => {
//   const requestObj = {
//     relationshipTaxIdentificationId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relationshipTaxIdentification/deleteRelationshipTaxIdentification`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.REL_TAX_IDENTIFICATION_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.REL_TAX_IDENTIFICATION_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteCustomerTaxIdentifications = (payload, cb) => {
//   const requestObj = {
//     customerTaxIdentificationId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTaxIdentification/deleteCustomerTaxIdentification`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.CUSTOMER_TAX_IDENTIFICATION_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         cb && cb(payload);
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.CUSTOMER_TAX_IDENTIFICATION_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const deleteSupplierTaxIdentifications = (payload, cb) => {
//   const requestObj = {
//     supplierTaxIdentificationId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierTaxIdentification/deleteSupplierTaxIdentification`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.SUPPLIER_TAX_IDENTIFICATION_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         cb && cb(payload);
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.SUPPLIER_TAX_IDENTIFICATION_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const createAuditTrail = (payload, props) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     let url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/auditTrail/${payload.apiUrl}`;
//     return axios
//       .post(url, payload)
//       .then(res => {
//         props.updateState({
//           isCommentVisible: false,
//           comment: ""
//         });
//         props.updateState({
//           trailList: res.data
//         });
//         props.updateTrailListInTransaction(res.data);
//         props.hideModal();
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//       })
//   }
// }



// export const deleteAuditTrail = (payload, props) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     let url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/auditTrail/${payload.deletApiUrl}`;
//     return axios
//       .post(url, payload)
//       .then(res => {
//         props.updateState({
//           trailList: res.data
//         })
//         props.updateTrailListInTransaction(res.data);
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//       })
//   }
// }



// export const getQualityChecklistSettings = (payload, history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/qualityChecklistSettings/getQualityChecklistSettings?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: SETTING_ACTION_LIST.QUALITY_CHECK_LIST_SETTING, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = SETTING_ACTION_LIST.QUALITY_CHECK_LIST_SETTING;
//         }
//       })
//   }
// }

// export const fetchIncoterms = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/incoterm/getAllIncoterm?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.INCOTERM_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.INCOTERM_LIST;
//         }
//       })
//   }
// }

// export const deleteIncoterm = (payload) => {
//   const requestObj = {
//     inctermId: payload.id,
//     relationshipId: payload.relationshipId,
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/incoterm/deleteIncoterm`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.INCOTERM_LIST_DELETION, data: { id: payload.id } })
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Deleted successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.INCOTERM_LIST_DELETION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }

// export const addIncoterm = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     incotermName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/incoterm/createIncoterm`, requestObj)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.INCOTERM_LIST_ADDITION, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = COMMON_ACTIONS.INCOTERM_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }


// export const fetchAddresses = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/getAddressByCustomerIdAndLocationTpe?relationshipId=${payload.relationshipId}&locationType=${payload.locationType}&customerId=${payload.customerId}`)
//       .then(res => {
//         if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
//           dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES, data: res.data });
//         } else {
//           dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES, data: res.data });
//         }
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
//             err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES;
//           } else {
//             err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES;
//           }
//         }
//       })
//   }
// }

// export const resetAddresses = () =>{
//   return dispatch => {
//     dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES, data: [] });
//     dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES, data: [] });
//   }
// }

// export const fetchSupplierSummary = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/suppliers/getSupplierSummary?relationshipId=${payload.relationshipId}&pageNumber=${(payload.pageNumber || 1)-1}&pageSize=${payload.pageSize || 10}`)
//       .then(res => {
//         const data = {
//           pageNo: payload.pageNumber,
//           list: res.data
//         }
//         dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_SUMMARY, data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_SUMMARY;
//         }
//       })
//   }
// } 

// export const getAllPaymentGatewayConfig = (payload) =>{
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getAllPaymentGateways?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         dispatch({
//           type: COMMON_ACTIONS.ALL_PAYMENT_GATEWAY_CONFIG,
//           data: res.data
//         });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'})
//       })
//   }
// }

// export const getAllMatchingInventoryItems = (payload) =>{
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getAllMatchingInventoryItems?relationshipId=${payload.relationshipId}&inventoryItemCol=${payload.itemIds}`)
//       .then(res => {
//         dispatch({
//           type: COMMON_ACTIONS.SELECTED_PRODUCT_LIST,
//           data: res.data
//         });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err = {}) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'})
//       })
//   }
// }
// export const resetMatchingInventoryItems = () =>{
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SELECTED_PRODUCT_LIST, data: [] });
//   }
// }

// export const getItemByUPC = (payload, props) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getItemByUpcCode?relationshipId=${payload.relationshipId}&upcCode=${payload.upc}`)
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         // (payload.callback && res.data) && payload.callback(res.data);
//         if (payload.callback && res.data && res.data.hasVariant) {
//           let load = { formData: { product: res.data } }
//           const formData = load.formData;
//           const data = {
//             title: lang['selectVariant.text'],
//             formData,
//             hideFooter: true,
//             modalData: {
//               modalType: MODAL_TYPE.SELECT_VARIANT,
//               data: load,
//             },
//             handleSubmit: (variant) => {
//               payload.callback({ ...res.data, inventoryItemVariantList: [variant] });
//               props.hideModal(data);
//             }
//           };
//           props.showModal(data);
//         } else if (payload.callback && res.data) {
//           payload.callback(res.data)
//         }
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//   }
// }

export const setDefaultSchoolForm = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/onlineUser/updateDefaultProgram`, payload)
      .then(res => {
        // dispatch({ type: COMMON_ACTIONS.DEFAULT_SCHOOL_FORM, data: payload.formId });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: res.data });
        addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(res.data));
        window.location.reload();
        showToasterMessage({
          description: lang[(res || {}).message] || 'Saved successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // dispatch({ type: COMMON_ACTIONS.DEFAULT_SCHOOL_FORM, data: payload.formId });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.DEFAULT_SCHOOL_FORM;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}


export const setDefaultCourse = (courseId) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.DEFAULT_COURSE, data: courseId});
    addToLocalStorage(COMMON_ACTIONS.DEFAULT_COURSE, JSON.stringify(courseId));
  }
}