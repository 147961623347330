import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker } from 'antd';
import { formatDateForBackend, getMomentDateForUI, validateEmail } from '../../../../../utils'
import { DropdownRef } from '../../../../general/Dropdown';
import { CustomButton } from '../../../../general/CustomButton';
import { CONSTANTS } from '../../../../../static/constants';
import { TextBox } from '../../../../general/TextBox';
import CKEditor from '../../../../general/CustomCKEditor/ckeditor';
import * as find from 'lodash.find';
// import moment from 'moment';
// import { ErrorMsg } from '../../../../general/ErrorMessage';
import { CUSTOM_CONFIGURATION_FORM } from '../../../../../static/ckEditorConfigConstants';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class EmploymentComponent extends Component {
  constructor(props) {
    super(props);
    props.updateState({ ...this.props.data.formData });
    this.state ={
      countryCallingCode: ((props.data || {}).formData || {}).countryCallingCode || (this.props.companyInfo || {}).countryCallingCode,
      ...props.data.formData
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    
    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content billing-address-control emapl-address">
           
            <Form.Item label={<span>Employer Name <span className="required">*</span></span>} colon={false}>
              {getFieldDecorator('employerName', {
                initialValue: ((this.props.data || {}).formData || {}).employerName || '',
                rules: [
                  {
                    required: true,
                    message: 'Employer name is required',
                  }]
              })
                (<TextBox maxLength={45} placeholder={'Employer Name'} />)}
            </Form.Item>
            <Form.Item className='hide' label={<FormattedMessage id='countryCallingCode.text' defaultMessage='' />} colon={false}>
                {getFieldDecorator('countryCallingCode', {
                  initialValue: this.props.countryCallingCode || '',
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: <FormattedMessage id='countryCallingCode.validationMessage' />,
                  //   }]
                })
                  (<DropdownRef
                    items={(this.props.countries || []).filter(el => { return el.countryCallingCode != null; }) || []}
                    optionKeyName='countryId'
                    valueKeyName='countryCallingCode'
                    valueKeyName2='countryName'
                    onSelect={(selectedValue, option) => {
                      const selectedCountry = find(this.props.countries, { countryId: selectedValue });
                      this.setState({
                        countryCallingCode: selectedCountry.countryCallingCode
                      });
                      this.props.form.setFieldsValue({ 'countryId': selectedValue });
                      this.props.form.setFieldsValue({ 'countryCallingCode': selectedCountry.countryCallingCode });
                    }}
                  />)}
              </Form.Item>
            <Form.Item label={'Employer Phone'} colon={false}>
              {getFieldDecorator('employerPhone', {
                initialValue: this.state.employerPhone || '',
                rules: [
                  {
                    pattern: /^[0-9\b]+$/,
                    message: 'Please enter number only',
                  },
                  {
                    pattern: CONSTANTS.PHONE_REGEX_EXP,
                    message: 'Mobile number should be 10 digits.',
                  }
                ]
              })
                (<span className='phone-city'>
                <DropdownRef
                  placeholder={this.props.intl.formatMessage({ id: 'countryCallingCode.text', defaultMessage: '' })}
                  items={(this.props.countries || []).filter(el => { return el.countryCallingCode != null; }) || []}
                  optionKeyName='countryId'
                  valueKeyName='countryCallingCode'
                  valueKeyName2='countryName'
                  value={this.state.countryCallingCode}
                  onSelect={(selectedValue, option) => {
                    const selectedCountry = find(this.props.countries, { countryId: selectedValue });
                    this.setState({
                      countryCallingCode: selectedCountry.countryCallingCode
                    });
                    this.props.form.setFieldsValue({ 'countryId': selectedValue });
                    this.props.form.setFieldsValue({ 'countryCallingCode': selectedCountry.countryCallingCode });
                  }}
                /><TextBox maxLength={10} placeholder='Employer Phone' />
                </span>)}
            </Form.Item>
           
            <Form.Item label={(<span>Employer Email</span>)} colon={false}>
              {getFieldDecorator('employerEmail', {
                initialValue: this.state.employerEmail || '',
                  rules: [
                  {
                    pattern: CONSTANTS.EMAIL_REGEX_EXP,
                    message: 'Invalid email address.',
                  }
                ]
              })
                (<TextBox maxLength={254} className={(this.state || {}).invalidEmailClass} placeholder='Employer Email'  onChange={(e)=>{
                  this.setState({
                    employerEmail: e.target.value,
                    invalidEmailClass: validateEmail(e.target.value) ? '' : 'input-text-error'
                  });
                }}/>
                )}
                {/* <ErrorMsg
                validator={() => { return !(this.state || {}).submittedOnce || !!(this.state || {}).employerEmail; }}
                message={this.props.intl.formatMessage(
                  { id: 'Employer Email', defaultMessage: '' }
                )}
              /> */}
            </Form.Item>
          
           
            <Form.Item label={<FormattedMessage id='modal.common.project.startDate' defaultMessage='' />} colon={false}>
              {getFieldDecorator('startDate', {
                initialValue: this.state.startDate ? getMomentDateForUI({ date: new Date(this.state.startDate), format }) : null
              })
                (<DatePicker
                  allowClear={true}
                  format={format}
                  key='startDate'
                  defaultValue={this.state.startDate ? getMomentDateForUI({ date: new Date(this.state.startDate), format }) : null}
                  value={this.state.startDate ? getMomentDateForUI({ date: new Date(this.state.startDate), format }) : null}
                  onChange={(selectedDate) => {
                    this.setState({ startDate: selectedDate });
                  }}
                  size={0}
                  // disabledDate={(current) => {
                  //   return current && current > moment().endOf('day');
                  // }}
                />)}
                
            </Form.Item>
                 <Form.Item label={<FormattedMessage id='modal.common.project.endDate' defaultMessage='' />} colon={false}>
              {getFieldDecorator('endDate', {
                initialValue: this.state.endDate ? getMomentDateForUI({ date: new Date(this.state.endDate), format }) : null
              })
                (<DatePicker
                  allowClear={true}
                  format={format}
                  key='endDate'
                  defaultValue={this.state.endDate ? getMomentDateForUI({ date: new Date(this.state.endDate), format }) : null}
                  value={this.state.endDate ? getMomentDateForUI({ date: new Date(this.state.endDate), format }) : null}
                  onChange={(selectedDate) => {
                    this.setState({ endDate: selectedDate });
                  }}
                  size={0}
                  // disabledDate={(current) => {
                  //   return current && current > moment().endOf('day');
                  // }}
                />)}
                </Form.Item>
                <Form.Item label={'Job Title'} colon={false}>
              {getFieldDecorator('jobTitle', {
                initialValue: ((this.props.data || {}).formData || {}).jobTitle || '',
                // rules: [
                //   {
                //     required: true,
                //     message: 'Institution name is required',
                //   }]
              })
                (<TextBox maxLength={45} placeholder={'Job Title'} />)}
            </Form.Item>
            
            <Form.Item label={'Job Duties'} colon={false}>
              {getFieldDecorator('jobDuties', {
                initialValue: ((this.props.data || {}).formData || {}).jobDuties || ''
              })
              //(<TextBox maxLength={45} placeholder={'Job Duties'} />
                (  <CKEditor
                // editor={ClassicEditor}
                data={this.state.jobDuties || ''}
                key={`${"jobDuties"}`}
                // onInit={editor => {
                //     this.editor = editor;
                // }}
                onChange={(event) => {
                    const data = event.editor.getData();
                    this.setState({ jobDuties: data });
                }}
                config={{ ...CUSTOM_CONFIGURATION_FORM, placeholder: 'Job Duties' }}
            />)}
            </Form.Item>
            <Form.Item label={'Reason For Leaving'} colon={false}>
              {getFieldDecorator('reasonForLeaving', {
                initialValue: ((this.props.data || {}).formData || {}).reasonForLeaving || '',
                // rules: [
                //   {
                //     required: true,
                //     message: 'Institution name is required',
                //   }]
              })
                (<TextBox maxLength={45} placeholder={'Reason For Leaving'} />)}
            </Form.Item>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.address.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => {
                  if(((this.props.data || {}).formData || {}).onCancel){
                    this.props.data.formData.onCancel();
                  }

                  if(((this.props.data || {}).formData || {}).addressType === 'relationship'){
                    this.props.hideModal();
                  }else{
                    this.props.hideModal();
                    this.props.popModalFromStack() 
                  }
                  
                }}
              />
              <CustomButton
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          employerName: values.employerName,
          version: ((this.props.data || {}).formData || {}).version,
          jobTitle: values.jobTitle,
          reasonForLeaving: values.reasonForLeaving,
          jobDuties: this.state.jobDuties,
          employerPhone: values.employerPhone,
          employerEmail: values.employerEmail,
          startDate: values.startDate ? formatDateForBackend(values.startDate) : null,
          endDate: values.endDate ? formatDateForBackend(values.endDate) : null,
          contactId: ((this.props.data || {}).formData || {}).contactId,
          customerId: ((this.props.data || {}).formData || {}).customerId,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          contact_employment_id: ((this.props.data || {}).formData || {}).contact_employment_id,
        }

        const modalData = {
          modalBody: 'Are you sure you want to save?',
          handleSubmit: () => {
            this.props.createEmployment(payload, (this.props.data || {}).formData, this.props);
            this.props.popModalFromStack();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Employment_component' })(injectIntl(EmploymentComponent));
