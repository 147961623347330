import React, { useState, Fragment } from "react";
import { Form } from '@ant-design/compatible';
import { injectIntl, FormattedMessage } from 'react-intl';

import './index.scss';
import { Row, Col, Radio, Checkbox, Button, Select, Card, Switch } from "antd";
import { TextBox } from "../general/TextBox";
// import { CustomAgGrid } from "../general/CustomAgGrid";
import { TextArea } from "../general/TextAreaComponent";
import { CustomButton } from '../general/CustomButton';
import { Dropdown } from '../general/Dropdown';
import { getMomentDateForUIReadOnly, getCurrentDateForBackend, showToasterMessage, formatDateForBackend,  } from '../../utils';
import { CONSTANTS, TX_STATUS, MODAL_TYPE } from '../../static/constants';
import ReactHtmlParser from 'react-html-parser';
// import { LogoComponent } from '../general/LogoComponent';
// import config from '../../config/environmentConfig';
import find from "lodash.find";
import AllStudent from "./AllStudent";
import LocationIcon from "../../assets/images/location.png";
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { Option } = Select;

const Register = (props) => {
    const { companyInfo } = props;
    // let self = props;
    // const [gridApi, updateGridApi] = useState({});
    const [formSetting, updateFormSetting] = useState(props.formSetting || {});
    // const onGridReady = (params) => {
    //     updateGridApi(params.api);
    //     params.api.sizeColumnsToFit();
    // }
    // const getRowHeight = (params) => {
    //     return 45;
    // }
    let gradeArr = [];

    // ((formSetting.gradeFees && Object.keys(formSetting.gradeFees).length) ? Object.keys(formSetting.gradeFees) : []).map((i) => {
    //     if (formSetting.isGradeDisplayed && formSetting.isGradeDisplayed[i]) {
    //         let d = {};
    //         d.gradeValidation = Number(i);
    //         d.gradefee = formSetting.gradeFees[d.gradeValidation];
    //         gradeArr.push(d);
    //     }
    //     return gradeArr;
    // });
    (props.subcourseAndFeeList || []).map((subcourse) => {
        if (subcourse && subcourse.grade && subcourse.subcourseFee) {
            let d = {};
            d.gradeValidation = subcourse.grade;
            d.gradefee = subcourse.subcourseFee;
            d.subcourseName = subcourse.subcourseName;
            d.subcourseId = subcourse.subcourseId;
            d.gId = subcourse.gId;
            gradeArr.push(d);
        }
        return gradeArr;
    });

    const getTotalGradeFees = () => {
        let total = 0;
        // gridApi && gridApi.forEachNode && gridApi.forEachNode((rowNode) => {
        //     let { data } = rowNode;
        (props.studentList || []).forEach((data) => {
            if (!data.isInvalid && data.gradefee)
                total = total + Number(data.gradefee);
        });
        return total;
    }

    const appendCountryCode = (number) => {
        // if (props.companyInfo.countryCallingCode && number) {
        //     return '+' + props.companyInfo.countryCallingCode + number;
        // }else{
        //     return number;
        // }
        return number;
    }

    const getSelectedPlan = (plan) => {
        if (!plan) {
            return {};
        } else {
            let startDate = new Date(plan.startDate);
            let endDate = new Date(plan.endDate);
            if (plan.period === 'monthly') {
                startDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
                endDate = new Date(endDate.setMonth(endDate.getMonth() + plan.totalInstallment)); 
            } else if (plan.period === 'quarterly') {
                startDate = new Date(startDate.setMonth(startDate.getMonth() + 3));
                endDate = new Date(endDate.setMonth(endDate.getMonth() + (plan.totalInstallment * 3))); 
            }
            plan.startDate = formatDateForBackend(startDate);
            plan.endDate = formatDateForBackend(endDate);
            return plan;
        }
    }

    const save = (e) => {
        e.preventDefault();
        let isRequiredFiledMissing = false;
        let isRequiredQuestionMissing = false;
        let studentList = [];
        let boContactChildList = [];
        let isInvalidGrade = false;
        let registerStudentChildIds = [];

        let isFNameMissing= false;
        let isLNameMissing= false;
        let isGradeMissing= false;
        let isDOBMissing= false;
        let isAllergiesMissing= false;
        let isGenderMissing= false;

        // gridApi && gridApi.forEachNode && gridApi.forEachNode((rowNode, index) => {
        //     let { data } = rowNode;
        (props.studentList || []).forEach((data, index) => {
            // if (data.invalidGrade) {
            //     isInvalidGrade = true
            // }
            //else
             if (data.firstName || data.lastName || data.grade || data.dob || data.allergies || data.gender) {
                // if ( (!data.firstName || !data.lastName || !data.grade || !data.dob || !data.allergies || !data.gender)) {
                //     isRequiredFiledMissing = true;
                //     if (!data.firstName) { isFNameMissing = true; }
                //     if (!data.lastName) { isLNameMissing = true; }
                //     if (!data.grade) { isGradeMissing = true; }
                //     if (!data.dob) { isDOBMissing = true; }
                //     if (!data.allergies) { isAllergiesMissing = true; }
                //     if (!data.gender) { isGenderMissing = true; }
                // } else {
                    studentList.push({
                        sNo: index,
                        studentId: data.studentId || 0,
                        schoolStudentId: data.schoolStudentId || 0,
                        relationshipId: props.relationshipId,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        gender: data.gender,
                        grade: data.grade,
                        allergies: data.allergies,
                        dob: data.dob ? formatDateForBackend(data.dob) : '',
                        courseFee: data.gradefee ? Number(data.gradefee).toFixed(2) : '',
                        customerId: props.customerId || 0,
                        // courseId: data.courseId,
                        contactId: data.contactId,
                        createdByEmpId: data.createdByEmpId,
                        contactId: props.contactId,
                        courseId: props.courseId,
                        orgRegistrationId: props.orgRegistrationId || 0,
                        schoolStudentClassRefList: data.schoolStudentClassRefList || [],
                        subcourseName: data.subcourseName,
                        subcourseId: data.subcourseId,
                        studentStatus: data.studentId ? data.studentStatus : 'Pending',
                        studentStatusColor: data.studentId ? data.studentStatusColor : '#277bcf'
                    });
                    boContactChildList.push({
                        sNo: index,
                        contactId: data.contactId || 0,
                        studentId: data.studentId || 0,
                        schoolStudentId: data.schoolStudentId || 0,
                        relationshipId: props.relationshipId,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        gender: data.gender,
                        allergies: data.allergies,
                        dob: data.dob ? formatDateForBackend(data.dob) : '',
                        //courseId: props.courseId,
                        contactId: props.contactId,
                        studentList: [{
                            sNo: index,
                            studentId: data.studentId || 0,
                            schoolStudentId: data.schoolStudentId || 0,
                            relationshipId: props.relationshipId,
                            orgRegistrationId: props.orgRegistrationId || 0,
                            customerId: props.customerId || 0,
                            contactId: data.contactId,
                            createdByEmpId: data.createdByEmpId,
                            contactId: props.contactId,
                            courseId: props.courseId,
                            grade: data.grade,
                            courseFee: data.gradefee ? Number(data.gradefee).toFixed(2) : '',
                            schoolStudentClassRefList: data.schoolStudentClassRefList || [],
                            subcourseName: data.subcourseName,
                            subcourseId: data.subcourseId,
                            studentStatus: data.studentId ? data.studentStatus : 'Pending',
                            studentStatusColor: data.studentId ? data.studentStatusColor : '#277bcf'
                        }]
                    });
                    if (data.contactId) {
                        registerStudentChildIds.push(data.contactId);
                    }
                }
            
        });
        let contact = props.customerInfo.boContactList[0] || {};
        if (contact.boContactChildList && contact.boContactChildList.length) {
            (contact.boContactChildList || []).forEach((childContact) => {
                if (childContact.contactId && registerStudentChildIds.indexOf(childContact.contactId) < 0) {
                    childContact.studentList = [];
                    boContactChildList.push(childContact);
                }
            })
          }
        if (isInvalidGrade && formSetting.isStopRegistrationAfterMaxStudent) {
            return showToasterMessage({
                messageType: 'error', description: formSetting.invalidGradeMessage? formSetting.invalidGradeMessage : props.intl.formatMessage({ id: 'no.more.seat.available.inGrade.message', values: { value: '' } })
            });
        }

        // if ( props.formSetting.collectStudentInfo && props.registrationType === 'student' && !studentList.length) {
        //     return showToasterMessage({
        //         messageType: 'error', description: props.intl.formatMessage({ id: 'no.student.message' })
        //     });
        // }

        // if (isRequiredFiledMissing) {
        //     return showToasterMessage({
        //         messageType: 'error', description: props.intl.formatMessage({ id: 'student.missing.details.message' })
        //     });
        // }

        if (isFNameMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.firstName.missing' })
            });
        }
        if (isLNameMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.lastName.missing' })
            });
        } 
        if (isGradeMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.grade.missing' })
            });
        }
        if (isDOBMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.dob.missing' })
            });
        }
        if (isGenderMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.gender.missing' })
            });
        }
        if (isAllergiesMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.allergies.missing' })
            });
        }

        (formSetting.questionList || []).forEach((ques) => {
            if (ques.isRequired && (!ques.answers || !ques.answers.length)) {
                isRequiredQuestionMissing = true;
            }
        })

        if (isRequiredQuestionMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'required.question.missing' })
            });
        }

        if (props.formSetting.addressRequired && (!props.streetAddress1 || !props.cityName || !props.stateName || !props.zipCode)) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'address.missing.message' })
            });
        }
        if ( props.registrationType === 'student' && !props.primaryContact) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'primaryContactForInvoicing.validation.message' })
            });
        }
        if (props.isAllowParentPaymentPlan && (!props.selectedPlan ||  !(Object.keys(props.selectedPlan)).length )){
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'registration.parentPaymentPlan.required' })
            });
        }
        if ((props.emergencyPhone && props.emergencyPhone.length > 0 && props.emergencyPhone.length < 10) || (props.familyDoctorPhone && props.familyDoctorPhone.length > 0 && props.familyDoctorPhone.length < 10)) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'phoneNumber.incorrect.message' })
            });;
        } 
        if (formSetting.askOrgMemberRequired && !props.orgMember) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'registration.orgMember.required' })
            });
        }
        if (formSetting.askFinanacialAssistanceRequired && !props.isFinancialAssistanceNeeded) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'registration.financialAssistance.required' })
            });
        }
        if (formSetting.askAddTeacherRequired && !props.isTeacher) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'registration.addTeacher.required' })
            });
        }
        if (formSetting.askAddVolunteerRequired && !props.isVolunteer) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'registration.addVolunteer.required' })
            });
        }
        if (formSetting.membershipOptionRequired && !props.membershipOption) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'registration.membershipOption.required' })
            });
        }
        let feeAmt = Number.isNaN(getTotalGradeFees()) ? 0 : getTotalGradeFees();
        const modalData = {
            modalBody: feeAmt ? <FormattedMessage id='continue.confirmation' /> : <FormattedMessage id='save.confirmation' />, 
            handleSubmit: () => {
                let contactList = [];
                let boLocationList = [];
                // if (props.fatherFirstName || props.motherFirstName) {
                //     contactList.push({
                //         firstName: props.fatherFirstName,
                //         lastName: props.fatherLastName,
                //         cellPhone: appendCountryCode(props.fatherMobile),
                //         emailAddress: props.fatherEmail,
                //         relationshipId: props.relationshipId,
                //         dateCreated: getCurrentDateForBackend(new Date()),
                //         gender: 'Male',
                //         contactType: "Customer",
                //         motherFirstName: props.motherFirstName,
                //         motherLastName: props.motherLastName,
                //         motherMobile: appendCountryCode(props.motherMobile),
                //         motherEmail: props.motherEmail,
                //         dateOfBirth: props.fatherDOB? formatDateForBackend(props.fatherDOB) : '',
                //         motherDateOfBirth: props.motherDOB? formatDateForBackend(props.motherDOB) : '',
                //         isPrimaryContact: 1,
                //         primaryContactForInvoicing: props.primaryContact,
                //         boContactChildList : boContactChildList, 
                //         contactId: props.contactId, 
                //         version: props.contactVersion,
                //         customerId: props.customerId, 
                //         countryCallingCode: props.companyInfo.countryCallingCode,
                //     });
                // }
                // if (props.motherFirstName) {
                //     contactList.push({
                //         firstName: props.motherFirstName,
                //         lastName: props.motherLastName,
                //         cellPhone: appendCountryCode(props.motherMobile),
                //         emailAddress: props.motherEmail,
                //         relationshipId: props.relationshipId,
                //         dateCreated: getCurrentDateForBackend(new Date()),
                //         gender: 'Female',
                //         contactType: "Customer",
                //         isPrimaryContact: props.primaryContact === 'mother' ? 1 : 0
                //     });
                // }

                if (props.firstName) {
                    contactList.push({
                        firstName: props.firstName,
                        lastName: props.lastName,
                        cellPhone: appendCountryCode(props.phoneNumber),
                        emailAddress: props.email,
                        relationshipId: props.relationshipId,
                        dateCreated: getCurrentDateForBackend(new Date()),
                        gender: props.gender,
                        contactType: "Customer",
                        countryCallingCode: props.companyInfo.countryCallingCode,
                    });
                }

                if (props.spouseFirstName) {
                    contactList.push({
                        firstName: props.spouseFirstName,
                        lastName: props.spouseLastName,
                        cellPhone: appendCountryCode(props.spouseMobile),
                        emailAddress: props.spouseEmail,
                        relationshipId: props.relationshipId,
                        dateCreated: getCurrentDateForBackend(new Date()),
                        gender: 'Female',
                        contactType: "Customer",
                        countryCallingCode: props.companyInfo.countryCallingCode,
                    });
                }

                if (props.streetAddress1 || props.cityName || props.stateName || props.zipCode) {
                    boLocationList.push({
                        streetAddress1: props.streetAddress1,
                        streetAddress2: props.streetAddress2,
                        cityName: props.cityName,
                        countryId: props.countryId,
                        countryName: props.countryName,
                        stateName: props.stateName,
                        zipCode: props.zipCode,
                        locationType: CONSTANTS.BILLING_ADDRESS,
                        relationshipId: props.relationshipId,
                        isDefault: 1,
                        locationId: props.locationId,
                        version: props.locationVersion,
                        customerId: props.customerId 
                    })
                }

                let registrationData = {
                    customerId: props.customerId || 0, 
                    contactId: props.contactId, 
                    orgRegistrationId: props.orgRegistrationId || 0,
                   // registrationFormSettingId: props.registrationFormSettingId,
                    formId: props.registrationFormSettingId,
                    courseId: props.courseId,
                    relationshipId: props.relationshipId,
                    fatherFirstName: props.fatherFirstName,
                    fatherLastName: props.fatherLastName,
                    fatherEmail: props.fatherEmail,
                    fatherMobile: appendCountryCode(props.fatherMobile),

                    motherFirstName: props.motherFirstName,
                    motherLastName: props.motherLastName,
                    motherEmail: props.motherEmail,
                    motherMobile: appendCountryCode(props.motherMobile),

                    emergencyContactName: props.emergencyContactName,
                    emergencyPhone: appendCountryCode(props.emergencyPhone),
                    orgMembershipDetailsList: studentList,
                    amount: feeAmt,
                    questionList: JSON.stringify(formSetting.questionList),
                    //isOrgMember: 1,
                    registrationType: props.formSetting.registrationType,

                    spouseFirstName: props.spouseFirstName,
                    spouseLastName: props.spouseLastName,
                    spouseEmail: props.spouseEmail,
                    spouseMobile: appendCountryCode(props.spouseMobile),
                    spouseDOB: props.spouseDOB,

                    familyDoctorName: props.familyDoctorName,
                    familyDoctorPhNumber: appendCountryCode(props.familyDoctorPhone),

                    nationality: props.nationality,
                    membershipType: props.membershipType,

                    firstName: props.firstName,
                    lastName: props.lastName,
                    phoneNumber: appendCountryCode(props.phoneNumber),
                    gender: props.gender,
                    dob: props.dob,

                    maritalStatus: props.maritalStatus,
                    selectedPlan: props.isAllowParentPaymentPlan ? JSON.stringify(getSelectedPlan(props.selectedPlan || {})) : '{}',
                    isInvoiced: props.isInvoiced || 0,
                    primaryContact: props.primaryContact,
                    
                    orgMember: props.orgMember,
                    isFinancialAssistanceNeeded: props.isFinancialAssistanceNeeded === 'yes' ? 1: 0,
                    isTeacher: props.isTeacher === 'yes' ? 1: 0,
                    isVolunteer: props.isVolunteer === 'yes' ? 1: 0,
                }
                if (registrationData && registrationData.amount) {
                    registrationData.systemStatus = TX_STATUS.UNPAID;
                    registrationData.isEnrollmentPaid = 1;
                }
                let companyName = props.firstName + (props.lastName ? ' ' + props.lastName : '');
                if (props.primaryContact === 'mother') { companyName = props.motherFirstName + (props.motherLastName ? ' ' + props.motherLastName : '') };
                if (props.primaryContact === 'father') { companyName = props.fatherFirstName + (props.fatherLastName ? ' ' + props.fatherLastName : '') };

                let customerPayload = {
                    clientOnlineUserAccountId: props.customerInfo.clientOnlineUserAccountId,
                    companyName: companyName,
                    boContactList: contactList,
                    boLocationList: boLocationList,
                    customerId: props.customerId || 0,
                    relationshipId: props.relationshipId,
                    customerType: 'individual',
                    orgMembershipList: [registrationData],
                    paymentTermId: formSetting.paymentTermId,
                    //isOrgMember: 1,
                    membershipOptionId: props.membershipOption,
                    registrationType: props.formSetting.registrationType,
                    primaryContact: props.primaryContact,

                    fatherFirstName: props.fatherFirstName,
                    fatherLastName: props.fatherLastName,
                    fatherMobile: appendCountryCode(props.fatherMobile),
                    fatherEmail: props.fatherEmail,
                    fatherDateOfBirth: props.fatherDOB? formatDateForBackend(props.fatherDOB) : '',

                    motherFirstName: props.motherFirstName,
                    motherLastName: props.motherLastName,
                    motherMobile: appendCountryCode(props.motherMobile),
                    motherEmail: props.motherEmail,
                    motherDateOfBirth: props.motherDOB? formatDateForBackend(props.motherDOB) : '',
                    
                    boContactChildList : boContactChildList, 
                    countryCallingCode: props.companyInfo.countryCallingCode,
                }
                
                props.saveRegistrationFound(customerPayload, props)
            }
        };
        props.pushModalToStack(modalData);
    }
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <span><FormattedMessage id='signup.firstname.placeholder' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
    //         },
    //         field: "firstName",
    //         colId: 'firstName_1',
    //         width: 130,
    //         resizable: true,
    //        // editable: true,
    //         cellEditor: 'customTextEditor',
    //         suppressMovable: true,
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <span><FormattedMessage id='signup.lastname.placeholder' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
    //         },
    //         field: "lastName",
    //         resizable: true,
    //        // editable: true,
    //         width: 130,
    //         cellEditor: 'customTextEditor',
    //         suppressMovable: true,
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <span><FormattedMessage id='grade.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
    //     //     },
    //     //     field: "gId",
    //     //     resizable: true,
    //     //     editable: true,
    //     //     suppressMovable: true,
    //     //     width: 150,
    //     //     suppressKeyboardEvent: (params) => {
    //     //         const KEY_ENTER = 13;
    //     //         const keyCode = params.event.keyCode;
    //     //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //     //         return gridShouldDoNothing;
    //     //     },
    //     //     cellEditor: 'customDropDownEditor',
    //     //     cellEditorParams: (obj) => {
    //     //         return {
    //     //             lastColumnCell: false,
    //     //             items: gradeArr,
    //     //             optionKeyName: 'gId',
    //     //             valueKeyName: 'gradeValidation',
    //     //             isGradeLevel: true,
    //     //             isDisplayYearlyFee: formSetting.isDisplayYearlyFee ? true: false,
    //     //             totalMonths: getMonthsBetweenDates(formSetting.sessionStart, formSetting.sessionEnd) || 1,
    //     //             valueKeyGradeFee: 'gradefee',
    //     //             addressLineKey1: 'subcourseName',
    //     //             currencyCode: companyInfo.currencyCode || '',
    //     //             onSelect: (gId, optionObj) => {
    //     //                 const selectedObj = find(gradeArr, { gId: optionObj.key  });
    //     //                 let selectedValue = selectedObj.gradeValidation;
                      
    //     //                 // if (obj.node.data.grade) {
    //     //                 //     let totalStudentCountByGrade = props.totalStudentCountByGrade || {};
    //     //                 //     totalStudentCountByGrade[obj.node.data.grade] = Number(totalStudentCountByGrade[obj.node.data.grade]) - 1;
    //     //                 //     props.updateState({
    //     //                 //         totalStudentCountByGrade
    //     //                 //     });
    //     //                 // }
    //     //                 obj.node.data.gradefee = null;
    //     //                 obj.node.setDataValue(obj.colDef.field, null);
                        
    //     //                 if (selectedObj) {
    //     //                     obj.node.data.gradefee = selectedObj.gradefee;
    //     //                     obj.node.data.subcourseName = selectedObj.subcourseName;
    //     //                     obj.node.data.subcourseId = selectedObj.subcourseId;
    //     //                     obj.node.data.gId = selectedObj.gId;
    //     //                     obj.node.data.grade = selectedValue;
    //     //                     obj.node.data.invalidGrade = formSetting.gradeLevelValidation ? true: false;
    //     //                     obj.node.setDataValue(obj.colDef.field, selectedValue);
    //     //                     obj.node.setDataValue("gradefee", selectedObj.gradefee);
                            
    //     //                     obj.api && obj.api.stopEditing();
    //     //                    // obj.node.setDataValue("subcourseName", selectedObj.subcourseName);
    //     //                     //obj.node.setDataValue("subcourseId", selectedObj.subcourseId);
    //     //                 }

    //     //                 if (formSetting.gradeLevelValidation) {
    //     //                     let isInvalid = false;
    //     //                     if(selectedValue && (Number((formSetting.gradeValidations || {})[selectedValue] || 0)) <= (Number((self.totalStudentCountByGrade || {})[selectedValue] || 0)) ){
    //     //                         isInvalid = true;
    //     //                     }
    //     //                     // if ((selectedValue === 1 || selectedValue === 2) && (Number((formSetting.gradeValidations || {})[1] || 0) + Number((formSetting.gradeValidations || {})[2] || 0)) <= (Number((props.totalStudentCountByGrade || {})[1] || 0) + Number((props.totalStudentCountByGrade || {})[2] || 0))) {
    //     //                     //     isInvalid = true;
    //     //                     // } else if ((selectedValue === 3 || selectedValue === 4 || selectedValue === 5) && (Number((formSetting.gradeValidations || {})[3] || 0) + Number((formSetting.gradeValidations || {})[4] || 0) + Number((formSetting.gradeValidations || {})[5] || 0)) <= (Number((props.totalStudentCountByGrade || {})[3] || 0) + Number((props.totalStudentCountByGrade || {})[4] || 0) + Number((props.totalStudentCountByGrade || {})[5] || 0))) {
    //     //                     //     isInvalid = true;
    //     //                     // } else if ((selectedValue === 6 || selectedValue === 7 || selectedValue === 8) && (Number((formSetting.gradeValidations || {})[6] || 0) + Number((formSetting.gradeValidations || {})[7] || 0) + Number((formSetting.gradeValidations || {})[8] || 0)) <= (Number((props.totalStudentCountByGrade || {})[6] || 0) + Number((props.totalStudentCountByGrade || {})[7] || 0) + Number((props.totalStudentCountByGrade || {})[8] || 0))) {
    //     //                     //     isInvalid = true;

    //     //                     // } else if ((selectedValue === 9 || selectedValue === 10 || selectedValue === 11 || selectedValue === 12) && (Number((formSetting.gradeValidations || {})[9] || 0) + Number((formSetting.gradeValidations || {})[10] || 0) + Number((formSetting.gradeValidations || {})[11] || 0) + Number((formSetting.gradeValidations || {})[12] || 0)) <= (Number((props.totalStudentCountByGrade || {})[9] || 0) + Number((props.totalStudentCountByGrade || {})[10] || 0) + Number((props.totalStudentCountByGrade || {})[11] || 0) + Number((props.totalStudentCountByGrade || {})[12] || 0))) {
    //     //                     //     isInvalid = true;

    //     //                     // }
    //     //                     if (isInvalid && formSetting.invalidGradeMessage) {
    //     //                         //showToasterMessage({   messageType: 'warning', description: props.intl.formatMessage({ id: 'no.more.seat.available.inGrade.message', values: { value: selectedValue } })});
    //     //                         showToasterMessage({   messageType: formSetting.isStopRegistrationAfterMaxStudent? 'error': 'warning', description: formSetting.invalidGradeMessage });

    //     //                         obj.api && obj.api.stopEditing();
    //     //                     } else {
    //     //                         obj.node.data.invalidGrade = false;
    //     //                         obj.node.data.gradefee = selectedObj.gradefee;
    //     //                         obj.node.data.subcourseName = selectedObj.subcourseName;
    //     //                         obj.node.data.subcourseId = selectedObj.subcourseId;
    //     //                         obj.node.data.gId = selectedObj.gId;
    //     //                         obj.node.setDataValue(obj.colDef.field, selectedValue);
    //     //                         obj.node.setDataValue("gradefee", selectedObj.gradefee);
    //     //                       //  obj.node.setDataValue("subcourseName", selectedObj.subcourseName);
    //     //                        // obj.node.setDataValue("subcourseId", selectedObj.subcourseId);
    //     //                         obj.node.data.grade = selectedValue;
    //     //                         let totalStudentCountByGrade = self.totalStudentCountByGrade || {};
    //     //                         totalStudentCountByGrade[selectedValue] = Number(totalStudentCountByGrade[selectedValue]||0) + 1;
    //     //                         props.updateState({
    //     //                             totalStudentCountByGrade
    //     //                         });
    //     //                         obj.api && obj.api.stopEditing();

    //     //                     }

    //     //                 }
    //     //             },
                   
    //     //             //allowClear: true,
    //     //             // onClear: () => {
    //     //             //     //obj.node.setDataValue(obj.colDef.field, undefined);
    //     //             // },
    //     //         }
    //     //     },
    //     //     cellRendererFramework: (params) => {
    //     //         return <div>{params.data.grade ? <div>
    //     //             <div>Grade {params.data.grade}</div>
    //     //             <div>{params.data.subcourseName}</div>
    //     //         </div> : ''}</div>
    //     //     },
    //     //     // valueGetter: (obj) =>{
    //     //     //     if (formSetting.gradeLevelValidation && Number((formSetting.gradeValidations || {})[obj.data.grade] || 0) <= (props.totalStudentCountByGrade || {})[obj.data.grade]) {
    //     //     //         return ""
    //     //     //     }
    //     //     //     let totalfee = 0;
    //     //     //     obj.api.forEachNode((rowNode, index) => {
    //     //     //         const { data } = rowNode;
    //     //     //         if (data.grade) {
    //     //     //             totalfee += Number((formSetting.gradeFees || {})[data.grade] || 0);
    //     //     //         }
    //     //     //       });
    //     //     //       props.updateState({ totalfee })
    //     //     //     return obj.data.grade;
    //     //     // }
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return formSetting.isDisplayYearlyFee ? <span><FormattedMessage id='gradefeeYearly.header.text' /></span> : <span><FormattedMessage id='gradefee.header.text' /></span>;
    //         },
    //         width: 80,
    //        // hide: !(Object.keys(formSetting.gradeFees || {})).length,
    //         field: "gradefee",
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             return <span>{formSetting.isDisplayYearlyFee ? (params.data.gradefee ? (Number(params.data.gradefee)*getMonthsBetweenDates(formSetting.sessionStart, formSetting.sessionEnd)).toFixed(2) : 0.00) : Number(params.data.gradefee||0).toFixed(2)}</span>
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <span><FormattedMessage id='dob.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
    //         },
    //         field: "dob",
    //         resizable: true,
    //        // editable: true,
    //         width: 130,
    //         cellEditor: 'customDatePicker',
    //         cellEditorParams: (obj) => {
    //             return { 
    //                 disabledDate: (date) => (date && date.valueOf() > Date.now()), 
    //                 onChange: (selectedDate) => {
    //                     obj.node.setDataValue(obj.colDef.field, selectedDate);
    //                   }
    //             }
    //         },
    //         cellRendererFramework: (params) => {
    //             return <span>{params.data.dob ? getMomentDateForUIReadOnly({ date: params.data.dob, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</span>
    //         },
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <span><FormattedMessage id='allergies.header.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
    //         },
    //         field: "allergies",
    //         resizable: true,
    //        // editable: true,
    //         cellEditor: 'customTextEditor',
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //         // cellEditorParams: (params) => {
    //         //     return {
    //         //         value: params.node.data.allergies || "",
    //         //         isEditorLoaded: props.isEditorLoaded,
    //         //         onChange: (value) => {
    //         //             params.node.data[params.colDef.field] = value;
    //         //             params.node.data.isEditorLoaded = true;
    //         //             props.updateState({
    //         //                 isEditorLoaded: true
    //         //             })
    //         //         }
    //         //     }
    //         // },
    //         cellRendererFramework: (params) => {
    //             return ReactHtmlParser(params.data.allergies)
    //         },
    //         // suppressKeyboardEvent: (params) => {
    //         //     const KEY_ENTER = 13;
    //         //     const keyCode = params.event.keyCode;
    //         //     const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
    //         //     params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
    //         //     if (params.event.target.scrollHeight > 30) {
    //         //         if (params.event.path[2]) {
    //         //             params.event.path[2].style.height = 'inherit';
    //         //             params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
    //         //         }
    //         //         params.node.setRowHeight(params.event.target.scrollHeight);
    //         //         params.api && params.api.onRowHeightChanged();
    //         //     } else {
    //         //         if (params.event.path[2]) {
    //         //             params.event.path[2].style.height = 'inherit';
    //         //             params.event.path[2].style.height = `${30}px`;
    //         //         }
    //         //         params.node.setRowHeight(30);
    //         //         params.api && params.api.onRowHeightChanged();
    //         //     }
    //         //     return gridShouldDoNothing;
    //         // }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <span><FormattedMessage id='gender.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span>;
    //         },
    //         field: "gender",
    //         resizable: true,
    //        // editable: true,
    //         width: 70,
    //         suppressMovable: true,
    //         cellEditor: 'customDropDownEditor',
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //         cellEditorParams: (obj) => {
    //             return {
    //                 lastColumnCell: false,
    //                 //items: ['Male', 'Female', 'Rather Not Say'],
    //                 items: ['Male', 'Female'],
    //                 onSelect: (selectedValue) => {
    //                     obj.node.setDataValue(obj.colDef.field, selectedValue);
    //                 },
    //                 allowClear: true,
    //                 onClear: () => {
    //                     obj.node.setDataValue(obj.colDef.field, undefined);
    //                 },
    //             }
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.header.action' />;
    //         },
    //         width: 120,
    //         field: "action",
    //         resizable: true,
    //         cellRenderer: 'actionEditAndDelete',
    //         suppressNavigable: true,
    //         cellRendererParams: (params) => {
    //             return {
    //                 onClick: (actionType) => {
    //                     if(actionType === GRID_ACTIONS.DELETE){
    //                     let totalStudentCountByGrade = self.totalStudentCountByGrade || {};
    //                     if (!params.data.invalidGrade) {
    //                         totalStudentCountByGrade[params.data.grade] = Number(totalStudentCountByGrade[params.data.grade]) - 1;
    //                         props.updateState({
    //                             totalStudentCountByGrade
    //                         });
    //                     }

    //                     params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
    //                     // if (params.api.rowRenderer.lastRenderedRow < 0) {
    //                     //     params.api.updateRowData({ add: [{}] });
    //                     // }
    //                 }else if(actionType === GRID_ACTIONS.EDIT){
    //                     editProfile();
    //                 }
    //             },
    //             }
    //         }

    //     }
    // ]
   
   
    const editProfile = () => {
        props.showModal({
            maskClosable: true,
            isClosable: false,
            keyboard: false,
            modalBody: <Fragment>
              <div className="warning-message">
              Personal information can be edited from the profile page. Do you want to continue?
              </div>
              <br />
              <div className="button-action" style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: '1px solid #e5e5e5',
                paddingTop: '15px'
              }}>
                 <Button className="ant-btn-default"
                  onClick={() => {
                    props.hideModal();
                  }}>  <FormattedMessage id='no' defaultMessage='' />
                </Button>
                <Button className="ant-btn-primary" onClick={() => {
                    props.history.push('/admin/edit-profile');
                    props.hideModal();
                  }}>  <FormattedMessage id='ok' defaultMessage='' /> </Button>
              </div>
            </Fragment>,
            hideFooter: true
          })
    }
    const addStudentHandle  = () => {
        let selectedStudentIds = [];
        // gridApi && gridApi.forEachNode && gridApi.forEachNode((rowNode) => {
        //     let { data } = rowNode;
        //     if (data.contactId)
        //     selectedStudentIds.push(data.contactId);
        // });
        (props.studentList || []).forEach((data) => {
            if (data.contactId)
                selectedStudentIds.push(data.contactId);
        })
        const modalData = {
            title: <React.Fragment>
                <div className='ant-modal-title' style={{ textAlign: 'center' }}>
                    Students
                </div>
            </React.Fragment>,
            modalBody: <AllStudent {...props} selectedStudentIds={selectedStudentIds}  addStudent={(data) => { 
                 let newRowIndex = ((props.studentList || []).length || 0)-1;
                // gridApi && gridApi.forEachNode((rowNode, index) => {
                //     newRowIndex = index;
                // });
                if (formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount + newRowIndex + 1)) {
                    return showToasterMessage({
                        messageType: 'error', description: props.intl.formatMessage({ id: 'no.more.seat.available.message' })
                    });
                }

                // gridApi && gridApi.stopEditing();
                // gridApi && gridApi.applyTransaction({ add: [data] });
                // gridApi && gridApi.startEditingCell({
                //     rowIndex: newRowIndex + 1,
                //     colKey: "firstName_1"
                // });
                // gridApi && gridApi.refreshCells({ force: true }); 
                let studentList = props.studentList || [];
                studentList.push(data);
                props.updateState(studentList);
            }}/>,
            width: 1000,
            hideFooter: true,
        };
        props.pushModalToStack(modalData);
    }
    const handleNewContactAddition = (props, payload, isStudentGrid, studentIndex) => {
        const formData = payload.formData;
        const {contactData = {}} = formData; 
        const { pushModalToStack, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
          formData.callback = (contact, boContactList) => {
              if (contact.customerId) {
                  props.updateState({ primayContactObj: contact });
              }
              if (contact.customerId && isStudentGrid && boContactList && boContactList.length) {
                  const obj = find(boContactList || [], { contactId: contactData.contactId }) || {};
                  let studentList = props.studentList || [];
                  obj.dob = obj.dateOfBirth;
                  studentList[studentIndex] = obj;
                  props.updateState(studentList);
    
              }
          }
        const data = {
          title: <div>
            <FormattedMessage id='addItem.text.contact' defaultMessage='' />
            <div style={{ 'fontSize': '12px' }}> {formData.customerName || ''}</div>
          </div>,
          formData,
          hideFooter: true,
          modalData: {
            modalType: MODAL_TYPE.CONTACT,
            data: payload,
    
          },
          handleSubmit: (formData = {}) => {
            data.formData.submittedOnce = true;
            props.popModalFromStack(data);
          }
        };
        pushModalToStack(data);
      }
    const handleAddressAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = (address) => {
          if (address.customerId) {
            props.updateState({address: address});
          }
        }
        const data = {
          title: <div>
            <FormattedMessage id='signup.tab.address' defaultMessage='' />
            <div style={{ 'fontSize': '12px' }}> {formData.customerName || ''}</div>
          </div>,
          formData,
          hideFooter: true,
          modalData: {
            modalType: MODAL_TYPE.BILLING_ADDRESS,
            data: payload,
    
          },
          handleSubmit: (formData = {}) => {
            data.formData.submittedOnce = true;
            props.hideModal(data);
          }
        };
        showModal(data);
      }
    return (
        <div style={{ padding: '0px 0px 200px' }} >
            {/* <Row gutter={24} style={{ background: formSetting.bgColor || '#31c0be', color: formSetting.textColor || '#ffffff', padding: '5px' }} className='ltiuy'>
                <Col span={2} className='litlyu'> </Col>
                <Col span={2}>
                    <div className="brand-logo logity">
                        {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                    </div>
                </Col>
                <Col span={12} style={{ alignSelf: 'center' }}>
                    <div className='logosizef'> {companyInfo.storeName}</div>
                </Col>
                <Col span={8} style={{ alignSelf: 'center' }} className='newaddr'>
                    {getAddressInfoFragment(props.relationshipBillingAddress, companyInfo)}
                </Col>
            </Row> */}
            <div className='main-container main-box'>
                <div className="modal-header align-left titr"><div style={{  textAlign: 'center', fontSize:'20px' }}>{props.programName || 'Registration'}</div>
                    {formSetting.subHeading ? <div style={{  textAlign: 'center' }}>{formSetting.subHeading}</div> : ''}
                    {/* {(formSetting.sessionStart && formSetting.sessionEnd) ? <div style={{ fontSize: '12px', textAlign: 'center' }}>{getMomentLoclDateForUIReadOnly({date: formSetting.sessionStart, format: CONSTANTS.DISPLAY_DATE_FORMAT_SESSION})} - {getMomentLoclDateForUIReadOnly({date: formSetting.sessionEnd, format: CONSTANTS.DISPLAY_DATE_FORMAT_SESSION})}</div> : ''} */}
                </div>
                {props.status === 'success' && <div style={{ fontSize: '16px', textAlign: 'center' }}><b>{ReactHtmlParser(props.successMessage) || props.intl.formatMessage({ id: 'registration.completion.success' })} </b></div>}
                {props.status === 'error' && <div style={{ fontSize: '16px', textAlign: 'center', color: 'red' }}><b>{props.intl.formatMessage({ id: 'registration.completion.failure' })} </b></div>}
                {props.status === 'inactive' && <div style={{ fontSize: '16px', textAlign: 'center' }}><b>{props.formSetting.inactiveMessage || 'Registration Closed' } </b></div>}
                {props.isFormSaved ? <div style={{ fontSize: '16px', textAlign: 'center' }}><b>{ReactHtmlParser(props.successMessage) || props.intl.formatMessage({ id: 'registration.completion.success' })} </b></div> : ((formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount)) ?
                    <div style={{ fontSize: '16px', textAlign: 'center' }}><b>{props.intl.formatMessage({ id: 'no.more.seat.available.message' })} </b>
                    
                    </div>
                    :
                    !props.status && <Form layout="vertical" onSubmit={save} >
                        <Row gutter={16}>
                            {formSetting.formDescription ?
                                <Col span={24}>
                                    {formSetting.formDescription ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>{ReactHtmlParser(formSetting.formDescription)}</div> : ''}
                                </Col> : ''}
                        </Row>
                        <Row gutter={22}>
                           
                                
                                    {formSetting.membershipTypeDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed ' label={<span><FormattedMessage id='membershipType.text' /> {formSetting.membershipTypeRequired ? <span className="required">*</span> : ''} </span>}>
                                        <Dropdown
                                                items={['New' , 'Renew']}
                                                required={formSetting.membershipTypeRequired}
                                                placeholder={props.intl.formatMessage({ id: 'membershipType.text' })}
                                                onSelect={(selectedValue) => {
                                                    props.updateState({ membershipType: selectedValue });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                </Row>
                        {/* <Row gutter={16} className='mb30'>
                            <Col span={12} md={12} xs={24} className="boxbor">
                                <Card
                                    title="Father Info"
                                    extra={<i className="fa fa-pencil" style={{cursor:'pointer'}} onClick={()=>{ editProfile(); }}></i>}
                                    style={{
                                        width: 450,
                                        background: '#f8f8f8'
                                    }}
                                >
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">First Name</Col><Col span={12}>{props.fatherFirstName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Last Name:</Col><Col span={12}>{props.fatherLastName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Email:</Col><Col span={12}>{props.fatherEmail || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6}className="textright15">Mobile:</Col><Col span={12}>{props.fatherMobile || '-'}</Col></Row>
                                </Card>
                            </Col>
                            <Col span={12} md={12} xs={24} className="boxbor">
                                <Card 
                                    title="Mother Info"
                                    extra={<i className="fa fa-pencil" style={{cursor:'pointer'}} onClick={()=>{ editProfile(); }}></i>}
                                    style={{
                                        width: 450,
                                        background: '#f8f8f8'
                                    }}
                                >
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">First Name:</Col><Col span={12}>{props.motherFirstName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Last Name:</Col><Col span={12}>{props.motherLastName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Email:</Col><Col span={12}>{props.motherEmail || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Mobile:</Col><Col span={12}>{props.motherMobile || '-'}</Col></Row>
                                </Card>
                            </Col>
                        </Row> */}
                        {/* <Row gutter={16}>
                            {formSetting.fatherFirstNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='register.fatherFirstName' />{formSetting.fatherFirstNameRequired ? <span className="required">*</span> : ''}</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}>
                                        <TextBox className='new-fed1'
                                            placeholder={intl.formatMessage(
                                                { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                            )}
                                            type='text'
                                            maxLength={25}
                                            value={props.fatherFirstName}
                                            onChange={(e) => {
                                                props.updateState({ fatherFirstName: e.target.value });
                                            }}
                                            required={formSetting.fatherFirstNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.fatherLastNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='register.fatherLastName' /> {formSetting.fatherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder={intl.formatMessage(
                                                { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                            )}
                                            type='text'
                                            required={formSetting.fatherLastNameRequired}
                                            maxLength={25}
                                            value={props.fatherLastName}
                                            onChange={(e) => {
                                                props.updateState({ fatherLastName: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>
                        {formSetting.fatherEmailDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='father.email' /> {formSetting.fatherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter email'
                                            type='email'
                                            maxLength={45}
                                            value={props.fatherEmail}
                                            onChange={(e) => {
                                                props.updateState({ fatherEmail: e.target.value });
                                            }}
                                            onBlur={() => {
                                                if(!props.customerId){
                                                    props.fetchStudentFatherEmail({ email: props.fatherEmail, type: 'fatherEmail', registrationFormSettingId: props.registrationFormSettingId }, props)
                                                }
                                            }}
                                            required={formSetting.fatherEmailRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.fatherMobileDisplay ?
                                <Col span={12} className="newregis">
                                    <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='father.mobile' /> {formSetting.fatherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                    <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter mobile number"
                                            style={{ paddingLeft: '45px' }}
                                            type='text'
                                            //value={contact.lastName}
                                            required={formSetting.fatherMobileRequired}
                                            maxLength={10}
                                            value={props.fatherMobile}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        fatherMobile: e.target.value,
                                                    });
                                                }else if(!props.fatherMobile){
                                                    props.updateState({ fatherMobile: '' });
                                                }
                                                //props.updateState({ fatherMobile: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            
                        </Row>

                        <Row gutter={16}>
                            {formSetting.motherFirstNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.fName' /> {formSetting.motherFirstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder={intl.formatMessage(
                                                { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                            )}
                                            type='text'
                                            maxLength={25}
                                            value={props.motherFirstName}
                                            onChange={(e) => {
                                                props.updateState({ motherFirstName: e.target.value });
                                            }}
                                            required={formSetting.motherFirstNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.motherLastNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.lName' /> {formSetting.motherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder={intl.formatMessage(
                                                { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                            )}
                                            type='text'
                                            maxLength={25}
                                            value={props.motherLastName}
                                            onChange={(e) => {
                                                props.updateState({ motherLastName: e.target.value });
                                            }}
                                            required={formSetting.motherLastNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>


                        <Row gutter={16}>
                        {formSetting.motherEmailDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.email' /> {formSetting.motherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter email'
                                            type='email'
                                            maxLength={45}
                                            value={props.motherEmail}
                                            onChange={(e) => {
                                                props.updateState({ motherEmail: e.target.value });
                                            }}
                                            onBlur={() => {
                                                if(!props.customerId){
                                                    props.fetchStudentFatherEmail({ email: props.motherEmail, type: 'motherEmail', registrationFormSettingId: props.registrationFormSettingId }, props)
                                                }
                                                
                                            }}
                                            required={formSetting.motherEmailRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.motherMobileDisplay ?
                                <Col span={12} className="newregis">
                                    <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='mother.mobile' /> {formSetting.motherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                    <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter mobile number"
                                            style={{ paddingLeft: '45px' }}
                                            type='text'
                                            maxLength={10}
                                            value={props.motherMobile}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        motherMobile: e.target.value,
                                                    });
                                                }else if(!props.motherMobile){
                                                    props.updateState({ motherMobile: '' });
                                                }
                                               // props.updateState({ motherMobile: e.target.value });
                                            }}
                                            required={formSetting.motherMobileRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            
                        </Row> */}
                        {/* <Row gutter={16}>
                            {formSetting.primaryContactForInvoicing ?
                                <Col span={12} >
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='primaryContactForInvoicing.text' /><span className="required">*</span> </span>}>
                                        <Dropdown
                                            items={[{ name: 'Father', id: 'father' }, { name: 'Mother', id: 'mother' }]}
                                            placeholder={props.intl.formatMessage({ id: 'primaryContactForInvoicing.text' })}
                                            valueKeyName='name'
                                            optionKeyName='id'
                                            value={props.primaryContact}
                                            onSelect={
                                                (optionValue) => {
                                                    props.updateState({ primaryContact: optionValue });
                                                }
                                            }
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row> */}

                          {/* <Row gutter={16}>
                            {formSetting.firstNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='contact.firstName' /> {formSetting.firstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="First Name"
                                            type='text'
                                            maxLength={45}
                                            value={props.firstName}
                                            onChange={(e) => {
                                                props.updateState({ firstName: e.target.value });
                                            }}
                                            required={formSetting.firstNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.lastNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='contact.lastName' /> {formSetting.lastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Last Name'
                                            type='text'
                                            maxLength={15}
                                            value={props.lastName}
                                            onChange={(e) => {
                                                props.updateState({ lastName: e.target.value });
                                            }}
                                            required={formSetting.lastNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>
                            {formSetting.phoneDisplay ?
                                <Col span={12} className="newregis">
                                    <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='company.employee.phone' /> {formSetting.phoneRequired ? <span className="required">*</span> : ''}</span>}>
                                        <span className="currency-tag" >
                                            {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                        </span>
                                        <TextBox className='new-fed1'
                                            style={{ paddingLeft: '45px' }}
                                            placeholder="Phone Number"
                                            type='text'
                                            maxLength={10}
                                            value={props.phoneNumber}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        phoneNumber: e.target.value,
                                                    });
                                                }
                                                else if (!props.phoneNumber) {
                                                    props.updateState({ phoneNumber: '' });
                                                }
                                                //props.updateState({ phoneNumber: e.target.value });
                                            }}
                                            required={formSetting.phoneRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.genderDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='gender.text' /> {formSetting.genderRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Dropdown
                                            items={['Male', 'Female', 'Rather Not Say']}
                                            placeholder={props.intl.formatMessage({ id: 'gender.text' })}
                                            onSelect={(selectedValue) => {
                                                props.updateState({ gender: selectedValue });
                                            }}
                                            required={formSetting.genderRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.emailDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='email' /> {formSetting.emailRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter email'
                                            type='email'
                                            maxLength={45}
                                            value={props.email}
                                            onChange={(e) => {
                                                props.updateState({ email: e.target.value });
                                            }}
                                            required={formSetting.emailRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                                {formSetting.maritalStatusDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='maritalStatus.text' /> {formSetting.maritalStatusRequired ? <span className="required">*</span> : ''}</span>}>
                                            <Dropdown
                                                items={['Single', 'Married', 'Divorced', 'Widowed']}
                                                placeholder={props.intl.formatMessage({ id: 'maritalStatus.text' })}
                                                onSelect={(selectedValue) => {
                                                    props.updateState({ maritalStatus: selectedValue });
                                                }}
                                                required={formSetting.maritalStatusRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                        </Row>
                        <Row gutter={16} >
                           
                           
                               {formSetting.dobDisplay ?
                               <Col span={12}>
                                   <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='dob.text' /> {formSetting.dobRequired ? <span className="required">*</span> : ''}</span>}>
                                       <DatePicker
                                           allowClear={false}
                                           format={format}
                                           key='dob'
                                           onChange={(selectedDate) => {
                                               props.updateState({
                                                   dob: selectedDate
                                               })
                                           }}
                                           size={0}
                                       />
                                   </Form.Item>
                               </Col> : ''}
                               {formSetting.nationalityDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='nationality.text' /> {formSetting.nationalityRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'nationality.text', defaultMessage: '' }
                                                )}
                                                type='text'
                                                required={formSetting.nationalityRequired}
                                                maxLength={25}
                                                value={props.nationality}
                                                onChange={(e) => {
                                                    props.updateState({ nationality: e.target.value });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                       </Row>
                        
                <Row gutter={16}>
                {formSetting.spouseFirstNameDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.fName' /> {formSetting.spouseFirstNameRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder={intl.formatMessage(
                                    { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                                maxLength={25}
                                value={props.spouseFirstName}
                                onChange={(e) => {
                                  props.updateState({ spouseFirstName: e.target.value });
                                }}
                            required={formSetting.spouseFirstNameRequired}
                            />
                        </Form.Item>
                    </Col> : ''}
                    {formSetting.spouseLastNameDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.lName' /> {formSetting.spouseLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder={intl.formatMessage(
                                    { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                                maxLength={25}
                                value={props.spouseLastName}
                                onChange={(e) => {
                                  props.updateState({ spouseLastName: e.target.value });
                                }}
                            required={formSetting.spouseLastNameRequired}
                            />
                        </Form.Item>
                    </Col> : ''}
                    {formSetting.spouseMobileDisplay ?
                    <Col span={12} className="newregis">
                        <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='spouse.mobile' /> {formSetting.spouseMobileRequired ? <span className="required">*</span> : ''}</span>}>
                        <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                        <TextBox className='new-fed1'
                                placeholder="Enter mobile number"
                                style={{ paddingLeft: '45px' }}
                                type='text'
                                maxLength={10}
                                value={props.spouseMobile}
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        props.updateState({
                                            spouseMobile: e.target.value,
                                        });
                                    }else if(!props.spouseMobile){
                                        props.updateState({ spouseMobile: '' });
                                    }
                                 // props.updateState({ spouseMobile: e.target.value });
                                }}
                            required={formSetting.spouseMobileRequired}
                            />
                        </Form.Item>
                    </Col>: ''}
                    {formSetting.spouseEmailDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.email' /> {formSetting.spouseEmailRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder='Enter email'
                                type='text'
                                maxLength={45}
                                value={props.spouseEmail}
                                onChange={(e) => {
                                  props.updateState({ spouseEmail: e.target.value });
                                }}
                            required={formSetting.spouseEmailRequired}
                            />
                        </Form.Item>
                    </Col>: ''}
                                {formSetting.spouseDOBDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouseDOB.text' /> {formSetting.spouseDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                key='spouseDOB'
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        spouseDOB: selectedDate
                                                    })
                                                }}
                                                size={0}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                </Row>

                       

                       

                        <Row gutter={16}>
                           
                           {formSetting.fatherDOBDisplay ?
                               <Col span={12}>
                                   <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='fatherDOB.text' /> {formSetting.fatherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                   <DatePicker
                                           allowClear={false}
                                           format={format}
                                           key='fatherDOB'
                                           defaultValue={props.fatherDOB ? getMomentDateForUI({ date: new Date(props.fatherDOB), format }) : null}
                                           value={props.fatherDOB ? getMomentDateForUI({ date: new Date(props.fatherDOB), format }) : null}
                                           onChange={(selectedDate) => {
                                               props.updateState({
                                                fatherDOB: selectedDate
                                               })
                                           }}
                                           size={0}
                                       />
                                   </Form.Item>
                               </Col> : ''}
                               {formSetting.motherDOBDisplay ?
                               <Col span={12}>
                                   <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='motherDOB.text' /> {formSetting.motherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                       <DatePicker
                                           allowClear={false}
                                           format={format}
                                           key='motherDOB'
                                           defaultValue={props.motherDOB ? getMomentDateForUI({ date: new Date(props.motherDOB), format }) : null}
                                           value={props.motherDOB ? getMomentDateForUI({ date: new Date(props.motherDOB), format }) : null}
                                           onChange={(selectedDate) => {
                                               props.updateState({
                                                motherDOB: selectedDate
                                               })
                                           }}
                                           size={0}
                                       />
                                   </Form.Item>
                               </Col> : ''}
                       </Row> */}

<Row gutter={16} className='mb30'>
                            <Col span={12} md={12} xs={24} className="boxbor edit-hover">
                                <Card
                                    title={<span><FormattedMessage id='primaryContactForInvoicing.text' /><span className="required">*</span> </span>}
                                    extra={<i className="fa fa-pencil" style={{cursor:'pointer'}} onClick={()=>{ editProfile(); }}></i>}
                                    style={{
                                        width: 450,
                                        background: '#f8f8f8'
                                    }}
                                >
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">First Name</Col><Col span={12}>{(props.primayContactObj || {}).firstName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Last Name:</Col><Col span={12}>{(props.primayContactObj || {}).lastName || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6} className="textright15">Email:</Col><Col span={12}>{(props.primayContactObj || {}).emailAddress || '-'}</Col></Row>
                                    <Row style={{padding:'8px', borderBottom:'solid 1px #ccc'}}><Col span={6}className="textright15">Mobile:</Col><Col span={12}>{(props.primayContactObj || {}).cellPhone || '-'}</Col></Row>
                                </Card>
                            </Col>
                            <Col span={12} md={12} xs={24} className="boxbor">
                                { (props.address && props.address.locationId )?
                                    <Card
                                        title={<span><FormattedMessage id='signup.tab.address' /> </span>}
                                        extra={<i className="fa fa-pencil" style={{ cursor: 'pointer' }} onClick={() => {
                                            let formData = { isUpdate: true, ...props.address, locationType: CONSTANTS.BILLING_ADDRESS, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName };
                                            handleAddressAddition(props, { formData: formData })
                                        }}></i>}
                                        style={{
                                            width: 450,
                                            background: '#f8f8f8'
                                        }}
                                    >
                                        <Row style={{ padding: '8px' }}><Col span={18} className="pl10">{props.address.streetAddress1 || ''}</Col></Row>
                                       {props.address.streetAddress2 ? <Row style={{ padding: '8px' }}><Col span={18} className="pl10">{props.address.streetAddress2 || ''}</Col></Row> : ''}
                                        <Row style={{ padding: '8px' }}><Col span={18} className="pl10">{props.address.cityName ? `${props.address.cityName || ''}${props.address.stateName ? ', ' + props.address.stateName : ''}${props.address.zipCode ? ', ' + props.address.zipCode : ''}` : ' '}</Col></Row>
                                        <Row style={{ padding: '8px' }}><Col span={18} className="pl10">{props.address.countryName || ''}</Col></Row>
                                    </Card> :

                                    <Card
                                        title={<span><FormattedMessage id='signup.tab.address' /> </span>}
                                        extra={<i className="fa fa-pencil" style={{ cursor: 'pointer' }} onClick={() => {
                                            let formData = { isUpdate: true, ...props.address, locationType: CONSTANTS.BILLING_ADDRESS, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName };
                                            handleAddressAddition(props, { formData: formData });
                                        }}></i>}
                                        style={{
                                            width: 450,
                                            background: '#f8f8f8'
                                        }}
                                    >
                                        <Row>
                                            <Col span={6}><img src={LocationIcon} alt="Location" className="pt35" /> </Col>
                                            <Col span={18} className="pl10 pt35 text-center pt35"> Address does not exist. <span style={{ cursor: 'pointer', color: '#676ED3' }} onClick={() => {
                                                    let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName }
                                                    handleAddressAddition(props, { formData: formData });
                                                }}>Click Here </span> to add address.

                                            </Col>
                                        </Row>
                                    </Card>
                                }
                            </Col>
                            </Row>
                       <Row gutter={16}>
                                {formSetting.familyDoctorNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='familyDoctorName.text' /> {formSetting.familyDoctorNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Family Doctor Name"
                                                type='text'
                                                maxLength={45}
                                                value={props.familyDoctorName}
                                                onChange={(e) => {
                                                    props.updateState({ familyDoctorName: e.target.value });
                                                }}
                                                required={formSetting.familyDoctorNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.familyDoctorPhoneDisplay ?
                                    <Col span={12} className="newregis">
                                        <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='familyDoctorPhone.text' /> {formSetting.familyDoctorPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                        <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                            <TextBox className='new-fed1'
                                            style={{ paddingLeft: '45px' }}
                                                placeholder='Family Doctor Number'
                                                type='text'
                                                maxLength={10}
                                                value={props.familyDoctorPhone}
                                                onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        props.updateState({
                                                            familyDoctorPhone: e.target.value,
                                                        });
                                                    }
                                                    else if(!props.familyDoctorPhone){
                                                        props.updateState({ familyDoctorPhone: '' });
                                                    }
                                                }}
                                                required={formSetting.familyDoctorPhoneRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                            {formSetting.emergencyContactDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='setting.student.registration.form.emergencyContactName' /> {formSetting.emergencyContactRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter Name"
                                            type='text'
                                            maxLength={45}
                                            value={props.emergencyContactName}
                                            onChange={(e) => {
                                                props.updateState({ emergencyContactName: e.target.value });
                                            }}
                                            required={formSetting.emergencyContactRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.emergencyPhoneDisplay ?
                                <Col span={12} className="newregis">
                                    <Form.Item className='label-emergency new-fed input-currency-control' label={<span><FormattedMessage id='setting.student.registration.form.emergencyPhone' /> {formSetting.emergencyPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                    <span className="currency-tag" >
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                        <TextBox className='new-fed1'
                                        style={{ paddingLeft: '45px' }}
                                            placeholder='Enter Number'
                                            type='text'
                                            maxLength={10}
                                            value={props.emergencyPhone}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        emergencyPhone: e.target.value,
                                                    });
                                                }
                                                else if(!props.emergencyPhone){
                                                    props.updateState({ emergencyPhone: '' });
                                                }
                                            }}
                                            required={formSetting.emergencyPhoneRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        {/* <Row gutter={16}>
                            {formSetting.addressDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='signup.tab.address' /> {formSetting.addressRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetName.placeholder' })}
                                                    type='text'
                                                    maxLength={45}
                                                    value={props.streetAddress1}
                                                    onChange={(e) => {
                                                        props.updateState({ streetAddress1: e.target.value });
                                                    }}
                                                    required={true}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetContd.placeholder' })}
                                                    type='text'
                                                    maxLength={45}
                                                    value={props.streetAddress2}
                                                    onChange={(e) => {
                                                        props.updateState({ streetAddress2: e.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.city.placeholder' })}
                                                    type='text'
                                                    maxLength={45}
                                                    value={props.cityName}
                                                    onChange={(e) => {
                                                        props.updateState({ cityName: e.target.value });
                                                    }}
                                                    required={true}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <Dropdown
                                                    items={props.countries || []}
                                                    optionKeyName='countryId'
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.country.placeholder' })}
                                                    valueKeyName='countryName'
                                                    value={props.countryName}
                                                    onSelect={(selectedValue, option) => {
                                                        props.updateState({ countryId: selectedValue, countryName: option.name });
                                                        props.fetchStates(selectedValue)
                                                    }}
                                                    required={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                            <Col span={12}>
                                                {(props.countryName === 'India' || props.countryName === 'United States' || props.countryName === 'United States (USA)') ?
                                                    <Dropdown
                                                        items={props.states || []}
                                                        optionKeyName='stateId'
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                        valueKeyName='stateName'
                                                        value={props.stateName}
                                                        onSelect={(selectedValue, option) => {
                                                            props.updateState({ stateName: option.name });
                                                        }}
                                                        required={true}
                                                    /> : <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                        type='text'
                                                        maxLength={45}
                                                        value={props.stateName}
                                                        onChange={(e) => {
                                                            props.updateState({ stateName: e.target.value });
                                                        }}
                                                        required={true}
                                                    />}
                                            </Col>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.zipCode.placeholder' })}
                                                    type='text'
                                                    maxLength={15}
                                                    value={props.zipCode}
                                                    onChange={(e) => {
                                                        props.updateState({ zipCode: e.target.value });
                                                    }}
                                                    required={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col> : ''}
                        </Row> */}
                        {/* <Row gutter={16}>

                            {formSetting.membershipOptions ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='sidebar.menuItem.membershipOption' /> </span>}>
                                        <Radio.Group value={props.membershipOption} onChange={(e) => {
                                            props.updateState({ membershipOption: e.target.value });
                                        }} >
                                            {
                                                (formSetting.membershipOptions || []).map((membershipOption, i) => {
                                                    return (
                                                        <div><Radio value={membershipOption}>{membershipOption}</Radio></div>
                                                    )
                                                })
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                </Col> : ''}
                        </Row> */}

                        <Row gutter={16}>
                            {(formSetting.selectedMembershipOptions && formSetting.selectedMembershipOptions.length) ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='sidebar.menuItem.membershipOption' /> {formSetting.membershipOptionRequired ? <span className="required">*</span>: ''} </span>}>
                                        <Radio.Group value={props.membershipOption} onChange={(e) => {
                                            props.updateState({ membershipOption: e.target.value });
                                        }} >
                                            {
                                                (formSetting.selectedMembershipOptions || []).map((membershipOption, i) => {
                                                    return (
                                                        <div key={'mem'+i}><Radio value={membershipOption.membershipOptionId}>{membershipOption.name} - {`$${membershipOption.termAndFees.fee}/${membershipOption.termAndFees.frequency} for ${membershipOption.termAndFees.duration} ${membershipOption.termAndFees.period}${membershipOption.termAndFees.type? ' - '+membershipOption.termAndFees.type : ''}`}</Radio></div>
                                                    )
                                                })
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                </Col> : ''}
                        </Row>
                        {formSetting.collectStudentInfo ?
                            <div  className="maibot">
                                <b className='label-emergency'>Family Member</b>
                                <Row gutter={16}>

                                    <Col span={24}>
                                        <div className='grid-container mb-res'>
                                            {/* {(Object.keys(formSetting.gradeValidations).length || !formSetting.gradeLevelValidation) && <CustomAgGrid
                                                columnDefs={columnDefs}
                                                rowData={(props.studentList || [])}
                                                //editType='fullRow'
                                                domLayout={"autoHeight"}
                                                getRowHeight={getRowHeight}
                                                animateRows={true}
                                                onGridSizeChanged={(params) => {
                                                    params.api.sizeColumnsToFit();

                                                }}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}

                                                onGridReady={onGridReady}
                                            >
                                            </CustomAgGrid>} */}
                                              {(Object.keys(formSetting.gradeValidations).length || !formSetting.gradeLevelValidation) &&  <div className="agGridWrapper">
                                            <div className='new-table-attend'>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }}>#</th>
                                                            <th><span><FormattedMessage id='signup.firstname.placeholder' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span></th>
                                                            <th><span><FormattedMessage id='signup.lastname.placeholder' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span></th>
                                                            {/* <th style={{width: '12%'}}><span><FormattedMessage id='grade.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span></th> */}
                                                            {/* <th>{formSetting.isDisplayYearlyFee ? <span><FormattedMessage id='gradefeeYearly.header.text' /></span> : <span><FormattedMessage id='gradefee.header.text' /></span>}</th> */}
                                                            <th><span><FormattedMessage id='dob.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span></th>
                                                            {/* <th><span><FormattedMessage id='allergies.header.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span></th> */}
                                                            <th><span><FormattedMessage id='gender.text' />{props.registrationType === 'student' ? <span className="required">*</span> : ''}</span></th>
                                                            <th><FormattedMessage id='customer.listing.header.action' /></th>
                                                        </tr>
                                                            {(props.studentList || []).map((e, i) => {
                                                                return <tr key={'std' + i}>

                                                                    <th style={{ textAlign: 'center' }}>{i + 1}</th>
                                                                    <th>{e.firstName}</th>
                                                                    <th>{e.lastName}</th>
                                                                    {/* <th>
                                                                        <Dropdown
                                                                            className='grade-dropdown'
                                                                            items={gradeArr}
                                                                            optionKeyName='gId'
                                                                            valueKeyName='gradeValidation'
                                                                            isGradeLevel={true}
                                                                            isDisplayYearlyFee={formSetting.isDisplayYearlyFee ? true : false}
                                                                            totalMonths={getMonthsBetweenDates(formSetting.sessionStart, formSetting.sessionEnd) || 1}
                                                                            valueKeyGradeFee='gradefee'
                                                                            addressLineKey1='subcourseName'
                                                                            currencyCode={companyInfo.currencyCode || ''}
                                                                            onSelect={(gId, optionObj) => {
                                                                                const selectedObj = find(gradeArr, { gId: optionObj.key });
                                                                                let selectedValue = selectedObj.gradeValidation;

                                                                                e.gradefee = null;

                                                                                if (selectedObj) {
                                                                                    e.gradefee = selectedObj.gradefee;
                                                                                    e.subcourseName = selectedObj.subcourseName;
                                                                                    e.subcourseId = selectedObj.subcourseId;
                                                                                    e.gId = selectedObj.gId;
                                                                                    e.grade = selectedValue;
                                                                                    e.invalidGrade = formSetting.gradeLevelValidation ? true : false;
                                                                                }

                                                                                if (formSetting.gradeLevelValidation) {
                                                                                    let isInvalid = false;
                                                                                    if (selectedValue && (Number((formSetting.gradeValidations || {})[selectedValue] || 0)) <= (Number((self.totalStudentCountByGrade || {})[selectedValue] || 0))) {
                                                                                        isInvalid = true;
                                                                                    }

                                                                                    if (isInvalid && formSetting.invalidGradeMessage) {
                                                                                        //showToasterMessage({   messageType: 'warning', description: props.intl.formatMessage({ id: 'no.more.seat.available.inGrade.message', values: { value: selectedValue } })});
                                                                                        showToasterMessage({ messageType: formSetting.isStopRegistrationAfterMaxStudent ? 'error' : 'warning', description: formSetting.invalidGradeMessage });

                                                                                    } else {
                                                                                        e.invalidGrade = false;
                                                                                        e.gradefee = selectedObj.gradefee;
                                                                                        e.subcourseName = selectedObj.subcourseName;
                                                                                        e.subcourseId = selectedObj.subcourseId;
                                                                                        e.gId = selectedObj.gId;
                                                                                        e.grade = selectedValue;
                                                                                        let totalStudentCountByGrade = self.totalStudentCountByGrade || {};
                                                                                        totalStudentCountByGrade[selectedValue] = Number(totalStudentCountByGrade[selectedValue] || 0) + 1;
                                                                                        props.updateState({
                                                                                            totalStudentCountByGrade
                                                                                        });

                                                                                    }

                                                                                }
                                                                                let studentList = props.studentList;
                                                                                studentList.splice(i, 1, e);
                                                                                props.updateState(studentList);
                                                                            }
                                                                            }
                                                                        />
                                                                    </th> */}
                                                                    {/* <th><span>{formSetting.isDisplayYearlyFee ? (e.gradefee ? (Number(e.gradefee) * getMonthsBetweenDates(formSetting.sessionStart, formSetting.sessionEnd)).toFixed(2) : 0.00) : Number(e.gradefee || 0).toFixed(2)}</span></th> */}
                                                                    <th><span>{e.dob ? getMomentDateForUIReadOnly({ date: e.dob, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</span></th>
                                                                    {/* <th>{ReactHtmlParser(e.allergies)}</th> */}
                                                                    <th>{e.gender || ''}</th>
                                                                    <th>
                                                                        <div className="delrt-sticky action-cell">
                                                                            <i className='fa fa-edit' onClick={() => {
                                                                                   let formData = { customerId: props.customerId, customerName: props.customerInfo.companyName, contactData: e }
                                                                                   handleNewContactAddition(props, { formData: formData }, true, i)
                                                                            }} type="edit" />
                                                                            <i className='fa fa-trash' onClick={() => {
                                                                                let studentList = props.studentList;
                                                                                studentList.splice(i, 1);
                                                                                props.updateState(studentList);
                                                                            }} type="delete" />
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                            </div>
                                        </div> }
                                        </div>
                                    </Col>
                                    <Col>
                                        <Button className="grey-button ant-btn ant-btn-default" onClick={
                                            () => {
                                                // let newRowIndex = -1;
                                                // gridApi && gridApi.forEachNode((rowNode, index) => {
                                                //     newRowIndex = index;
                                                // });
                                                // if (formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount + newRowIndex + 1)) {
                                                //     return showToasterMessage({
                                                //         messageType: 'error', description: props.intl.formatMessage({ id: 'no.more.seat.available.message' })
                                                //     });
                                                // }

                                                // gridApi && gridApi.stopEditing();
                                                // gridApi && gridApi.applyTransaction({ add: [{}] });
                                                // gridApi && gridApi.startEditingCell({
                                                //     rowIndex: newRowIndex + 1,
                                                //     colKey: "firstName_1"
                                                // });
                                                // gridApi && gridApi.refreshCells({ force: true });
                                                //editProfile();
                                                if(props.customerInfo.boContactList && props.customerInfo.boContactList.length > 0){
                                                    addStudentHandle();
                                                }else{
                                                    editProfile(); 
                                                }
                                            }}><FormattedMessage id='addButton.familyMember.text' defaultMessage='' /></Button>
                                    </Col>
                                </Row>

                                <br />
                            </div> : ''}
                            {/* <Row gutter={16}>
                                {(props.isAllowParentPaymentPlan && props.paymentPlan && props.paymentPlan.length) ?
                                    <Col span={12}>

                                        <Form.Item className='label-emergency  new-fed'>
                                            <div><FormattedMessage id='paymentType.ask.message' /> </div>
                                            <Radio.Group onChange={(e) => {
                                                props.updateState({ paymentType: e.target.value });
                                            }} value={props.paymentType} >
                                               <div> <Radio value='oneTime'> <FormattedMessage id='oneTimePayment.text' /></Radio></div>
                                               <div> <Radio value='installment'><FormattedMessage id='installment.text' /></Radio></div>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col> : ''}
                            </Row> */}
                            <Row gutter={16}>

                                {(props.isAllowParentPaymentPlan && props.paymentPlan && props.paymentPlan.length) ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency netiu' label={<span><FormattedMessage id='select.paymentPlan' /> <span className="required">*</span></span>}>

                                            <Select style={{ width: '80%' }} value={props.selectedPlanIndex} onChange={(val) => {
                                                let selectedPlan = props.paymentPlan[val];
                                                props.updateState({ selectedPlanIndex: val, selectedPlan: selectedPlan });
                                            }}>
                                                {
                                                    (props.paymentPlan || []).map((plan, i) => {
                                                        return (
                                                            <Option key={i} value={i}>Pay in {plan.totalInstallment || 0} {plan.totalInstallment === 1 ? 'installment' : 'installments'}</Option>
                                                        )
                                                    })
                                                }


                                            </Select>

                                            {/* <Radio.Group value={props.selectedPlan} onChange={(e) => {
                                                props.updateState({ selectedPlan: e.target.value });
                                            }} >
                                                {
                                                    (props.paymentPlan || []).map((plan, i) => {
                                                        return (
                                                            <div key={i}><Radio value={plan.period}>{plan.totalInstallment || 0} Installments {plan.period} </Radio></div>
                                                        )
                                                    })
                                                }
                                            </Radio.Group> */}
                                        </Form.Item>
                                    </Col> : ''}
                                    </Row>
                                    <Row>
                                    {(formSetting.askOrgMember) ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency netiu' label={<span>Are you a member of {companyInfo.storeName}?{formSetting.askOrgMemberRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Select style={{ width: '80%' }} value={props.orgMember} onChange={(val) => {
                                            props.updateState({ orgMember: val});
                                        }}>
                                            <Option key={'No'} value={'No'}>No</Option>
                                            <Option key={'Individual Membership'} value={'Individual Membership'}>Individual Membership</Option>
                                            <Option key={'Family Membership'} value={'Family Membership'}>Family Membership</Option>
                                            <Option key={'Yes-Other'} value={'Yes-Other'}>Yes-Other</Option>
                                        </Select>
                                    </Form.Item>
                                </Col> : ''}
                                </Row> 
                                <Row>
                                {(formSetting.askFinanacialAssistance) ?
                                <Col span={24}>
                                    <Form.Item className='label-emergency netiu' label={<span> <FormattedMessage id='financialAssistance.message' />{formSetting.askFinanacialAssistanceRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Switch
                                            checked={(props.isFinancialAssistanceNeeded === 'yes')}
                                            onClick={(checked, e) => {
                                                props.updateState({ isFinancialAssistanceNeeded: checked ? 'yes' : 'no'})
                                            }} />
                                    </Form.Item>
                                </Col> : ''}
                                {(formSetting.askAddTeacher) ?
                                <Col span={24}>
                                    <Form.Item className='label-emergency netiu' label={<span> <FormattedMessage id='addTeacher.level' />{formSetting.askAddTeacherRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Switch
                                            checked={(props.isTeacher === 'yes')}
                                            onClick={(checked, e) => {
                                                props.updateState({ isTeacher: checked ? 'yes' : 'no' })
                                            }} />
                                    </Form.Item>
                                </Col> : ''}
                                {(formSetting.askAddVolunteer) ?
                                <Col span={24}>
                                    <Form.Item className='label-emergency netiu' label={<span> <FormattedMessage id='addVolunteer.level' />{formSetting.askAddVolunteerRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Switch
                                            checked={(props.isVolunteer === 'yes')}
                                            onClick={(checked, e) => {
                                                props.updateState({ isVolunteer: checked ? 'yes' : 'no' })
                                            }} />
                                    </Form.Item>
                                </Col> : ''}
                            </Row>
                        

                        {
                            (formSetting.questionList || []).map((queObj, i) => {

                                return (
                                    <div style={{ marginBottom: '10px', padding: '10px 10px 10px 10px' }} key={'que'+i}>
                                        <Row style={{ marginBottom: '10px' }}>
                                            <Col span={20}><b>{queObj.questionText} {queObj.isRequired ? <span className="required">*</span> : ''}</b></Col>
                                        </Row>
                                        <Row>
                                        <Col span={20}>
                                            {(queObj.optionList && queObj.optionList.length) ?
                                                <div className="nertr">
                                                    {queObj.answerType === 'multiChoice' ?
                                                        <Checkbox.Group value={queObj.answers} onChange={(answers) => {
                                                            queObj.answers = answers;
                                                            let questionList = formSetting.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            formSetting.questionList = questionList;
                                                            updateFormSetting(formSetting);
                                                        }}
                                                        >
                                                            {(queObj.optionList || []).map((optObj, j) => {
                                                                return (<div key={'opt'+j}><Checkbox value={optObj.optText}>{optObj.optText}</Checkbox></div>)
                                                            })
                                                            }

                                                        </Checkbox.Group> :
                                                        <Radio.Group value={queObj.answers} onChange={(e) => {
                                                            queObj.answers = e.target.value;
                                                            let questionList = formSetting.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            formSetting.questionList = questionList;
                                                            updateFormSetting(formSetting);
                                                        }} >
                                                            {(queObj.optionList || []).map((optObj, k) => {
                                                                return (optObj.optText ? <div key={'optA'+k}><Radio value={optObj.optText}>{optObj.optText}</Radio></div> : '')
                                                            })
                                                            }

                                                        </Radio.Group>
                                                    }
                                                </div> : ''
                                            }
                                            <div style={{clear:'both'}}></div>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                        <Col span={20}>   
                                        </Col>
                                        </Row> */}
                                        {queObj.answerType === 'shortAnswer' ?
                                            <Row>
                                                <Col span={20}>
                                                    <TextArea
                                                        style={{ width: '100%' }}
                                                        placeholder='Enter here'
                                                        type='text'
                                                        value={queObj.answers}
                                                        onChange={(e) => {
                                                            queObj.answers = e.target.value;
                                                            let questionList = formSetting.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            formSetting.questionList = questionList;
                                                            updateFormSetting(formSetting);
                                                        }}
                                                    />
                                                </Col>
                                            </Row> : ''
                                        }
                                    </div>
                                )
                            })
                        }

                        <Row gutter={22}>
                            {formSetting.footerMessage ?
                                <Col span={24}>
                                    {formSetting.footerMessage ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>{ReactHtmlParser(formSetting.footerMessage)}</div> : ''}
                                </Col> : ''}
                        </Row>

                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                        <Row gutter={16} className='regists'>
                            <div className='regist'>
                                <Col span={1} >

                                </Col>
                                <Col span={12}>
                                    {/* <Button className="primary ant-btn ant-btn-primary" onClick={() => {
                        save();
                    }}>Submit</Button> */}
                                    <div>
                                        {formSetting.collectOnlinePayment ? <Button
                                            className="primary ant-btn ant-btn-primary"
                                            htmlType='submit'
                                            key={2}
                                            style={{ color: 'white', height: 'auto' }}
                                            disabled={props.modalType === 'preview'}
                                        ><FormattedMessage id='proceedToPay.text' values={{value: getTotalGradeFees() ? ' ('+(companyInfo.currencyCode || '') + ' '+ getTotalGradeFees() + ')': ''}} />
                                            
                                        </Button> :
                                            <CustomButton
                                                intlId={'confirmation.submit'}
                                                defaultMessage={''}
                                                htmlType='submit'
                                                key={2}
                                            />
                                        }
                                    </div>
                                </Col>
                                <Col span={7}>
                                    <div>
                                    Powered by Alif App (<a href="https://www.alifapp.com">alifapp.com</a>)
                                    </div>
                                </Col>
                            </div>
                        </Row>


                    </Form>
                )}
                {(props.isFormSaved || ((formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount)))) ?  <div style={{display: 'flex', justifyContent: 'center'}}>
                   <div>Powered by Alif App (<a href="https://www.alifapp.com">alifapp.com</a>)</div>
                </div>: null}
            </div>
        </div>
    )
};

export default injectIntl(Register);
