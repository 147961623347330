/* eslint-disable no-control-regex */
export const CONSTANTS = {
  EMAIL_REGEX_EXP:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  PHONE_REGEX_EXP: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
  INDIA_COUNTRY_ID: 45,
  RELATIONSHIP_BILLING_ADDRESS: "RelationshipBillingAddress",
  RELATIONSHIP_SHIPPING_ADDRESS: "RelationshipShippingAddress",
  BILLING_ADDRESS: "BillingAddress",
  SHIPPING_ADDRESS: "ShippingAddress",
  DISPLAY_DATE_FORMAT: "DD MMM YYYY",
  DISPLAY_DATE_TIME_FORMAT: "DD MMM YYYY HH:mm:ss",
  DISPLAY_DATE_FORMAT_FULl: "ddd, DD MMM YYYY HH:mm:ss",
  DISPLAY_DATE_FORMAT_FY: "DD MMMM YYYY",
  TABLE_DATE_FORMAT: "DD MMM YYYY",
  DISPLAY_DATE_FORMAT_SHORT: "DD MMM YY",
  BACKEND_FORMAT: "YYYY-MM-DD HH:mm:ss",
  BACKEND_FORMAT_FORMAT_SHORT: "YYYY-MM-DD",
  DISPLAY_TOOLTIP_DATE_FORMAT: "ddd, DD MMM YYYY hh:mm A",
  PRIORITIES: ["Low", "Medium", "High", "Urgent"],
  DISPLAY_DATE_FORMAT_SESSION: "MMMM YYYY",
  DISPLAY_ONLY_DATE: "DD MMMM YYYY",
  DISPLAY_ONLY_TIME: "HH:mm:ss",
  BACKEND_FORMAT: "YYYY-MM-DD HH:mm:ss",
  BACKEND_FORMAT_FORMAT_SHORT: "YYYY-MM-DD",
  DISPLAY_TOOLTIP_DATE_FORMAT: "ddd, DD MM YYYY hh:mm A",
  CONTACT_TYPE_CUSTOMER: "Customer",
  BASE_URL_S3: "https://s3.amazonaws.com",
  TABLE_DATE_FORMAT: "DD MMMM YYYY",


};
export const ROLE_TYPES = Object.freeze({
  FATHER: "Father",
  MOTHER: "Mother",
  STUDENT: "Student",
});


export const MODAL_ACTIONS = {
  SHOW_MODAL: "show_modal",
  HIDE_MODAL: "hide_modal",
  MODAL_FORM_DATA_UPDATE: "modal_form_data",
  PUSH_STACK_MODAL: "push_stack_modal",
  POP_STACK_MODAL: "pop_stack_modal",
  CLEAR_STACK_MODAL: "clear_stack_modal",
};

export const STORAGE_CONSTANTS = {
  REMEMBER_ME: "rememberMe",
  LOGIN_EMAIL: "email",
  LOGIN_PASSWORD: "password",
};


export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "access_token_p",
  REFRESH_TOKEN: "refresh_token",
  ACCESS_TOKEN_BACKUP: "_access_token",
  EXPIRE_TIME: "expires_in",
  LOGGEDIN_ENTITY_DATA: "logged_in_entity",
  PERMISSION_DATA: "permission_data",
  ADMIN_TOKEN: "admin_token",
  DEFAULT_SCHOOL_FORM: 'default_school_form',
  LOGGEDIN_EMAIL: "logged_email",
};

export const COMMON_ACTIONS = {
  ADMIN_TOKEN: "admin_token",
  XERO_CONFIG_RELATIONSHIP_DATA: "xero_config_relationship_data",
  RELATIONSHIP_ID: "relationship_id",
  EMAIL_ADDRESS_LIST: "email_address_list",
  SHOW_LOADER: "show_loader",
  HIDE_LOADER: "hide_loader",
  LOGGEDIN_COMPANY_INFO: "logged_in_company_info",
  LOGGEDIN_USER_INFO: "logged_in_user_info",
  LOGGEDIN_USER_BANK_INFO: "logged_in_user_bank_info",
  LOGGEDIN_COMPANY_EMP_LIST: "logged_in_user_emp_list",
  LOGGEDIN_CUSTOMER_INFO: "logged_in_customer_info",
  COUNTRIES_LIST: "countries_list",
  STATES_LIST: "states_list",
  SUBDOMAIN: "subdomain",
  PAYMENT_LIST: "payment_list",
  PAYMENT_LIST_ADDITION: "payment_list_addition",
  PAYMENT_LIST_DELETION: "payment_list_deletion",
  DEFAULT_SCHOOL_FORM: 'default_school_form',
  LOGGEDIN_EMAIL: "logged_email",
  CONTACT_EDUCATION_LIST: "conatct_education_list",
  CONTACT_EMPLOYMENT_LIST: "conatct_employment_list",
  IS_FLASH_VISIBLE: "is_flash_visible",
  DEFAULT_COURSE: "default_course",
}

export const SCHOOL_ACTIONS = {  
  FEE_LIST: "fee_list",
  FEE_COUNT: "fee_count",
  FEE_LIST_PAGE_RESET: "fee_list_page_reset",
  STUDENT_FORM_LIST: "student_form_list",
  DONATION_SETTING_LOADING: "donation_setting_loading",
  REGISTRATION_FORM_SETTING: "registration_form_setting",
  RESET_REGISTRATION_FORM_SETTING: "reset_registration_form_setting",
  REGISTRATION_List: "registration_List",
  COMMUNICATION_LIST: "communication_list",
  COMMUNICATION_COUNT: "communication_count",
  COMMUNICATION_LIST_PAGE_RESET: "communication_list_page_reset",
  
  ASSIGNMENT_LIST: "assignment_list",
  ASSIGNMENT_COUNT: "assignment_count",
  ASSIGNMENT_LIST_PAGE_RESET: 'assignment_list_page_reset',
  CLASS_STUDENT_LIST: 'class_student_list',
  SCHOOL_ATTENDENCE_BY_CLASS_ID: "school_attendence_by_classId",
  CLASS_LIST: 'class_list',
  SCHOOL_ATTENDENCE_BY_STUDENT_ID: "school_attendence_by_studentId",
  CUSTOMER_SCHOOL_COURSE: "customer_school_course",
  CLASS_SECTION_LIST: "class_section_list",
  TIME_TABLE_LIST: "time_table_list",
  INVOICE_PROFORMA_LIST: "invoice_proforma_list",
  
}
export const MEMBERSHIP_ACTIONS = {  
  MEMBERSHIP_FORM_LIST: "membership_form_list",
  MEMBERSHIP_INFO: "membership_info",
}

export const AUTH = {
  LOGOUT: "logout",
  SET_PERMISSIONS: "set_permissions",
  SHOW_RESEND_ACTIVATION: "show_resend_activation",
  HIDE_RESEND_ACTIVATION: "hide_resend_activation",
};
export const PERMISSION_VALUES = {
  READ: 1,
  CREATE: 2,
  UPDATE: 4,
  DELETE: 8,
};


export const TX_STATUS = {
  NEW: "new",
  OPEN: "open",
  PAID: "paid",
  COMPLETED: "completed",
  NOT_REQUIRED: "not.required",
  NOT_STARTED: "not.started",
  SPLIT: "Split",
  ACTIVE: "Active",
  UNPAID: "unpaid",
  DRAFT: 'draft'
};

export const DASHBOARD_PAGE_CONSTANTS = {
  FEES_DUE: "fees_due",
  FEES_PAID: "fees_paid",
  INVOICE_GENERATED: "invoice_generated",
  TOTAL_STUDENT: "total_student",
};

export const DASHBOARD_ACTION_LIST = {  
  UNPAID_CUSTOMER_AMOUNT: "unpaid_customer_amount",
  PAID_CUSTOMER_AMOUNT: "paid_customer_amount",
  TOTAL_INVOICE_CUSTOMER_AMOUNT: "total_invoice_customer_amount",
  ALL_STUDENT_LIST: "all_student_list",
  STUDENT_CLASS_LIST: "student_class_list",
  TOTAL_FEE_AMOUNT: "total_fee_amount",
  
  UNPAID_INVOICES_BY_FORM_ID: "unpaid_invoices_by_formId",
  UNPAID_INVOICES_COUNT_BY_FORM_ID: "unpaid_invoices_count_by_formId",
  UNPAID_INVOICES_BY_FORM_ID_RESET:"unpaid_invoices_by_formId_reset",
  PAYMENT_SUBSCRIPTION: "payment_subscription",
  BROADCAST_MESSAGE_LIST: "broadcast_message_list",
}

export const GRID_ACTIONS = {
  DELETE: "delete",
  SAVE: "save",
  EMAIL: "email",
  DOWNLOAD_PDF: "download_pdf",
  EDIT: "edit",
  CANCEL: "cancle",
};

export const STUDENT_REGISTRATION_ACTION_LIST = {
  TOTAL_STUDENT_COUNT: "total_student_count",
  TOTAL_STUDENT_COUNT_BY_GRADE: "total_student_count_by_grade",
};

export const MODAL_TYPE = {
  CONTACT: "Contact",
  BILLING_ADDRESS: "Billing Address",
  SHIPPING_ADDRESS: "Shipping Address",
  RELATIONSHIP_BILLING_ADDRESS: "Relationship Billing Address",
  RELATIONSHIP_SHIPPING_ADDRESS: "Relationship Shipping Address",
  EDUCATION: "Education",
  EMPLOYMENT: "Employment",
  URL_POPUP: 'URL Popup'
};

export const FAMILY_RELATIONSHIP_NAME_BY_KEY = {
  father: 'Father',
  mother: 'Mother',
  son: 'Son',
  daughter: 'Daughter',
  brother: 'Brother',
  sister: 'Sister',
  grandpa: 'Grandpa',
  grandma: 'Grandma',
  uncle: 'Uncle',
  aunt: 'Aunt',
  other: 'Other',
}

export const FAMILY_RELATIONSHIP_KEY_BY_NAME = {
  Father: 'father',
  Mother: 'mother',
  Son: 'son',
  Daughter: 'daughter',
  Brother: 'brother',
  Sister: 'sister',
  Grandpa: 'grandpa',
  Grandma: 'grandma',
  Uncle: 'uncle',
  Aunt: 'aunt',
  Other: 'other',
}

export const TEXT_CONSTANTS = {
  REGISTRATION_FORM : 'Registration Form',
  ENROLEMENT_FORM : 'Enrolment Form',

}

export const MONTH_NAME_CONSTANT = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sept",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};