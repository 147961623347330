import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UnpaidInvoicesComponent from '../../../components/dashboard/UnpaidInvoices';
import { getUnpaidInvoicesByFormId,  getUnpaidInvoiceCount, resetPaginatedData } from './action';

class UnpaidInvoices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            context: props.context,
            customerId: (props.customerData || {}).customerId,
            pageNumber: 1
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const customerInfo = this.props.customerInfo || {};
        const boContactChildList = customerInfo.boContactChildList || [];
        let schoolStudentIdList = [];
        boContactChildList.forEach(e => {
            if (((e.studentList || [])[0] || {}).schoolStudentId) {
                schoolStudentIdList.push(((e.studentList || [])[0] || {}).schoolStudentId);
            }
        });
        const payload = {
            relationshipId: companyInfo.relationshipId,
            context: this.state.context,
            customerId: customerInfo.customerId,
            contactId: ((customerInfo.boContactList || [])[0] || {}).contactId,
            schoolStudentIdList: schoolStudentIdList,
            pageNumber: 0, pageSize: 100,
            formId: this.props.defaultSchoolForm
        };
        this.props.getUnpaidInvoicesByFormId(payload);
        this.props.getUnpaidInvoiceCount(payload);
    }

    render() {
        return <div>
            <UnpaidInvoicesComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        
        companyInfo: state.common.companyInfo,
        customerInfo: state.common.customerInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        defaultSchoolForm: state.common.defaultSchoolForm,
        unpaidInvoiceList: state.dashboard.unpaidInvoices,
        unpaidInvoiceCount: state.dashboard.unpaidInvoiceCount,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getUnpaidInvoicesByFormId,  getUnpaidInvoiceCount, resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnpaidInvoices);