import React from 'react'
import { Button, Empty } from 'antd';
import { capitalizeFirstLetter, getMomentDateForUIReadOnly } from '../../utils';
import config from '../../config/environmentConfig';


const PendingFees = (props) => {
    const { invoiceProformaList = [] } = props;
  return <div className="fee-card-container">
    {invoiceProformaList?.length ? 
      invoiceProformaList.map((item, index) => (
        <div key={index} className="fee-card">
          <div className="fee-card-content">
              <div className="card-header">Fees Installement {index+1}</div>
              <div className="fee-card-row">
                <div className='row-item'>
                  <span className="label">Amount</span>
                  <span className="value">{JSON.parse(item.invoiceData || '{}')?.currencyCode} {JSON.parse(item.invoiceData || '{}')?.invoiceTotalAmount.toFixed(2)}</span>
                </div>
                <div className='row-item'>
                  <span className="label">Due Date</span>
                  <span className="value">{item.expiryDate ? getMomentDateForUIReadOnly({
                    date: item.expiryDate
                  }) : '-'}</span>
                </div>

                <div className='row-item'>
                  <span className="label">Status</span>
                  <span className="value">
                    {item.paymentStatus ?
                      <div className={`status-label ${item.paymentStatus}`}>{capitalizeFirstLetter(item.paymentStatus)}</div> 
                      : 
                      <div className='status-label unpaid'>{'Unpaid'}</div> 
                    }
                  </span>
                </div>

                {item.paymentStatus === 'paid' && item.isInvoiceConverted ?
                  <Button 
                    type='primary'
                    className='mt10'
                    style={{height: '32px', backgroundColor: 'green'}}
                  >
                    View Invoice
                  </Button>
                  :
                  <Button 
                    type='primary'
                    className='mt10'
                    style={{height: '32px'}}
                    onClick={()=>{
                      window.open(`${config.UI_BASE_URL}proforma-payment?${encodeURIComponent(`rid=${item.relationshipId}&proformaId=${item.invoiceProformaId || 0}&customerId=${props.customerInfo?.customerId || 0}`)}`, '_blank');
                    }}
                  >
                    {console.log(props)}
                    Pay Now
                  </Button>
                }
              </div>
          </div>
        </div>
      ))
      :
      <Empty />
    }
    </div>
}

export default PendingFees;