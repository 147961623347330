import React, { useRef, useState, forwardRef, useImperativeHandle } from "react";
import CKEditor from "./CustomCKEditor/ckeditor";
import { CUSTOM_DESCRIPTION_CONFIGURATION } from "../../static/ckEditorConfigConstants";


const CKEditorComponent = (props, ref) => {
  const inputRef = useRef();
  let [value, setValue] = useState(props.value);
  let [editor, setEditor] = useState({});
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: () => {
        inputRef.current.element.focus();
        setTimeout(() => {
          editor && editor.focus && editor.focus();
        }, 100)
      },
      isCancelAfterEnd: () => {

        if (props.lastColumnCell && props.node.lastChild && !props.node.shiftKey) {
          props.api.updateRowData({ add: [{}] });
        }
        return false;
      }
    };
  });



  inputRef.current && inputRef.current.element.addEventListener('keyup', (event) => {
    let params = {
      ...props,
      event: event
    };
    props.colDef.suppressKeyboardEvent(params);
  });

  return (
    <CKEditor
      ref={inputRef}
      type="inline"
      key={`${"value"}`}
      data={value}
      onInit={editor => {
        setEditor(editor);
      }}
      onChange={(event) => {
        const data = event.editor.getData();
        if (props.onChange) {
          props.onChange(data);
        }
        setValue(data);
      }
      }
      config={CUSTOM_DESCRIPTION_CONFIGURATION}
    />
  );
}

export const CKEditorGrid = forwardRef(CKEditorComponent);