import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OpenRegistrationComp from "../../components/OpenRegistration";
import { fetchPaginationDataIfNeeded } from "../../utils";
import { getAllStudentForm } from "./action";
import { getOrgRegistration } from "../Enrolled/action";
import { showModal, hideModal, pushModalToStack, popModalFromStack, getSubdomain } from './../../actions/commonActions';

class OpenRegistration extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      templateType: linkProps.templateType,
      enrolledForm: []
    };
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true,
    // });
    this.props.updateHeaderState({headerHeading: 'Form'})
    const companyInfo = this.props.companyInfo || {};
    const customerInfo = this.props.customerInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0, 
      pageSize: 100,
      contactId: (customerInfo?.boContactList || {}).contactId,
      customerId: customerInfo.customerId,
    };
    fetchPaginationDataIfNeeded("getAllStudentForm", "studentFormList", this.props, payload);
    this.props.getSubdomain(payload);
    this.props.getOrgRegistration(payload);
  }
  componentWillReceiveProps(props) {
    if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
      this.setState({
        subdomainName: props.subdomain.subdomainName
      });
    }
    if (props.registrationList && props.registrationList.length ) {
      var enrolledForm = props.registrationList.map(function(obj) {
        return obj.registrationFormSettingId;
      })
      this.setState({
        enrolledForm: enrolledForm || []
      });
    }
  }

  render() {
    return <OpenRegistrationComp  {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}>

    </OpenRegistrationComp>;
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    customerInfo: state.common.customerInfo,
    studentFormList: state.school.studentFormList,
    subdomain: state.common.subdomain,
    adminToken: state.common.adminToken,
    registrationList: state.school.registrationList,
    defaultSchoolForm: state.common.defaultSchoolForm,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllStudentForm,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getSubdomain,
      getOrgRegistration
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpenRegistration);
