import { MEMBERSHIP_ACTIONS } from '../static/constants';
const initialState = {
  membershipFormList: [],
  membershipInfo: {}
}

const SchoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEMBERSHIP_ACTIONS.MEMBERSHIP_FORM_LIST:
      return { ...state, membershipFormList: action.data };
      case MEMBERSHIP_ACTIONS.MEMBERSHIP_INFO:
        return { ...state, membershipInfo: action.data };
    default:
      return state;
  }
};

export default SchoolReducer;