import {fr} from './fr'
import {en} from './en'
import {de} from './de'
import {ar} from './ar'

export const languages = {
    fr,
    en,
    de,
    ar
};