import React, { Component, Fragment } from "react";
// import config from "../../config/environmentConfig";
import { FormattedMessage, injectIntl } from "react-intl";
import { getMomentDateForUIReadOnly, getUserDefinedName } from "../../utils";
import { CONSTANTS, TEXT_CONSTANTS } from '../../static/constants';
import {Menu, Button, Dropdown } from 'antd';
import DropdownAction from "antd/lib/dropdown";
// import DropdownAction from "antd/lib/dropdown";

import * as filter from 'lodash.filter';
import {  MoreOutlined, EditOutlined} from '@ant-design/icons';
import PageBreadcrumb from "../PageBreadcrumb";
//import RegistrationFormSetting from '../../../containers/modal/modalBody/settings/RegistrationFormSetting';
//import ShareRegistrationFormCompo from '../../../components/modal/modalBody/settings/ShareRegistrationForm';
class OpenRegistrationComp extends Component {

  
// class breadCrumbList extends Component{

// }

  // const OpenRegistrationComp = (props) => {
  //   const breadCrumbList = [
  //     {
  //       name: <FormattedMessage id='sidebar.menuItem.eStores' defaultMessage='' />,
  //     },
  //     {
  //       name: <FormattedMessage id='sidebar.menuItem.storeSettings' defaultMessage='' />,
  //     },
     
  //   ];

  render() {

    // const actionMenu = (e) => {
    //   return (
    //     <Menu className="row-action-dropdown">
    //       <Menu.Item key="0" value="title">
    //         Actions
    //       </Menu.Item>

    //       <Menu.Divider />

    //       {(e.isFormActive || (this.props.adminToken && this.props.adminToken.token)) && 
    //       (this.props.enrolledForm && this.props.enrolledForm.indexOf(e.registrationFormSettingId)) > -1 ? 
    //         <Menu.Item
    //           key="3"
    //           value="Edit"
    //           onClick={() => {
    //             // const v = this.props.registrationList;
    //             const itemf = filter(this.props.registrationList || [], { registrationFormSettingId : e.registrationFormSettingId})
    //             this.props.history.push({
    //               pathname: "/admin/register",
    //               state: {
    //                 formId: e.registrationFormSettingId,
    //                 orgRegistrationId: itemf[0].orgRegistrationId,
    //               },
    //             });
    //           }}
    //         >
    //           <EditOutlined /> Edit
    //         </Menu.Item>
    //         : 
    //         <Menu.Item
    //           key="1"
    //           value="Register"
    //           onClick={() => {
    //             // let url = `https://${this.props.subdomainName}/app/register?formId=${e.registrationFormSettingId}`
    //             // Object.assign(document.createElement('a'), {
    //             //   target: '_blank',
    //             //   href: url,
    //             // }).click();

    //             this.props.history.push({
    //               pathname: "/admin/register",
    //               state: {
    //                 formId: e.registrationFormSettingId,
    //               },
    //             });
    //           }}
    //         >
    //           <EditOutlined /> Register
    //         </Menu.Item>
    //       }
    //     </Menu>
    //   )
    // };

    const format = CONSTANTS.TABLE_DATE_FORMAT;
    const breadCrumbList=[
  {
    name: <FormattedMessage id='breadcrum.dashboard' defaultMessage='' />,
  },
  {
    name: <FormattedMessage id='fundraising.campaign.addDrawer.field.form' defaultMessage='' />,


  },

  {
    name: <FormattedMessage id='list.text' defaultMessage='' />,

  },
    ];



    const actionMenu = (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>
   
          <Menu.Divider />
  
          <Menu.Item key="1" value="edit" >
          <i class="fi fi-rr-edit mr5"></i> 
           Edit    
              </Menu.Item>
  
          <Menu.Item key="2">
          <i class="fi fi-rr-trash mr5"></i>       
          Delete
          </Menu.Item>
        </Menu>
      );
    
  

    return (
      <Fragment>
        <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='modal.education.forms' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          
        </div>
      </div>
        <div className="view-container">
        <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='modal.education.forms' defaultMessage='' />
              </div>
             
            </div>
            <div className="right-actions">
            </div>
          </div>
            <div className='table-container'> 
              <table>
                <thead>
                  <tr>
                    <th width='6%'><FormattedMessage id='common.serial.no' defaultMessage='' /></th>
                    <th><FormattedMessage id='formName.text' defaultMessage=''/></th>
                    <th><FormattedMessage id='common.date.text' defaultMessage='' /></th>
                    <th><FormattedMessage id='status.text' defaultMessage='' /></th>
                    <th width='6%'><FormattedMessage id='actions.text' defaultMessage='' /></th>
                  </tr>
                </thead>
                <tbody>
                  {(this.props.studentFormList || []).filter(obj => 
                    obj.isPublishParentPortal === 1 ).map((filteredObj, i) => {
                    return <tr key={'reg' + i}>
                      <td>{ i+1 }</td>
                      <td style={{fontWeight:"600"}}>{ getUserDefinedName(filteredObj.programName) || '-' }</td>

                      <td>{ filteredObj.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(filteredObj.dateCreated), format }) : '-' }</td>
                      
                      <td>
                        {(filteredObj.isFormActive || (this.props.adminToken && this.props.adminToken.token)) && 
                          (this.props.enrolledForm && this.props.enrolledForm.indexOf(filteredObj.registrationFormSettingId)) > -1 ? 
                            // <div className="link-for-form"
                          
                            //   onClick={() => {
                            //     // const v = this.props.registrationList;
                            //     const itemf = filter(this.props.registrationList || [], { registrationFormSettingId : e.registrationFormSettingId})
                            //     this.props.history.push({
                            //       pathname: "/admin/register",
                            //       state: {
                            //         formId: e.registrationFormSettingId,
                            //         orgRegistrationId: itemf[0].orgRegistrationId,
                            //       },
                            //     });
                            //   }}
                            // >
                            //  Edit
                            // </div>
                            'Already Enrolled'
                            : 
                            <div className="link-for-form"
                              type="primary"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: "/admin/register",
                                  state: {
                                    formId: filteredObj.registrationFormSettingId,
                                  },
                                });
                              }}
                            >
                              Register
                            </div>
                          }
                      </td>
                    
                      <td style={{paddingLeft: '50px'}}>
                        <Dropdown overlay={actionMenu} trigger={['click']} >
                          <i className="fi fi-rs-menu-dots-vertical"/>
                        </Dropdown>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          
            <div className="footer">
              <div className="f-left"></div>
              <div className="f-right"></div>
            </div>
        </div>
      </Fragment>
    );
  };
};

  


export default injectIntl(OpenRegistrationComp);
