import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EnrolledComp from "../../components/Enrolled";
import { getOrgRegistration } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../actions/commonActions';

class Enrolled extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      templateType: linkProps.templateType,
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true,
    // });
    const companyInfo = this.props.companyInfo || {};
    const customerInfo = this.props.customerInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1, pageSize: 25,
      contactId: (customerInfo.boContactList[0] || {}).contactId,
      customerId: customerInfo.customerId
    };
    this.props.getOrgRegistration(payload);
  }
  componentWillReceiveProps(props) {
   
  }

  render() {
    return <EnrolledComp  {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}>

    </EnrolledComp>;
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    customerInfo: state.common.customerInfo,
    registrationList: state.school.registrationList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getOrgRegistration
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Enrolled);
