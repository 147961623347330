import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import { TextBox } from '../general/TextBox';
import { CustomButton } from '../general/CustomButton';
import { validateEmail } from '../../utils';
import './activationPending.scss';

function ActivationPending(props) {
  const { username, emailClass, emailInvalidClass, updateState, intl, history } = props;
  return (
    <Row className='forgot-wrapper' type='flex' justify='center' align='middle'>
      <Col md={6} xs={24} sm={24} className="forgot-panel">
        <h4 align="center">
          <FormattedMessage id='activation.panding.tile' defaultMessage='' />
        </h4>
        <div style={{textAlign: 'justify'}}>
        <FormattedMessage id='activation.panding.message' defaultMessage='' />
        </div>
        <br/>
        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(props); }}>
          <Row className='pb15'>
            <Col span={3} className='forgot-lock-icon'>
                <i className='fi fi-rr-envelope'></i>
            </Col>
            <Col span={21}>
              <TextBox
                placeholder={intl.formatMessage(
                  { id: 'forgot.email.placeholder', defaultMessage: '' }
                )}
                type='text'
                value={username}
                className={emailClass || 'forgot-form-input'}
                invalidClassName={emailInvalidClass || 'forgot-form-input-error'}
                validator={(val) => { return validateEmail(val); }}
                onChange={(e) => {
                  updateState({
                    email: e.target.value,
                    emailClass: 'forgot-form-input'
                  })
                }}
              />
            </Col>
          </Row>
          <Form.Item>
            <Row className='mt25'>
              <Col span={24}>
                <CustomButton
                  type='primary'
                  htmlType='submit'
                  className='activate-send-email'
                  intlId='resend.activation.mail.text'
                  defaultMessage=''
                />
              </Col>
              <Col span={24}>
                <CustomButton
                  type='default'
                  htmlType='button'
                  className='forgot-goback'
                  intlId='forgot.btn.back'
                  defaultMessage=''
                  onClick={(e) => { e.preventDefault(); history.push('/') }}
                />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row >
  );
}

const handleSubmit = (payload) => {
  if (payload.username && validateEmail(payload.username)) {
    payload.resendActivationLink(payload , payload.history);
  }
}

export default injectIntl(ActivationPending);
