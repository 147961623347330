import React, { Component, Fragment } from "react";
// import config from "../../config/environmentConfig";
import { FormattedMessage, injectIntl } from "react-intl";
import { getMomentDateForUIReadOnly } from "../../utils";
import { CONSTANTS } from '../../static/constants';
import { Button } from 'antd';
import * as filter from 'lodash.filter';
import { NavLink } from "react-router-dom";
//import RegistrationFormSetting from '../../../containers/modal/modalBody/settings/RegistrationFormSetting';
//import ShareRegistrationFormCompo from '../../../components/modal/modalBody/settings/ShareRegistrationForm';

class MembershipComp extends Component {

  render() {
    const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    return (
      <Fragment>
         <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
           MemberShip
          </div>
        
        </div>
        <div className="top-view-right">
          <Button className="ant-btn  ant-btn ant-btn-default" style={{backgroundColor: '#1CB961',border: '1px solid #1CB961',color: '#ffffff'}}>

        <NavLink to = '/admin/profile'>
            View Profile
          </NavLink>
          </Button>
        </div>
      </div>
        {/* <div style={{float: 'right'}} className= 'pr20 pb20 pt20'>
          <NavLink to = '/admin/profile'>
            View Profile
          </NavLink>
        </div> */}
        <div className="view-container">
        
        <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                {/* <FormattedMessage id='Fee list' defaultMessage='' /> */}
                MemberShip detail
              </div>
             
            </div>
            <div className="right-actions">
            </div>
          </div>
           
              <div className='table-container'>
                <table>
                  <thead>

                    <tr>
                      <th> S.no</th>
                      <th> <FormattedMessage id='programName.text' defaultMessage='' /></th>
                      <th> <FormattedMessage id='setting.student.registration.list.date' defaultMessage='' /></th>
                      <th> Action</th>
                    </tr>
                  </thead>
                    
                  <tbody>
                    {(filter(this.props.membershipFormList || [], { isPublishParentPortal: 1 }) || []).map((e, i) => {
                      //  return (registationObj.studentList || []).map((e, j) => {isPublishParentPortal
                      return <tr key={'std' + i}>
                        <td>{i+1}</td>
                        <td style={{width: '50%'}}><span>{e.programName || '-'}</span></td>

                        <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : '-'}</td>

                        <td>
                          {(e.isFormActive || (this.props.adminToken && this.props.adminToken.token)) ?
                          (this.props.enrolledForm && this.props.enrolledForm.indexOf(e.registrationFormSettingId) > -1) ? <span style={{ background: '000080', padding: '5px' }}>Already Enrolled</span>:
                            <Button type="primary" style={{cursor: (this.props.enrolledForm && this.props.enrolledForm.indexOf(e.registrationFormSettingId)>-1) ? 'no-drop': 'pointer'}} onClick={() => {
                              // let url = `https://${this.props.subdomainName}/app/register?formId=${e.registrationFormSettingId}`
                              // Object.assign(document.createElement('a'), {
                              //   target: '_blank',
                              //   href: url,
                              // }).click();
                             
                              this.props.history.push({
                                pathname: '/admin/register-member',
                                state: {
                                  formId: e.registrationFormSettingId,
                                }
                              })
                            }}> Register</Button> :
                            <span><b>Registration Closed</b></span>
                          } </td>
                      </tr>
                      // })
                    })}
                  </tbody>
                </table>
              </div>
           

          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right"></div>
          </div>
        </div>
      </Fragment>
    );
  };
};

export default injectIntl(MembershipComp);
