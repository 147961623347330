import axios from "axios";
import config from "../../config/environmentConfig";
import { SCHOOL_ACTIONS } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getSchoolAttendanceByClassId = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS
        }/attendance/getSchoolAttendanceByClassId?relationshipId=${payload.relationshipId}&classId=${payload.classId}&startDate=${payload.startDate}&endDate=${payload.endDate}&studentIdList=${payload.schoolStudentIdList}`
      )
      .then((res) => {
        dispatch({
          type: SCHOOL_ACTIONS.SCHOOL_ATTENDENCE_BY_CLASS_ID,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const resetAttendenceData = () => {
  return (dispatch) => {
    dispatch({ type: SCHOOL_ACTIONS.SCHOOL_ATTENDENCE_BY_CLASS_ID, data: [] });
  };
};

// export const getSchoolCount = (payload) => {
//   return (dispatch) => {
//     return axios
//       .get(
//         `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/school/countSchool?relationshipId=${payload.relationshipId}`
//       )
//       .then((res) => {
//         dispatch({
//           type: SCHOOL_ACTIONS.SCHOOL_COUNT,
//           data: res.data,
//         });
//       })
//       .catch((err) => {
//         if (!err.__isRetryRequest) {
//           showToasterMessage({
//             messageType: "error",
//             description:
//               lang[((err.response || {}).data || {}).message] ||
//               "Some error occurred",
//           });
//         }
//       });
//   };
// };

// export const saveSchool = (payload, props) => {
//   return (dispatch) => {
//     return axios
//       .post(
//         `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/school/saveOrUpdate`,
//         payload
//       )
//       .then((response) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({
//             messageType: "error",
//             description:
//               lang[((err.response || {}).data || {}).message] ||
//               "Some error occurred",
//           });
//         }
//       });
//   };
// };

// export const deleteSchool = (payload, props) => {
//   return dispatch => {
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/school/delete`, payload)
//       .then(res => {
//         // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         // props.getFees && props.getFees(props);
//         getSchool(props)(dispatch);
//         //props.getFeeCount && props.getFeeCount(props);
//        // showToasterMessage({ messageType: 'success', description: 'Term & Fee deleted successfully' });
//       })
//       .catch((err) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
//         }
//       })
//   }
// }
// export const resetPaginatedData = () => {
//   return (dispatch) => {
//     dispatch({ type: SCHOOL_ACTIONS.SCHOOL_LIST_PAGE_RESET });
//   };
// };

export const getClass = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentClassRef/getClassByStudentIds?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
        }&pageNumber=${(payload.pageNumber || 1) - 1}&formId=${payload.formId}&studentIdList=${payload.schoolStudentIdList}`
      )
      .then((res) => {
        dispatch({
          type: SCHOOL_ACTIONS.CLASS_LIST,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const getSchoolAttendanceByStudentId = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/attendance/getSchoolAttendanceByStudentId?relationshipId=${payload.relationshipId}&studentId=${payload.studentId}&startDate=${payload.startDate}&endDate=${payload.endDate}`
      )
      .then((res) => {
        dispatch({
          type: SCHOOL_ACTIONS.SCHOOL_ATTENDENCE_BY_STUDENT_ID,
          data: res.data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};
