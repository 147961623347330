import React from "react";
import { Button, Col, Drawer, Form, Row, Tabs, DatePicker } from "antd";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import { ErrorMsg } from "../../general/ErrorMessage";
import { FormattedMessage } from "react-intl";
import {
  formatDateForBackend,
  getCurrentDateForBackend,
  getMomentDateForUI,
} from "../../../utils";
import { CONSTANTS } from "../../../static/constants";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const isSmallScreen = window.innerWidth <= 600;

const AddFamilyMemberComp = (props) => {

  const closeDrawer = () => {
    props.updateHeaderState({
      drawer: {
        addFamilyMemberDrawerVisible: false,
      },
    });

    props.updateHeaderState({
        addFamilyMember: null
      });
  };

  const handleSubmit = () => {
    props.updateHeaderState({
      submittedOnce: true,
    });
    if (
      props.addFamilyMember?.firstName &&
      props.addFamilyMember?.lastName &&
      props.addFamilyMember.gender &&
      props.addFamilyMember?.familyRelationship &&
      props.addFamilyMember?.cellPhone &&
      props.addFamilyMember?.emailAddress &&
      props.addFamilyMember?.allergies &&
      (props.addFamilyMember?.allergies === 'No' || props.addFamilyMember?.allergiesDesc) &&
      props.addFamilyMember.dateOfBirth &&
      props.addFamilyMember.bloodGroup
    ) {
      const payload = {
        salutationName: props.addFamilyMember.titleName,
        salutationId: props.addFamilyMember.salutationId,
        firstName: props.addFamilyMember.firstName,
        middleName: props.addFamilyMember.middleName,
        lastName: props.addFamilyMember.lastName,
        preferCallName: props.addFamilyMember.preferCallName,
        contactId: props.addFamilyMember.contactId,
        cellPhone: props.addFamilyMember.cellPhone
          ? props.addFamilyMember.cellPhone
          : null,
        faxNumber: props.addFamilyMember.faxNumber,
        emailAddress: props.addFamilyMember.emailAddress,
        jobTitle: props.addFamilyMember.jobTitle,
        department: props.addFamilyMember.department,
        relationshipId: (props.companyInfo || {}).relationshipId,
        dateCreated: props.dateCreated || getCurrentDateForBackend(new Date()),
        isPrimaryContact:
          props.addFamilyMember.isPrimaryContact ||
          props.addFamilyMember.isPrimaryContact
            ? 1
            : 0,
        countryCallingCode: props.addFamilyMember.countryCallingCode || 91,
        familyRelationship: props.addFamilyMember.familyRelationship,
        isEnablePortalAccess:
          props.addFamilyMember.isEnablePortalAccess ||
          props.addFamilyMember.isEnablePortalAccess
            ? 1
            : 0,
        dateOfBirth: props.addFamilyMember.dateOfBirth
          ? formatDateForBackend(props.addFamilyMember.dateOfBirth)
          : null,
        allergies:
          props.addFamilyMember.allergies === "Yes"
            ? props.addFamilyMember.allergiesDesc
            : "No",
        gender: props.addFamilyMember.gender,
        bloodGroup: props.addFamilyMember.bloodGroup,
        facebookLink: props.addFamilyMember.facebookLink,
        instagramLink: props.addFamilyMember.instagramLink,
        twitterLink: props.addFamilyMember.twitterLink,
        linkedIn: props.addFamilyMember.linkedIn,
        docDetailList: props.docDetailList || [],
        contactType: "customer",
        version:
          props.addFamilyMember.version || props.addFamilyMember.version || 0,
      };
      if (props.customerInfo?.customerId) {
        payload.customerId = props.customerInfo?.customerId;
      }

      props.createContact(payload, props);
    }
  };

  let relationList = [
    { name: "Grandpa", value: "grandpa" },
    { name: "Grandma", value: "grandma" },
    { name: "Father", value: "father" },
    { name: "Mother", value: "mother" },
    { name: "Uncle", value: "uncle" },
    { name: "Aunt", value: "aunt" },
    { name: "Brother", value: "brother" },
    { name: "Sister", value: "sister" },
    { name: "Son", value: "son" },
    { name: "Daughter", value: "daughter" },
    { name: "Self", value: "self" },
    { name: "Other", value: "other" },
  ];

  return (
    <>
      <Drawer
        title={"Add Family Member"}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.drawer.addFamilyMemberDrawerVisible}
        footer={
          <>
            <Button defaultMessage="" type="default" onClick={closeDrawer}>
              Cancel
            </Button>
            <div>
              <Button type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </>
        }
      >
        <Row gutter={[16, 25]}>
          <Col span="12">
            <label className="input-label">
              First Name <span className="required">*</span>
            </label>
            <TextBox
              className="custom-text-box"
              value={props.addFamilyMember?.firstName}
              onChange={(e) => {
                props.updateHeaderState({
                  addFamilyMember: {
                    ...props.addFamilyMember,
                    firstName: e.target.value,
                  },
                });
              }}
              placeholder={"Enter First Name"}
            />

            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || props.addFamilyMember?.firstName;
              }}
              message={
                <FormattedMessage
                  id="modal.addFamilyMember.firstName"
                  defaultMessage=""
                />
              }
            />
          </Col>
          <Col span="12">
            <label className="input-label">
              Last Name <span className="required">*</span>
            </label>
            <TextBox
              className="custom-text-box"
              value={props.addFamilyMember?.lastName}
              onChange={(e) => {
                props.updateHeaderState({
                  addFamilyMember: {
                    ...props.addFamilyMember,
                    lastName: e.target.value,
                  },
                });
              }}
              placeholder={"Enter Last Name"}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || props.addFamilyMember?.lastName;
              }}
              message={
                <FormattedMessage
                  id="modal.addFamilyMember.lastName"
                  defaultMessage=""
                />
              }
            />
          </Col>
          <Col span="12">
            <div className="input-label">
              Gender<span className="required">*</span>{" "}
            </div>
            <Dropdown
              className="custom-dropdown-box"
              items={["Male", "Female", "Rather Not Say"]}
              value={props.addFamilyMember?.gender}
              placeholder="Select Gender"
              onSelect={(selectedValue, option) => {
                props.updateHeaderState({
                  addFamilyMember: {
                    ...props.addFamilyMember,
                    gender: selectedValue,
                  },
                });
              }}
              allowClear={true}
              onClear={() => {
                props.updateHeaderState({ allergies: undefined });
              }}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || props.addFamilyMember?.gender;
              }}
              message={
                <FormattedMessage
                  id="modal.addFamilyMember.gender"
                  defaultMessage=""
                />
              }
            />
          </Col>
          <Col span="12">
            <div className="input-label">
              Relationship<span className="required">*</span>{" "}
            </div>
            <Dropdown
              className="custom-dropdown-box"
              items={relationList}
              optionKeyName="value"
              valueKeyName="name"
              value={props.addFamilyMember?.familyRelationship}
              placeholder="Select Gender"
              onSelect={(selectedValue, option) => {
                props.updateHeaderState({
                  addFamilyMember: {
                    ...props.addFamilyMember,
                    relationshipName: option.name,
                    familyRelationship: option.key,
                  },
                });
              }}
              allowClear={true}
              onClear={() => {
                props.updateHeaderState({ allergies: undefined });
              }}
            />
            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedOnce ||
                  props.addFamilyMember?.familyRelationship
                );
              }}
              message={
                <FormattedMessage
                  id="modal.addFamilyMember.familyRelationship"
                  defaultMessage=""
                />
              }
            />
          </Col>

          <Col span="24">
            <label className="input-label">
              {"Phone"} <span className="required">*</span>
            </label>

            <div className="input-currency-control">
              <span className="currency-tag2" style={{ marginTop: "0px" }}>
                +91-
              </span>
              <TextBox
                className="custom-text-box"
                value={props.addFamilyMember?.cellPhone}
                onChange={(e) => {
                  props.updateHeaderState({
                    addFamilyMember: {
                      ...props.addFamilyMember,
                      cellPhone: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || props.addFamilyMember?.cellPhone;
              }}
              message={
                <FormattedMessage
                  id="modal.addFamilyMember.cellPhone"
                  defaultMessage=""
                />
              }
            />
          </Col>

          <Col span="24">
            <label className="input-label">
              {"Email"} <span className="required">*</span>
            </label>
            <TextBox
              className="custom-text-box"
              value={props.addFamilyMember?.emailAddress}
              onChange={(e) => {
                props.updateHeaderState({
                  addFamilyMember: {
                    ...props.addFamilyMember,
                    emailAddress: e.target.value,
                  },
                });
              }}
              placeholder={"hello@yourname.com"}
            />
            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedOnce || props.addFamilyMember?.emailAddress
                );
              }}
              message={
                <FormattedMessage
                  id="modal.addFamilyMember.emailAddress"
                  defaultMessage=""
                />
              }
            />
          </Col>

          <Col span="12">
            <div className="input-label">
              Allergy<span className="required">*</span>{" "}
            </div>
            <Dropdown
              className="custom-dropdown-box"
              items={["Yes", "No"]}
              value={props.addFamilyMember?.allergies}
              placeholder="Select Gender"
              onSelect={(selectedValue, option) => {
                props.updateHeaderState({
                  addFamilyMember: {
                    ...props.addFamilyMember,
                    allergies: selectedValue,
                  },
                });

                
              }}
              allowClear={true}
              onClear={() => {
                props.updateHeaderState({ allergies: undefined });
              }}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || props.addFamilyMember?.allergies;
              }}
              message={
                <FormattedMessage
                  id="modal.addFamilyMember.allergies"
                  defaultMessage=""
                />
              }
            />
          </Col>

          {props.addFamilyMember?.allergies === "Yes" && (
            <Col span="12">
              <label className="input-label">
                Specify Allergy <span className="required">*</span>
              </label>
              <TextBox
                className="custom-text-box"
                value={props.addFamilyMember?.allergiesDesc}
                onChange={(e) => {
                  props.updateHeaderState({
                    addFamilyMember: {
                      ...props.addFamilyMember,
                      allergiesDesc: e.target.value,
                    },
                  });
                }}
                placeholder={"Specify the allergy"}
              />

              <ErrorMsg
                validator={() => {
                  return (
                    !props.submittedOnce || props.addFamilyMember?.allergiesDesc
                  );
                }}
                message={
                  <FormattedMessage
                    id="modal.addFamilyMember.allergiesDesc"
                    defaultMessage=""
                  />
                }
              />
            </Col>
          )}

          <Col span="12">
            <label className="input-label">
              DOB <span className="required">*</span>
            </label>
            <DatePicker
              className="custom-date-picker"
              allowClear={false}
              format={format}
              defaultValue={getMomentDateForUI({
                date: props.addFamilyMember?.dateOfBirth,
                format,
              })}
              onChange={(selectedDate) => {
                props.updateState({
                  addFamilyMember: {
                    ...props.addFamilyMember,
                    dateOfBirth: selectedDate,
                  },
                });
              }}
              placeholder="endDate"
            />

            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedOnce || props.addFamilyMember?.dateOfBirth
                );
              }}
              message={
                <FormattedMessage
                  id="modal.addFamilyMember.dateOfBirth"
                  defaultMessage=""
                />
              }
            />
          </Col>

          <Col span="12">
            <div className="input-label">
              Blood Group<span className="required">*</span>{" "}
            </div>
            <Dropdown
              className="custom-dropdown-box"
              items={[
                "A+",
                "A-",
                "B+",
                "B-",
                "O+",
                "O-",
                "AB+",
                "AB-",
                "Other",
              ]}
              value={props.addFamilyMember?.bloodGroup}
              placeholder="Select Blood Group"
              onSelect={(selectedValue, option) => {
                props.updateHeaderState({
                  addFamilyMember: {
                    ...props.addFamilyMember,
                    bloodGroup: selectedValue,
                  },
                });
              }}
              allowClear={true}
              onClear={() => {
                props.updateHeaderState({ allergies: undefined });
              }}
            />
            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedOnce || props.addFamilyMember?.bloodGroup
                );
              }}
              message={
                <FormattedMessage
                  id="modal.addFamilyMember.bloodGroup"
                  defaultMessage=""
                />
              }
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default AddFamilyMemberComp;
