import React, { Fragment } from 'react';
import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, LOCAL_STORAGE_KEYS } from '../../static/constants';
import { showToasterMessage, addToLocalStorage } from '../../utils';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import * as find from 'lodash.find';
import { Dropdown } from '../../components/general/Dropdown';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchStudentFatherEmail = (payload, props) => {
  // let FY = getFinancialYear(payload.companyInfo);
  // let url = payload.type === 'fatherEmail' ? `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByFatherEmail?fatherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}` :
  //   `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByMotherEmail?motherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}`;
  let url = payload.type === 'fatherEmail' ? `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByFatherEmail?fatherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}` :
    `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByMotherEmail?motherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}`;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(url)
      .then(res => {
        if (res?.data?.customerId) {
          let orgRegistration = res.data.orgRegistrationList[0] || {};
         // let contact = res.data.boContactList[0] || {};
         // let contactMother = res.data.boContactList[1] || {};
          let location = res.data.boLocationList[0] || {};
          const contact = find(res.data.boContactList || [], { emailAddress: res.data.fatherEmail  }) || {};
          const contactMother = find(res.data.boContactList || [], { emailAddress: res.data.motherEmail  }) || {};
          props.showModal({
            maskClosable: true,
            isClosable: false,
            keyboard: false,
            modalBody: <Fragment>
              <div className="warning-message">
                <FormattedMessage id='registration.form.email.alreadyExists' defaultMessage='' />
              </div>
              <br />
              <div className="button-action" style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: '1px solid #e5e5e5',
                paddingTop: '15px'
              }}>
                <Button className="ant-btn-default"
                  onClick={() => {
                    if (payload.type === 'fatherEmail') {
                      props.updateState({
                        fatherEmail: ''
                      })
                    } else {
                      props.updateState({
                        motherEmail: ''
                      })
                    }

                    props.hideModal();
                  }}>  <FormattedMessage id='no' defaultMessage='' />
                </Button>
                <Button className="ant-btn-primary"
                  onClick={() => {

                    let studentList = [];
                    if (orgRegistration.studentList && orgRegistration.studentList.length) {
                      studentList = (orgRegistration.studentList || []).map((e) => {
                        const childContact = find(contact.boContactChildList || [], { contactChildId: e.contactChildId }) || {};
                        e.firstName = childContact.firstName;
                        e.lastName = childContact.lastName;
                        e.gender = childContact.gender;
                        e.allergies = childContact.allergies;
                        e.dob = childContact.dob ? new Date(childContact.dob) : '';
                        e.gradefee = e.courseFee ? Number(e.courseFee) : null;
                        return e;
                        //e.gradefee = (props.formSetting || {}).gradeFees[e.grade];
                      })

                    } else if (contact.boContactChildList && contact.boContactChildList.length) {
                      studentList = (contact.boContactChildList || []).map((childContact) => {
                        childContact.dob = childContact.dob ? new Date(childContact.dob) : '';
                        return childContact;
                      })
                    }

                    props.updateState({
                      ...orgRegistration,
                      familyDoctorPhone: orgRegistration.familyDoctorPhNumber,
                      customerId: res.data.customerId,
                      contactId: contact.contactId,
                      contactVersion: contact.version,
                      fatherFirstName: res.data.fatherFirstName,
                      fatherLastName: res.data.fatherLastName,
                      fatherMobile: res.data.fatherMobile,
                      fatherEmail: res.data.fatherEmail,
                      motherFirstName: res.data.motherFirstName,
                      motherLastName: res.data.motherLastName,
                      motherMobile: res.data.motherMobile,
                      motherEmail: res.data.motherEmail,

                      fatherDOB: res.data.fatherDateOfBirth ? new Date(res.data.fatherDateOfBirth) : '',
                      motherDOB: res.data.motherDateOfBirth ? new Date(res.data.motherDateOfBirth) : '',

                      studentList: studentList,

                      streetAddress1: location.streetAddress1,
                      streetAddress2: location.streetAddress2,
                      cityName: location.cityName,
                      countryId: location.countryId,
                      countryName: location.countryName,
                      stateName: location.stateName,
                      zipCode: location.zipCode,
                      locationId: location.locationId,
                      locationVersion: location.version,
                      // primaryContact: contact.primaryContactForInvoicing,
                      primaryContact: res.data.primaryContact,

                      // motherFirstName: contactMother.firstName,
                      // motherLastName: contactMother.lastName,
                      // motherMobile: contactMother.cellPhone,
                      // motherEmail: contactMother.emailAddress, 
                      // motherDOB: contactMother.dateOfBirth ? new Date(contactMother.dateOfBirth) : '',
                      motherContactId: contactMother.contactId,
                      motherContactVersion: contactMother.version,
                      
                    })
                    props.hideModal();
                  }}>  <FormattedMessage id='yes' defaultMessage='' /> </Button>
              </div>
            </Fragment>,
            hideFooter: true
          })
        }
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || ((err.response || {}).data || {}).message })
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

// export const fetchRegistrationFormSetting = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     dispatch({ type: SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/getFormSetting?relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`)
//       .then(res => {
//         const { data } = res
//         dispatch({ type: SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING, data });
//         //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING;
//         }
//       })
//   }
// }


export const saveRegistration = (requestObj, props, amount) => {
  let selectedFormId = props.selectedFormId || 0;
  // let formData = new FormData();
  // formData.append('customer', JSON.stringify(requestObj, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/editOnlineCustomer`,
        requestObj
      )
      .then(res => {
        const { data } = res;
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
       // showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] });
        if (data?.customerId) {
          dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: data });
          addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(data));
        }
        
        props.pushModalToStack({
          maskClosable: true,
          isClosable: false,
          keyboard: false,
		  title:'Register',
          modalBody: <Fragment>
            <div className="warning-message">
              Profile saved successfully. Select the program you want to register. Click cancel if you do not want to register.
            </div>
            <br />
            <div style={{ textAlign: 'center' }}>
              <Dropdown
                style={{ paddingTop: '5px' }}
                items={props.studentFormList || []}
                valueKeyName='formShortName'
                optionKeyName='registrationFormSettingId'
                placeholder='Select Program'
                value={selectedFormId}
                onSelect={(formId) => {
                  props.updateState({ selectedFormId: formId });
                  selectedFormId = formId;
                  let registeredForm = find(props.registrationList || [], { registrationFormSettingId: formId });
                  if (registeredForm && registeredForm.registrationFormSettingId) {
                    props.popModalFromStack();
                    props.pushModalToStack({
                      maskClosable: true,
                      isClosable: false,
                      keyboard: false,
                      modalBody: <Fragment>
                        <div className="warning-message">
                          You have already registerd for the selected program.
                        </div>
                        <br />

                        <div style={{ textAlign: 'center' }}>
                          <div className="button-action" style={{ justifyContent: 'space-between', borderTop: '1px solid #e5e5e5', paddingTop: '15px', textAlign: 'center' }}>
                            <Button className="ant-btn-primary" onClick={() => {
                              props.history.push('/admin/dashboard');
                              props.popModalFromStack();
                            }}>  <FormattedMessage id='ok' defaultMessage='' />
                            </Button>

                          </div>
                        </div>
                      </Fragment>,
                      hideFooter: true
                    })

                  }
                }}
              />
            </div>
            <br />
            <div className="button-action" style={{ justifyContent: 'space-between', borderTop: '1px solid #e5e5e5', paddingTop: '15px', textAlign: 'center' }}>
              <Button className="ant-btn-default" onClick={() => {
                props.history.push('/admin/dashboard');
                props.popModalFromStack();
              }}>  <FormattedMessage id='confirmation.cancel' defaultMessage='' />
              </Button>

              <Button className="ant-btn-primary" style={{ marginLeft: '55%' }} onClick={() => {
                if (!selectedFormId) {
                  return showToasterMessage({ description: 'Please select a program' });
                } else {
                  props.history.push({
                    pathname: '/admin/register',
                    state: {
                      formId: selectedFormId,
                    }
                  })
                  props.popModalFromStack();
                }
              }}>  <FormattedMessage id='confirmation.submit' defaultMessage='' />
              </Button>
            </div>
          </Fragment>,
          hideFooter: true
        })

      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}