import React, { Fragment, Component } from 'react';
// import config from '../../../../../config/environmentConfig';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
// import { UploadOutlined } from '@ant-design/icons';
import { DatePicker, } from 'antd';
import { validateEmail, getCurrentDateForBackend, formatDateForBackend, getMomentDateForUI, validateMobileNumber } from '../../../../../utils'
import { DropdownRef } from '../../../../general/Dropdown';
import { MODAL_TYPE, CONSTANTS } from '../../../../../static/constants';
import { CustomButton } from '../../../../general/CustomButton';
import { TextBox } from '../../../../general/TextBox';
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
import { ErrorMsg } from '../../../../general/ErrorMessage';
import { showToasterMessage } from '../../../../../utils';
import * as find from 'lodash.find';
import moment from 'moment';
// import { ImageCropper } from '../../../../general/ImageCropper';
// import { CustomUploader } from "../../../../general/CustomUploader";
// import Resizer from "react-image-file-resizer";
// import FacebookIcon from "../../../../../assets/images/facebook-icon.png";
// import TwitterIcon from "../../../../../assets/images/twitter-icon.png";
// import LinkedinIcon from "../../../../../assets/images/linkedin-icon.png";
// import InstagramIcon from "../../../../../assets/images/instagram-icon.png";
// const { Dragger } = Upload;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class ContactComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profileIcon: '',
      titleName: (((props.data || {}).formData || {}).contactData || {}).salutationName,
      salutationId: (((props.data || {}).formData || {}).contactData || {}).salutationId,
      firstName: (((props.data || {}).formData || {}).contactData || {}).firstName,
      contactId: (((props.data || {}).formData || {}).contactData || {}).contactId,
      middleName: (((props.data || {}).formData || {}).contactData || {}).middleName,
      lastName: (((props.data || {}).formData || {}).contactData || {}).lastName,
      preferCallName: (((props.data || {}).formData || {}).contactData || {}).preferCallName,
      workPhone: (((props.data || {}).formData || {}).contactData || {}).workPhone,
      cellPhone: (((props.data || {}).formData || {}).contactData || {}).cellPhone,
      faxNumber: (((props.data || {}).formData || {}).contactData || {}).faxNumber,
      email: (((props.data || {}).formData || {}).contactData || {}).emailAddress,
      jobTitle: (((props.data || {}).formData || {}).contactData || {}).jobTitle,
      department: (((props.data || {}).formData || {}).contactData || {}).department,
      isPrimaryContact: (((props.data || {}).formData || {}).contactData || {}).isPrimaryContact ? true : false,
      dateCreated: (((props.data || {}).formData || {}).contactData || {}).dateCreated,
      familyRelationship: (((props.data || {}).formData || {}).contactData || {}).familyRelationship,
      isEnablePortalAccess: (((props.data || {}).formData || {}).contactData || {}).isEnablePortalAccess ? true : false,
      allergies: (((props.data || {}).formData || {}).contactData || {}).allergies,
      dateOfBirth: (((props.data || {}).formData || {}).contactData || {}).dateOfBirth,
      gender: (((props.data || {}).formData || {}).contactData || {}).gender,
      bloodGroup: (((props.data || {}).formData || {}).contactData || {}).bloodGroup,
      fileList: [],
      docDetailList: (((props.data || {}).formData || {}).contactData || {}).docDetailList,
      conatctImgAWSObj: ((((props.data || {}).formData || {}).contactData || {}).docDetailList || [])[0] || {},
      conatctImgAWSBucket: ((((props.data || {}).formData || {}).contactData || {}).docDetailList || [])[0] || {},
      countryCallingCode: (((props.data || {}).formData || {}).contactData || {}).countryCallingCode || (this.props.companyInfo || {}).countryCallingCode,
      contactType: (((props.data || {}).formData || {}).contactData || {}).contactType || CONSTANTS.CONTACT_TYPE_CUSTOMER,
      clientOnlineUserAccountId: props.customerInfo.clientOnlineUserAccountId,
    }

  }

  addItemConfirmationModal = (props, text, modalType) => {
    const { companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = <span>
      <FormattedMessage
        id='addItem.confirmation'
        values={{ value: `${text} to ` }}
        defaultMessage=''
      />
      {labelName} ?
    </span>;

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        addFunc = props.addSalutation;
        break;
      }
      default: {
        addFunc = () => { }
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId })
        props.popModalFromStack();
      },
    };
    props.pushModalToStack(modalData);
  }

  getModalTitle = (modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        title = <FormattedMessage id='addItem.text.salutation' defaultMessage='' />;
        break;
      }
      default:
        title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
    }
    return title;
  }

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    // const { showModal } = props;

    if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            props.pushModalToStack(data);
          }
        }
      };
      props.pushModalToStack(data);
    }
  }

  deleteClickHandler = (props, modalType, payload) => {
    const { companyInfo } = props;
    const modalBody =
      <FormattedMessage
        id='deleteItem.confirmation'
        defaultMessage=''
        values={{ value: payload.text }}
      />;

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        deleteFunc = props.deleteSalutation;
        break;
      }
      default: {
        deleteFunc = () => { }
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
    };
    props.pushModalToStack(modalData);
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const { intl } = this.props;

    const self = this;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    // const dummyRequest = async ({ file, onSuccess }) => {
    //   setTimeout(() => {
    //       onSuccess("ok");
    //   }, 0);
    // }
    //     //self = this;
    //     const uploadProps = {
    //       name: 'file',
    //       multiple: false,
    //       customRequest: dummyRequest,
    //       beforeUpload: (file) => {
    //         const isImage = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg';
    //         if (!isImage) {
    //           //message.error(`${file.name} is not a image file`);
    //           showToasterMessage({ messageType: 'error', description: `${file.name} is not a image file` });
    //         }
    //         return isImage || Upload.LIST_IGNORE;
    //       },
    //       onChange(info) {
    //         const { status } = info.file;
    //         if (status !== 'uploading') {
    //         }
    //         if (status === 'done') {
    //           // let fileList = self.props.fileList || [];
    //           // fileList.push(info.file.originFileObj);
    //           // self.setState({
    //           //   fileToUpload: info.file.originFileObj,
    //           //   fileList
    //           // })
    //           self.props.showModal({
    //             title: <Fragment>
    //                 <div className='ant-modal-title'>Crop Image</div>
    //             </Fragment>,
    //             modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
    //             }} handleSubmit={(newUrl) => {
    //               // self.props.uploadImage({
    //               //       objectKey: props.companyInfo.logoAwsObjectKey,
    //               //       bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
    //               //       file: base64Toblob(newUrl)
    //               //   });
    //                // updateProfileIcon(newUrl);
    //                 self.setState({
    //                   fileToUpload: base64Toblob(newUrl),
    //                 })

    //             }}{...self.props} url={info.target.result} />,
    //             width: 800,
    //             hideFooter: true,
    //             wrapClassName: 'Upload-profile-modal'
    //         })
    //         } else if (status === 'error') {
    //         }
    //       },
    //       onDrop(e) {
    //       },
    //     };
  //   const customProps = {
  //     type: CONSTANTS.TYPE_IMAGE,
  //     imgUrl: this.state.conatctImgAWSObj.awsKeyName ? getAWSFileURL(this.state.conatctImgAWSObj.awsKeyName, this.state.conatctImgAWSBucket) : this.state.profileIcon,
  //     height: 100,
  //     width: 100,
  //     imgStyle: { height: 100, width: 100 },
  //     name: 'file',
  //     action: null,
  //     buttonName: <FormattedMessage id='button.uploadCustomerPO.label' defaultMessage='' />,
  //     onChange: (file) => {
  //       if (file.file.originFileObj) {
  //         new Promise((resolve) => {
  //           Resizer.imageFileResizer(
  //             file.file.originFileObj,
  //             230,
  //             90,
  //             "png",
  //             100,
  //             0,
  //             (uri) => {
  //               //updateProfileIconUrl(uri);
  //               const fileData = base64Toblob(uri);

  //               var reader = new FileReader();

  //               reader.onload = function (e) {
  //                 self.props.showModal({
  //                   title: <Fragment>
  //                     <div className='ant-modal-title'>Crop Image</div>
  //                   </Fragment>,
  //                   modalBody: <ImageCropper {...self.props} cropperRef={this.cropperRef} onClick={(e) => {
  //                   }} handleSubmit={(newUrl) => {
  //                     self.setState({
  //                       fileToUpload: base64Toblob(newUrl),
  //                       profileIcon: newUrl
  //                     })

  //                   }} url={e.target.result} />,
  //                   width: 800,
  //                   hideFooter: true,
  //                   wrapClassName: 'Upload-profile-modal'
  //                 })
  //               }

  //               reader.readAsDataURL(fileData); // convert to base64 string

  //             },
  //             "base64"
  //           );
  //         });
  //       }
  //       //   file.file.originFileObj = file.file.originFileObj || {};
  //       //   file.file.originFileObj.name = `${file.file.originFileObj.name} (${bytesToSize(file.file.originFileObj.size)})`;
  //       //   this.setState({
  //       //     fileToUpload: file.file.originFileObj
  //       //   })
  //     }
  //   }
  //   const closeDrawer = () =>{
  //     this.state.updateState({
  //       editDrawerVisible:false
  //     })
  // }

    let relationList = [
      { name: 'Grandpa', value: 'grandpa' },
      { name: 'Grandma', value: 'grandma' },
      { name: 'Father', value: 'father' },
      { name: 'Mother', value: 'mother' },
      { name: 'Uncle', value: 'uncle' },
      { name: 'Aunt', value: 'aunt' },
      { name: 'Brother', value: 'brother' },
      { name: 'Sister', value: 'sister' },
      { name: 'Son', value: 'son' },
      { name: 'Daughter', value: 'daughter' },
      { name: 'Self', value: 'self'},
      { name: 'Other', value: 'other' },
    ]

    
    return (
      <Fragment>
        <Form  {...formItemLayout} layout="horizontal" onSubmit={(e) => this.handleSubmit(e, self)} hideRequiredMark={true}>
          <div className='conts-scrolls'>
            <div className="modal-content employee-modal-control">
              {/* {!this.state.isTeacher ? <Form.Item label={(
              <Tooltip placement="bottom" title={<FormattedMessage id='contact.primary.message' defaultMessage="" />}>
                <span className="tooltip-title"><FormattedMessage id='contact.primary' defaultMessage='' /></span>
              </Tooltip>
            )} colon={false}>
              {getFieldDecorator('isDefault', {
                initialValue: (this.state || {}).isPrimaryContact || 0
              })(<Checkbox checked={(this.state || {}).isPrimaryContact ? true : false} disabled={(((this.props.data || {}).formData || {}).contactData || {}).isPrimaryContact ? true : false} onClick={(e) => {
                this.setState({
                  isPrimaryContact: e.target.checked ? 1 : 0
                })
              }} className='login-form-checkbox'></Checkbox>)}
            </Form.Item> : ''
            } */}
              {/* <Form.Item label={(
                <Tooltip placement="bottom" title={<FormattedMessage id='Enable Portal Access' defaultMessage="" />}>
                  <span className="tooltip-title">Enable Portal Access </span>
                </Tooltip>
              )} colon={false}>
                {getFieldDecorator('isEnablePortalAccess', {
                  initialValue: (this.state || {}).isEnablePortalAccess || 0
                })(<Checkbox checked={(this.state || {}).isEnablePortalAccess ? true : false} disabled={(((this.props.data || {}).formData || {}).contactData || {}).isEnablePortalAccess ? true : false} onClick={(e) => {
                  this.setState({
                    isEnablePortalAccess: e.target.checked ? 1 : 0
                  })
                }} className='login-form-checkbox'></Checkbox>)}
              </Form.Item> */}
              
              {/* <Form.Item label={<FormattedMessage id='contact.title' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('titleName', {
                initialValue: this.state.titleName || ''
              })
                (<span />)}
            </Form.Item> */}

              <Form.Item label={(<span><FormattedMessage id='contact.firstName' defaultMessage='' /><span className="required">*</span> </span>)} colon={false}>
                {/* <Form.Item label={(<span><FormattedMessage id='contact.firstName' defaultMessage='' /></span>)} colon={false}> */}
                {getFieldDecorator('firstName', {
                  initialValue: this.state.firstName || '',
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='contact.firstName.message' />,
                    }]
                })(<TextBox maxLength={25} placeholder={this.props.intl.formatMessage({ id: 'contact.firstName.placeholder', defaultMessage: '' })} />)}
              </Form.Item>
              {/* <Form.Item label={<FormattedMessage id='contact.middleName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('middleName', {
                initialValue: this.state.middleName || ''
              })
                (<TextBox maxLength={25} placeholder={this.props.intl.formatMessage({ id: 'contact.middleName.placeholder', defaultMessage: '' } )} />)}
            </Form.Item> */}
              <Form.Item label={<span><FormattedMessage id='contact.lastName' defaultMessage='' /><span className="required">*</span></span>} colon={false}>
                {getFieldDecorator('lastName', {
                  initialValue: this.state.lastName || '',
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='contact.firstName.message' />,
                    }]
                })
                  (<TextBox maxLength={25} placeholder={this.props.intl.formatMessage({ id: 'contact.lastName.placeholder', defaultMessage: '' })} />)}
              </Form.Item>
              {/* <Form.Item label={<FormattedMessage id='contact.callName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('preferCallName', {
                initialValue: this.props.preferCallName || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'contact.callName.placeholder', defaultMessage: '' } )} />)}
            </Form.Item> */}
              <Form.Item label={<span>Gender<span className="required">*</span></span>} colon={false}>
                {getFieldDecorator('gender', {
                  initialValue: this.state.gender || '',
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='student.gender.missing' />,
                    }]
                })
                  (<DropdownRef
                    placeholder="Gender"
                    items={['Male', 'Female', 'Rather Not Say']}
                    onSelect={(selectedValue, option) => {
                      this.setState({
                        gender: selectedValue,
                      });
                      this.props.form.setFieldsValue({ 'gender': selectedValue });
                    }}
                  />)}
              </Form.Item>
              <Form.Item label={<span>Relationship<span className="required">*</span></span>} colon={false}>
                {getFieldDecorator('familyRelationship', {
                  initialValue: this.state.familyRelationship || '',
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='contact.relationship.message' />,
                    }]
                })
                  (<DropdownRef
                    placeholder={this.props.intl.formatMessage({ id: 'contact.relationship.message', defaultMessage: '' })}
                    items={relationList}
                    optionKeyName='value'
                    valueKeyName='name'
                    onSelect={(selectedValue, option) => {
                      this.setState({
                        relationshipName: option.name,
                        familyRelationship: option.key
                      });
                      this.props.form.setFieldsValue({ 'familyRelationship': selectedValue });
                      this.props.form.setFieldsValue({ 'relationshipName': option.name });
                    }}
                  />)}
              </Form.Item>

              <Form.Item className='hide' label={<FormattedMessage id='countryCallingCode.text' defaultMessage='' />} colon={false}>
                {getFieldDecorator('countryCallingCode', {
                  initialValue: this.props.countryCallingCode || '',
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='countryCallingCode.validationMessage' />,
                    }]
                })
                  (<DropdownRef
                    items={(this.props.countries || []).filter(el => { return el.countryCallingCode != null; }) || []}
                    optionKeyName='countryId'
                    valueKeyName='countryCallingCode'
                    valueKeyName2='countryName'
                    onSelect={(selectedValue, option) => {
                      const selectedCountry = find(this.props.countries, { countryId: selectedValue });
                      this.setState({
                        countryCallingCode: selectedCountry.countryCallingCode
                      });
                      this.props.form.setFieldsValue({ 'countryId': selectedValue });
                      this.props.form.setFieldsValue({ 'countryCallingCode': selectedCountry.countryCallingCode });
                    }}
                  />)}
              </Form.Item>


              {/* <Form.Item label={<FormattedMessage id='contact.workPhone' defaultMessage='' />} colon={false}>
              {getFieldDecorator('workPhone', {
                initialValue: this.state.workPhone || '',
                rules: [
                  {
                    pattern: /^[0-9\b]+$/,
                    message: 'Please enter number only',
                  }
                ]
              })
                (<TextBox maxLength={10} placeholder={this.props.intl.formatMessage({ id: 'contact.mobile.placeholder', defaultMessage: '' } )} />)}
            </Form.Item> */}
              <Form.Item label={<FormattedMessage id='contact.mobile' defaultMessage='' />} colon={false}>
                {getFieldDecorator('cellPhone', {
                  initialValue: this.state.cellPhone || '',
                  rules: [
                    {
                      pattern: /^[0-9\b]+$/,
                      message: 'Please enter number only.',
                    },
                    // {
                    //   pattern: CONSTANTS.PHONE_REGEX_EXP,
                    //   message: 'Mobile number should be 10 digits.',
                    // }
                  ]
                })
                  (<span className='phone-city'>
                    <DropdownRef
                      placeholder={this.props.intl.formatMessage({ id: 'countryCallingCode.text', defaultMessage: '' })}
                      items={(this.props.countries || []).filter(el => { return el.countryCallingCode != null; }) || []}
                      optionKeyName='countryId'
                      valueKeyName='countryCallingCode'
                      valueKeyName2='countryName'
                      value={this.state.countryCallingCode}
                      onSelect={(selectedValue, option) => {
                        const selectedCountry = find(this.props.countries, { countryId: selectedValue });
                        this.setState({
                          countryCallingCode: selectedCountry.countryCallingCode
                        });
                        this.props.form.setFieldsValue({ 'countryId': selectedValue });
                        this.props.form.setFieldsValue({ 'countryCallingCode': selectedCountry.countryCallingCode });
                      }}
                    />
                    <TextBox value={this.state.cellPhone} maxLength={10} className={(this.state || {}).invalidPhoneClass} placeholder={this.props.intl.formatMessage({ id: 'contact.mobile.placeholder', defaultMessage: '' })} onChange={(e) => {
                      this.setState({
                        cellPhone: e.target.value,
                        invalidPhoneClass: validateMobileNumber(e.target.value) ? '' : 'input-text-error'
                      });
                    }} />
                  </span>)}
                <ErrorMsg
                  validator={() => { return !((this.state || {}).submittedOnce && (this.state || {}).cellPhone && !validateMobileNumber((this.state || {}).cellPhone)); }}
                  message={intl.formatMessage(
                    { id: 'contact.mobileNumber.missing', defaultMessage: '' }
                  )}
                />
              </Form.Item>
              {/* <Form.Item label={<FormattedMessage id='contact.faxNumber' defaultMessage=''/>} colon={false}>
              {getFieldDecorator('faxNumber', {
                initialValue: this.state.faxNumber || ''
              })
                (<TextBox maxLength={15} placeholder={this.props.intl.formatMessage({ id: 'contact.faxNumber', defaultMessage: '' } )} />)}
            </Form.Item> */}
              <Form.Item label={(<span><FormattedMessage id='contact.email' defaultMessage='' /> </span>)} colon={false}>
                {getFieldDecorator('emailAddress', {
                  initialValue: this.state.email || '',
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: 'Email is required',
                  //   }
                  //   // {
                  //   //   pattern: CONSTANTS.EMAIL_REGEX_EXP,
                  //   //   message: 'Invalid email address.',
                  //   // }
                  // ]
                })
                  (<TextBox
                      maxLength={254}
                      value={this.email}
                      type='text'
                      className={(this.state || {}).invalidEmailClass}
                      placeholder={this.props.intl.formatMessage({ id: 'contact.email', defaultMessage: '' })}
                      onChange={(e) => {
                        this.setState({
                          email: e.target.value,
                          isEmailExist: false,
                          invalidEmailClass: validateEmail(e.target.value) ? '' : 'input-text-error'
                        });
                      }}
                      validator={(val) => {
                          return validateEmail(val);
                      }}
                      autoComplete="none"
                      onBlur={(e) => {
                        if (this.state.email && validateEmail(this.state.email)) {
                          let self = this;
                          this.props.doesEmailExist({ emailAddress: this.state.email }, this.props, ()=>{
                          self.props.form.setFieldsValue({ 'emailAddress': '' });
                            this.setState({
                              email: null,
                              isEmailExist: true
                            })
                          });
                        }}
                      }
                    />
                  )}
                <ErrorMsg
                  validator={() => { return !((this.state || {}).submittedOnce && (this.state || {}).email && !validateEmail((this.state || {}).email)); }}
                  message={intl.formatMessage(
                    { id: 'login.email.invalidEmail', defaultMessage: '' }
                  )}
                />
              </Form.Item>
              <Form.Item label={<><FormattedMessage id='allergies.label' defaultMessage='' /><span className="required">*</span></>} colon={false}>
                {getFieldDecorator('allergies', {
                  initialValue: this.state.allergies !== 'No' ?  'Yes' : 'No',
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='allergies.missing.message' />,
                    }]
                })
                (<DropdownRef
                  placeholder='Allergies(Yes/No)'
                  items={['Yes', 'No']}
                  onSelect={(selectedValue, option) => {
                    this.setState({
                      allergies: selectedValue,
                    });
                    this.props.form.setFieldsValue({ 'allergies': selectedValue });
                  }}
                />)}
                  {/* (<TextBox maxLength={45} placeholder={this.props.intl.formatMessage({ id: 'allergies.label', defaultMessage: '' })} />) */}
              </Form.Item>
              
              {
                this.state.allergies !== 'No' ?
                  <Form.Item label={<>Specify the Allergy<span className="required">*</span></>} colon={false}>
                    {getFieldDecorator('allergiesDesc', {
                      initialValue: this.state.allergies !== 'No'? this.state.allergies : '',
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage id='allergies.missing.message' />,
                        }],
                      // onChange: (e) =>{
                      //   this.setState({
                      //     allergiesDesc: e.target.value
                      //   })
                      // }
                    
                    })
                    (<TextBox maxLength={45} placeholder={'Specify the Allergy'} />)
                    }
                  </Form.Item>
                  :
                  ''
              }


              <Form.Item label={<FormattedMessage id='dob.text' defaultMessage='' />} colon={false}>
                {getFieldDecorator('dateOfBirth', {
                  initialValue: this.state.dateOfBirth ? getMomentDateForUI({ date: new Date(this.state.dateOfBirth), format }) : null
                })
                  (<DatePicker
                    style={{height: '38px'}}
                    allowClear={true}
                    format={format}
                    key='dateOfBirth'
                    defaultValue={this.state.dateOfBirth ? getMomentDateForUI({ date: new Date(this.state.dateOfBirth), format }) : null}
                    value={this.state.dateOfBirth ? getMomentDateForUI({ date: new Date(this.state.dateOfBirth), format }) : null}
                    onChange={(selectedDate) => {
                      this.setState({ dateOfBirth: selectedDate });
                    }}
                    size={0}
                    disabledDate={(current) => {
                      return current && current > moment().endOf('day');
                    }}
                  />)}
              </Form.Item>

              {/* <Form.Item label={<FormattedMessage id='age.text' defaultMessage='' />} colon={false}>
                {getFieldDecorator('age', {
                  initialValue: this.state.age ? this.state.age : null
                })
                (<TextBox maxLength={3} placeholder={this.props.intl.formatMessage({ id: 'age.text', defaultMessage: '' })} />)}
              </Form.Item> */}
              <Form.Item label={<span>Blood Group</span>} colon={false}>
                {getFieldDecorator('bloodGroup', {
                  initialValue: this.state.bloodGroup || '',
                })
                  (<DropdownRef
                    placeholder='Blood Group'
                    items={['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-', 'Other']}
                    onSelect={(selectedValue, option) => {
                      this.setState({
                        bloodGroup: selectedValue,
                      });
                      this.props.form.setFieldsValue({ 'bloodGroup': selectedValue });
                    }}
                  />)}
              </Form.Item>
              {/* <Form.Item label={<FormattedMessage id='facebook.text' defaultMessage='' />} colon={false}>
                {getFieldDecorator('facebookLink', {
                  initialValue: this.state.facebookLink || ''
                })
                  (<div className='socimida'><img src={FacebookIcon} alt="facebook" /> <TextBox maxLength={35} placeholder={this.props.intl.formatMessage({ id: 'facebookLink.text', defaultMessage: '' })} /></div>)}
              </Form.Item> */}
              {/* <Form.Item label={<FormattedMessage id='twitter.text' defaultMessage='' />} colon={false}>
                {getFieldDecorator('twitterLink', {
                  initialValue: this.state.twitterLink || ''
                })
                  (<div className='socimida'><img src={TwitterIcon} alt="Twitter" /><TextBox maxLength={35} placeholder={this.props.intl.formatMessage({ id: 'twitterLink.text', defaultMessage: '' })} /></div>)}
              </Form.Item> */}
              {/* <Form.Item label={<FormattedMessage id='linkedIn.text' defaultMessage='' />} colon={false}>
                {getFieldDecorator('linkedIn', {
                  initialValue: this.state.linkedIn || ''
                })
                  (<div className='socimida'><img src={LinkedinIcon} alt="Linkedin" /> <TextBox maxLength={35} placeholder={this.props.intl.formatMessage({ id: 'linkedInLink.text', defaultMessage: '' })} /></div>)}
              </Form.Item> */}
              {/* <Form.Item label={<FormattedMessage id='instagram.text' defaultMessage='' />} colon={false}>
                {getFieldDecorator('instagramLink', {
                  initialValue: this.state.instagramLink || ''
                })
                  (<div className='socimida'><img src={InstagramIcon} alt="Instagram" /><TextBox maxLength={35} placeholder={this.props.intl.formatMessage({ id: 'instagramLink.text', defaultMessage: '' })} /></div>)}
              </Form.Item> */}

              {/* <Form.Item className='por-pics' label={'Profile Image'} colon={false}>
                {getFieldDecorator('contactImg', {
                  initialValue: this.state.contactImg || ''
                })
                  //   (<Dragger {...uploadProps}>
                  //     <p className="ant-upload-drag-icon">
                  //         <UploadOutlined style={{ fontSize: '34px', color: '#7883b0' }} />
                  //     </p>
                  //     <p className="ant-upload-text" style={{ color: '#363b51' }}> Drag and Drop image </p>

                  //     <p className="ant-upload-text">or</p>
                  //     <p className="ant-upload-hint">
                  //         <div>  <Button type="primary" style={{ borderRadius: '0px', padding: '0px 50px', color: '#3157c2', border: 'solid 0px #578dc8' }}  >
                  //             Browse files
                  //         </Button>
                  //         </div>
                  //     </p>
                  // </Dragger>)}
                  (<CustomUploader {...customProps} />)}
              </Form.Item> */}
            </div>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer" style={{margin:'0px'}}>
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => {
                  if (this.state.isPrimaryContact && !(((this.props.data || {}).formData || {}).contactData || {}).familyRelationship) {
                    showToasterMessage({ messageType: 'error', description: "Profile update is required" });
                  }
                  else { this.props.popModalFromStack(); this.props.hideModal() }
                }
                }
              />
              <CustomButton
                intlId={'confirmation.submit'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
       
      </Fragment >
    )
  }

  handleSubmit = (e, self) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({
        submittedOnce: true,
        invalidEmailClass: (!this.state.email || validateEmail(values.emailAddress || this.state.email)) ? '' : 'input-text-error',
        invalidPhoneClass: (!this.state.cellPhone || validateMobileNumber(values.cellPhone || this.state.cellPhone)) ? '' : 'input-text-error'
      });
      if (!err && (!values.emailAddress || validateEmail(values.emailAddress)) && (!values.cellPhone || validateMobileNumber(values.cellPhone))) {
        const payload = {
          salutationName: values.titleName,
          salutationId: values.salutationId,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          preferCallName: values.preferCallName,
          // workPhone: values.workPhone ? (values.countryCallingCode || '') +values.workPhone : null,
          contactId: this.state.contactId,
          cellPhone: values.cellPhone ? values.cellPhone : null,
          faxNumber: values.faxNumber,
          emailAddress: values.emailAddress,
          jobTitle: values.jobTitle,
          department: values.department,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          dateCreated: this.state.dateCreated || getCurrentDateForBackend(new Date()),
          isPrimaryContact: (values.isPrimaryContact || this.state.isPrimaryContact) ? 1 : 0,
          countryCallingCode: values.countryCallingCode,
          familyRelationship: values.familyRelationship,
          isEnablePortalAccess: (values.isEnablePortalAccess || this.state.isEnablePortalAccess) ? 1 : 0,
          dateOfBirth: values.dateOfBirth ? formatDateForBackend(values.dateOfBirth) : null,
          // age: values.age,
          allergies: values.allergies === 'Yes' ? values.allergiesDesc : 'No',
          gender: values.gender,
          bloodGroup: values.bloodGroup,
          facebookLink: values.facebookLink,
          instagramLink: values.instagramLink,
          twitterLink: values.twitterLink,
          linkedIn: values.linkedIn,
          docDetailList: this.state.docDetailList || [],
          contactType: this.state.contactType,
          version: this.state.version || values.version || 0,
        }
        if (this.props.data && this.props.data.formData && this.props.data.formData.customerId) {
          payload.customerId = (this.props.data.formData || {}).customerId;
        }
        // if(this.props.data && this.props.data.formData && this.props.data.formData.supplierId){
        //   payload.supplierId = this.props.data.formData.supplierId;
        //   payload.contactType = CONSTANTS.CONTACT_TYPE_SUPPLIER;
        // }
        // if(this.props.data && this.props.data.formData && this.props.data.formData.isFundraiser){
        //   payload.contactType = CONSTANTS.CONTACT_TYPE_FUNDRAISER;
        //   payload.teamMasterId = this.props.data.formData.teamMasterId
        // }
        const modalData = {
          modalBody: 'Are you sure you want to create Contact ?',
          handleSubmit: () => {
            this.props.createContact(payload, (this.props.data || {}).formData, self.state);
            this.props.handleSubmit && this.props.handleSubmit();
            this.props.popModalFromStack();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Contact_component' })(injectIntl(ContactComponent));
