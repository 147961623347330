import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, LOCAL_STORAGE_KEYS } from '../../../../../static/constants';
import { showToasterMessage, addToLocalStorage } from '../../../../../utils';
import * as find from 'lodash.find';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createContact = (payload, formDataT, props) => {
  //let actionType = payload.customerId ? CUSTOMER_ACTION_LIST.ADD_CUSTOMER_BO_CONTACT : SUPPLIER_ACTION_LIST.ADD_SUPPLIER_BO_CONTACT;
  var formData = new FormData();
  formData.append("payload", JSON.stringify(payload));
  if(props.fileToUpload){
    formData.append('file', props.fileToUpload, props.fileToUpload.name);
  }
  // if(props.fileList && props.fileList.length){
  //   props.fileList.forEach((file) => {
  //     formData.append('file', file, file.name);
  //   })
  // }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/updateContact`, formData,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if ((res.data || {}).customerId) {
          const custVar = {...res.data, clientOnlineUserAccountId: props.clientOnlineUserAccountId};
          dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: custVar });
          addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(custVar));
        }

        if (formDataT.funcToCall) {
          formData.funcToCall(formDataT.funcToCallPayload)
        }
        if (formDataT.callback) {
          const primayContact = find(res.data.boContactList || [], {isPrimaryContact: 1 }) || {};
          formDataT.callback(primayContact, res.data.boContactList)
        }
        if (formDataT.callbackAddStudent) {
          formDataT.callbackAddStudent(payload, res.data.boContactList)
        }
        const newStudentData = res.data.boContactList;
        showToasterMessage({ messageType: 'success', description: 'Contact saved successfully' });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
         // err.actionToCall = actionType;
        }
      })
  }
}