import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AssignmentComp from "../../components/AssignmentList"
import { getSchoolAssignment, getSchoolAssignmentCount, resetPaginatedData, deleteAssignment } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../actions/commonActions';
import { downloadFileFromBucket } from '../../actions/downloadFileAction';
import { getStudentsByCustomerId } from '../Dashboard/action';
import { sendResetMail } from '../Forgot/action';

class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
      isMoreDetailBtnDisplay: {},
      isAssignmentLoaded: false,
    };
  }
    
  componentDidMount() {
    this.props.updateHeaderState({headerHeading: 'Assignment'})
  }
  componentWillReceiveProps(props) {
    if(!this.state.isAssignmentLoaded && (props.allStudentList || []).length){
      let payload = {
        relationshipId: props.companyInfo.relationshipId,
        classId : props.allStudentList[0].classId,
        pageNumber: 1,
        pageSize: 100,
      }
      console.log(`vvv`, payload)
      props.getSchoolAssignment(payload);
      this.setState({
        isAssignmentLoaded: true,
        activeStuKey: props.allStudentList[0].schoolStudentId.toString(),
        selectedStudent: props.allStudentList[0]
      })
    }
  }

  render() {
    return (
      <AssignmentComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    customerInfo: state.common.customerInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    assignmentList: state.school.assignmentList,
    assignmentCount: state.school.assignmentCount,
    subdomain: state.common.subdomain,
    studentClassList: state.dashboard.studentClassList,
    allStudentList: state.dashboard.allStudentList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSchoolAssignment, 
      getSchoolAssignmentCount,
      resetPaginatedData,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      sendResetMail,
      deleteAssignment,
      // getStudentClassByStudentId,
      downloadFileFromBucket,
      getStudentsByCustomerId
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Assignment);
