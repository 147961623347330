import axios from 'axios';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import ResetPassword from '../modal/modalBody/customer/ResetPassword';
import config from '../../config/environmentConfig';
import { showToasterMessage, addToLocalStorage } from '../../utils';
import { LOCAL_STORAGE_KEYS, COMMON_ACTIONS, AUTH, MODAL_TYPE } from '../../static/constants';
import { Button } from 'antd';
import * as find from 'lodash.find';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchRelationshipDataByRID = (payload) => {
  return dispatch => {
    //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/getRelationshipByRID?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        // dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data });
        dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data: data });
        addToLocalStorage(COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, JSON.stringify(data));
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO;
        }
      })
  }
}
export const fetchRelationshipByDomainName = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getRelationshipId?name=${payload.domainName}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const { data } = res;
        dispatch({ type: COMMON_ACTIONS.RELATIONSHIP_ID, data: data.relationshipId });
        const load = { relationshipId: data.relationshipId }
        //props.fetchCountries(load);
        props.fetchRelationshipDataByRID(load);
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // dispatch({ type: COMMON_ACTIONS.RELATIONSHIP_ID, data: data.relationshipId });
        // const load = { relationshipId: data.relationshipId }
        // props.fetchRelationshipDataByRID(load);
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          //err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
        }
      })
  }
}

export const verifyCredentials = (payload, props, adminToken) => {
  var loginData;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: AUTH.HIDE_RESEND_ACTIVATION });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/onlineUser/login`,
        {
          email: payload.email, password: payload.password, relationshipId: payload.relationshipId,
          accountActivationToken: payload.accountActivationToken, source: payload.source
        }
      )
      // .then(res => {
      //   loginData = res.data;
      //   return axios
      //     .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/getUserPermission?relationshipId=${loginData.relationshipId}&userId=${loginData.userId}`
      //     )
      // })
      .then(res => {

        loginData = res.data;
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const result = loginData;
        let tokenInfo = result.tokenInfo ? JSON.parse(result.tokenInfo) : {};
        const primaryContact = find(loginData.boContactList || [], { isPrimaryContact: 1 }) || {};
        const permissionData = {};

        if ((loginData || {}).hasAccountActivated) {
          permissionData.primary = res.data.primary || [];
          permissionData.secondary = res.data.secondaryPermission || {};
          addToLocalStorage(LOCAL_STORAGE_KEYS.EXPIRE_TIME, tokenInfo.expires_in);
          addToLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, tokenInfo.access_token);
          addToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, tokenInfo.refresh_token);
          addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(loginData));
          addToLocalStorage(LOCAL_STORAGE_KEYS.PERMISSION_DATA, JSON.stringify(permissionData));
          addToLocalStorage(LOCAL_STORAGE_KEYS.ADMIN_TOKEN, JSON.stringify(adminToken || {}));
          addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_EMAIL, payload.email || '');
          dispatch({ type: AUTH.SET_PERMISSIONS, data: permissionData });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          // if ((loginData || {}).isProfileSetup !== 1 || !primaryContact.familyRelationship) {
          //   props.showModal({
          //     maskClosable: true,
          //     isClosable: false,
          //     keyboard: false,
          //     modalBody: <Fragment>
          //       <div className="warning-message">
          //         Your profile setup is pending. Please complete the profile setup.
          //       </div>
          //       <br />
          //       <div className="button-action" style={{
          //         justifyContent: 'space-between',
          //         borderTop: '1px solid #e5e5e5',
          //         paddingTop: '15px',
          //         textAlign: 'center'
          //       }}>
          //         <Button className="ant-btn-primary"
          //           onClick={() => {
          //             //props.history.push('/admin/edit-profile');
          //             props.history.push({
          //               pathname: '/admin/profile',
          //               state: { source: 'login' }
          //             })
          //             props.hideModal();
          //           }}>  <FormattedMessage id='ok' defaultMessage='' /> </Button>
          //       </div>
          //     </Fragment>,
          //     hideFooter: true
          //   })
          // } else {
            props.history.push('/admin/open-registration');
            dispatch({ type: COMMON_ACTIONS.IS_FLASH_VISIBLE, data: true });

         // }

        }
        else {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: AUTH.SHOW_RESEND_ACTIVATION });
          props.history.push({
            pathname: '/activation-pending',
            state: {
              username: payload.email
            }
          })
          // showToasterMessage({messageType: 'error', description: "Your account has not been activated yet. Check your email to your activate your account. If you have not received a confirmation email, please check your spam folder."});
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || ((err.response || {}).data || {}).message })
      })
  }
}

export const getPermissions = (payload, props) => {
  // return dispatch => {
  //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  //   return axios
  //     .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/activateCustomerRelationship?token=${payload.token}`)
  //     .then(res => {
  //       showToasterMessage({ messageType: 'success', description: ((res || {}).data || {}).message || 'Congratulations, your account has been activated. You can sign in.' })
  //       //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
  //       props.history.push('/')
  //     })
  //     .catch((err) => {
  //       //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
  //       if (!err.__isRetryRequest) {
  //         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
  //         props.history.push('/')
  //       }
  //     })
  // }
}

export const activateCustomerAccount = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/onlineUser/activate?token=${payload.token}`)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: ((res || {}).data || {}).message || 'Congratulations, your account has been activated. You can sign in' })
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // props.history.push('/')
        //  props.pushModalToStack({
        //   modalBody: <ResetPassword {...props} userAccountData={res.data}  />,
        //   title: <FormattedMessage id='forgot.headingText' defaultMessage='' />,
        //   width: '30%',
        //   hideFooter: true,
        // })
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
          props.history.push('/')
        }
      })
  }
}
