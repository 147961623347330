import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {changePassword } from './action';
import { pushModalToStack, popModalFromStack, showModal, hideModal } from '../../../actions/commonActions';
//import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from  '../../../../../utils';
import ChangePasswordComp from '../../../components/Profile/ChangePassword/index';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
          searchedText: ''
        };
      }

    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};
        // const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.invoiceData.customerId, invoiceMasterId: this.props.invoiceData.invoiceMasterId };
        
        // this.props.resetAvailableCreditList();
        // this.props.fetchAvailableCreditList(payload);
        
    }

    render() {
        // return <ChangePasswordComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        return <ChangePasswordComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />

    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        userInfo: state.common.userInfo,
        customerInfo: state.common.customerInfo,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  changePassword
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);