import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AssignmentAttemp from "../../components/AssignmentAttemp"
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../utils";
import { getSchoolAssignmentCount, resetPaginatedData, deleteAssignment, submitAssignment, getStudentByClassId } from "./action";
import {getSchoolAssignment} from '../AssignmentList/action'
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../actions/commonActions';
import { sendResetMail } from '../Forgot/action';
import * as find from 'lodash.find';

class AssignmentSubmission extends Component {
  constructor(props) {
    super(props);
    
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageNumber: 1,
      pageSize: 200,
      isSeeMore: false,
      isLoadData: false,
      assignmentId: props.assignmentId ||linkProps.assignmentId,
      classId:props.classId || linkProps.classId,
      studentId: props.studentId || linkProps.studentId,
      className:props.className || linkProps.className,
      studentName: props.studentName || linkProps.studentName
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const customerInfo = this.props.customerInfo || {};
    const orgRegistration = find(customerInfo.orgRegistrationList || [], { registrationFormSettingId: customerInfo.formId }) || {};
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
    //   pageNumber: 1,
    //   pageSize: 200,
    //   formId: this.props.defaultSchoolForm,
    //   orgRegistrationId: orgRegistration.orgRegistrationId,
    //   classId: this.props.classId,
    //   studentId : this.props.studentId
    // };
    // fetchPaginationDataIfNeeded("getSchoolAssignment", "assignmentList", this.props, payload);
    //fetchDataIfNeeded("getSchoolAssignmentCount", "assignmentCount", this.props, payload);
    // this.props.getStudentByClassId(payload);
  }
    componentWillReceiveProps(props) {
        // if (!this.state.isLoadData && props.assignmentList && props.assignmentList[1] && props.assignmentList[1].length && this.state.assignmentId) {
        //     const asmData = find(props.assignmentList[1], { assignmentId: this.state.assignmentId }) || {};
        //     const assignmentResponseObj = (asmData.assignmentResponseList || [])[0] || {};
        //   //  let asmData = props.assignmentList[1][0] || {};
        //     const questionList = JSON.parse((assignmentResponseObj.questionAnswer || asmData.questionList) || '[]');
        //     this.setState({
        //         asmData,
        //         questionList,
        //         isLoadData: true,
        //         assignmentResponseObj,
        //         assignmentResponse: assignmentResponseObj.response,
        //         teacherComment: assignmentResponseObj.teacherComment
        //     })
        // }
    }


  render() {
    return (
      <AssignmentAttemp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    customerInfo: state.common.customerInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    assignmentList: state.school.assignmentList,
    assignmentCount: state.school.assignmentCount,
    subdomain: state.common.subdomain,
   // classStudentList: state.school.classStudentList
   studentClassList: state.dashboard.studentClassList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSchoolAssignment, getSchoolAssignmentCount,
      resetPaginatedData,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      sendResetMail,
      deleteAssignment,
      submitAssignment,getStudentByClassId
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentSubmission);
