import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CustomButton } from '../../../../general/CustomButton';
import { Input } from 'antd';
import { showToasterMessage} from '../../../../../utils';

class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };


    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content employee-modal-control">
          <div style={{textAlign: 'center'}}>Please reset your password to continue</div>
          <br/>
            <Form.Item label={(<span><FormattedMessage id='signup.password.text' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Password is required.',
                    initialValue: this.props.username || ''
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'signup.password.text', defaultMessage: '' })} minLength={8}
                onChange={e => {
                  this.setState({ password: e.target.value });
                }}
              />)}
            </Form.Item>

            <Form.Item label={(<span><FormattedMessage id='signup.passwordRepeat.text' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('passwordRepeat', {
                rules: [
                  {
                    required: true,
                    message: 'Password is required.',
                    initialValue: this.props.passwordRepeat || '',
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'signup.passwordRepeat.text', defaultMessage: '' })} minLength={8}
                onChange={e => {
                  this.setState({ passwordRepeat: e.target.value });
                }} />)}
            </Form.Item>
            
          </div>
          <br/>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.popModalFromStack(); }}
              />
              <CustomButton
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...this.props.data,
          password: values.password,
          passwordRepeat: values.passwordRepeat,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          clientOnlineUserAccountId: (this.props.userAccountData || {}).clientOnlineUserAccountId
        }

        if(payload.password !== payload.passwordRepeat){
            return showToasterMessage({
              description: 'Password does not match',
              messageType: 'error'
            })
          }

        const modalData = {
          modalBody: <FormattedMessage id='save.confirmation' defaultMessage='' />,
          handleSubmit: () => {
            this.props.changePassword(payload, (this.props.data || {}).formData);
            this.props.handleSubmit && this.props.handleSubmit();
            this.props.history.push('/');
            this.props.popModalFromStack();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Reset_Password_Component' })(injectIntl(ResetPasswordComponent));
