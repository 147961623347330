import React, {Fragment} from "react";
import { Row, Col, Button } from "antd";
import { injectIntl, FormattedMessage } from 'react-intl';
import { getInitialsFromString, getMomentDateForUIReadOnly, showToasterMessage } from '../../utils';
import { CONSTANTS, MODAL_TYPE, FAMILY_RELATIONSHIP_NAME_BY_KEY } from '../../static/constants';
import AddUser from '../../assets/images/add-user.png';
import StudentPic from '../../assets/images/student-pic.png'
import * as find from 'lodash.find';


const AllStudents = (props) => {
  const { customerInfo = {} } = props;
  // const boContactChildList = customerInfo.boContactChildList || [];

 const handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
        props.hideModal(data);
        if (contact.customerId) {
            // props.fetchAllContacts({
            //     customerId: contact.customerId,
            //     relationshipId: (props.companyInfo || {}).relationshipId,
            // });
        }
      }
   formData.callbackAddStudent = (payload, contactList) => {
     props.hideModal(data);
     if (payload.contactId && contactList && contactList.length) {
       let contactObj = find(contactList || [], { contactId: Number(payload.customerId), }) || {};
       addStudent(JSON.parse(JSON.stringify(contactObj)));
     } else if (contactList && contactList.length) {
       let maxContactId = 0;
       let latestContact;

       contactList.forEach(contact => {
         if (contact.contactId > maxContactId) {
           maxContactId = contact.contactId;
           latestContact = contact;
         }
       });
       addStudent(JSON.parse(JSON.stringify(latestContact)));
     }
   }
    const data = {
        title: <div>
            <FormattedMessage id='addItem.text.contact' defaultMessage='' />
            <div style={{ 'fontSize': '12px' }}> {formData.customerName || ''}</div>
        </div>,
        formData,
        hideFooter: true,
        modalData: {
            modalType: MODAL_TYPE.CONTACT,
            data: payload,
        },
        handleSubmit: (formData = {}) => {
            data.formData.submittedOnce = true;
            props.hideModal(data);
        }
    };
    showModal(data);
}

const isStudentAdded = (studentContactId) =>{
  const index = (props.studentList || []).findIndex(obj => obj.contactId === studentContactId);
  if(index !== -1){
    return true;
  }
  else{
    return false;
  }

}

const addStudent = (student) =>{

  const index = props.studentList.findIndex(obj => obj.contactId === student.contactId);
  let newList = props.studentList;
  if (index !== -1) {
    // newList.splice(index, 1);
  } else {
    newList.push(student);
  }
  props.updateState({studentList: newList});
}
  
  return (
    <Row className="contact-container">
      {(customerInfo.boContactList || []).map((student, i) => {
        return <Col xs={24} md={8} className='mat mt5 mab pl10 pr10 pt10 pb10' key={'std-'+i}>
          <div className="add-adrsboxne add-boxtop-bot">
            <div className="hed-studl"> 
            {/* <span><div className={student.isPrimaryContact?'user-icon-profile-add-parent':'user-icon-profile-family'}>
                                  {customerInfo ? getInitialsFromString(student.firstName + (student.lastName ? ' ' + student.lastName : '') || 'XX') : ''}
                                      </div></span> */}
            <Row style={{ padding: '0px 0px 5px', fontSize:'15px'}}>
              {/* <Col span={8} className="textright15"> <b>First Name</b></Col> */}
              <Col span={23}  style={{padding:'0px 0px 5px 0px'}}><b>{student.firstName + (student.lastName ? ' ' + student.lastName : '')}</b>
              <div className="primarys"> 
              {/* {student.familyRelationship ?<span>{FAMILY_RELATIONSHIP_NAME_BY_KEY[student.familyRelationship]}</span> : ''}  */}
              {student.isPrimaryContact ?<span className=" yelw-color">Primary</span> :''} </div>
              </Col></Row>  </div>
            {student.isDefault ? <div className="add-top-hed">Default</div> : ''}
            <div style={{fontSize:'12px'}}>
              <Row style={{ padding: '8px 0px'}}><Col span={10} className="textright15"><b>Relationship </b></Col><Col span={14}  className="primarys">{student.familyRelationship ? <span> {FAMILY_RELATIONSHIP_NAME_BY_KEY[student.familyRelationship.toLowerCase()]}</span> : '-'}</Col></Row>
              <Row style={{ padding: '8px 0px'}}><Col span={10} className="textright15"><b>Gender </b></Col><Col span={14}>{student.gender || '-'}</Col></Row>
              <Row style={{ padding: '8px 0px'}}><Col span={10} className="textright15"><b>DOB </b></Col><Col span={14}>{student.dateOfBirth ? getMomentDateForUIReadOnly({ date: student.dateOfBirth, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : '-'}</Col></Row>
              <Row style={{ padding: '8px 0px'}}><Col span={10} className="textright15"><b>Allergies </b></Col><Col span={14}>{student.allergies || '-'}</Col></Row>
            </div>
            {/* <div><span className="cursor-pointer">Edit</span></div> */}
            <br/>
            {/* {props.selectedStudentIds.indexOf(student.contactId) > -1 ? */}

            <div className="add-stn-btn">
                {student.isPrimaryContact ? 
                  <span className='disabled' onClick={() => {
                      showToasterMessage({messageType: 'warning', description: 'Primary account cannot be student'})
                  }}>
                    Add
                  </span>   
                  :
                  <span className={isStudentAdded(student.contactId) ? 'added' : 'cursor-pointer'} onClick={() => {
                    // props.addStudent(student);
                    addStudent(JSON.parse(JSON.stringify(student)));
                    props.popModalFromStack();
                  }}>
                    Add{isStudentAdded(student.contactId) ? 'ed' : ''}
                  </span> 
                }
              
            </div>
          </div>

        </Col>
      })
      }

      <Col xs={24} md={8} className='mat mab mt5 pl10 pr10 pt10 pb10'>
        <div className="add-adrsbox" style={{textAlign:'center', padding: '97px 0px', background:'#F8F8F8', cursor:'pointer'}}>
          <div className="plus-icon" onClick={() => {
            let formData = {customerId : props.customerId }
            handleNewContactAddition(props, {formData: formData});
          //  props.showModal({
          //   maskClosable: true,
          //   isClosable: false,
          //   keyboard: false,
          //   modalBody: <Fragment>
          //     <div className="warning-message">
          //     Personal information can be edited from the profile page. Do you want to continue?
          //     </div>
          //     <br />
          //     <div className="button-action" style={{
          //       display: 'flex',
          //       justifyContent: 'space-between',
          //       borderTop: '1px solid #e5e5e5',
          //       paddingTop: '15px'
          //     }}>
          //        <Button className="ant-btn-default"
          //         onClick={() => {
          //           props.hideModal();
          //         }}>  <FormattedMessage id='no' defaultMessage='' />
          //       </Button>
          //       <Button className="ant-btn-primary" onClick={() => {
          //       //  props.history.push('/admin/edit-profile');
          //         props.hideModal();
          //         props.popModalFromStack();
          //       }}>  <FormattedMessage id='ok' defaultMessage='' /> </Button>
          //     </div>
          //   </Fragment>,
          //   hideFooter: true
          // })
          }}>
            <div style={{textAlign:'center'}}><img src={AddUser} alt=""/></div>
            {/* <span><i className="fa fa-plus add-adrsbox" aria-hidden="true"></i></span> */}
            <div className="add-address">
              <span><h3 className="add-heading"><b>Add Student</b></h3></span>
            </div>

          </div>
        </div>
      </Col>

    </Row>

  )
}

export default injectIntl(AllStudents);