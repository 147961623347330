import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, DASHBOARD_ACTION_LIST } from '../../static/constants'
import { showToasterMessage } from '../../utils';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getUnpaidAmtByCustomerId = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getUnpaidAmtByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || 0}&formId=${payload.formId || 0}`)
        .then(res => {
          const { data } = res
          dispatch({ type: DASHBOARD_ACTION_LIST.UNPAID_CUSTOMER_AMOUNT, data });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            // showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = DASHBOARD_ACTION_LIST.UNPAID_CUSTOMER_AMOUNT;
          }
        })
    }
}

  
export const getPaidAmtByCustomerId = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPayment/amountReceivedByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || 0}&module=${payload.module || 'school'}&formId=${payload.formId || 0}`)
        .then(res => {
          const { data } = res
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: DASHBOARD_ACTION_LIST.PAID_CUSTOMER_AMOUNT, data });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = DASHBOARD_ACTION_LIST.PAID_CUSTOMER_AMOUNT;
          }
        })
    }
  }

  
export const getTotalFees = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getTotalFees?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || 0}&formId=${payload.formId || 0}`)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          const { data } = res
          dispatch({ type: DASHBOARD_ACTION_LIST.TOTAL_FEE_AMOUNT, data });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            // showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = DASHBOARD_ACTION_LIST.TOTAL_FEE_AMOUNT;
          }
        })
    }
  }

    
export const getTotalInvoiceAmtByCustomerId = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getSchoolTotalInvoiceAmountByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || 0}&formId=${payload.formId || 0}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const { data } = res
        dispatch({ type: DASHBOARD_ACTION_LIST.TOTAL_INVOICE_CUSTOMER_AMOUNT, data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          // showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = DASHBOARD_ACTION_LIST.TOTAL_INVOICE_CUSTOMER_AMOUNT;
        }
      })
    }
}



  export const getStudentClassByStudentId = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentClassRef/getStudentClassDetailsByStudentIds?relationshipId=${payload.relationshipId}&schoolStudentIdList=${payload.schoolStudentIdList || []}&formId=${payload.formId || 0}`)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          const { data } = res
          dispatch({ type: DASHBOARD_ACTION_LIST.STUDENT_CLASS_LIST, data });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            // showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = DASHBOARD_ACTION_LIST.STUDENT_CLASS_LIST;
          }
        })
    }
  }

  export const getStudentsByCustomerId = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/student/getStudentsByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || []}&pageSize=500&pageNumber=0`)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          const { data } = res
          dispatch({ type: DASHBOARD_ACTION_LIST.ALL_STUDENT_LIST, data });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
      }
  }

  
export const getPaymentSubscription = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/alpidepay/subscriptionPaymentProfile/getCustomerProfile?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || 0}&module=${payload.module || 'school'}&formId=${payload.formId || 0}`)
      .then(res => {
        const { data } = res
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: DASHBOARD_ACTION_LIST.PAYMENT_SUBSCRIPTION, data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = DASHBOARD_ACTION_LIST.PAYMENT_SUBSCRIPTION;
        }
      })
    }
}

export const getBroadcastMessageByCustomerId = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/broadcastMessage/get?relationshipId=2&pageSize=25&pageNumber=0&createdByEmpId=2`)
        // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/broadcastMessage/get?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&createdByEmpId=${payload.relationshipEmployeeId || 2}`)
        // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/broadcastMessage/get?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&createdByEmpId=${payload.relationshipEmployeeId || 2}`)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          const { data } = res
          dispatch({ type: DASHBOARD_ACTION_LIST.BROADCAST_MESSAGE_LIST, data });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
           // showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = DASHBOARD_ACTION_LIST.BROADCAST_MESSAGE_LIST;
          }
        })
    }
}

// export const fetchSalesData = (payload, history) => {
//   let FY = getFinancialYear(payload.companyInfo);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getSalesData?relationshipId=${payload.relationshipId}&period=${payload.selectedRange}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
//       .then(res => {
//         dispatch({ type: DASHBOARD_ACTION_LIST.SALES_DATA_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }


// export const fetchPurchaseData = (payload, history) => {
//   let FY = getFinancialYear(payload.companyInfo);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierTransaction/getPurchaseData?relationshipId=${payload.relationshipId}&period=${payload.selectedRange}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
//       .then(res => {
//         dispatch({ type: DASHBOARD_ACTION_LIST.PURCHASE_DATA_LIST, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }



// export const fetchTopCustomerBySales = (payload, history) => {
//   let FY = getFinancialYear(payload.companyInfo);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getTopCustomersBySales?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=100&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
//       .then(res => {
//         dispatch({ type: DASHBOARD_ACTION_LIST.TOP_SALES_CUSTOMER, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }




// export const fetchTopSupplierByPurchase = (payload, history) => {
//   let FY = getFinancialYear(payload.companyInfo);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierTransaction/getTopSuppliersByPurchase?relationshipId=${payload.relationshipId}&period=${payload.selectedRange}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
//       .then(res => {
//         dispatch({ type: DASHBOARD_ACTION_LIST.TOP_PURCHASE_SUPPLIER, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }




// export const fetchTopCustomerByPayment = (payload, history) => {
//   let FY = getFinancialYear(payload.companyInfo);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getTopCustomersByPayment?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
//       .then(res => {
//         dispatch({ type: DASHBOARD_ACTION_LIST.TOP_PAYMENT_CUSTOMER, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }


// export const fetchTopSupplierByPayment = (payload, history) => {
//   let FY = getFinancialYear(payload.companyInfo);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierTransaction/getTopSuppliersByPayment?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
//       .then(res => {
//         dispatch({ type: DASHBOARD_ACTION_LIST.TOP_PAYMENT_SUPPLIER, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }



// export const fetchFastestMovingProducts = (payload, history) => {
//   let FY = getFinancialYear(payload.companyInfo);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getFasterMovingProducts?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
//       .then(res => {
//         dispatch({ type: DASHBOARD_ACTION_LIST.FASTEST_MOVING_PRODUCTS, data: res.data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }

// export const fetchCustomerUnpaidInvoices = (payload, history) => {
//   let FY = getFinancialYear(payload.companyInfo);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getAllUnpaidInvoices?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
//       .then(res => {
//         const data = {
//           pageNo: payload.pageNumber,
//           list: res.data
//         }
//         dispatch({ type: DASHBOARD_ACTION_LIST.CUSTOMER_UNPAID_INVOICES, data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = DASHBOARD_ACTION_LIST.CUSTOMER_UNPAID_INVOICES;
//           err.data = {
//             pageNo: payload.pageNumber,
//             list: []
//           };
//         }
//       })
//   }
// }

// export const fetchSupplierUnpaidInvoices = (payload, history) => {
//   let FY = getFinancialYear(payload.companyInfo);
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/getAllUnpaidInvoices?&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
//       .then(res => {
//         const data = {
//           pageNo: payload.pageNumber,
//           list: res.data
//         }
//         dispatch({ type: DASHBOARD_ACTION_LIST.SUPPLIER_UNPAID_INVOICES, data });
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = DASHBOARD_ACTION_LIST.SUPPLIER_UNPAID_INVOICES;
//           err.data = {
//             pageNo: payload.pageNumber,
//             list: []
//           };
//         }
//       })
//   }
// }

// export const fetchCashflow = (payload) => {
//   return dispatch => {
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPayment/getPayment?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         const { data } = res;
//         dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_CASHFLOW, data });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }


// export const fetchTotalExpense = (payload) => {
//   return dispatch => {
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relationshipExpense/getTotalExpenseAmount?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         const { data } = res;
//         dispatch({ type: EXPENSE_ACTION_LIST.RELATIONSHIP_EXPENSE, data });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }

// export const fetchRecentPayment = (payload) => {
//   return dispatch => {
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPayment/get10RecentPayments?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         const { data } = res
//         dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.RECENT_10_PAYMENTS, data });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }



// export const fetchOrgMember = (payload) => {
//   return dispatch => {
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/countOrgMembers?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         const { data } = res;
//         dispatch({ type: CUSTOMER_ACTION_LIST.MEMBER_COUNT, data });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }


// // export const getAllRelExpenses =  (payload) => {
// //   return dispatch => {
// //      return axios
// //       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/countOrgMembers?relationshipId=${payload.relationshipId}`)
// //       .then(res => {
// //         const { data } = res;
// //         dispatch({ type: CUSTOMER_ACTION_LIST.MEMBER_COUNT, data });
// //       })
// //       .catch((err) => {
// //         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
// //       })
// //   }
// // }

export const getStudentsByCustomerIdAndCourseId = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/student/getByCustomerIdAndCourseId?relationshipId=${payload.relationshipId || 0}&customerId=${payload.customerId || 0}&courseId=${payload.courseId || 0}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const { data } = res
        dispatch({ type: DASHBOARD_ACTION_LIST.ALL_STUDENT_LIST, data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
    }
}