import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegisterComponent from "../../components/Register";
import { fetchRegistrationFormSetting, saveRegistration, getTotalStudentcCount, getTotalStudentcCountByGrade, fetchStudentFatherEmail, saveRegistrationFound, } from './action';
import { getContactEducation, resetContactEducation,deleteContactEducation, getContactEmployment,
    resetContactEmployment, } from '../Profile/action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchCountries, fetchStates, fetchPaymentTerms } from '../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../static/constants';

class Register extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(this.props.location.search) || {};
        const isFormEdit = this.props.location.state.orgRegistrationId ? 1 : 0;
        this.state = {
            formSetting: {},
            status: linkProps.status,
            // relationshipId: linkProps.relationshipId,
            relationshipBillingAddress: {},
            ledgerAccountId: 0,
            isAnswerSubmitted: false,
            questionList: [],
            isFormEdit,
        }
    }
        componentDidMount() {
        this.props.updateHeaderState({headerHeading: 'Form'})
        const linkProps = (this.props.location && this.props.location.state) || {};

        const companyInfo = this.props.companyInfo || {};
        const customerInfo = this.props.customerInfo || {};

        //let contact = customerInfo.boContactList[0] || {};
        let contact = (this.props.customerInfo.boContactList || [])[0] || {};

        //  this.props.fetchRelationshipByDomainName({ domainName: window.location.host, registrationFormSettingId: linkProps.formId }, this.props);
        const load = { relationshipId: companyInfo.relationshipId, registrationFormSettingId: linkProps.formId, orgRegistrationId: linkProps.orgRegistrationId || 0, customerId: customerInfo.customerId }
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: 0, pageSize: 100,
            contactId: (customerInfo.boContactList[0] || {}).contactId,
            customerId: customerInfo.customerId,
          };
        this.props.fetchStudentFatherEmail(load, { ...this.props, updateState: (data) => { this.setState(data) } })
        this.props.fetchRegistrationFormSetting(load);
        this.props.fetchCountries(load);
        this.props.fetchPaymentTerms(load);
        this.props.getTotalStudentcCount(load);
        this.props.getTotalStudentcCountByGrade(load);
        (contact && contact.contactId) && this.props.getContactEducation(contact);
        (contact && contact.contactId) && this.props.getContactEmployment(contact);
    //     this.props.getMembershipInfo(contact);
    // this.props.getAllMembrshipForm(payload);
    }

    componentWillReceiveProps(props) {
        if (props.registrationFormSetting && props.registrationFormSetting.registrationFormSettingId) {
            let formSetting = JSON.parse(props.registrationFormSetting.formSetting);
            if(!this.state.isAnswerSubmitted){this.state.quesList= formSetting.questionList}
            this.setState({
                successMessage: props.registrationFormSetting.successMessage,
                relationshipId: props.registrationFormSetting.relationshipId,
                registrationFormSettingId: props.registrationFormSetting.registrationFormSettingId,
                courseId: props.registrationFormSetting.courseId,
                formSetting: formSetting,
                questionList: this.state.quesList,
                classList: formSetting.classFees || [],
                programName: props.registrationFormSetting.programName,
                status: props.registrationFormSetting.isFormActive || (this.props.adminToken && this.props.adminToken.token) ? undefined : 'inactive',
                registrationType: props.registrationFormSetting.registrationType,
                ledgerAccountId: props.registrationFormSetting.ledgerAccountId,
                projectMasterId: props.registrationFormSetting.projectMasterId,
                maxStudentCountByGrade: formSetting.totalStudentCountByGrade || {},
                paymentPlan: JSON.parse(props.registrationFormSetting.paymentPlan || '[]'),
                isAllowParentPaymentPlan: props.registrationFormSetting.isAllowParentPaymentPlan,
                subcourseAndFeeList: props.registrationFormSetting.subcourse ? JSON.parse(props.registrationFormSetting.subcourse) : [],
                orgMember: 'Family Membership',
                oneTimeCharges: JSON.parse(props.registrationFormSetting.onetimeInvoiceItem|| '[]')
            })
        }
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            this.setState({
                relationshipBillingAddress,
            })
        }
        if (props.countries !== this.state.countries && props.countries && !this.state.countryName) {
            let obj = props.countries.find(country => (country.countryName === (props.companyInfo).countryName)) || {};
            if ((obj.countryName === 'United States' || obj.countryName === 'United States (USA)' || obj.countryName === 'India') && !props.states.length) {
                this.props.fetchStates(obj.countryId);
                // fetchDataIfNeeded('fetchStates', 'states', this.props, obj.countryId);
            }
            this.setState({ countryName: obj.countryName || '', countryId: obj.countryId });
        }
        if (this.state.paymentPlan && this.state.paymentPlan.length && Object.keys(this.state.selectedPlan || {}).length && !this.state.selectedPlanIndex) {
            let selectedPlanIndex = 0;
            (this.state.paymentPlan || []).map((plan, index) => {
                if (plan && this.state.selectedPlan.period === plan.period && this.state.selectedPlan.totalInstallment === plan.totalInstallment) {
                    selectedPlanIndex = index;
                    return '';
                }
            });
            this.setState({ selectedPlanIndex });
        }

    }
    render() {
        window.document.title = this.props?.companyInfo?.storeName || '';
        return <div>
            {Object.keys(this.props.registrationFormSetting).length ? <RegisterComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> : <div></div>}
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        customerInfo: state.common.customerInfo,
        registrationFormSetting: state.school.registrationFormSetting,
        contactEducationList: state.common.contactEducationList,
        countries: state.common.countries,
        states: state.common.states,
        totalStudentCount: state.studentRegistration.totalStudentCount,
        totalStudentCountByGrade: state.studentRegistration.totalStudentCountByGrade,
        adminToken: state.common.adminToken,
        defaultSchoolForm: state.common.defaultSchoolForm,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveRegistration,
    fetchRegistrationFormSetting,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    resetContactEducation,
    resetContactEmployment,
    fetchCountries,
    getContactEducation,
    getContactEmployment,
    fetchStates,
    fetchPaymentTerms,
    getTotalStudentcCount,
    getTotalStudentcCountByGrade,
    fetchStudentFatherEmail,
    saveRegistrationFound,
    deleteContactEducation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register);
