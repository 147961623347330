import axios from 'axios';
import config from '../../../config/environmentConfig';
import { DASHBOARD_ACTION_LIST, COMMON_ACTIONS } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getUnpaidInvoicesByFormId= (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      let url = `/customerInvoice/getUnpaidInvoicesByCustomerIdAndModule?formId=${payload.formId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}&module=school&customerId=${payload.customerId || 0}`;
      // if (payload.context) {
      //   if (payload.context === 'totalInvoices') {
      //     url = `/customerInvoice/getByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}&module=school`
      //   } else if (payload.context === 'unpaidInvoices') {
      //     url = `/customerInvoice/getUnpaidInvoicesByCustomerIdAndModule?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}&module=school`
      //   } else if (payload.context === 'paidInvoices') {
      //     url = `/customerInvoice/getPaidInvoicesByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}&module=school`
      //   }
      // }
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${url}`)
        .then(res => {
          const data = {
            pageNo: payload.pageNumber,
            list: res.data
          }
          dispatch({ type: DASHBOARD_ACTION_LIST.UNPAID_INVOICES_BY_FORM_ID, data });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = DASHBOARD_ACTION_LIST.UNPAID_INVOICES_BY_FORM_ID;
          }
        })
    }
  }
  
  export const getUnpaidInvoiceCount = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      let url = `/customerInvoice/countUnpaidInvoicesByCustomerId?formId=${payload.formId}&relationshipId=${payload.relationshipId}&module=school&customerId=${payload.customerId || 0}`;
      // if (payload.context) {
      //   if (payload.context === 'totalInvoices') {
      //     url = `/customerInvoice/getTotalInvoiceCountByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&module=school`
      //   } else if (payload.context === 'unpaidInvoices') {
      //     url = `/customerInvoice/countUnpaidInvoicesByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&module=school`
      //   } else if (payload.context === 'paidInvoices') {
      //     url = `/customerInvoice/countPaidInvoicesByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&module=school`
      //   }
      // }
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${url}`)
        .then(res => {
          dispatch({ type: DASHBOARD_ACTION_LIST.UNPAID_INVOICES_COUNT_BY_FORM_ID, data: res.data  });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = DASHBOARD_ACTION_LIST.UNPAID_INVOICES_COUNT_BY_FORM_ID;
          }
        })
    }
  }

  export const resetPaginatedData = (payload) => {
    return dispatch => {
      dispatch({ type: DASHBOARD_ACTION_LIST.UNPAID_INVOICES_BY_FORM_ID_RESET });
    }
  }