import { notification } from 'antd';
import * as get from 'lodash.get';
import moment from 'moment-timezone';
import { CONSTANTS } from '../static/constants';
import exportFromJSON from 'export-from-json';
import config from '../config/environmentConfig';

export const showToasterMessage = (payload = {}) => {
  if(!payload.messageType){
    payload.messageType = 'error';
  }
  notification[payload.messageType || 'error'](
    {
      message: payload.message || (payload.messageType === "error" ? 'Error' : (payload.messageType === "success" ? 'Success': 'Warning')),
      description: payload.description || 'Error',
      ...payload,
      duration: 6
    }
  )
}

export const validateEmail = (val) => {
  return val && val.toLowerCase().match(CONSTANTS.EMAIL_REGEX_EXP)
}

export const validateMobileNumber = (val) => {
  return val && val.toLowerCase().match(CONSTANTS.PHONE_REGEX_EXP)
}

export const getMomentDateForUI = (data={}) =>{
  return
   moment((data.date || new Date()), (data.format || CONSTANTS.DISPLAY_DATE_FORMAT));
  
  
}

export const getMomentDateForUIReadOnly = (data) =>{
  return moment(new Date(data.date || new Date())).format(data.format || CONSTANTS.DISPLAY_DATE_FORMAT);
}

export const addToLocalStorage = (keyName, value) => {
  sessionStorage.setItem([keyName], value);
}

export const addToLocalMemory = (keyName, value) => {
  localStorage.setItem([keyName], value);
}

export const fetchFromLocalStorage = (keyName) => {
  return sessionStorage.getItem([keyName]);
}

export const fetchFromLocalMemory = (keyName) => {
  return localStorage.getItem([keyName]);
}

export const deleteFromLocalStorage = (keyName) => {
  sessionStorage.removeItem([keyName]);
}

export const deleteFromLocalMemory = (keyName) => {
  localStorage.removeItem([keyName]);
}

export const clearLocalStorage = () => {
  sessionStorage.removeItem("expires_in");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.removeItem("logged_in_entity");
  sessionStorage.removeItem("permission_data");
  sessionStorage.removeItem("access_token_p");
}

export const getCompanyInfo = () => {
  const loggedInEntityData = sessionStorage.getItem("logged_in_entity") ? JSON.parse(sessionStorage.getItem("logged_in_entity")) : {};
  return loggedInEntityData.defaultCompany;

}

export const fetchDataIfNeeded = (methodName, propName, props, payload, isForceUpdate) => {
  props[methodName](payload, props.history);
  // isForceUpdate = true;
  // let isDataAvailable = false;
  // if (props[propName] instanceof Number) { isDataAvailable = props[propName] > 0 ? false : true };
  // if (props[propName] instanceof Object) { isDataAvailable = Object.keys(props[propName]).length > 0 ? false : true };
  // if (props[propName] instanceof Array) { isDataAvailable = props[propName].length > 0 ? false : true };
  // if (isForceUpdate || !props[propName] || isDataAvailable) {
  //   props[methodName](payload, props.history);
  // }
}

export const fetchPaginationDataIfNeeded = (methodName, propName, props, payload, isForceUpdate) => {
  props[methodName](payload, props.history);
  // isForceUpdate = true;
  // if (isForceUpdate || !props[propName] || !props[propName][payload.pageNumber || 0] || !props[propName][payload.pageNumber || 0].length) {
  //   props[methodName](payload, props.history);
  // }
}

// export const fetchPaginationDataIfNeeded = (methodName, propName, props, payload, isForceUpdate) => {
//   let totalRecords = 0;
//   if (payload && payload.pageSize) {
//     totalRecords = (payload.pageSize * (payload.pageNumber || 0) - 1) + payload.pageSize;
//   }
//   if (isForceUpdate || !props[propName] || !props[propName].length || props[propName].length < totalRecords) {
//     props[methodName](payload, props.history);
//   }
// }

export const removeDuplicateObject = (arr, uniqueKey) => {
  let set = new Set();
  let unionArray = arr.filter(item => {
    if (!set.has(item[uniqueKey])) {
      set.add(item[uniqueKey]);
      return true;
    }
    return false;
  }, set);
  return unionArray;
}

export const base64Toblob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  let ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  let blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const addDaysToDate = (date, days) => {
  date = new Date(date);
  date.setDate(date.getDate() + (days || 0));
  return date;
}

export const getMonthsBetweenDates = (startDate, endDate) => {
  let date1 = new Date(startDate);
  let date2 = new Date(endDate);
  return date2.getMonth() - date1.getMonth() + 
    (12 * (date2.getFullYear() - date1.getFullYear())) + 1;
} 


export const getCurrentDateForBackend = (dt) => {

  // let today = dt ? new Date(dt) : new Date();
  // let dd = today.getDate();
  // let mm = today.getMonth() + 1; //January is 0!
  // let yyyy = today.getFullYear();
  // let hr = today.getHours();
  // let min = today.getMinutes();
  // let second = today.getSeconds();

  // if (dd < 10) {
  //   dd = '0' + dd
  // }

  // if (mm < 10) {
  //   mm = '0' + mm
  // }

  // let date = yyyy + '-' + mm + '-' + dd + ' ' + hr + ':' + min + ':' + second;

  // let ue = new Date(date);
  // let fullYear = ue.getFullYear();
  // let month = ("0" + (ue.getMonth() + 1)).slice(-2);
  // date = ("0" + ue.getDate()).slice(-2);
  // let seconds = ("0" + ue.getSeconds()).slice(-2);
  // let minutes = ("0" + ue.getMinutes()).slice(-2);
  // let hours = ("0" + ue.getHours()).slice(-2);
  // let fullDate = fullYear + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
  // let fullDate = moment.utc(new Date(dt)).format();
  let fullDate = moment.utc(new Date(dt)).format("YYYY-MM-DD HH:mm:ss");
  fullDate = fullDate.replace("T", " ")
  fullDate = fullDate.replace("Z", " ")
  return fullDate;
};

export const formatDateForBackend = (date) => {

  // let ue = new Date(date);
  // let fullYear = ue.getFullYear();
  // let month = ("0" + (ue.getMonth() + 1)).slice(-2);
  // let day = ("0" + ue.getDate()).slice(-2);
  // let seconds = ("0" + ue.getSeconds()).slice(-2);
  // let minutes = ("0" + ue.getMinutes()).slice(-2);
  // let hours = ("0" + ue.getHours()).slice(-2);
  // //2016-06-18 13:5:59
  // let fullDate = fullYear + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  // fullDate = moment.utc(fullDate).format()
  // let fullDate = moment.utc(new Date(date)).format();
  let fullDate = moment.utc(new Date(date)).format("YYYY-MM-DD HH:mm:ss");

  fullDate = fullDate.replace("T", " ")
  fullDate = fullDate.replace("Z", " ")
  return fullDate;
};

export const formatDateFilterForBackend = (date, dateType) => {
  let tempDate = new Date(date);
  if (dateType === CONSTANTS.FILTER_DATE_TYPE.END) {
    tempDate.setHours(23, 59, 59, 999);
  } else {
    tempDate.setHours(0, 0, 0, 0)
  }
  let fullDate = moment.utc(tempDate).format("YYYY-MM-DD HH:mm:ss");
  fullDate = fullDate.replace("T", " ")
  fullDate = fullDate.replace("Z", " ")
  return fullDate;
};


export const formatDateForBackendFY = (date) => {

  let ue = new Date(date);
  let fullYear = ue.getFullYear();
  let month = ("0" + (ue.getMonth() + 1)).slice(-2);
  let day = ("0" + ue.getDate()).slice(-2);
  let seconds = ("0" + ue.getSeconds()).slice(-2);
  let minutes = ("0" + ue.getMinutes()).slice(-2);
  let hours = ("0" + ue.getHours()).slice(-2);
  //2016-06-18 13:5:59
  let fullDate = fullYear + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  //fullDate = moment.utc(fullDate).format()
  return fullDate;
};

export const getPartialDateForBackend = (date, dateType, returnPart) => {
  let tempDate = new Date(date);
  if (dateType === CONSTANTS.FILTER_DATE_TYPE.END) {
    tempDate.setHours(23, 59, 59, 999);
  } 
  if(dateType === CONSTANTS.FILTER_DATE_TYPE.START) {
    tempDate.setHours(0, 0, 0, 0)
  }
  let dateObj = moment.utc(tempDate);
  
  switch(returnPart) {
    case CONSTANTS.DATE_PART_TYPE.DAY : {
      return dateObj.format("DD")
     // break;
    }
    case CONSTANTS.DATE_PART_TYPE.MONTH : {
      return dateObj.format("MM")
    }
    case CONSTANTS.DATE_PART_TYPE.YEAR : {
      return dateObj.format("YYYY")
    }
    default: {
      let fullDate = dateObj.format("YYYY-MM-DD HH:mm:ss");
      fullDate = fullDate.replace("T", " ")
      fullDate = fullDate.replace("Z", " ")
      return fullDate;
    }
  }

};

export const getFinancialYear = (companyInfo) => {
  let FY = {};
  if(companyInfo && companyInfo.fyStartDate){
    let fyStartDate = new Date(companyInfo.fyStartDate);
    let fyEndDate = new Date(companyInfo.fyEndDate);
    fyStartDate.setHours(0, 0, 0, 0)
      fyEndDate.setHours(23, 59, 59, 999);
    let fullStartDate = moment.utc(fyStartDate).format("YYYY-MM-DD HH:mm:ss");
    fullStartDate = fullStartDate.replace("T", " ")
    fullStartDate = fullStartDate.replace("Z", " ")

    let fullEndDate = moment.utc(fyEndDate).format("YYYY-MM-DD HH:mm:ss");
    fullEndDate = fullEndDate.replace("T", " ")
    fullEndDate = fullEndDate.replace("Z", " ")

    FY.fyStartDate = fullStartDate;
    FY.fyEndDate = fullEndDate;
  }
  
  return FY;
};

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const downloadDataFromAWS = (bucketName, key, fileName) => {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", "https://s3.amazonaws.com/" + bucketName + "/" + key, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    let urlCreator = window.URL || window.webkitURL;
    let imageUrl = urlCreator.createObjectURL(this.response);
    let tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }
  xhr.send();
}

export const getAWSFileURL = (awsKey, bucketName) => {
  if (!awsKey) return null;
  if (bucketName) {
    return 'https://s3.amazonaws.com/' + bucketName + '/' + awsKey + '?' + Date.now();
  } else {
    return 'https://s3.amazonaws.com/bo-relationship\\' + awsKey + '?' + Date.now();
  }
}

export const checkSecondaryPermission = (secondary = {}, keyPath) => {
  let hasPermission = true;
  if (Object.keys(secondary).length) {
    hasPermission = get(secondary, keyPath);
    if (hasPermission === undefined) {
      hasPermission = true;
    }
  }
  return hasPermission;
}

export const primaryPermissionConversion = (permissions) => {
  const result = {};
  if (Array.isArray(permissions)) {
    permissions.forEach(perm => {
      const permSplitted = perm.split('-');
      if (permSplitted && permSplitted.length === 2 && !isNaN(permSplitted[1])) {
        result[permSplitted[0]] = +permSplitted[1];
      }
    })
  }
  return Object.keys(result).length > 0 ? result : permissions;
}

export const checkACLPermission = (primary, moduleCode, operation) => {
  let hasPermission = true;
  // if (moduleCode && operation && primary && Object.keys(primary).length && primary[moduleCode]) {
  //   if ((primary[moduleCode] & +operation) === 0) {
  //     hasPermission = false;
  //   } else {
  //     hasPermission = true;
  //   }
  // }
  return hasPermission;
}
export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}


export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const fixedDecimalNumber = (number) => {
  const defaultCompany = getCompanyInfo() || {};
  return (Number(number || 0)).toFixed(defaultCompany.decimalDigitsLenth || CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
}

export const formatedNumber = (number) => {
  return (number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const fixedDecimalAndFormateNumber = (number) => {
  const defaultCompany = getCompanyInfo() || {};
  return (Number(number || 0)).toFixed(defaultCompany.decimalDigitsLenth || CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const isValidDateRange = (startDate, endDate) => {
  let date1 = new Date(startDate);
  let date2 = new Date(endDate);
  let isValid = date1.getTime() < date2.getTime();
  return isValid;
}

export const exportExcel = (data, fileName) => {
  exportFromJSON({ data: data, fileName: fileName, exportType: exportFromJSON.types.xls });
  return "";
} 

export const getInitialsFromString = function(strData){
  const nameArr = strData.split(' ');
  let initials;
  if(nameArr.length >= 2){
    initials = `${nameArr[0].slice(0, 1)}${nameArr[1].slice(0, 1)}`.toUpperCase();
  }
  else{
    initials= `${nameArr[0].slice(0, 2)}`.toUpperCase();
  }
  return initials;
}
export const getGreetingFromTime = (date)=>{
let curHr = date.getHours()

if (curHr < 12) {
  return "Good Morning," 
} else if (curHr < 18) {
  return "Good Afternoon,"
} else {
  return "Good Evening,"
}

}

export const getUserDefinedName = function(name, props){
  const labels = props?.labelConfig?.educationLabel || {};
  let text = name;
  if(text.includes('.')){
    text = props.intl.formatMessage({id: text})
  }
  if(Object.keys(labels).length){
    text = labels.program ? text.replaceAll("Program", labels.program) : text;
    text = labels.subject ? text.replaceAll("Subject", labels.subject) : text;
    text = labels.section ? text.replaceAll("Section", labels.section) : text;
    text = labels.program ? text.replaceAll("Department", labels.program) : text;
    text = labels.program ? text.replaceAll("department", labels.program) : text;
    text = labels.term ? text.replaceAll("Term", labels.term) : text;
    text = labels.course ? text.replaceAll("Course", labels.course) : text;
    text = labels.class ? text.replaceAll("Class", labels.class) : text;
    text = labels.program ? text.replaceAll("program", labels.program) : text;
    text = labels.subject ? text.replaceAll("subject", labels.subject) : text;
    text = labels.section ? text.replaceAll("section", labels.section) : text;
    text = labels.term ? text.replaceAll("term", labels.term) : text;
    text = labels.course ? text.replaceAll("course", labels.course) : text;
    text = labels.class ? text.replaceAll("class", labels.class) : text;
  }
  return text;
}
export const getAgeFromDOB = (dob) => {
  let birthDate = new Date(dob);
  let today = new Date();
  let month = 0;
  var year = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    year--;
    month = 12 + m;
  } else {
    month = m;
  }
  let age = '<1 month';
  if (year) {
    age = `${year} ${year > 1 ? 'yrs' : 'yr'}`
  }
  if (month) {
    age += ` ${month} ${month > 1 ? 'months' : 'month'}`
  }
  return age;
} 

export const getTimeFromDate = (dateStr, format) => {
  const utcDate = moment.utc(dateStr);
  const localDate = utcDate.local();
  const time = localDate.format(format || 'HH:mm');

  return time;
};

