import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActivationPendingComp from '../../components/ActivationPending/ActivationPending';
import { resendActivationLink } from './action';

class ActivationPending extends Component {
  constructor(props) {
    super(props);
    const linkProps = (props.location && props.location.state) || {};
    this.state = {
      username: linkProps.username || '',
    }
  }

  render() {
    return <ActivationPendingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
  }

}

const mapDispatchToProps = (dispatch) => bindActionCreators({ 
  resendActivationLink 
}, dispatch);

export default connect(null, mapDispatchToProps)(ActivationPending);
