import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../actions/commonActions';
import { getSchoolAttendanceByClassId, getClass, resetAttendenceData, getSchoolAttendanceByStudentId } from "./action";
import { getStudentClassByStudentId } from "../Dashboard/action";
import AttendanceCompo from '../../components/Attendance';
import {getCurrentDateForBackend} from '../../utils';

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAttendenceLoaded: false,
      pageNumber: 1,
      pageSize: 25,
      currentMonth: (new Date()).getMonth(),
    };
    
  }

  componentDidMount() {
    this.props.updateHeaderState({headerHeading: 'Attendance'})
    const companyInfo = this.props.companyInfo || {};  
    const customerInfo = this.props.customerInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      customerId: (customerInfo || {}).customerId,
      pageNumber: 1,
      pageSize: 1000,
    };
  }
  
  componentWillReceiveProps(props) {
    if(!this.state.isAttendenceLoaded && (props.allStudentList || []).length){
      const currentDate = new Date();
      let payload = {
        relationshipId: props.companyInfo.relationshipId,
        studentId : props.allStudentList[0].schoolStudentId,
        startDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
        endDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)),
        pageNumber: 1,
        pageSize: 100,
      }
      this.props.getSchoolAttendanceByStudentId(payload);
      this.setState({
        isAttendenceLoaded: true,
        activeAttendKey: props.allStudentList[0].schoolStudentId.toString(),
        selectedStudent: props.allStudentList[0]
      })
      
    }

    
  }

  render() {
    return <AttendanceCompo 
      {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}
    />
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    customerInfo: state.common.customerInfo,
    // studentAttendanceList: state.school.studentAttendanceList,
    allStudentList: state.dashboard.allStudentList,
    attendanceList: state.school.attendanceList,
    studentAttendance: state.school.studentAttendance,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getSchoolAttendanceByClassId, 
      resetAttendenceData,
      getClass,
      getStudentClassByStudentId,
      getSchoolAttendanceByStudentId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
