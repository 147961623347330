import React, { Fragment } from 'react';
import { injectIntl, } from 'react-intl';
import '@ant-design/compatible/assets/index.css';

const IframeComponent = (props) => {
  
  const { formData={} } = props;
  return (
    <Fragment>
      <div>
        <iframe
          title="External Page"
          src={formData.urlToOpen || '#'} // Replace this with your desired URL
          width="100%"
          frameBorder="0"
          height="700px"
        />
      </div>
      {/* <div className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.address.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => {
                  props.handleSubmit();                  
                }}
              />
             
            </div>
          </div> */}
    </Fragment >
  )
  
}
export default (injectIntl(IframeComponent ));