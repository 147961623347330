const configuration = {
  UI_BASE_URL: 'http://localhost:3001/app/',
  API_BASE_URL: 'http://localhost:8080/',
  API_VERSION: '',
  rootContext: 'alpide',
  rootContextSMS: 'alpide-sms',
  BUCKET_NAME: {
    BO_RELATIONSHIP: 'relationship-u',
  }
};

export default configuration;
