import React from "react";
import { Switch } from "react-router-dom";
import { injectIntl } from "react-intl";
import PrivateRoute from "./privateRoute";
import "./index.scss";
import Dashboard from "../containers/Dashboard";
import FeeList from "../containers/FeeList";
import Attendance from "../containers/Attendance";
import Communication from "../containers/Communication";
import Documents from "../containers/Documents";
import FundraisingCampaign from "../containers/FundraisingCampaign";
import Membership from "../containers/Membership";
import Newsletter from "../containers/Newsletter";
import Events from "../containers/Events";
import EditProfile from "../containers/EditProfile";
import OpenRegistration from "../containers/OpenRegistration";
import Register from "../containers/Register";
import Enrolled from "../containers/Enrolled";
import AssignmentList from "../containers/AssignmentList";
import AssignmentSubmission from "../containers/AssignmentAttemp";
import RegisterMember from "../containers/RegisterMember";
import Profile from '../containers/Profile';
import Broadcast from "../containers/Communication/Broadcast";
import TimeTable from "../containers/Timetable";
import NotFound from "../components/Common/NotFound";

var isMobile = {
  Android: function() {
      return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};
const PartialViewRoute = (props) => {
  const { isCollapsed } = props;
  return (
    <div className={`view-container-wrapper ${(isCollapsed || isMobile) ? 'expendedView' : ' normalContainer'}`}>
      <Switch>
        <PrivateRoute {...props}
          exact
          path='/admin/dashboard'
          component={Dashboard}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/fee'
          component={FeeList}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/attendance'
          component={Attendance}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/documents'
          component={Documents}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/communication'
          component={Communication}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/events'
          component={Events}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/newsletter'
          component={Newsletter}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/fundraising-campaign'
          component={FundraisingCampaign}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/membership'
          component={Membership}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/edit-profile'
          component={EditProfile}
        />
         <PrivateRoute {...props}
          exact
          path='/admin/open-registration'
          component={OpenRegistration}
        />
          <PrivateRoute {...props}
          exact
          path='/admin/register'
          component={Register}
        />
         <PrivateRoute {...props}
          exact
          path='/admin/enrolled'
          component={Enrolled}
        />
          <PrivateRoute {...props}
          exact
          path='/admin/assignment-list'
          component={AssignmentList}
        />
         <PrivateRoute {...props}
          exact
          path='/admin/assignment-submission'
          component={AssignmentSubmission}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/register-member'
          component={RegisterMember}
        />
          <PrivateRoute {...props}
          exact
          path='/admin/profile'
          component={Profile}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/broadcast'
          component={Broadcast}
        />
        <PrivateRoute {...props}
          exact
          path='/admin/time-table'
          component={TimeTable}
        />
         <PrivateRoute {...props}
          path='/admin/*'
          component={NotFound}
    
        />

      </Switch>
    </div>
  );
};

export default injectIntl(PartialViewRoute);
