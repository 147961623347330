import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS} from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createEducation = (payload, props) => {

  // Need to update switch for supplier
  // switch (payload.locationType) {
  //   case CONSTANTS.BILLING_ADDRESS:
  //   case CONSTANTS.SHIPPING_ADDRESS: {
  //     actionType = payload.customerId ? CUSTOMER_ACTION_LIST.ADD_CUSTOMER_BO_LOCATION : SUPPLIER_ACTION_LIST.ADD_SUPPLIER_BO_LOCATION;
  //     break;
  //   }
  //   case CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS :
  //   case CONSTANTS.RELATIONSHIP_BILLING_ADDRESS : {
  //     actionType = COMMON_ACTIONS.BO_LOCATION_ADDITION;
  //     break;
  //   }
  //   default:
  //     break;
  // }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/education/save`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      //  dispatch({ type:  actionType, data: res.data , formData});

       console.log("res.data", res.data)
      if(res.data) {
        const payload2 = {
          relationshipId: res.data[0].relationshipId,
          customerId: res.data[0].customerId,
          contactId: res.data[0].contactId,
        }
           
            props.getContactEducation(payload2);
          }
        // if(formData.funcToCall){
        //   formData.funcToCall(formData.funcToCallPayload)
        // }
        // if(formData.callback){
        //   formData.callback(res.data)
        // }
        showToasterMessage({ messageType: 'success', description: 'Saved successfully' });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
         // err.actionToCall = actionType;
        }
      })
  }
}