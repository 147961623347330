import axios from "axios";
import config from "../../config/environmentConfig";
import { SCHOOL_ACTIONS } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getCustomerCommunication = (payload) => {
    return (dispatch) => {
        return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerCommunication/getCustomerCommunication?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&customerId=${payload.customerId}`)
            .then((res) => {
              const data = {
                pageNo: payload.pageNumber,
                list: res.data
              }
                dispatch({
                    type: SCHOOL_ACTIONS.COMMUNICATION_LIST,
                    data: data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            })
            .finally(() => {
              //dispatch({ type: SCHOOL_ACTIONS.COMMUNICATION_LIST, data: {isStudentFormListLoaded : true}});
            });
    };
};



export const getCustomerCommunicationCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerCommunication/getCommunicationCountByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`
      )
      .then((res) => {
        dispatch({
          type: SCHOOL_ACTIONS.COMMUNICATION_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SCHOOL_ACTIONS.COMMUNICATION_LIST_PAGE_RESET });
  };
};