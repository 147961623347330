import React, { Fragment } from 'react';
import { Button, Col, Drawer, Form, Row, Tabs , DatePicker } from "antd";
import { getAWSFileURL, getInitialsFromString } from '../../../utils';

const EmailInfo = (props) => {

    const closeDrawer = () => {
        props.updateHeaderState({
          drawer: {
            EmailInfoDrawerVisible: false,
          },
        });
      };


      const handleSubmit =() =>{

      }




    console.log("propssssww", props?.communicationDetail)
  return (
    <Fragment>
         <Drawer
        title={"Add Family Member"}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.drawer.EmailInfoDrawerVisible}
        footer={
          <>
            <Button defaultMessage="" type="default" onClick={closeDrawer}>
              Cancel
            </Button>
            <div>
              <Button type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </>
        }
       >
      <div className='email-info-container'>
      <Row>
       <Col span={24}>
         <h3 className='shipped-heading'>Delhi Public School order # 0 has shipped</h3>
       </Col>
     </Row>
     
     <Row>
        <Col span={24}>
            <div className='email-user-profile'>
              <Row>
                <Col span={12}>
                  <div className='email-info'>
                    <div className='user-profile'>
                    <div className="thum">
              {props?.communicationDetail ?
                <img  src={getAWSFileURL(props?.communicationDetail?.relationshipEmployeeName)} alt="Avatar" />
                : <div className='user-icon-profile-parent'>
                {props?.communicationDetail ? getInitialsFromString(props?.communicationDetail?.relationshipEmployeeName || 'XX') : ''}
                    </div>
              }
            </div> 
                    </div>
                    <div>

                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className='date-info'></div>
                </Col>
              </Row>
            </div>
        </Col>
     </Row>
      </div>
      </Drawer>
    </Fragment>
  )
}

export default EmailInfo