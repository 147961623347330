import CommonReducer from "./CommonReducer";
import { ModalReducer, StackModalReducer } from "./ModalReducer";
import SchoolReducer from "./SchoolReducer";
import DashboardReducer from "./DashboardReducer";
import StudentRegistrationReducer from "./StudentRegistrationReducer";
import MembershipReducer from "./MembershipReducer";

const reducers = {
  common: CommonReducer,
  modal: ModalReducer,
  stackModal: StackModalReducer,
  school: SchoolReducer,
  dashboard: DashboardReducer,
  studentRegistration: StudentRegistrationReducer,
  membership: MembershipReducer
};

export default reducers;
