import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from "antd";
import ReactHtmlParser from 'react-html-parser';


const EmailInfo = (props) => {

    return (
        <div>
            <div className="modal-dialog">
                <div className="modal-content full-width modify-width" style={{ minHeight: '100%' }}>
                    <div className="inner-content">
                        <Form layout="vertical" hideRequiredMark className="pr30">
                            <Row>
                                <Col span={4} style={{fontWeight: 'bold' }}><FormattedMessage id='to.text' /> : </Col>
                                <Col span={19} className='ml5'>{props.communicationDetail.communiatedTo}</Col>
                            </Row>
                             {props.communicationDetail.emailCC ?
                                <Row>
                                    <Col span={4} style={{fontWeight: 'bold' }}><FormattedMessage id='cc.text' /> : </Col>
                                    <Col span={19} className='ml5'>{props.communicationDetail.emailCC}</Col>
                                </Row> : ''
                            }
                           {/* {props.communicationDetail.emailBCC ?
                                <Row>
                                    <Col span={4} style={{fontWeight: 'bold' }}><FormattedMessage id='bcc.text' /> : </Col>
                                    <Col span={19} className='ml5'> {props.communicationDetail.emailBCC}</Col>
                                </Row> : ''
                            } */}
                            {props.communicationDetail.emailSubject ?
                                <Row>
                                    <Col span={4} style={{fontWeight: 'bold' }}><FormattedMessage id='subject.text' /> : </Col>
                                    <Col span={19} className='ml5'>{props.communicationDetail.emailSubject}</Col>
                                </Row> : ''
                            }
                            {props.communicationDetail.emailSubject ?
                                <Row>
                                    <Col span={4} style={{fontWeight: 'bold' }}><FormattedMessage id='message.text' /> : </Col>
                                    <Col span={19} className='ml5'>{ReactHtmlParser(props.communicationDetail.emailMessage)}</Col>
                                </Row> : ''
                            }
                            {/* {props.communicationDetail.transactionType ?
                            <Row>
                                <Col span={4} style={{fontWeight: 'bold' }}><FormattedMessage id='modal.settings.emailTemplate.transaction' /> : </Col>
                                <Col span={19} className='ml5'> <FormattedMessage id={props.communicationDetail.transactionType} /></Col>
                            </Row> : ''
                            } */}

                            {props.communicationDetail.refDocId ?
                                <Row>
                                    <Col span={4} style={{fontWeight: 'bold' }}><FormattedMessage id='modal.txDetails.common.attachments' /> : </Col>
                                    <Col span={19} className='ml5'>
                                        <div className="cursor-pointer" onClick={() => {
                                            props.downloadFileFromBucket({ awsKeyName: props.communicationDetail.refDocId, bucketName: props.communicationDetail.bucketName })
                                        }}>
                                            <FormattedMessage id={props.communicationDetail.transactionType} />
                                        </div>
                                    </Col>
                                </Row> : ''
                            }
                        </Form>
                    </div>

                </div>
            </div>
        </div>
    );

}


export default Form.create({ name: 'email_info_component' })(injectIntl(EmailInfo));
