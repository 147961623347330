import { Button, Col, Drawer, Form, Row, Tabs , DatePicker } from "antd";
import React from "react";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import * as find from "lodash.find";
import { formatDateForBackend, getMomentDateForUI } from "../../../utils";
import { CONSTANTS } from "../../../static/constants";
import { ErrorMsg } from "../../general/ErrorMessage";
import { FormattedMessage } from "react-intl";


const { TabPane } = Tabs;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const EducationComp = (props) => {
  const closeDrawer = () => {
    props.updateHeaderState({
      drawer: {
        ...props.drawer,
        educationDrawerVisible: false,
      },
    });

    props.updateHeaderState({
      education: null
    });
  };



  const  handleSubmit = (e) => {
    props.updateHeaderState({
      submittedOnce : true
    });
    if(props.education?.institutionName && props.education?.institutionName &&  props.education?.startDate && props.education?.score ) {
        const {companyInfo} = props;
        const payload = {
          institutionName: props.education?.institutionName,
          version: companyInfo.version,
          degreeType: props.education?.degreeType,
          major:  props.education?.major,
          grade:  props.education?.grade,
          startDate:  props.education.startDate ? formatDateForBackend( props.education.startDate) : null,
          endDate:  props.education.endDate ? formatDateForBackend( props.education.endDate) : null,
          contactId: props.conatctObj ? props.conatctObj.contactId :  props.customerInfo.boContactList[0].contactId,
          customerId: props.conatctObj ? props.conatctObj.customerId :  props.customerInfo.boLocationList[0].customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          contactEducationId: props.education.contactEducationId && props.education.contactEducationId,
          schoolType:  props.education?.schoolType,
          score:  props.education?.score
        }
        props.createEducation([payload], props);
        closeDrawer();
      }
  };

  const applyFilter = () => {};

  const onTabChange = () => {};



  return (
    <>
      <Drawer
        title={"Education"}
        width={580}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.drawer.educationDrawerVisible}
        footer={
          <>
            <Button defaultMessage="" type="default" onClick={closeDrawer}>
              Cancel
            </Button>
            <div>
              <Button type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </>
        }
      >
        <Row gutter={[16, 25]}>
          <Col span={24}>
            <div className="input-label">Education Type  <span className="required">*</span> </div>
            <Dropdown
             className="custom-dropdown-box"
              items={[
                { name: "School", value: "school" },
                { name: "College/University", value: "college" },
              ]}
              optionKeyName="value"
              valueKeyName="name"
              value={props.education?.schoolType}
              placeholder="Choose Education Type "
              onSelect={(selectedValue, option) => {
                props.updateHeaderState({
                  education: {
                    schoolTypeName: option.name,
                    schoolType: option.key,
                  },
                });
              }}
              allowClear={true}
              onClear={() => {
                props.updateHeaderState({ allergies: undefined });
              }}
            />
               <ErrorMsg
                  validator={() => { return !props.submittedOnce || props.education?.schoolTypeName }}
                  message={<FormattedMessage id='modal.education.educationType' defaultMessage='' />}
                  />
          </Col>

          <Col span="24">
           <label className="input-label">{props.education?.schoolType === "college"  ? "College/University"  : "School" } <span className="required">*</span></label>
           <TextBox
            className="custom-text-box"
            value={props.education?.institutionName}
              onChange={(e) => {
                props.updateHeaderState({ education  : {
                  ...props.education,
                  institutionName  : e.target.value
                }});
              }}
              placeholder={props.education?.schoolType === "college"  ? "College/University"  : "School"}
            />
             <ErrorMsg
                  validator={() => { return !props.submittedOnce || props.education?.schoolType  || props.education?.schoolTypeName }}
                  message={<FormattedMessage id='modal.education.CollegeUniversity' defaultMessage='' />}
                  />
          </Col>
       {props.education?.schoolType === "college" ?  "" :
          <Col span="12">
            <label className="input-label">Grade</label>
            {/* <TextBox  
              className="custom-text-box"
              value={props.education?.grade}
              onChange={(e) => {
                props.updateHeaderState({ education  : {
                   ...props.education,
                  grade  : e.target.value
                }});
              }} 
              placeholder={"Grade"} /> */}
              <Dropdown

              className="custom-dropdown-box"
              items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              // optionKeyName="value"
              // valueKeyName="name"
              value={props.education?.grade}
              placeholder="Choose Grade"
              onSelect={(selectedValue, option) => {
                props.updateHeaderState({
                  education: {
                    ...props.education,
                    grade: selectedValue
                  },
                });
              }}
              allowClear={true}
              onClear={() => {
                props.updateHeaderState({
                  education: {
                   ...props.education,
                    // grade: none
                  },
                });
              }}
            />
          </Col>}

          {
            props.education?.schoolType === "college" &&  <Col span="12">
            <label className="input-label">Degree Type</label>
            <TextBox  
             className="custom-text-box"
            value={props.education?.degreeType}
              onChange={(e) => {
                props.updateHeaderState({ education  : {
                   ...props.education,
                   degreeType  : e.target.value
                }});
              }} placeholder={"Degree Type"} />
          </Col>

          }

          
{
            props.education?.schoolType === "college" &&  <Col span="12">
            <label className="input-label">Major</label>
            <TextBox 
             className="custom-text-box" 
            value={props.education?.degreeType}
              onChange={(e) => {
                props.updateHeaderState({ education  : {
                   ...props.education,
                   major  : e.target.value
                }});
              }} placeholder={"Major"} />
          </Col>
          }


          <Col span="12">
            <label className="input-label">Score/GPA <span className="required">*</span></label>
            <TextBox 
             className="custom-text-box"
             value={props.education?.score}
             onChange={(e) => {
               props.updateHeaderState({ education  : {
                ...props.education,
                score : e.target.value
               }});
             }}
               placeholder={"Score/GPA"} />
              <ErrorMsg
                  validator={() => { return !props.submittedOnce || props.education?.score }}
                  message={<FormattedMessage id='modal.education.ScoreGPA' defaultMessage='' />}
               />
          </Col>

          <Col span="12">
            <label className="input-label">Start Date <span className="required">*</span> </label>
               <DatePicker
                className="custom-date-picker"
                allowClear={false}
                format={format}
                defaultValue={getMomentDateForUI({ date: props.education?.startDate, format })}
                onChange={(selectedDate) => {
                  props.updateState({
                    education:{
                      ...props.education,
                      startDate: selectedDate
                    }
                  })
                }}
                placeholder="startDate"
              />

                  <ErrorMsg
                  validator={() => { return !props.submittedOnce || props.education?.startDate }}
                  message={<FormattedMessage id='modal.education.startDate' defaultMessage='' />}
                  />
            
          </Col>
          <Col span="12">
            <label className="input-label">End Date</label>
            <DatePicker
                className="custom-date-picker"
                allowClear={false}
                format={format}
                defaultValue={getMomentDateForUI({ date: props.education?.endDate, format })}
                onChange={(selectedDate) => {
                  props.updateState({
                    education:{
                      ...props.education,
                       endDate: selectedDate
                    }
                  })
                }}
                placeholder="endDate"
              />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default EducationComp;
