import React from 'react';
import './general.scss';
export const ErrorMsg = (props) => {
  let className = 'text-red';
  if (props.message) {
    if (props.validator && props.validator()) {
      className += ' hide';
    }
  } else {
    className += ' hide';
  }

  const errorMsg = props.message || '';

  return (
    <span className={className} style={{fontSize: '12px'}}> {errorMsg}</span>
  )
}