import React, { Fragment } from "react";
//import config from "../../config/environmentConfig";
import { injectIntl, FormattedMessage } from "react-intl";
import { getMomentDateForUIReadOnly } from "../../utils";
import { CONSTANTS } from '../../static/constants';
import './index.scss';
import DropdownAction from "antd/lib/dropdown";
import { MoreOutlined, EditOutlined} from '@ant-design/icons';
import {Menu} from "antd";

//import checkIcon from '../../../assets/images/check.png';
//import crossIcon from '../../../assets/images/cross.png';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const EnrolledList = (props) => {
  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

            <Menu.Item key="0" value="title">
              Actions
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item key="1" value="Register" onClick={()=>{
              props.history.push({
                              pathname: '/admin/register',
                              state: {
                                formId: e.registrationFormSettingId,
                                orgRegistrationId: e.orgRegistrationId,
                              }
                            })
                        }}
            >
              <EditOutlined />
              Edit
            </Menu.Item>
        </Menu >
    )
  };
  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            {/* <FormattedMessage id='' defaultMessage='' /> */}
            Currently Enrolled
          </div>
          {/* <div className="vertical-sep" /> */}
          {/* <PageBreadcrumb breadCrumbList={breadCrumbList} /> */}
        </div>
        <div className="top-view-right">
          
        </div>
      </div>
      <div className="view-container">
      <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='Currently Enrolled' defaultMessage='' />
              </div>
             
            </div>
            <div className="right-actions">
            </div>
          </div>
          
            <div className='table-container'>
              <table>
                <thead>
                  <tr>
                    <th style={{width: '50%'}}> <FormattedMessage id='programName.text' defaultMessage='' /></th>
                    <th> <FormattedMessage id='setting.student.registration.list.date' defaultMessage='' /></th>
                    <th>Action</th>
                  </tr>

                </thead>
                <tbody>
                  {(props.registrationList || []).map((e, i) => {
                    return <tr key={'std' + i}>
                      <td><span>{e.formName || '-'}</span></td>

                      <td >{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : '-'}</td>

                      <td style={{paddingLeft: '50px'}}>
                      <div className="action-icon">
                          <DropdownAction overlay={actionMenu(e)} trigger={['click']}>
                            <MoreOutlined />
                          </DropdownAction>
                        </div>
                      </td>
                    </tr>
                    // })
                  })}
                </tbody>
              </table>    
          </div>
          {/* <Pagination
            size="small"
            total={feeCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          /> */}
          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right"></div>
          </div>
        </div>
      
    </Fragment>
  );
};

export default injectIntl(EnrolledList);
