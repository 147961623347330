import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  getMomentDateForUIReadOnly,
  showToasterMessage,
} from "../../utils";
import { Row, Col } from "antd";
import { CONSTANTS } from "../../static/constants";
import ReactHtmlParser from "react-html-parser";
import { Tabs } from "antd";
import AssignmentSubmission from "../../containers/AssignmentAttemp";
import PageBreadcrumb from "../PageBreadcrumb";
import './index.scss'

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { TabPane } = Tabs;

const Assignment = (props) => {
  const { allStudentList, assignmentList, pageNumber } = props;
  const [activeStuKey, setActiveStuKey] = useState(props.activeStuKey);

  const breadCrumbList=[
    {name: <FormattedMessage id='breadcrum.dashboard' defaultMessage='' />},
    {name: <FormattedMessage id='sidebar.menuItem.assignment' defaultMessage='' />},
    {name: <FormattedMessage id='card.text' defaultMessage='' />},
  ];

  const getQuestionCount = (assignment) => {
    let questionList = JSON.parse(assignment.questionList || "[]");
    return questionList.length;
  };

  useEffect(() => {
    let isMoreDetailBtnDisplay = {};
    (assignmentList[pageNumber] || []).forEach((asm, i) => {
      const descDiv = document.getElementById("desc-" + i) || {};
      if (descDiv && descDiv.clientHeight > 60) {
        isMoreDetailBtnDisplay[["desc-" + i]] = true;
        // props.updateState({...props.isMoreDetailBtnDisplay, ['desc-'+i]: true})
      }
      const teacherCommentDiv =
        document.getElementById("teacherComment-" + i) || {};
      if (teacherCommentDiv && teacherCommentDiv.clientHeight > 20) {
        isMoreDetailBtnDisplay[["teacherComment-" + i]] = true;
        // props.updateState({...props.isMoreDetailBtnDisplay, ['desc-'+i]: true})
      }
    });
    props.updateState({ isMoreDetailBtnDisplay: isMoreDetailBtnDisplay });
  }, [assignmentList[pageNumber]]);

  const openLongtText = (title, Text, id) => {
    props.showModal({
      title: title,
      modalBody: (
        <div style={{ maxHeight: "550px", overflow: "auto" }}>
          {ReactHtmlParser(Text)}
        </div>
      ),
      hideFooter: true,
      width: "70%",
    });
  };

  const openShowFileList = (asmData) => {
    props.showModal({
      title: "Attachement",
      modalBody: (
        <div style={{ maxHeight: "550px", overflow: "auto" }}>
          {asmData.docDetailList && asmData.docDetailList.length ? (
            <Row>
              <Col span={24}>
                <table style={{ width: "100%", textAlign: "center" }}>
                  <thead>
                    <tr style={{ border: "1px solid #ddd" }}>
                      <th style={{ borderLeft: "1px solid #ddd" }}>
                        File Name
                      </th>
                      <th style={{ borderLeft: "1px solid #ddd" }}>Size</th>
                      <th style={{ borderLeft: "1px solid #ddd" }}>Type</th>
                      <th style={{ borderLeft: "1px solid #ddd" }}>Date</th>
                      <th style={{ borderLeft: "1px solid #ddd" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {asmData.docDetailList.map((doc, i) => {
                      return (
                        <tr
                          key={i}
                          style={{ border: "1px solid #ddd" }}
                          className="pt5"
                        >
                          <td style={{ borderLeft: "1px solid #ddd" }}>
                            {doc.fileName}
                          </td>
                          <td style={{ borderLeft: "1px solid #ddd" }}>
                            {doc.fileSize || ""}
                          </td>
                          <td style={{ borderLeft: "1px solid #ddd" }}>
                            {doc.fileType || ""}
                          </td>
                          <td style={{ borderLeft: "1px solid #ddd" }}>
                            {doc.dateCreated
                              ? getMomentDateForUIReadOnly({
                                  date: doc.dateCreated,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                })
                              : ""}
                          </td>
                          <td style={{ borderLeft: "1px solid #ddd" }}>
                            <span
                              className={"cursor-pointer"}
                              onClick={() => {
                                props.downloadFileFromBucket({
                                  bucketName: doc.bucketName,
                                  awsKeyName: doc.awsKeyName,
                                  fileName: doc.fileName,
                                });
                              }}
                            >
                              Download
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      ),
      hideFooter: true,
      width: "35%",
    });
  };

  const handleOnChange = (studentId) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
      classId: (allStudentList || []).find(obj => obj.schoolStudentId === Number(studentId)).classId
    }
    setActiveStuKey(studentId);
    props.getSchoolAssignment(payload);
    props.updateState({selectedStudent: (allStudentList || []).find(obj => obj.schoolStudentId === Number(studentId))})
  };

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='sidebar.menuItem.assignment' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div>
      
      <div className="view-container">
        {/* <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">Assignment List</div>
          </div>
          <div className="right-actions"></div>
        </div> */}
        {
          (allStudentList || []).length ?
            <div className="tabs-container">
              <div className="tab-container">
                <Tabs
                  onChange={handleOnChange}
                  activeKey={activeStuKey || props.activeStuKey}
                >
                  {(props.allStudentList || []).map((item, i) => {
                    return (
                      <TabPane
                        tab={`${item.firstName || `Student ${i}`} ${item.lastName || ''}`}
                        key={item.schoolStudentId.toString()}
                      >
                        <div className="details-container">
                          <Row>
                            { (assignmentList[pageNumber] || []).length ? (
                              assignmentList[pageNumber].map((e, i) => {
                                return (
                                  <Col xs={24} lg={6} key={"asss" + i}>
                                    <div className="asstmarl asm-details">
                                      <div className="texthert">
                                        <span>{e.assignmentName || ""}</span> 
                                        <span>{e.subjectName ? <div className="subj-label">{e.subjectName}</div>:''}</span>
                                      </div>

                                      <div className="asm-card-body">
                                        <Row className="asm-card-list">
                                          <Col span={10} className="asm-card-label">Group</Col>
                                          <Col span={14} className="leftt">
                                            {e.assignmentGroupName}
                                          </Col>
                                        </Row>
                                        <Row  className="asm-card-list">
                                          <Col span={10} className="asm-card-label" >Questions</Col>
                                          <Col span={14} className="leftt">
                                            {getQuestionCount(e) > 1
                                              ? getQuestionCount(e) + " Questions"
                                              : getQuestionCount(e) + " Question"}
                                          </Col>
                                        </Row>
                                        <Row  className="asm-card-list">
                                          <Col span={10} className="asm-card-label">Due Date</Col>
                                          <Col span={14} className="leftt">
                                            {e.dueDate &&
                                              getMomentDateForUIReadOnly({
                                                date: e.dueDate,
                                                format: format,
                                              })}
                                          </Col>
                                        </Row>
                                        <Row className="asm-status asm-card-list">
                                          <Col span={10} className="asm-card-label">Status</Col>
                                          <Col span={14}>
                                            {e.assignmentResponseList &&
                                            e.assignmentResponseList[0] ? (
                                              <span className="submit-status"> Submitted</span>
                                            ) : (
                                              <span className="not-submitted-status">
                                                Not Submitted
                                              </span>
                                            )}
                                            {/* {(e.numberOfAttempts === 'Multiple' && e.maxAttempts) ? <span className="remaining-attemps-text">{(e.maxAttempts - ((e.assignmentResponseList[0] || {}).totalAttempts || 0))} attempts left</span> : ''} */}
                                          </Col>
                                        </Row>
                                        <Row className="asm-card-list">
                                          <Col span={10}  className="asm-card-label">
                                            Submission Date
                                          </Col>
                                          <Col
                                            span={14}
                                            className=""
                                            style={{ fontSize: "13px" }}
                                          >
                                            {(e.assignmentResponseList[0] || {}).dateSubmitted
                                              ? getMomentDateForUIReadOnly({
                                                  date: (e.assignmentResponseList[0] || {})
                                                    .dateSubmitted,
                                                  format: format,
                                                })
                                              : "-"}
                                          </Col>
                                        </Row>
                                        <Row className="asm-card-list">
                                          <Col span={10} className="asm-card-label">
                                            Grade
                                          </Col>
                                          <Col
                                            span={14}
                                            className="leftt"
                                            style={{ fontSize: "13px" }}
                                          >
                                            {(e.assignmentResponseList[0] || {}).grade || "-"}
                                          </Col>
                                        </Row>
                                        {e.docDetailList && e.docDetailList.length ? (
                                          <div>
                                            <Row className="asm-card-list">
                                              <Col span={10} className="asm-card-label">
                                                Attachemnt
                                              </Col>
                                              <Col
                                                span={14}
                                                className="leftt cursor-pointer"
                                                style={{ fontSize: "13px" }}
                                                onClick={() => {
                                                  openShowFileList(e);
                                                }}
                                              >
                                                {e.docDetailList.length}{" "}
                                                <span>
                                                  {e.docDetailList.length > 1
                                                    ? " Files "
                                                    : " File"}
                                                </span>
                                              </Col>
                                            </Row>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        <Row className="asm-card-list">
                                          <Col span={10}>
                                            <span className="asm-card-label"> Comment </span>
                                          </Col>
                                          <Col span={14} className="leftt">
                                            <div
                                              className="pointer-cu teach-comment-max"
                                              style={{ fontSize: "13px" }}
                                              id={"teacherComment-" + i}
                                            >
                                              {ReactHtmlParser(
                                                (e.assignmentResponseList[0] || {})
                                                  .teacherComment || "-"
                                              )}{" "}
                                            </div>
                                            {props.isMoreDetailBtnDisplay[
                                              "teacherComment-" + i
                                            ] ? (
                                              <button
                                                className="ant-btn more-details-btn"
                                                onClick={() => {
                                                  openLongtText(
                                                    "Comment",
                                                    (e.assignmentResponseList[0] || {})
                                                      .teacherComment || ""
                                                  );
                                                }}
                                              >
                                                Show More
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </Col>
                                        </Row>
                                        <Row className="asm-card-list">
                                          <Col span={10}>
                                            <span style={{ fontSize: "13px" }}>
                                              Date Graded
                                            </span>
                                          </Col>
                                          <Col
                                            span={14}
                                            className="leftt"
                                            style={{ fontSize: "13px" }}
                                          >
                                            {(e.assignmentResponseList[0] || {}).dateGraded
                                              ? getMomentDateForUIReadOnly({
                                                  date: (e.assignmentResponseList[0] || {})
                                                    .dateGraded,
                                                  format: format,
                                                })
                                              : "-"}
                                          </Col>
                                        </Row>
                                      </div>

                                      <div style={{ clear: "both" }}></div>
                                      {/* <div className="bor"></div>  */}
                                      {!e.assignmentResponseList ||
                                      !e.assignmentResponseList.length ||
                                      e.assignmentResponseList[0].totalAttempts <
                                        e.maxAttempts ? (
                                        <div className="boxfrt">
                                          <button
                                            className="ant-btn ant-btn-primary cutom-button"
                                            onClick={() => {
                                              if (
                                                !props.selectedStudent ||
                                                !props.selectedStudent.schoolStudentId
                                              ) {
                                                return showToasterMessage({
                                                  description: "Please select a student",
                                                  messageType: "error",
                                                });
                                              } 
                                              props.pushModalToStack({
                                                title: "Assignment",
                                                modalBody: (
                                                  <AssignmentSubmission {...props}
                                                    assignmentId={e.assignmentId}
                                                    asmData={e}
                                                    questionList={JSON.parse(e?.questionList || '[]')}
                                                    studentId={
                                                      props.selectedStudent.schoolStudentId
                                                    }
                                                    classId={props.selectedClassId}
                                                    className={props.className}
                                                    assignmentList={assignmentList}
                                                    studentName={`${props.selectedStudent.firstName} ${props.selectedStudent.lastName}`}
                                                  />
                                                ),
                                                width: "100%",
                                                hideFooter: true,
                                                wrapClassName: "model-open-wrapper",
                                              });
                                              
                                            }}
                                          >
                                            <span>
                                              Submit Assignment
                                              {/* {(e.numberOfAttempts === 'Multiple' && e.maxAttempts) ? <span className="remaining-attemps-text">({(e.maxAttempts - ((e.assignmentResponseList[0] || {}).totalAttempts || 0))} Attempts Left)</span> : ''} */}
                                              {e.numberOfAttempts === "Multiple" &&
                                              e.maxAttempts ? (
                                                <span className="remaining-attemps-text">
                                                  (
                                                  {(e.assignmentResponseList[0] || {})
                                                    .totalAttempts || 0}
                                                  /{e.maxAttempts} Attempts)
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </Col>
                                );
                              })
                            ) : (
                              <div className="mt10 ml10">
                                No assignment available currently.
                              </div>
                            )}
                          </Row>
                        </div>
                      </TabPane>
                    );
                  })}
                </Tabs>
              </div>
            </div>
            :
            ''
        }
      </div>
    </>
  );
};

export default injectIntl(Assignment);
