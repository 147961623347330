import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Tooltip, DatePicker, Radio } from 'antd';
import { showToasterMessage, formatDateForBackend, getMomentDateForUI, getUserDefinedName } from '../../../../../utils'
import { DropdownRef } from '../../../../general/Dropdown';
import { CustomButton } from '../../../../general/CustomButton';
import {  MODAL_TYPE, CONSTANTS } from '../../../../../static/constants';
import { TextBox } from '../../../../general/TextBox';
import moment from 'moment';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class NewAddressComponent extends Component {
  constructor(props) {
    super(props);
    props.updateState({ ...this.props.data.formData });
    this.state = {
      schoolType: 'school',
      ...props.data.formData
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    
    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content billing-address-control pro-address">
          <Form.Item label={'Education Type'} colon={false}>
              {getFieldDecorator('schoolType', {
                initialValue: this.state.schoolType || '',
                rules: [
                  {
                    required: true,
                    message: 'School type is required',
                  }]
              })
                ( (
                // <DropdownRef 
                //   items={[{ name: 'School', value: 'school'},   { name: 'College/University', value: 'college'} ]}
                //   optionKeyName='value'
                //   valueKeyName='name'
                //   onSelect={(selectedValue, option) => {
                //     this.setState({
                //       schoolTypeName: option.name,
                //       schoolType: option.key
                //     });
                //     this.props.form.setFieldsValue({ 'schoolType': selectedValue });
                //     this.props.form.setFieldsValue({ 'schoolTypeName': option.name });
                //   }}
                // />
                  <Radio.Group value={this.state.schoolType} onChange={(e) => {
                    this.setState({ schoolType: e.target.value  });
                    this.props.form.setFieldsValue({ 'schoolType': e.target.value });
                  }} >
                    <Radio value={'school'}>School</Radio>
                    <Radio value={'college'}>College/University</Radio>
                  </Radio.Group>
                ))}
            </Form.Item>
           
            <Form.Item label={<span>{this.state.schoolType === 'school' ? 'School': 'College/University'}<span className="required">*</span></span>} colon={false}>
              {getFieldDecorator('institutionName', {
                initialValue: ((this.props.data || {}).formData || {}).institutionName || '',
                rules: [
                  {
                    required: true,
                    message: 'Institution name is required',
                  }]
              })
                (<TextBox maxLength={45} placeholder={this.state.schoolType === 'school' ? 'School': 'College/University'} />)}
            </Form.Item>
            {this.state.schoolType === 'college' ?
              <div>
                <Form.Item label={'Degree Type'} colon={false}>
                  {getFieldDecorator('degreeType', {
                    initialValue: ((this.props.data || {}).formData || {}).degreeType || '',
                    // rules: [
                    //   {
                    //     required: true,
                    //     message: 'Institution name is required',
                    //   }]
                  })
                    (<TextBox maxLength={45} placeholder={'Degree Type'} />)}
                </Form.Item>
                <Form.Item label={'Major'} colon={false}>
                  {getFieldDecorator('major', {
                    initialValue: ((this.props.data || {}).formData || {}).major || '',
                    // rules: [
                    //   {
                    //     required: true,
                    //     message: 'Institution name is required',
                    //   }]
                  })
                    (<TextBox maxLength={45} placeholder={'Major'} />)}
                </Form.Item>
                
              </div>
              : <div>
                
                <Form.Item label={<span>{getUserDefinedName('Grade', this.props)}</span>} colon={false}>
              {getFieldDecorator('grade', {
                initialValue: ((this.props.data || {}).formData || {}).grade || '',
                // rules: [
                //   {
                //     required: true,
                //     message: 'School Grade is required',
                //   }]
              })
                ( (<DropdownRef 
                  placeholder={'School Grade'}
                  items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                  onSelect={(selectedValue, option) => {
                    this.setState({
                      grade: selectedValue
                    });
                    this.props.form.setFieldsValue({ 'grade': selectedValue });
                  }}
                />))}
            </Form.Item>
              </div>
            }
             <Form.Item label={'Score/GPA'} colon={false}>
                  {getFieldDecorator('score', {
                    initialValue: ((this.props.data || {}).formData || {}).score || '',
                  })
                    (<TextBox maxLength={45} placeholder={'Grade/GPA'} />)}
                </Form.Item>
           
            <Form.Item label={<FormattedMessage id='modal.common.project.startDate' defaultMessage='' />} colon={false}>
              {getFieldDecorator('startDate', {
                initialValue: this.state.startDate ? getMomentDateForUI({ date: new Date(this.state.startDate), format }) : null
              })
                (<DatePicker
                  allowClear={true}
                  format={format}
                  key='startDate'
                  defaultValue={this.state.startDate ? getMomentDateForUI({ date: new Date(this.state.startDate), format }) : null}
                  value={this.state.startDate ? getMomentDateForUI({ date: new Date(this.state.startDate), format }) : null}
                  onChange={(selectedDate) => {
                    this.setState({ startDate: selectedDate });
                  }}
                  size={0}
                  // disabledDate={(current) => {
                  //   return current && current > moment().endOf('day');
                  // }}
                />)}
                
            </Form.Item>
                 <Form.Item label={<FormattedMessage id='modal.common.project.endDate' defaultMessage='' />} colon={false}>
              {getFieldDecorator('endDate', {
                initialValue: this.state.endDate ? getMomentDateForUI({ date: new Date(this.state.endDate), format }) : null
              })
                (<DatePicker
                  allowClear={true}
                  format={format}
                  key='endDate'
                  defaultValue={this.state.endDate ? getMomentDateForUI({ date: new Date(this.state.endDate), format }) : null}
                  value={this.state.endDate ? getMomentDateForUI({ date: new Date(this.state.endDate), format }) : null}
                  onChange={(selectedDate) => {
                    this.setState({ endDate: selectedDate });
                  }}
                  size={0}
                  // disabledDate={(current) => {
                  //   return current && current > moment().endOf('day');
                  // }}
                />)}
                </Form.Item>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.address.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => {
                  if(((this.props.data || {}).formData || {}).onCancel){
                    this.props.data.formData.onCancel();
                  }

                  if(((this.props.data || {}).formData || {}).addressType === 'relationship'){
                    this.props.hideModal();
                  }else{
                    this.props.hideModal();
                    this.props.popModalFromStack() 
                  }
                  
                }}
              />
              <CustomButton
                intlId={'confirmation.submit'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          institutionName: values.institutionName,
          version: ((this.props.data || {}).formData || {}).version,
          degreeType: values.degreeType,
          major: values.major,
          grade: values.grade,
          startDate: values.startDate ? formatDateForBackend(values.startDate) : null,
          endDate: values.endDate ? formatDateForBackend(values.endDate) : null,
          contactId: ((this.props.data || {}).formData || {}).contactId,
          customerId: ((this.props.data || {}).formData || {}).customerId,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          contactEducationId: ((this.props.data || {}).formData || {}).contactEducationId,
          schoolType: values.schoolType,
          score: values.score
        }

        const modalData = {
          modalBody: 'Are you sure you want to save?',
          handleSubmit: () => {
            this.props.createEducation([payload], (this.props.data || {}).formData, this.props);
            this.props.popModalFromStack()
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'New_address_component' })(injectIntl(NewAddressComponent));
