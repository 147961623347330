import React, { useState } from "react";
import { Tooltip } from 'antd';
import { injectIntl, FormattedMessage } from "react-intl";
import "./sidebar.scss";
import sideMenuList from "./menuList";
import { getInitialsFromString } from "../../utils";
import { LogoComponent } from "../general/LogoComponent";
import { CONSTANTS } from "../../static/constants";
import config from '../../config/environmentConfig';
import ToggleButton from '../../assets/images/toggle-button.svg';
import { Button } from "antd";
import { useHistory } from 'react-router-dom';
import { logout } from "../../actions/commonActions";

const SidebarMenu = React.memo((props) => {
    const history = useHistory();

  const [activeMenuChild, setMenuChild] = useState(false);
   const [expend, setexpend] = useState(false);

   const handleMenu = () =>{
     setexpend(!expend);

   }

   const handleViewProfile = () => {
    history.push('/admin/profile');
    handleMenu();
};

  return (
    <>
        <div className={`master-menu ${expend ? "master-menu-expend" : ""}`}>
            <ul>
                <li className="first-child-mm">
                 <div className="li-desktop-screen">
                       {(props.companyInfo || {}).logoAwsObjectKey ? (
                        <LogoComponent
                            src={CONSTANTS.BASE_URL_S3 +"/" +config.BUCKET_NAME.BO_RELATIONSHIP +"/" +(props.companyInfo || {}).logoAwsObjectKey +"?" +Date.now()}
                            size={45}
                            style={{borderRadius: '10px'}}
                        />
                        ) : 
                    <div className="name-initial">
                        {getInitialsFromString(props.companyInfo.storeName || 'Alpide Tech')}
                    </div>
                     }
                </div>
 
                <div className="li-mobile-screen">
                 <div className="left-menu">
                       {(props.companyInfo || {}).logoAwsObjectKey ? (
                        <LogoComponent
                            src={CONSTANTS.BASE_URL_S3 +"/" +config.BUCKET_NAME.BO_RELATIONSHIP +"/" +(props.companyInfo || {}).logoAwsObjectKey +"?" +Date.now()}
                            size={45}
                            style={{borderRadius: '10px'}}
                        />
                        ) : 
                     <div className="name-initial">
                        {getInitialsFromString(props.companyInfo.storeName || 'Alpide Tech')}
                    </div>
                     }

                    <div className="school-name-mobile">
                        {(props.companyInfo.storeName || 'Alpide Tech')}
                    </div> 
                  </div>
                    <div className="right-menu">
                      <Button className="toggle-button" onClick={handleMenu}>
                        <img src={ToggleButton} alt=""/>
                      </Button>
                    </div>
                </div>
                </li>
    
              <div className="menu-list-desktop">
              {/* <li>
                 <div className="li-desktop-screen">
                       {(props.companyInfo || {}).logoAwsObjectKey ? (
                        <LogoComponent
                            src={CONSTANTS.BASE_URL_S3 +"/" +config.BUCKET_NAME.BO_RELATIONSHIP +"/" +(props.companyInfo || {}).logoAwsObjectKey +"?" +Date.now()}
                            size={45}
                            style={{borderRadius: '10px'}}
                        />
                        ) : 
                    <div className="name-initial">
                        {getInitialsFromString(props.companyInfo.storeName || 'Alpide Tech')}
                    </div>
                     }
                </div>
 
                <div className="li-mobile-screen">
                 <div className="left-menu">
                       {(props.companyInfo || {}).logoAwsObjectKey ? (
                        <LogoComponent
                            src={CONSTANTS.BASE_URL_S3 +"/" +config.BUCKET_NAME.BO_RELATIONSHIP +"/" +(props.companyInfo || {}).logoAwsObjectKey +"?" +Date.now()}
                            size={45}
                            style={{borderRadius: '10px'}}
                        />
                        ) : 
                     <div className="name-initial">
                        {getInitialsFromString(props.companyInfo.storeName || 'Alpide Tech')}
                    </div>
                     }

                    <div className="school-name-mobile">
                        {(props.companyInfo.storeName || 'Alpide Tech')}
                    </div> 
                  </div>
                    <div className="right-menu">
                      <Button className="toggle-button" onClick={handleMenu}>
                        <img src={ToggleButton}/>
                      </Button>
                    </div>
                </div>
                </li> */}
                {sideMenuList && sideMenuList.length ? sideMenuList.map((item) => {                 
                    return <>
                        <Tooltip 
                            // title={<FormattedMessage id={item.menuName} />} 
                            placement="right"
                            overlayClassName='sidebar-tooltip'
                        >
                            <li 
                                onClick={()=>{
                                    if(item.rightIconClass){
                                        // if again clicked on opend menu, side popup will close
                                        if(props.activeMasterMenu === item.menuName){
                                            props.updateState({activeMasterMenu: false});
                                            return
                                        }
                                        props.updateState({activeMasterMenu: item.menuName});
                                        props.updateState({activeMenuList : item.childList});
                                    }
                                    else{
                                        props.updateState({activeMasterMenu:item.menuName});
                                        props.updateState({activeMenuList:false});
                                        props.history.push(item.path);
                                        return
                                    }
                                }
                            }>
                                <span className={`master-menu-icon ${props.activeMasterMenu === item.menuName ? ' active':''}`}>{item.iconClassName ? <i className={`${item.iconClassName}`} />:'XX'}                            
                                <span>{<FormattedMessage id={item.menuName} defaultMessage='' />}</span>
                                </span>
                                <span className="dot-menu-icon"><i className={item.rightIconClass}></i></span>
                                
                            </li>
                        </Tooltip>
                    </>
                })
                :''
                }
            </div>

               <div className="menu-list-mobile">

               <li>
                              
                              <span className={"master-menu-icon"}><i className={`{fi fi-rr-file-signature`}></i></span>
                              <span onClick={()=>handleViewProfile()}>My Profile</span>
                </li>

                {sideMenuList && sideMenuList.length ? sideMenuList.map((item) => {
                    return <>
                    
                            <li 
                                onClick={()=>{
                                    handleMenu();
                                    if(item.rightIconClass){
                                        // if again clicked on opend menu, side popup will close
                                        if(props.activeMasterMenu === item.menuName){
                                            props.updateState({activeMasterMenu: false});
                                            return
                                        }
                                        props.updateState({activeMasterMenu: item.menuName});
                                        props.updateState({activeMenuList : item.childList});
                                    }
                                    else{
                                        props.updateState({activeMasterMenu:item.menuName});
                                        props.updateState({activeMenuList:false});
                                        props.history.push(item.path);
                                        return
                                    }
                                }
                            }>
                                <span className={`master-menu-icon ${props.activeMasterMenu === item.menuName ? ' active':''}`}>{item.iconClassName ? <i className={`${item.iconClassName}`}></i>:'XX'}</span>
                                {/* <span className="dot-menu-icon"><i className={item.rightIconClass}></i></span> */}
                                <span>{<FormattedMessage id={item.menuName} defaultMessage='' />}</span>
                            </li>
                    </>
                 })
                :''
                }
                   <li>   
                     <span className={"master-menu-icon"}> <i className="fa fa-unlock "/></span>
                     <span onClick={() => logout()}>Sign out</span>
                   </li>
                </div>
            </ul>
        </div>
        
        <div className={`active-menu ${props.activeMasterMenu && props.activeMenuList ? 'visible' : ''}`}>
            {   props.activeMasterMenu && props.activeMenuList ? <>
                    {/* <div className="org-name-box">
                        {props.companyInfo.storeName}
                    </div> */}
                    <div className="menu-header">
                        <FormattedMessage id={props.activeMasterMenu} />
                        <div className="menu-closure" onClick={()=>{
                            props.updateState({activeMasterMenu: false});
                        }}>
                            <i class="fi fi-bs-angle-left"/>
                        </div>
                    </div>
                    <ul>
                        {
                            props.activeMenuList && props.activeMenuList.length ? props.activeMenuList.map((menuItem, index)=>{
                            return <> <li key={index}>
                                    <div
                                        className={`menu-item ${activeMenuChild && activeMenuChild ===  menuItem.menuName ? 'active' : ''}`}
                                        onClick={()=>{
                                            if(menuItem.rightIconClass){
                                                setMenuChild(activeMenuChild && activeMenuChild === menuItem.menuName ? false : menuItem.menuName);
                                            }
                                            else{
                                                props.history.push(menuItem.path || '/admin/not-found');
                                                setMenuChild(menuItem.menuName);
                                                props.updateState({activeMasterMenu: false})
                                                return
                                            }
                                        }
                                    }>
                                        <span className="menu-icon">{<i className={menuItem.iconClassName}></i>}</span>
                                        <span className="menu-name"><FormattedMessage id= {menuItem.menuName} /></span>
                                        <span className={`right-icon ${activeMenuChild && activeMenuChild ===  menuItem.menuName ? 'trans' : ''}`}>{<i className={menuItem.rightIconClass} />}</span>
                                    </div>

                                    {/* menu child */}
                                    <div className={`menu-childs ${activeMenuChild && activeMenuChild ===  menuItem.menuName ? 'open' : ''}`}>
                                        <ul>
                                            {
                                                menuItem.childList && menuItem.childList.length ? menuItem.childList.map((menuChildItem, j)=>{
                                                    return <li className={`menu-child-item ${props.activeChild && props.activeChild ===  menuChildItem.menuName ? 'active' : ''}`} onClick={()=>{
                                                        props.updateState({activeChild: menuChildItem.menuName});
                                                        props.history.push(menuChildItem.path || '/admin/not-found');
                                                        props.updateState({activeMasterMenu: false})
                                                    }}>
                                                        <FormattedMessage id={menuChildItem.menuName} />
                                                    </li>
                                                }):''
                                            }  
                                        </ul>
                                    </div>
                                </li>
                                </>
                            })
                            :
                            '' 
                        }
                    </ul>
                </>
            :
            ''
            }
        </div>
    </>
  )
  
});

export default injectIntl(SidebarMenu);