import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS} from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createEmployment = (payload, formData, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/employment/save`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      //  dispatch({ type:  actionType, data: res.data , formData});
        (props && props.getContactEmployment) && props.getContactEmployment(res.data);
        if(formData.funcToCall){
          formData.funcToCall(formData.funcToCallPayload)
        }
        if(formData.callback){
          formData.callback(res.data)
        }
        showToasterMessage({ messageType: 'success', description: 'Saved successfully' });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
         // err.actionToCall = actionType;
        }
      })
  }
}