import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { MODAL_ACTIONS, MODAL_TYPE } from '../../static/constants';
import { CustomButton } from '../general/CustomButton';
import { AddItemBody } from './../modal/modalBody/customerInfo';
import ContactBody from '../../containers/modal/modalBody/common/Contact';
import AddressBody from '../../containers/modal/modalBody/common/Address';
import EducationBody from '../../containers/modal/modalBody/common/Education';
import EmploymentBody from '../../containers/modal/modalBody/common/Employment';
import IframeComponent from './modalBody/common/IframeComponent';

import './modal.scss';

export function StackModal(props) {
  const modalStack = props.modalStack || [];

  return (
    <Fragment>
      {
        modalStack.map((modalObj, i) => <CustomModal key={i} {...modalObj} />)
      }
    </Fragment>
  );
}

function CustomModal(props) {
  const cancelBtnProps = (props.footer || {}).cancel || {};
  const submitBtnProps = (props.footer || {}).submit || {};
  const [formData, updateFormData] = useState(props.formData)
  const dispatch = useDispatch();
  const bodyHtml = getBody(props, formData, updateFormData);
  const defaultTitle = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />

  return (
    <Fragment>
      {
        !bodyHtml
          ? ''
          : (
            <Modal
              centered={false}
              title={props.hideTitle ? null : props.title || defaultTitle}
              visible={true} 
              closable={props.hideCloseIcon === true ? false : true}
              maskClosable={props.maskClosable === true? false : true}
              width={props.width}
              keyboard={props.keyboard}
              wrapClassName={props.wrapClassName}
              style={props.style}
              onCancel={() => { dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL }); props.handleSubmit && props.handleSubmit(); }}
              footer={props.hideFooter ? null : [
                <CustomButton
                  intlId={cancelBtnProps.intlId || 'confirmation.cancel'}
                  defaultMessage=''
                  type={cancelBtnProps.type || 'default'}
                  key={0}
                  onClick={() => { 
                    props.onCancel ? props.onCancel() : dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL }) 
                  }}
                />,
                <CustomButton
                  intlId={submitBtnProps.intlId || 'confirmation.submit'}
                  defaultMessage=''
                  htmlType='submit'
                  key={1}
                  onClick={() => {
                    document.getElementsByClassName("add-new-input-text").length && document.getElementsByClassName("add-new-input-text")[0].classList.add("input-text-error")
                    if (formData && Object.keys(formData).length) {
                      props.handleSubmit(formData);
                    } else {
                      props.handleSubmit();
                      dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL });
                    }
                  }}
                />,
              ]}
            >
              {
                bodyHtml
              }

            </Modal>
          )
      }
    </Fragment >
  );
}

const getBody = (props, formData, updateFormData) => {
  const { modalBody, modalData = {}, handleSubmit } = props;

  let body = <Fragment />;
  if (modalBody) {
    body = modalBody;
  } else {
    const { modalType } = modalData;
    switch (modalType) {
      case MODAL_TYPE.EMPLOYEE_TYPE: {
        body = <AddItemBody {...modalData} {...props} formData={formData} updateFormData={updateFormData} handleSubmit={handleSubmit} />
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.SHIPPING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
        body = <AddressBody {...modalData} formData={formData} handleSubmit={handleSubmit} />
        break;
      }
      case MODAL_TYPE.CONTACT: {
        body = <ContactBody {...modalData} formData={formData} handleSubmit={handleSubmit} />
        break;
      }
      case MODAL_TYPE.EDUCATION: {
        body = <EducationBody {...modalData} formData={formData} handleSubmit={handleSubmit} />
        break;
      }
      case MODAL_TYPE.EMPLOYMENT: {
        body = <EmploymentBody {...modalData} formData={formData} handleSubmit={handleSubmit} />
        break;
      }
      case MODAL_TYPE.URL_POPUP: {
        body = <IframeComponent {...modalData} formData={formData} handleSubmit={handleSubmit} />
        break;
      }
      default:
        break;
    }
   
  }
  return body;
}