import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MembershipComp from "../../components/Membership";
import { fetchPaginationDataIfNeeded } from "../../utils";
import { getAllMembrshipForm } from "./action";
import { getOrgRegistration } from "../Enrolled/action";
import { showModal, hideModal, pushModalToStack, popModalFromStack, getSubdomain } from './../../actions/commonActions';

class Membership extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      templateType: linkProps.templateType,
      enrolledForm: []
    };
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true,
    // });
    const companyInfo = this.props.companyInfo || {};
    const customerInfo = this.props.customerInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0, pageSize: 100,
      contactId: (customerInfo.boContactList[0] || {}).contactId,
      customerId: customerInfo.customerId,
    };
    fetchPaginationDataIfNeeded("getAllMembrshipForm", "membershipFormList", this.props, payload);
    this.props.getSubdomain(payload);
    //this.props.getOrgRegistration(payload);
  }
  componentWillReceiveProps(props) {
    if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
      this.setState({
        subdomainName: props.subdomain.subdomainName
      });
    }
    // if (props.registrationList && props.registrationList.length ) {
    //   var enrolledForm = props.registrationList.map(function(obj) {
    //     return obj.registrationFormSettingId;
    //   })
    //   this.setState({
    //     enrolledForm: enrolledForm || []
    //   });
    // }
  }

  render() {
    return <MembershipComp  {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}>

    </MembershipComp>;
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    customerInfo: state.common.customerInfo,
    membershipFormList: state.membership.membershipFormList,
    subdomain: state.common.subdomain,
    adminToken: state.common.adminToken,
    defaultSchoolForm: state.common.defaultSchoolForm,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllMembrshipForm,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getSubdomain,
      getOrgRegistration
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Membership);
