import React,{Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';

const Broadcast = (props) => {
  return (
   
    <Fragment>
   {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            Broadcast 
          </div>
        </div>
        <div className="top-view-right">
          
        </div>
      </div> */}
        <div className='view-container'>
        <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
              Broadcast Messages
              </div>
             
            </div>
            <div className="right-actions">
        
            </div>
          </div>
            {(props.broadcastMessageList && props.broadcastMessageList.length) ? 
                <div className='table-container'>
                    <table >
                          <thead>

                            <tr>
                                <th style={{ textAlign: 'center' }}>#</th>
                                <th>Subject</th>
                                <th style={{ textAlign: 'center', width: '60%' }}>Message</th>
                                <th>Documents</th>
                            </tr>
                          </thead>
                        <tbody>
                            {(props.broadcastMessageList || []).map((e, i) => {
                                return <tr key={'BMsg' + i}>
                                    <th style={{ textAlign: 'center' }}>{i + 1}</th>
                                    <td><div>{e.subject}</div></td>
                                    <td><div>{ReactHtmlParser(e.message || '')}</div></td>
                                    <td> <div>~No Docs~</div></td>
                                </tr>
                            })
                            }
                        </tbody>
                    </table>
                </div>
             : <div style={{ textAlign: 'center', }}>
                No Broadcast Message
            </div>

            }
            {/* <div className="footer">
                <div className="f-left"></div>
                <div className="f-right"></div>
            </div> */}
        </div>
    </Fragment>
  
  )
}

export default Broadcast