import React, { useState, useRef, useImperativeHandle } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';


const onCustomRequest = file => {
  return new Promise(((resolve, reject) => {
    setTimeout(() => {
      file.onSuccess(null);
    }, 100)
  }));
};

export const CustomUploaderGrid = (props) => {
  const [disabled, setDisabled] = useState(props.disabled || false);
  const uploaderRef = useRef();
  const [defaultFileList, setFileList] = useState(props.defaultFileList);
  useImperativeHandle(uploaderRef, () => {
      return {
          getValue: () => {
              return defaultFileList;
          },
          afterGuiAttached: () => {
            uploaderRef.current.focus();
          },
          isCancelAfterEnd: () => {
              if (props.lastColumnCell && props.node.lastChild && !props.node.shiftKey) {
                  props.api.updateRowData({ add: [{}] });
              }
              return false;
          }
      };
  });
  const onChange = ({ file, fileList }) => {
    if (file.status !== 'uploading') {
      if (props.fileLimit) {
        if (props.fileLimit === fileList.length) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      }
      if (props.onChange) {
        setFileList(fileList);
        props.onChange(fileList);
      }
    }
  }
  return (
    <Upload
      ref={uploaderRef}
      openFileDialogOnClick={!disabled}
      defaultFileList={defaultFileList || []}
      onChange={onChange}
      onDownload={props.onDownload}
      showUploadList={{
        showDownloadIcon: !props.hideDownloadIcon
      }}
      customRequest={onCustomRequest} {...props}
      listType={"text"}
      multiple={props.multiple || true}
    >
      <Button ref={props.reference} className={props.hideButton ? 'hide' : ''}>
        <UploadOutlined /> {props.buttonName || 'Upload File'}
      </Button>
    </Upload >
  );

}
