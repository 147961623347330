import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EditProfileComponent from "../../components/EditProfile";
import {
    saveRegistration
    // fetchRegistrationFormSetting, saveRegistration,
    // fetchRelationshipDataByRID, getTotalStudentcCount, getTotalStudentcCountByGrade,
    // fetchRelationshipByDomainName, fetchStudentFatherEmail, saveRegistrationFound,

} from './action';
import {getAllStudentForm } from '../OpenRegistration/action';
import { getOrgRegistration } from "../Enrolled/action";
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchCountries, fetchStates } from '../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../static/constants';

class EditProfile extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(this.props.location.search) || {};
        
        const customerInfo = props.customerInfo || {};
        //let contact = customerInfo.boContactList[0] || {};
        let contact = find(customerInfo.boContactList || [], { emailAddress: customerInfo.fatherEmail }) || {};
        let contactMother = find(customerInfo.boContactList || [], { emailAddress: customerInfo.motherEmail }) || {};
        let location = customerInfo.boLocationList[0] || {};
        this.state = {
            formSetting: {},
            status: linkProps.status,
           // relationshipId: linkProps.relationshipId,
            relationshipBillingAddress: {},
            ledgerAccountId: 0,

            primaryContact: customerInfo.primaryContact || 'father',
            customerId: props.customerInfo.customerId,
            contactId: contact.contactId,
            contactVersion: contact.version,
            
            fatherFirstName: customerInfo.fatherFirstName,
            fatherLastName: customerInfo.fatherLastName,
            fatherMobile: customerInfo.fatherMobile,
            fatherEmail: customerInfo.fatherEmail,
            motherFirstName: customerInfo.motherFirstName,
            motherLastName: customerInfo.motherLastName,
            motherMobile: customerInfo.motherMobile,
            motherEmail: customerInfo.motherEmail,

            fatherDOB: customerInfo.fatherDateOfBirth ? new Date(customerInfo.fatherDateOfBirth) : '',
            motherDOB: customerInfo.motherDateOfBirth ? new Date(customerInfo.motherDateOfBirth) : '',

            streetAddress1: location.streetAddress1,
            streetAddress2: location.streetAddress2,
            cityName: location.cityName,
            countryId: location.countryId,
            countryName: location.countryName,
            stateName: location.stateName,
            zipCode: location.zipCode,
            locationId: location.locationId,
            locationVersion: location.version,
            motherContactId: contactMother.contactId,
            motherContactVersion: contactMother.version,
        }
    }
    componentDidMount() {
        // const linkProps = queryString.parse(this.props.location.search) || {};
        // this.props.fetchRelationshipByDomainName({ domainName: window.location.host, registrationFormSettingId: linkProps.formId }, this.props);
        const companyInfo = this.props.companyInfo || {};
        const payload = {
          companyInfo,
          relationshipId: companyInfo.relationshipId,
          pageNumber: 0, pageSize: 100,
          customerId: this.state.customerId
        };
        this.props.fetchCountries();
        this.props.getAllStudentForm(payload);
        this.props.getOrgRegistration(payload);
    }

    componentWillReceiveProps(props) {

        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            this.setState({
                relationshipBillingAddress,
            })
        }
        if (props.countries !== this.state.countries && props.countries && !this.state.countryName) {
            let obj = props.countries.find(country => (country.countryName === (props.companyInfo).countryName)) || {};
            if ((obj.countryName === 'United States' || obj.countryName === 'United States (USA)' || obj.countryName === 'India') && !props.states.length) {
                this.props.fetchStates(obj.countryId);
                // fetchDataIfNeeded('fetchStates', 'states', this.props, obj.countryId);
            }
            this.setState({ countryName: obj.countryName || '', countryId: obj.countryId });
        }

        if (props.customerInfo && props.customerInfo.customerId && !this.state.isFormLoaded) {

            const customerInfo = props.customerInfo || {};
            // let contact = props.customerInfo.boContactList[0] || {};
            // let contactMother = props.customerInfo.boContactList[1] || {};

            let contact = find(props.customerInfo.boContactList || [], { emailAddress: props.customerInfo.fatherEmail }) || {};
            let contactMother = find(props.customerInfo.boContactList || [], { emailAddress: props.customerInfo.motherEmail }) || {};
            let location = props.customerInfo.boLocationList[0] || {};
            let studentList = [];
            if (customerInfo.boContactChildList && customerInfo.boContactChildList.length) {
                studentList = (customerInfo.boContactChildList || []).map((childContact) => {
                    childContact.dob = childContact.dob ? new Date(childContact.dob) : '';
                    return childContact;
                })
            }
            if ((location.countryName === 'United States' || location.countryName === 'United States (USA)' || location.countryName === 'India')) {
                this.props.fetchStates(location.countryId);
            }
            
            this.setState({
                isFormLoaded: true,
                studentList,
                primaryContact: customerInfo.primaryContact || 'father',
                customerId: props.customerInfo.customerId,
                contactId: contact.contactId,
                contactVersion: contact.version,

                fatherFirstName: customerInfo.fatherFirstName,
                fatherLastName: customerInfo.fatherLastName,
                fatherMobile: customerInfo.fatherMobile,
                fatherEmail: customerInfo.fatherEmail,
                motherFirstName: customerInfo.motherFirstName,
                motherLastName: customerInfo.motherLastName,
                motherMobile: customerInfo.motherMobile,
                motherEmail: customerInfo.motherEmail,

                fatherDOB: customerInfo.fatherDateOfBirth ? new Date(customerInfo.fatherDateOfBirth) : '',
                motherDOB: customerInfo.motherDateOfBirth ? new Date(customerInfo.motherDateOfBirth) : '',

                streetAddress1: location.streetAddress1,
                streetAddress2: location.streetAddress2,
                cityName: location.cityName,
                countryId: location.countryId,
                countryName: location.countryName,
                stateName: location.stateName,
                zipCode: location.zipCode,
                locationId: location.locationId,
                locationVersion: location.version,
                motherContactId: contactMother.contactId,
                motherContactVersion: contactMother.version,
            });
        }

    }
    render() {
        window.document.title = this.props?.companyInfo?.storeName || '';
        return <div>
            <EditProfileComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        relationshipId: state.common.relationshipId,
        companyInfo: state.common.companyInfo,
        countries: state.common.countries,
        states: state.common.states,
        customerInfo: state.common.customerInfo,
        studentFormList: state.school.studentFormList,
        defaultSchoolForm: state.common.defaultSchoolForm,
        registrationList: state.school.registrationList,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    //saveRegistration,
    // fetchRegistrationFormSetting,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    fetchCountries,
    fetchStates,
    saveRegistration,
    // fetchRelationshipDataByRID,
    // getTotalStudentcCount,
    // getTotalStudentcCountByGrade,
    // fetchRelationshipByDomainName,
    // fetchStudentFatherEmail,
    // saveRegistrationFound
    getAllStudentForm,
    getOrgRegistration
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
