import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import SignupComponent from '../../components/signup/SignupComponent';
import { signIn, doesEmailExist } from './action';
import { fetchDataIfNeeded } from '../../utils';
import { fetchCountries, fetchStates, showModal, hideModal } from './../../actions/commonActions';
import { fetchRelationshipByDomainName, fetchRelationshipDataByRID } from '../Login/action';

class Signup extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(props.location.search) || {};
    this.state = {
      plan: linkProps.plan,
      email: null,
      password: null,
      repeatPassword: null,
      company: null,
      country: null,
      primaryContact: 'father',
      contact: {
        firstName: null,
        lastName: null,
        workPhone: null
      },
      address: {
        street: null,
        streetCont: null,
        city: null,
        zip: null,
        state: null
      },
      invalidEmailClass: null,
      invalidPasswordClass: null,
      invalidRepeatPasswordClass: null,
      invalidCompanyClass: null,
      passwordMismatch: null,
      submittedOnce: false,
    }
  }

  componentDidMount() {
    // const linkProps = queryString.parse(this.props.location.search) || {};
    this.props.fetchRelationshipByDomainName({ domainName: window.location.host }, this.props);
    fetchDataIfNeeded('fetchCountries', 'countries', this.props);
  }

  render() {
    return (
      <Fragment>
        <SignupComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    states: state.common.states,
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.relationshipId,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCountries,
  fetchStates,
  signIn,
  showModal,
  hideModal,
  fetchRelationshipByDomainName, 
  fetchRelationshipDataByRID,
  doesEmailExist
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
