import React, {useState} from 'react';
import { Input } from 'antd';
import './general.scss';
export function TextBox(props) {
  let className = props.className || 'input-text';
  if (props.value && props.validator && !props.validator(props.value)) {
    className = props.invalidClassName || 'input-text-error text-red';
  }

  let [isFocus, setIsFocus] = useState(false)

  return (
   <div style={{position:'relative'}}>
      <Input
      placeholder={props.placeholder}
      type={props.type || 'text'}
      maxLength={props.maxLength}
      defaultValue={props.type === 'number'?(props.value || ''): (props.value || '')}
      className={className}
      onFocus={() => {
        setIsFocus(true);
      }}
      onBlur={()=>{
        props.onBlur && props.onBlur();
        setIsFocus(false);
      }}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      required={props.required}
      ref={props.ref}
    />
     {/* {isFocus ? <div style={{textAlign: "right"}}>{(props.maxLength && (props.value || "").length) ? props.value.length +" / " + props.maxLength + " characters": "" }</div>: null} */}
     {isFocus ? <div style={{textAlign: "right", position:'absolute', right:'10px', top:'7px', lineHeight:'30px'}}>{(props.maxLength && (props.value || "").length) ? props.value.length +" / " + props.maxLength + " chars": "" }</div>: null}
     </div>
  )
}