import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Tabs } from "antd";
import './index.scss';
import PendingFees from "./PendingFees";
import PageBreadcrumb from "../PageBreadcrumb";
const { TabPane } = Tabs;


const FeeList = (props) => {

  const breadCrumbList=[
    {
      name: <FormattedMessage id='breadcrum.dashboard' defaultMessage='' />,
    },
    {
      name: <FormattedMessage id='heading.feesManagement.text' defaultMessage='' />,
    },
  
    {
      name: <FormattedMessage id='list.text' defaultMessage='' />,
    },
  ];


  // const handleTabChange = (tabKey) => {
  //   props.updateState({
  //     activeTab: tabKey
  //   })
  //   switch(tabKey){
  //     case 'fees': {
  //         console.log('Fees Tab')
  //       }
  //       break;
  //     case 'pending_fees': {
  //       props.getInvoiceProforma({
  //         relationshipId: props.companyInfo.relationshipId,
  //         customerId: 240 || props.customerInfo.customerId,
  //         orgRegistrationId: 388

  //       });
  //         console.log('Pending Fees Tab')
  //       }
  //       break;
  //     default: break;

  //   }
  // };

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="heading.feesManagement.text"
              defaultMessage=""
            />
            </div>
             <div className="vertical-sep" />
             <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
        <div className="top-view-right"></div>
      </div>
      <div className="view-container">
        <Tabs
          // activeKey={props.activeTab}
          // onChange={handleTabChange}
          type="line"
        >
          {props.allStudentList.map((student, index)=>{
            return <TabPane key={student.schoolStudentId} tab={(student.firstName || `Student ${index}`) + " " + (student.lastName || '')}>
              <PendingFees {...props}/>
            </TabPane>
          })}
        </Tabs>
      </div>
    </>
  );
};

export default injectIntl(FeeList);
