import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Tooltip } from 'antd';
import { showToasterMessage } from '../../../../../utils'
import { DropdownRef } from '../../../../general/Dropdown';
import { CustomButton } from '../../../../general/CustomButton';
import { TextBox } from '../../../../general/TextBox';

class NewAddressComponent extends Component {
  constructor(props) {
    super(props);
    props.updateState({ ...this.props.data.formData })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    

    const _getLocationType = () =>{
      if(((this.props.data || {}).formData || {}).addressType === 'relationship'){
        return [
          {
            name: "Shipping Address",
            value: "RelationshipShippingAddress"
          },
          {
            name: "Billing Address",
            value: "RelationshipBillingAddress"
          }
        ]
      }else{
        return [
          {
            name: "Billing Address",
            value: "BillingAddress"
          },
          {
            name: "Shipping Address",
            value: "ShippingAddress"
          }
        ]
      }
    }

    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content billing-address-control pro-address">
            {/* <Form.Item label={(
              <Tooltip placement="bottom" title={<FormattedMessage id='address.primary.message' defaultMessage="" />}>
                <span className="tooltip-title"><FormattedMessage id='address.primary' defaultMessage='' /></span>
              </Tooltip>
            )} colon={false}>
              {getFieldDecorator('isDefault', {
                initialValue:  ((this.props.data || {}).formData || {}).isDefault
              })(<Checkbox checked={this.props.isDefault? true: false} onChange={(e)=>{
                this.props.form.setFieldsValue({ 'isDefault': e.target.checked });
                this.props.updateState({
                  isDefault: e.target.checked 
                })
              }} className='login-form-checkbox'></Checkbox>)}
            </Form.Item>
            <Form.Item label={(
              <Tooltip placement="bottom" title="Select address type">
                <span className="tooltip-title">{<FormattedMessage id='address.type' defaultMessage='' />}</span>
              </Tooltip>
            )} colon={false}>
              {getFieldDecorator('locationType', {
                initialValue: ((this.props.data || {}).formData || {}).locationType
              })(
                <DropdownRef
                  items={_getLocationType()}
                  optionKeyName='value'
                  
                  valueKeyName='name'
                  onSelect={(selectedValue, option) => {
                    this.props.form.setFieldsValue({ 'locationType': selectedValue });
                  }}
                />
              )}
            </Form.Item> */}
            <Form.Item label={<FormattedMessage id='address.streetName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('streetAddress1', {
                initialValue: this.props.streetAddress1 || '',
                rules: [
                  {
                    required: true,
                    message: <FormattedMessage id='address.validation.street' defaultMessage='' />,
                  }]
              })
                (<TextBox maxLength={45} placeholder={this.props.intl.formatMessage({ id: 'address.streetName', defaultMessage: '' })} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.streetNameContd' defaultMessage='' />} colon={false}>
              {getFieldDecorator('streetAddress2', {
                initialValue: this.props.streetAddress2 || ''
              })
                (<TextBox maxLength={45} placeholder={this.props.intl.formatMessage({ id: 'address.streetNameContd', defaultMessage: '' })} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.city' defaultMessage='' />} colon={false}>
              {getFieldDecorator('cityName', {
                initialValue: this.props.cityName || '',
                rules: [
                  {
                    required: this.props.countryName === 'India' ? true : false,
                    message: <FormattedMessage id='address.validation.city' defaultMessage='' />,
                  }]
              })
                (<TextBox maxLength={25} placeholder={this.props.intl.formatMessage({ id: 'address.city', defaultMessage: '' })} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.country' defaultMessage='' />}  colon={false} >
              {getFieldDecorator('countryName', {
                initialValue: ((this.props.data || {}).formData || {}).countryName || this.props.countryName,
                rules: [
                  {
                    required: true,
                    message: <FormattedMessage id='signup.validation.country' defaultMessage='' />,
                  }]
              })
                (<DropdownRef
                  items={this.props.countries || []}
                  optionKeyName='countryId'
                  valueKeyName='countryName'
                  onSelect={(selectedValue, option) => {
                    this.props.updateState({ 'countryId': selectedValue });
                    this.props.form.setFieldsValue({ 'countryName': option.props.name });
                    this.props.fetchStates(selectedValue)
                  }}
                />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.country' defaultMessage='' />} className='hide' colon={false}>
              {this.props.countries && getFieldDecorator('countryId', {
                initialValue: ((this.props.data || {}).formData || {}).stateName
              })
                (<span/>)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.state' defaultMessage='' />} className='hide' colon={false}>
              {getFieldDecorator('stateName', {
                initialValue: this.props.stateName || ''
              })
                (<span />)}
            </Form.Item>
            {(this.props.form.getFieldValue('countryName') === 'India' || this.props.form.getFieldValue('countryName') === 'United States' || this.props.form.getFieldValue('countryName') === 'United States (USA)') ?
              <Form.Item label={<FormattedMessage id='address.state' defaultMessage='' />} colon={false}>
                {getFieldDecorator('stateId', {
                  initialValue: ((this.props.data || {}).formData || {}).stateName,
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='address.validation.state' defaultMessage='' />,
                    }]
                })
                  (<DropdownRef
                    items={this.props.states || []}
                    optionKeyName='stateId'
                    valueKeyName='stateName'
                    onSelect={(selectedValue, option) => {
                      this.props.form.setFieldsValue({ 'stateId': selectedValue });
                      this.props.form.setFieldsValue({ 'stateName': option.props.name });
                    }}
                  />)}
              </Form.Item>
              :
              <Form.Item label={<FormattedMessage id='address.state' defaultMessage='' />} colon={false}>
                {getFieldDecorator('stateName', {
                  initialValue: this.props.stateName || '',
                  rules: [
                    {
                      required: false,
                      message: <FormattedMessage id='address.validation.state' defaultMessage='' />,
                    }]
                })
                  (<TextBox maxLength={45} placeholder={this.props.intl.formatMessage({ id: 'address.state', defaultMessage: '' })} />)}
              </Form.Item>

            }
            <Form.Item label={<FormattedMessage id='address.zip' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('zipCode', {
                initialValue: this.props.zipCode || ''
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.zip' defaultMessage='' />} colon={false}>
              {getFieldDecorator('zipCode', {
                initialValue: this.props.zipCode || ''
              })
                (<TextBox maxLength={25}  placeholder={this.props.intl.formatMessage({ id: 'address.zip', defaultMessage: '' })} />)}
            </Form.Item>
          </div>
          {console.log(`address`, this.props)}
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.address.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => {
                  if(((this.props.data || {}).formData || {}).onCancel){
                    this.props.data.formData.onCancel();
                  }

                  if(((this.props.data || {}).formData || {}).addressType === 'relationship'){
                    this.props.hideModal();
                  }else{
                    this.props.hideModal();
                    this.props.popModalFromStack() 
                  }
                  
                }}
              />
              <CustomButton
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          locationName: values.locationName,
          streetAddress1: values.streetAddress1,
          version: ((this.props.data || {}).formData || {}).version,
          streetAddress2: values.streetAddress2,
          cityName: values.cityName,
          locationId: this.props.locationId ? this.props.locationId : 0,
          countryId: this.props.countryId || ((this.props.data || {}).formData || {}).countryId,
          countryName: values.countryName,
          stateName: values.stateName,
          zipCode: values.zipCode,
          customerId: ((this.props.data || {}).formData || {}).customerId || this.props.customerId,
          supplierId: ((this.props.data || {}).formData || {}).supplierId,
          locationType: 'BillingAddress', //values.locationType || ((this.props.data || {}).formData || {}).locationType,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          //dateUpdated: getCurrentDateForBackend(new Date()),
          isDefault: values.isDefault ? 1 : 1,
        }

        if (this.props.data && this.props.data.formData && this.props.data.formData.customerId) {
          payload.customerId = this.props.data.formData.customerId;
        }
        else if (this.props.data && this.props.data.formData && this.props.data.formData.supplierId) {
          payload.supplierId = this.props.data.formData.supplierId;
        }

        if(!payload.locationType){
          return showToasterMessage({
            description: 'Location type is mandatory.',
            messageType: 'error'
          })
        }

        const modalData = {
          modalBody: 'Are you sure you want to create address ?',
          handleSubmit: () => {
            this.props.createAddress(payload, (this.props.data || {}).formData, this.props);
            this.props.popModalFromStack();
            this.props.hideModal();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'New_address_component' })(injectIntl(NewAddressComponent));
