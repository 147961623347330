import React ,{updateState}from 'react';
import { connect } from 'react-redux';
import config from '../../config/environmentConfig';
import { injectIntl, FormattedMessage } from 'react-intl';
import './header.scss';
import { bindActionCreators } from 'redux';
import { Row, Col, Button } from 'antd';
//import AlpideLogo from '../../assets/images/alpide-logo.png';
//import AlifLogo from '../../assets/images/alifapp-logo.png';
import PartialViewRoute from '../../route/partialViewRoute';
// import SidebarMenu from '../sidebar';
import SidebarMenu from '../sidebar/sidebar.js';
import { LOCAL_STORAGE_KEYS, COMMON_ACTIONS } from '../../static/constants';
//import HeaderMenuOptions from './HeaderMenuOptions';
import { SignoutForm } from './Signout';
import { fetchFromLocalStorage, checkACLPermission, getAWSFileURL ,getGreetingFromTime, getUserDefinedName, addToLocalStorage} from '../../utils';
import { setLocalStorageData, pushModalToStack, popModalFromStack,  showModal, hideModal, setDefaultSchoolForm, setFlash, setDefaultCourse } from '../../actions/commonActions';
import {getAllStudentForm } from '../../containers/OpenRegistration/action';
import {getAllCustomerCourses } from '../../containers/OpenRegistration/action';
import { uploadImage } from '../../actions/commonActions';
import FlashGif from '../../assets/images/form1.png';
import FlashGif2 from '../../assets/images/form2.png';
import EducationComp from '../../containers/Profile/Education/index.js';
import EmploymentComp from '../../containers/Profile/Employment/index.js';
import ContactComp from '../../containers/Profile/AddFamilyMember/index.js';
import Communication from '../../containers/Communication/index.js';
import { getStudentsByCustomerId, getStudentsByCustomerIdAndCourseId } from '../../containers/Dashboard/action.js';
import { Dropdown } from '../general/Dropdown.js';



class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeaderMenuOptions: false,
            showUserDropdown: false,
            isFlashVisible: false,
            headerHeading: '',
            defaultCourseLoaded : false,
            drawer: {
                educationDrawerVisible: false,     
            },
            defaultCourse: JSON.parse(fetchFromLocalStorage(COMMON_ACTIONS.DEFAULT_COURSE) || '0')
        }
        // const customerInfo = props.customerInfo || {};
        
        const loggedInCompanyInfo = fetchFromLocalStorage(COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO) ? JSON.parse(fetchFromLocalStorage(COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO)) : {};
        const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
        const adminToken = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.ADMIN_TOKEN) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.ADMIN_TOKEN)) : {};
        const loggedInEmail = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_EMAIL) ;
        const payload = {
            companyInfo: loggedInCompanyInfo,
            loggedInEntityInfo: loggedInEntityInfo,
            adminToken: adminToken,
            loggedInEmail: loggedInEmail,
        };
        props.setLocalStorageData(payload);
    }

    toggleUserDropdown = () => {
        const { showUserDropdown } = this.state;
        this.setState({
            showUserDropdown: !showUserDropdown
        });
    }

    componentDidMount(){
        const payload = {
            relationshipId: JSON.parse(fetchFromLocalStorage(COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO) || '0').relationshipId,
            customerId: JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) || '0').customerId,
            courseId: JSON.parse(fetchFromLocalStorage(COMMON_ACTIONS.DEFAULT_COURSE) || '0'),
        }
        this.props.getStudentsByCustomerIdAndCourseId(payload);
        this.props.getAllCustomerCourses(payload);
    }
    

    componentWillReceiveProps(props){
        let labelConfig = props.companyInfo.labelConfig ? JSON.parse(props.companyInfo.labelConfig) : [];
        this.setState({
            labelConfig: labelConfig,
        })
        if(!props.defaultCourseLoaded && (props.schoolCourseList || []).length && props.schoolCourseList[0].courseId){
            this.setState({defaultCourseLoaded: true});
        }
    }
      
    render() {
        const { showUserDropdown, headerHeading } = this.state;
        // const primaryPermissions = (this.props.permissions || {}).primary || [];
        // const filteredMenuData = filterOnBasisOfPermission(headerMenuOptionsData, primaryPermissions) || [];
        const {customerInfo, companyInfo, studentFormList=[]} = this.props

        return (
            <>
                {false && this.props.isFlashVisible ?     
                    <div className='top-layer'>
                        <div className='info-container'>
                            <div className='left'>
                                {
                                    studentFormList.length > 1 ?
                                    <img src={FlashGif} alt='Form Register' height='120px' width='120px' />
                                    :
                                    (
                                        studentFormList.length === 1 ?
                                        ''
                                        :
                                        // <svg xmlns="http://www.w3.org/2000/svg" width="119" height="119" viewBox="0 0 119 119" fill="none">
                                        //     <g clip-path="url(#clip0_3672_5380)">
                                        //     <path d="M45.8643 0H8.67678C3.89216 0 0 3.89216 0 8.67678V30.9893C0 35.7748 3.89216 39.667 8.67678 39.667H45.8643C50.6498 39.667 54.542 35.7748 54.542 30.9893V8.67678C54.542 3.89216 50.6498 0 45.8643 0Z" fill="#5555E3"/>
                                        //     <path d="M45.8643 49.583H8.67678C3.89216 49.583 0 53.4752 0 58.2607V110.323C0 115.108 3.89216 119 8.67678 119H45.8643C50.6498 119 54.542 115.108 54.542 110.323V58.2607C54.542 53.4752 50.6498 49.583 45.8643 49.583Z" fill="#4545E3"/>
                                        //     <path d="M110.323 79.333H73.1357C68.3502 79.333 64.458 83.2252 64.458 88.0107V110.323C64.458 115.108 68.3502 119 73.1357 119H110.323C115.108 119 119 115.108 119 110.323V88.0107C119 83.2252 115.108 79.333 110.323 79.333Z" fill="#5555E3"/>
                                        //     <path d="M110.323 0H73.1357C68.3502 0 64.458 3.89216 64.458 8.67678V60.7393C64.458 65.5248 68.3502 69.417 73.1357 69.417H110.323C115.108 69.417 119 65.5248 119 60.7393V8.67678C119 3.89216 115.108 0 110.323 0Z" fill="#4545E3"/>
                                        //     </g>
                                        //     <defs>
                                        //     <clipPath id="clip0_3672_5380">
                                        //     <rect width="119" height="119" fill="white"/>
                                        //     </clipPath>
                                        //     </defs>
                                        // </svg>
                                        <div style={{height: '180px', width: '180px', padding:'30px', backgroundColor: '#603030'}}>
                                            <img src={FlashGif2} alt='Form Register' height='120px' width='120px' />
                                        </div>
                                    ) 
                                }

                            </div>
                            <div className='right'>
                                <i className='fi fi-br-cross close' onClick={()=>this.props.setFlash(false)}></i>
                                <div className='header'>
                                    Welcome to the {companyInfo.storeName}
                                </div>
                                <div className='body'>
                                    <div><b>Dear {customerInfo.companyName || 'User'},</b></div>
                                    <div>Congratulations on joining our {companyInfo.storeName} Parent Portal! </div>
                                    {this.props.studentFormList.length ? 
                                        <><div>We have introduced a new online form.</div>
                                        <div className='form-list'>
                                            {this.props.studentFormList.map(obj=>{
                                                return <div>{obj.programName || ''}</div>
                                            })}
                                        </div>
                                        </>
                                        :
                                        <div>
                                            Kindly click below to proceed to the Portal Dashboard.
                                        </div>
                                    }
                                    {/* <div><b>Admission Registrations are Open for {companyInfo?.fyStartDate?.split("-")[0]}.</b></div> */}
                                    {/* <div>Please click below to complete the online registration for your child's admission process.</div> */}
                                    {
                                        studentFormList.length > 1 ?
                                            <Button 
                                                type='primary'
                                                onClick={()=>{
                                                    this.props.history.push('/admin/open-registration');
                                                    this.props.setFlash(false);
                                                }}   
                                            >
                                                Go to Registration Form List
                                            </Button>
                                        :
                                        (studentFormList.length === 1 && !this.props.customerInfo?.orgRegistrationList[0]?.orgRegistrationId ?
                                            <Button 
                                                type='primary'
                                                onClick={()=>{
                                                    this.props.setFlash(false);
                                                    // openFirstForm(studentFormList[0]);
                                                    this.props.history.push({
                                                        pathname: "/admin/register",
                                                        state: {
                                                          formId: studentFormList[0].registrationFormSettingId,
                                                        //   orgRegistrationId: this.props.customerInfo?.orgRegistrationList[0]?.orgRegistrationId || 0,
                                                        },
                                                      });
                                                }}   
                                            >
                                                Register Now
                                            </Button>
                                            :
                                            <Button 
                                                type='primary'
                                                onClick={()=>{
                                                    this.props.setFlash(false);
                                                    this.props.history.push('/admin/dashboard')
                                                }}   
                                            >
                                                Go to Dashboard
                                            </Button>
                                        )
                                    }

                                    <div>We are looking forward to support your child's academic journey!</div>

                                    <div>
                                        Best Regards,<br/>
                                        <b>Team - {companyInfo.storeName || ''}</b>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    ''
                }

                <div className='header-wrapper'>

                    <div className='header-left-section'>
                        <ul>

                            {/* <li onClick={this.toggleCollapsed}
                                span={2}
                                className='header-option menu-option-wrapper'>
                                <div className="menu-icon">
                                    <div className="bar line-1" />
                                    <div className="bar line-2" />
                                    <div className="bar line-3" />
                                </div>
                               
                            </li>
                            <li
                                span={2}
                                className="header-option"
                            >
                                {(this.props.companyInfo || {}).logoAwsObjectKey ? <img  style={{ width: '30px', height: '30px',marginRight: '10px'}} src={getAWSFileURL((this.props.companyInfo || {}).logoAwsObjectKey, config.BUCKET_NAME.BO_RELATIONSHIP)} alt=""/>: ''}
                                {(this.props.companyInfo || {}).storeName}
                            </li> */}
                        </ul>
                
                        {/* <div className='left-head'>{getGreetingFromTime(new Date())}</div> */}
                        {/* <div className='left-head'>{this.state.headerHeading}  */}
                         <div className='name-heading'>Hello, {customerInfo ? customerInfo.companyName: 'User'}</div>
                         {/* </div> */}
                         
                    </div>

                    <div className='header-right-section'>
                        <ul>
                         {/* {
                                (Array.isArray(filteredMenuData) && filteredMenuData.length && !this.state.isAlifApp)
                                    ? (
                                        <li
                                            span={2}
                                            onClick={this.toggleHeaderMenuOptions}
                                            className='header-option menu-option-wrapper'
                                        >
                                            <i className={`fa ${showHeaderMenuOptions ? 'fa-times' : 'fa-plus'}`} />
                                            {
                                                showHeaderMenuOptions && (
                                                    <HeaderMenuOptions
                                                        updateState={(data) => { this.setState(data) }}
                                                        data={filteredMenuData}
                                                        getSelectedOption={this.getSelectedHeaderMenuOption}
                                                    />
                                                )
                                            }
                                        </li>
                                    )
                                    : <Fragment />
                            }  */}
                            
                            {/* {!this.state.isAlifApp ?
                                <li
                                    span={2}
                                    className="header-option"
                                >
                                    <i className="fa fa-bell" />
                                </li>
                                : ''
                            } */}
                            {/* <li>
                                <select 
                                    name="programName"
                                    value={this.props.defaultSchoolForm}
                                    style={{width: '200px'}}
                                    onChange={(e) => {
                                        let selectedForm = find(this.props.studentFormList, { registrationFormSettingId: Number(e.target.value) });
                                        this.props.showModal({
                                            modalBody: `Are you sure you want to set ${selectedForm.formShortName} as a default program?`,
                                            handleSubmit: () => {
                                                this.props.setDefaultSchoolForm({
                                                    relationshipId: this.props.companyInfo.relationshipId,
                                                    formId: selectedForm.registrationFormSettingId,
                                                    userId: (this.props.userInfo || {}).userId,
                                                    clientOnlineUserAccountId:  (this.props.customerInfo|| {}).clientOnlineUserAccountId
                                                }, this.props);
                                                // addToLocalStorage("defaultProgram", e.target.value);
                                            }
                                        })
                                    }}
                                >
                                    {(this.props.studentFormList || []).map((data, i) => {
                                        return <option key={i} value={data.registrationFormSettingId || ""}>{data.formShortName || ""}</option>
                                    })
                                    }
                                </select>
                            </li> */}

                            <li>
                                <Dropdown 
                                    style={{width: '200px', border: '1px solid #e7e7e7', borderRadius: '2px'}}
                                    items = {this.props.schoolCourseList}
                                    optionKeyName = 'courseId'
                                    valueKeyName = 'courseName'
                                    value={this.props.defaultCourse || this.state.defaultCourse}
                                    placeholder = {getUserDefinedName('Select Course', this.state)}
                                    onSelect = {(courseId)=>{
                                        this.props.setDefaultCourse(courseId);
                                        const payload = {
                                            relationshipId: this.props.companyInfo?.relationshipId,
                                            customerId: this.props.customerInfo?.customerId,
                                            courseId: courseId
                                        }
                                        this.props.getStudentsByCustomerIdAndCourseId(payload);
                                    }}
                                />
                            </li>

                            <li className={showUserDropdown ? 'header-option active' : 'header-option'}>
                                <SignoutForm {...this.props}  {...this.state} updateState={(data) => { this.setState(data) }} />
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="user-view-container">
                    <SidebarMenu {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />

                    <PartialViewRoute
                        {...this.state}
                        {...this.props} 
                        permissions={this.props.permissions}
                        history={this.props.history}
                        updateHeaderState={(data) => {
                            this.setState(data);
                        }} 
                        // isCollapsed={collapsed} 
                        headerHeading= {headerHeading}
                    />
                </div>


            {this.state.drawer.EmailInfoDrawerVisible && (
              <Communication
                {...this.state}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
                
            {this.state.drawer.addFamilyMemberDrawerVisible && (
              <ContactComp
                {...this.state}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}


            {this.state.drawer.educationDrawerVisible && (
              <EducationComp
                {...this.state}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}

            {this.state.drawer.employmentDrawerVisible && (
              <EmploymentComp
                {...this.state}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.companyInfo,
        customerInfo: state.common.customerInfo,
        adminToken: state.common.adminToken,
        studentFormList: state.school.studentFormList,
        defaultSchoolForm: state.common.defaultSchoolForm,
        // defaultSchoolForm: 0,
        loggedInEmail: state.common.loggedInEmail,
        isLoading: state.common.isLoading,
        isFlashVisible: state.common.isFlashVisible,
        schoolCourseList: state.school.schoolCourseList,
        defaultCourse: state.common.defaultCourse,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setLocalStorageData, 
    pushModalToStack, 
    popModalFromStack, 
    showModal, 
    hideModal, 
    setDefaultSchoolForm, 
    getAllStudentForm,
    getAllCustomerCourses,
    uploadImage,
    setFlash,
    getStudentsByCustomerId,
    setDefaultCourse,
    getStudentsByCustomerIdAndCourseId,
}, dispatch);

const filterOnBasisOfPermission = (data, primaryPermissions) => {
    let result;
    const clonedData = [...data];
    if (Array.isArray(Object.keys(data)) && data.length) {
        result = []
        data.forEach((obj, index) => {
            if (Array.isArray(obj.hierarchy) && obj.hierarchy.length) {
                clonedData[index].hierarchy = obj.hierarchy.filter(x => {
                    return checkACLPermission(primaryPermissions, x.moduleCode, x.operation);
                });
                if (clonedData[index].hierarchy.length) {
                    result.push(clonedData[index]);
                }
            }
        });
    } else {
        result = data;
    }
    return result;
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HeaderComponent));
