import React, { Fragment, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { TreeSelect} from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { showToasterMessage, checkACLPermission } from './../../utils';
import * as debounce from 'lodash.debounce';
import './general.scss';
import { PERMISSION_VALUES } from "../../static/constants";

const { TreeNode } = TreeSelect;

const TreeDropdownComp = (props, ref) => {
  const inputRef = useRef();
  let canAddNew = props.canAddNew || false;
  const [selectedProductValue, updateSelectedValue] = useState(props.selectedLabel);
  const [allOptions, updateAllOptions] = useState(props.options);
  //const [customOptions, updateCustomOptions] = useState([]);
  const [fetching, updateFetching] = useState(false);
  //const [editable, updateeditable] = useState(true);
  let [expandedKeys, updateExpandedKeys] = useState([]);
  const [isDropdownVisible, updateDropdownVisible] = useState(false);
  const operations = props.operations;
  const permissions = props.permissions || {};
  const moduleName = props.moduleName;
  const primaryPerm = permissions.primary;
  let canDelete = props.canDelete || false;
  if (canAddNew) {
    canAddNew = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.CREATE);
    
  }
  if (canDelete && Array.isArray(operations) && operations.indexOf(PERMISSION_VALUES.DELETE) > -1) {
    canDelete = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.DELETE);
  }
  
  // const updateCustomList = (itemList) => {
  //   let customeList = [];
  //   itemList && itemList.length && itemList.map((product) => {
  //       let productToCreate = {
  //         title: product.itemName,
  //         value: product.itemId,
  //         id: product.itemId,
  //         itemId: product.itemId
  //       }
  //       if (product.inventoryItemVariantList.length) {
  //         productToCreate.children = [];
  //       };
  //       product.inventoryItemVariantList.map((variant, index) => {
  //         if (variant.attributeValue1 || variant.attributeValue2 || variant.attributeValue3) {
  //           productToCreate.children.push({
  //             itemId: product.itemId,
  //             pId: product.itemId,
  //             itemName: product.itemName,
  //             title: (variant.attributeValue1 || '') +  (variant.attributeValue2 ? ' | ': '') + (variant.attributeValue2 || '') + '' + (variant.attributeValue3 ? ' | ' + variant.attributeValue3 : ''),
  //             value: Date.now() + '' + variant.variantId,
  //             variantId: variant.variantId
  //           });
  //         }
  //         return productToCreate;
  //       });
        
  //       return customeList.push(productToCreate);
  //     });
  //     updateCustomOptions(customeList);
  // } 

  //updateCustomList(allOptions);
  
let customOptions = [];
  allOptions && allOptions.length && allOptions.map((product) => {
    let productToCreate = {
      title: product.itemName,
      value: product.itemId,
      id: product.itemId,
      itemId: product.itemId
    }
    if (product.inventoryItemVariantList.length) {
      productToCreate.children = [];
    };
    product.inventoryItemVariantList.map((variant, index) => {
      if (variant.attributeValue1 || variant.attributeValue2 || variant.attributeValue3) {
        productToCreate.children.push({
          itemId: product.itemId,
          pId: product.itemId,
          itemName: product.itemName,
          title: (variant.attributeValue1 || '') +  (variant.attributeValue2 ? ' | ': '') + (variant.attributeValue2 || '') + '' + (variant.attributeValue3 ? ' | ' + variant.attributeValue3 : ''),
          value: Date.now() + '' + variant.variantId,
          variantId: variant.variantId
        });
      }
      return productToCreate;
    });
    return customOptions.push(productToCreate);
  });

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return selectedProductValue;
      },
      afterGuiAttached: () => {
        inputRef.current.focus();
      },
      isCancelAfterEnd: () => {
        if (props.lastColumnCell && props.node.lastChild) {
          props.api.updateRowData({ add: [{}] });
        }
        return false;
      }
    };
  });

  const onChange = (values, selectedOption) => {
    if (selectedOption.children && selectedOption.children.length) {
      return showToasterMessage({ messageType: 'error', description: 'Please select variant' })
    }
    let selectedProductValue = '';
    let valueArray = [selectedOption.itemId];
    if (selectedOption.variantId) {
      valueArray.push(selectedOption.variantId);
    }

    selectedProductValue = selectedOption.itemName ? selectedOption.itemName + " (" + selectedOption.title + ")" : selectedOption.title;
    updateSelectedValue(selectedProductValue);
    props.node.selectedLabel = selectedProductValue;
    props.node.selectedProductValue = valueArray;
    props.node.selectedOption = selectedOption;
    props.onSelect(selectedProductValue, selectedOption);
  }

  const filter = (inputValue, option) => {
    return option.value && option.title && option.title.toLowerCase().includes(inputValue.toLowerCase());
  }

  const onSearchInput = debounce((text) => {
    updateFetching(true);
    props.onSearch(text, updateAllOptions, updateFetching)
  }, 1000);

  // if (editable) {
  //   onSearchInput();
  //   updateeditable(false);
  // }


  const onExpand = (expandedKeys) => {
    updateExpandedKeys(expandedKeys);
  };

  const onDropdownVisibleChange = visible => {
    if (!visible) return;
    updateDropdownVisible(
      visible
    );
  };

  return (
    <TreeSelect
      showSearch={true}
      ref={inputRef}
      value={selectedProductValue || undefined}
      filterTreeNode={filter}
      open={isDropdownVisible}
      treeExpandedKeys={expandedKeys}
      onSearch={onSearchInput}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Please select"
      notFoundContent={fetching ? <Loading3QuartersOutlined className="spinnerIcon" spin /> : "No record(s) found"}
      //treeData={customOptions}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onTreeExpand={onExpand}
      onSelect={(value, node) => {
        if(node.isAddNew){
          setTimeout(()=>{
            updateSelectedValue("")
          },100);
          return props.onAddNewClick();
        }
        if (node.children && node.children.length) {
          updateDropdownVisible(true);
          const isKey = expandedKeys.indexOf(node.key) !== -1;
          if (isKey) {
            expandedKeys = expandedKeys.filter((e) => {
              return e !== node.key;
            })
            updateExpandedKeys(expandedKeys);
          } else {
            updateExpandedKeys(expandedKeys.concat(node.key));
          }
        } else {
          updateDropdownVisible(false);
          onChange(value, node);
        }
      }}>

      {
        canAddNew ?
          <Fragment>
            <div
            key ={"Add New"}
              title = {<span><i className="fa fa-plus" /> Add New</span>}
              isAddNew={true}
              style={{ padding: "4px 8px", cursor: "pointer" }}
              //onMouseDown={e => e.preventDefault()}
              onClick={() => {
                props.onAddNewClick()
              }}
              className='add-new'
            >
              
            </div>
          </Fragment>
          : ''
      }

      {
        customOptions.map((d) => {
          return <TreeNode name={d.title} key={d.itemId} value={d.value} title={d.title} id={d.id} itemId={d.itemId}>
            {(d && d.children && d.children.length ) ? d.children.map((child) => {
              return <TreeNode name={d.title} key={child.value} itemId={child.itemId} itemName={child.itemName} title={child.title} id={child.id} pId={child.pId} variantId={child.variantId} value={child.value} />
            }) : null}
          </TreeNode>
        })
      }
    </TreeSelect >
  );
}

export const TreeDropDown = forwardRef(TreeDropdownComp);