import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, LOCAL_STORAGE_KEYS} from '../../../../../static/constants';
import { showToasterMessage, fetchFromLocalStorage, addToLocalStorage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createAddress = (payload, formData) => {
 
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/createAddress`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (formData.callback) {
          formData.callback(res.data)
        }
        axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/getAddressByCustomerId?relationshipId=${payload.relationshipId}&locationType=${payload.locationType}&customerId=${payload.customerId}&pageSize=1000&pageNumber=0`)
          .then(resAddress => {
            const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
            loggedInEntityInfo.boLocationList = resAddress.data;
            dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: loggedInEntityInfo });
            addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(loggedInEntityInfo));
          })
        showToasterMessage({ messageType: 'success', description: 'Address saved successfully' });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
         // err.actionToCall = actionType;
        }
      })
  }
}