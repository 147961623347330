import { STUDENT_REGISTRATION_ACTION_LIST } from '../static/constants';
const initialState = {
  totalStudentCount: 0,
  totalStudentCountByGrade: {},
  totalStudentCountByStatus: {},
}

const StudentRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT:
      return { ...state, totalStudentCount: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE:
      return { ...state, totalStudentCountByGrade: action.data };

    default:
      return state;
  }
};

export default StudentRegistrationReducer;