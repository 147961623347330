import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContactCompo from '../../../components/modal/modalBody/common/Contact';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchCountries } from '../../../actions/commonActions';
import { createContact } from './action';
import { doesEmailExist } from '../../Signup/action'
import { fetchDataIfNeeded } from '../../../utils';
import AddFamilyMemberComp from '../../../components/Profile/AddFamilyMember';

class ContactComp extends Component {

  constructor(props){
    super(props);
    this.state = {
      countryCallingCode: null,
      countryId: 0,


    }
}
 
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId
    };
    // this.state{
    //   addFamilyMember?.allergies  : "Yes"

    // }
  //  fetchDataIfNeeded('fetchSalutation', 'salutations', this.props, payload);
    fetchDataIfNeeded('fetchCountries', 'countries', this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.countries !== this.state.countries && nextProps.countries && !this.state.countryCallingCode) {
      let obj = nextProps.countries.find(country => (country.countryName === (this.props.companyInfo).countryName)) || {};
      this.setState({ countryCallingCode: obj.countryCallingCode || '', countryId: obj.countryId});
    }
  
  }

  render() {
    return (
      // <ContactCompo {...this.props} {...this.state} />
      <AddFamilyMemberComp updateState={(data) => { this.setState(data) }} {...this.props} {...this.state} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    customerInfo: state.common.customerInfo,
    userInfo: state.common.userInfo,
    salutations: state.common.salutations,
    countries: state.common.countries,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createContact,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  fetchCountries,
  doesEmailExist,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactComp);
