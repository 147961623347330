import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import './general.scss';
export function CustomButton(props) {
  return (
    <Button
      style={props.style}
      type={props.type || 'primary'}
      htmlType={props.htmlType}
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <FormattedMessage
        id={props.intlId || 'login.submit'}
        defaultMessage=''
      />
    </Button>
  )
}