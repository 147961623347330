import axios from "axios";
import config from "../../config/environmentConfig";
import { SCHOOL_ACTIONS } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getSchoolAssignment = (payload) => {
  return (dispatch) => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignment/getByClassId?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}&classId=${payload.classId}`)
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SCHOOL_ACTIONS.ASSIGNMENT_LIST, data});
      })
      .catch((err) => {
        // showToasterMessage({
        //   messageType: "error",
        //   description:
        //     lang[((err.response || {}).data || {}).message] ||
        //     "Some error occurred",
        // });
        dispatch({ type: SCHOOL_ACTIONS.ASSIGNMENT_LIST, data:{}});
      });
  };
};

export const getSchoolAssignmentCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignment/countSchoolAssignment?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: SCHOOL_ACTIONS.ASSIGNMENT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SCHOOL_ACTIONS.ASSIGNMENT_LIST_PAGE_RESET });
  };
};

export const deleteAssignment = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignment/delete`, payload)
      .then(res => {
        // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // props.getFees && props.getFees(props);
        getSchoolAssignment({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm}, )(dispatch);
        getSchoolAssignmentCount({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm})(dispatch);
       // showToasterMessage({ messageType: 'success', description: 'Term & Fee deleted successfully' });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}