import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, DASHBOARD_ACTION_LIST } from '../../../static/constants'
//import { showToasterMessage } from '../../utils';
//const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const getBroadcastMessageByCustomerId = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/broadcastMessage/get?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&createdByEmpId=${payload.relationshipId || 2}`)
          .then(res => {
            dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            const { data } = res
            dispatch({ type: DASHBOARD_ACTION_LIST.BROADCAST_MESSAGE_LIST, data });
          })
          .catch((err) => {
            dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
             // showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = DASHBOARD_ACTION_LIST.BROADCAST_MESSAGE_LIST;
            }
          })
      }
  }